import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class AssessmentApi {
  async getAssessment(id, skillId, productId) {
    try {
        let url = `assessments?id=${id}&skillId=${skillId}&productId=${productId}`;
        const responseData = await makeRequest(
            getAPIEndpoint() + url,
            'get',
            null,
            true,
        );
        return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getSkill(id) {
    try {
      const responseData = await makeRequest(
        getAPIEndpoint() + `assessment-skill-list?id=${id}`,
        'get',
        null,
        true,
    );
    return Promise.resolve(responseData);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getProducts(id) {
    try {
      const responseData = await makeRequest(
        getAPIEndpoint() + `assessment-product-list?id=${id}`,
        'get',
        null,
        true,
    );
    return Promise.resolve(responseData);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new AssessmentApi();