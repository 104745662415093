import React, { Fragment } from 'react';
import { Button, FormGroup, TextField } from '@material-ui/core';
import useSettingPage from '../../../hooks/SettingPage/useSettingPage';
import CircularProgress from '../Common/CircularProgress';

import checkIcon from '../../../../assets/svg/check.svg';

const MyPassword = () => {
  const { validateFormik, infoResetPassword, resetInProgress, resetResultsLoaded } = useSettingPage();
  return (
    <Fragment>
      <div className="setting-title">
        Password
      </div>
      <div className="setting-subtitle setting-subtitle__password">
        You can change your password at any time to keep your account safe and secure
      </div>
      <div className="communication MyClassFriend MyPassword">
        <h3>Update your password</h3>
        <div className="MyPassword-message">Passwords must be at least 8 characters in length, have at least one uppercase and one lowercase letter, and one number</div>
        <div className="settingItem myPass">
          <form onSubmit={validateFormik.handleSubmit}>
            <FormGroup noValidate autoComplete="off" className="form-login">
              <div className="form-title">Current password</div>
              <TextField
                id={'outlined-name'}
                name={'currentPassword'}
                type="password"
                onChange={validateFormik.handleChange}
                value={validateFormik.values.currentPassword}
                className="form-login__input"
                variant="outlined"
              />
              <div className="form-title" style={{paddingTop: '20px'}}>New password</div>
              <TextField
                id={'outlined-name'}
                name={'password'}
                type="password"
                onChange={validateFormik.handleChange}
                value={validateFormik.values.password}
                className="form-login__input"
                variant="outlined"
              />
              {(validateFormik.touched.password && validateFormik.errors.password) &&
                <div className="error-block">
                  <div className="error-border"></div>
                  <div className="error-password">{validateFormik.errors.password}</div>
                </div>
              }
              <div className="form-title" style={{paddingTop: '20px'}}>Re-enter new password</div>
              <TextField
                id={'login-email outlined-name'}
                name={'rePassword'}
                type="password"
                onChange={validateFormik.handleChange}
                value={validateFormik.values.rePassword}
                className="form-login__input"
                variant="outlined"
              />
              {(validateFormik.touched.rePassword && validateFormik.errors.rePassword) &&
                <div className="error-block">
                  <div className="error-border"></div>
                  <div className="error-password">{validateFormik.errors.rePassword}</div>
                </div>
              }
            </FormGroup>
            <div className="setting-footer">
              {!resetInProgress && resetResultsLoaded && <div className={`setting-footer__status ${infoResetPassword.success === true ? 'success' : 'error'}`}>
                {infoResetPassword.success === true && <img src={checkIcon} alt="Success" />}
                <span>
                  {infoResetPassword.message}
                </span>
              </div>}
              <Button variant="contained" className="btn btn-return btn-bottom" type="submit" disabled={resetInProgress}>
                {resetInProgress && <CircularProgress />}
                {!resetInProgress && "Update"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  )
}

export default MyPassword;