import { useEffect, useState } from "react";
import { createSocketConnection } from "../../pages/private/Home/SocketService";
import { getCurrentUser } from '../../../utils/currentUser';
import { useSelector } from 'react-redux';

function listen(callBack, channel, event) {
  const host = localStorage.getItem("apiEndpointPrefix") || "";
  if (host.indexOf('.eu') !== -1) {
    return
  }
  
  window.Echo.channel(channel).listen(event, (payload) => {
    callBack(payload);
  });

  return function cleanUp() {
    window.Echo.leave(channel);
  };
}

export const useSocket = ({ callBack }) => {
  const auth = useSelector(state => state.auth) || "";
  const studentid = (auth.user.responseUserData.id);
  useEffect(() => {
    createSocketConnection();
    return listen(callBack, `britich_council_database_user-channel-${studentid}`, ".UserEvent");
    
  }, []);
};
