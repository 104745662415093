import { useState } from "react";
import {getFeedbackScreenOne, getFeedbackScreenTwo} from '../../redux/actions/Feedback/FeedbackAction';

const useFeedback = () => {
  const [feedbackDataScreenOne, setFeedbackDataScreenOne] = useState(null);
  const [feedbackDataScreenTwo, setFeedbackDataScreenTwo] = useState(null);
  const openFeedbackScreenOne = async (lessonTimeId) => {
    const feedbackScreenOne = await getFeedbackScreenOne(lessonTimeId);
    setFeedbackDataScreenOne(feedbackScreenOne);
  };
  const openFeedbackScreenTwo = async (lessonTimeId) => {
    const feedbackScreenOne = await getFeedbackScreenTwo(lessonTimeId);
    setFeedbackDataScreenTwo(feedbackScreenOne);
  };

  return {
    feedbackDataScreenOne,
    feedbackDataScreenTwo,
    openFeedbackScreenOne,
    openFeedbackScreenTwo,
  }
}

export default useFeedback;