import { useEffect, useState } from "react";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import { setStep, updateSection } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import LearningBanner from '../../../../assets/image/learning_background.png';
import ProfileCaptureApi from '../../../redux/services/profileCaptureApi';
import { handleGoBack } from "../../../../utils/helper";

const LearningPage = (props) => {
  const { history, dispatch, step, previousStep, nextStep, centreId, sections, pageOrder, profileId, setSubmitStep, setSubmitFlag, motivationId, translations } = props
  const [tempMotivationSections, setTempMotivationSections] = useState([])
  const [formValid, setFormValid] = useState(false)
  const onNext = () => {
    dispatch(setStep(nextStep))
    setSubmitStep(step)
    setSubmitFlag(true)
  }

  const onChangeText = (value) => {
    if (!value) value = ''
    const data = { ...tempMotivationSections, comment: value }
    setTempMotivationSections(data)
    dispatch(updateSection({ type: step, data: data }))
  }

  const onRating = (id, value) => {
    const data = tempMotivationSections.profile_motivation_statements.map(data => {
      if (data.id === id)
        return { ...data, value: value }
      return data
    })
    setTempMotivationSections(prevState => ({ ...prevState, profile_motivation_statements: data }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: data } }))
  }

  // Validation
  useEffect(() => {
    if (tempMotivationSections && tempMotivationSections.profile_motivation_statements?.length > 0) {
      const valid = tempMotivationSections.profile_motivation_statements.filter(item => item.value).length === tempMotivationSections.profile_motivation_statements.length
      if (valid) setFormValid(true)
      else setFormValid(false)
    }
  }, [tempMotivationSections])

  useEffect(() => {
    if (sections.length > 0) {
      const data = sections.filter(item => item.type === step)[0]
      setTempMotivationSections(data)
    }
  }, [sections, step])

  return (
    <ProfileCapturePage history={history} dispatch={dispatch} previousStep={previousStep} pageTitle={tempMotivationSections?.motivation_name || ''} centreId={centreId} step={step} profileId={profileId} bannerMaster={LearningBanner}>
      {tempMotivationSections.motivation_text && 
      <div className="rate-important-top">
        <div className="profile-capture-wrapper">
          <p className="rate-ttl">{tempMotivationSections.motivation_text}</p>
        </div>
      </div>}
      <div className="rate-important-inner">
        <div className="profile-capture-wrapper profile-capture-learning">
          <div className="rate-ttl-select">
            <div className="ttl rate-list-ttl"></div>
            <div className="rate-list-column">
            {tempMotivationSections?.profile_importance?.length > 0 && tempMotivationSections.profile_importance.map(item => {
              return (
                <div className="ttl" key={item.id}>{item.description}</div>
              )
            })}
            </div>
          </div>
          {tempMotivationSections?.profile_motivation_statements?.length > 0 && tempMotivationSections.profile_motivation_statements.map(item => {
          return (
            <div className="rate-list-inner" key={item.id}>
              <div className="rate-list-ttl rate-list">{item.learning_question_text}</div>
              <div className="rate-list-column">
                {tempMotivationSections?.profile_importance?.length > 0 && tempMotivationSections.profile_importance.map(profile => {
                  return (
                    <div
                      key={profile.id}
                      className={`rate-list ${item.value === profile.id ? 'active' : ''}`}
                      onClick={() => onRating(item.id, profile.id)}
                    >
                      <div className="rate-list-items"></div>
                    </div>
                  )
                })}
              </div>        
            </div>
          )
        })}
        </div>
        <div className="other-reason-inner">
        <div className="other-reason-w">
          <div className="type-other-social">
            <label className="lbl-other">{tempMotivationSections.motivation_comment_text}</label>
            <input
                type="text"
                className="form-control type-input"
                placeholder={ translations['[text_type_here]'] }
                onChange={e => onChangeText(e.target.value)}
                value={tempMotivationSections.comment || ''}
              />
          </div>
          <div className="select-options-inner">
            <div className="page">{pageOrder}</div>
            <div>
              <button className="btn-back-options button" onClick={() => handleGoBack(dispatch, previousStep, profileId, step, tempMotivationSections?.id)}>{ translations['[button_back]'] }</button>  
              {formValid ? 
                <button className="btn-select-options next active" type="button" onClick={onNext}>{ translations['[button_next]'] }</button>
              :
                <button className="btn-select-options" type="button">{ translations['[button_select_option]'] }</button>
              }
            </div>
          </div>
        </div>
      </div>
      </div>
    </ProfileCapturePage>
  )
}

export default LearningPage;