import React from "react";
import useCheckMobileTablet from "../../../../utils/useCheckMobileTablet";
import ProgressBar from "./ProgressBar";
import ArrowRight from "../../../components/icon/ArrowRight";
import Slider from "react-slick";
import { colorChartBarActive, colorChartBarDefault, colorChartBarProgress } from "../../../components/Colors";

const ProgressPathways = (props) => {
  const { onRedirectPage, pathways } = props;
  const { isTablet, isMobile } = useCheckMobileTablet();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    variableWidth: true,
  };
  
  return (
    <div className="content-wrapper pathways-list">
      <p className="block-title">Pathways</p>
      {!isMobile && (
        <div className="row">
          {pathways && (
            <>
              {pathways.map((item, index) => (
                <div className="col" key={index}>
                  <div className="progress-pathway-item" onClick={onRedirectPage}>
                    <div className="progress-pathway-item__wrapper">
                      <div className="title">{item.title} pathway</div>
                      <ProgressBar
                        label=""
                        total={item.totalTheme}
                        completed={item.completeTheme}
                        inProgress={item.inProgress}
                        width={isTablet ? "16.67%" : "40px"}
                        height="16px"
                        colorActive={colorChartBarActive}
                        colorDefault={colorChartBarDefault}
                        colorProgress={colorChartBarProgress}
                      />
                      <p className="progress-pathway-item__description">
                        {(item.completeTheme || item.inProgress) ? 'Your current progress with this pathway' : 'You haven’t started this pathway'}
                      </p>
                      <ArrowRight className="progress-pathway-item__icon" />
                    </div>
                    <div className="progress-pathway-item__note">
                      <div className="icon-block">
                        <span
                          className={`completed-icon icon ${item.completeTheme < 1 ? "grey" : ""}`}
                        />
                        {`${item.completeTheme}/${item.totalTheme} module${
                          item.totalTheme > 1 ? "s" : ""
                        } completed`}
                      </div>
                      {item.inProgress > 0 && (
                        <div className="icon-block">
                          <span
                            className="progress-icon icon"
                            style={{ "backgroundColor": colorChartBarProgress }}
                          ></span>
                          {`${item.inProgress} module${item.inProgress > 1 ? "s" : ""} in progress`}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
      {isMobile && 
        <>
        {pathways && (
            <div className="slider-wrapper">
              <Slider
                  {...settings}
              >   
                {pathways.map((item, index) => (
                  <div className="slide-item" key={index}>
                    <div className="progress-pathway-item" onClick={onRedirectPage}>
                      <div className="progress-pathway-item__wrapper">
                        <div className="title">{item.title} pathway</div>
                        
                        <p className="progress-pathway-item__description">
                          {(item.completeTheme || item.inProgress) ? 'Your current progress with this pathway' : 'You haven’t started this pathway'}
                        </p>
                        <ProgressBar
                          label=""
                          total={item.totalTheme}
                          completed={item.completeTheme}
                          inProgress={item.inProgress}
                          width={isTablet ? "16.67%" : "40px"}
                          height="16px"
                          colorActive="#00EDC3"
                          colorDefault="#898A8D"
                          colorProgress="#FFE300"
                        />
                        <div className="progress-pathway-item__note">
                          <div className="icon-block">
                            <span
                              className={`completed-icon icon ${item.completeTheme < 1 ? "grey" : ""}`}
                            />
                            {`${item.completeTheme}/${item.totalTheme} module${
                              item.totalTheme > 1 ? "s" : ""
                            } completed`}
                          </div>
                          {item.inProgress > 0 && (
                            <div className="icon-block">
                              <span
                                className="progress-icon icon"
                                style={{ "backgroundColor": "#FFE300" }}
                              ></span>
                              {`${item.inProgress} module${item.inProgress > 1 ? "s" : ""} in progress`}
                            </div>
                          )}
                        </div>
                        <ArrowRight className="progress-pathway-item__icon" />
                      </div>
                    </div>
                  </div>
                ))}
                
              </Slider>
            </div>
          )}
        </>
      }
    </div>
  );
};

export default ProgressPathways;
