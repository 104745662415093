import React from 'react';
import '../../../../sass/private/CirclePercent/CircularProgressBar.scss';

class LockedCircularProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const sqSize = this.props.sqSize;
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - dashArray * this.props.completeProgress / 100;
    const text = this.props.text || ((100 - this.props.percentage)/10);
    return (
      <div className="lock-circle">
        <svg
          width={this.props.sqSize}
          height={this.props.sqSize}
          viewBox={viewBox}>
          <circle
            className="locked-circle-background"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`} />
        </svg>
        <div className="lock-circle-icon"></div>
      </div>
    );
  }
}

LockedCircularProgressBar.defaultProps = {
  sqSize: 100,
  percentage: 0,
  strokeWidth: 3
};

export default LockedCircularProgressBar;