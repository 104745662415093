import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class getNotification{
    async getNotification(page, limit){
        try {
            let url = `get-student-notifications?page=${page}&limit=${limit}`;
            const responseData = await makeRequest(
                getAPIEndpoint() + url,
                'get',
                null,
                true,
            );
            return Promise.resolve(responseData);
          } catch (err) {
              return Promise.reject(err);
          }
    }

    async checkRedDot() {
        try {
            let url = `check-red-dot`;
            const responseData = await makeRequest(
                getAPIEndpoint() + url,
                'get',
                null,
                true,
            );
            return Promise.resolve(responseData);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async clearRedDot() {
        try {
            let url = `clear-red-dot`;
            const responseData = await makeRequest(
                getAPIEndpoint() + url,
                'post',
                null,
                true,
            );
            return Promise.resolve(responseData);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async dismissNotiItem(id) {
        try {
            let url = `dismiss-notification?id=${id}`;
            const responseData = await makeRequest(
                getAPIEndpoint() + url,
                'post',
                null,
                true,
            );
            return Promise.resolve(responseData);
        } catch (err) {
            return Promise.reject(err);
        }
    }
}

export default new getNotification();