import React from 'react';
import PropTypes from 'prop-types';
import arrowIcon from '../../../../assets/image/vector_indigo.png';

const NavbarMobile = (props) => {
  const { menuLists, onClickMenu } = props;

  const content = menuLists.map((item, index) => {
    return (
      <div key={index}>
        <li
          className={`${item.active ? 'active' : ''}`}
          onClick={() => onClickMenu(item.id)}
        >
          {item.value}
          <div className="arrow-icon">
            <img src={arrowIcon} alt={item.active? 'Collapse': 'Expand'} />
          </div>
        </li>
        {item.active && <div>{item.component}</div>}
      </div>
    )
  });

  return (
    <ul className="navbar">
      {content}
    </ul>
  )
}

NavbarMobile.propTypes = {
  menuLists: PropTypes.array,
}

NavbarMobile.defaultProps = {
  menuLists: [],
}

export default NavbarMobile;