import { useHistory } from "react-router";
import ReactHtmlParser from 'react-html-parser';
import { getRoute } from "../../../components/Utilities";
import ArrowRight from "../../../components/icon/ArrowRight";

export const MobileCourseItem = (props) => {
  const history = useHistory();
  const {item, lesson_name, lesson_category, lesson_count, lesson_id, lesson_type, content_type} = props;
  const routeChange = () => {
    history.push(getRoute("COURSEDETAIL") + '?id=' + lesson_id + '&content_type=' + content_type);
  };

  let totalAvailableClasses;
	if (lesson_count) {
		totalAvailableClasses = lesson_count > 9 ? "9+" : lesson_count;
	}
	let themeItem = '';
	switch (item.final_status) {
		case 'past':
			themeItem = 'lesson-theme-past';
			break;
		case 'full':
			themeItem = 'lesson-theme-full';
			break;
		case 'booked':
			themeItem = 'lesson-theme-booked';
			break;
	
		default:
			themeItem = '';
			break;
	}
  return (
    <div 
      className={`list-item-lesson lesson-theme mobile-course-item ${themeItem}`}
      onClick={(e) => { e.preventDefault(); routeChange() }}
    >
      <div className="flex-container">
        <div className="list-item-lesson__mintdash"></div>
        <p className="list-item-lesson__name secondary">{lesson_name || ""}</p>
        {/* <p className="list-item-lesson__name category">{ReactHtmlParser(lesson_category)}</p> */}
        {/* <div className="list-item-lesson__description">{lesson_count} {lesson_type || ""}</div> */}
        {totalAvailableClasses &&
          <span className="total-classes">
            {totalAvailableClasses}
          </span>
        }
        <ArrowRight className="list-item-lesson__view-detail" />
      </div>
    </div>
  )
}

export default MobileCourseItem;