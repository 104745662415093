import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class BokkingWindow {
  async getBookingWindow(lessonTimeId) {
    try {
      const url = `lesson-time-detail?lessonTimeId=${lessonTimeId}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getBookingClass(id, lessonTimeId) {
    try {
      const url = `book-class`;
      const data = {
        id: id,
        lessonTimeId: lessonTimeId,
        isNativeApp: this.isNativeApp(),
      }
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'post',
          data,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getRefundCredit(id, lessonTimeId) {
    try {
      let url = `check-cencel-class-refund?id=${id}&lessonTimeId=${lessonTimeId}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async cancelClass(id, lessonTimeId) {
    try {
      let url = `cancel-class`;
      const data = {
        id: id,
        lessonTimeId: lessonTimeId,
        isNativeApp: this.isNativeApp(),
      }
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'post',
          data,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getBookingWarning(bookWarningParam) {
    try {
      const url = `check-booking-warning${bookWarningParam}`;
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      );

      return Promise.resolve(responseData);
    }
    catch (err) {
      return Promise.reject(err);
    }
  }

  isNativeApp() {
    return window.isNativeApp === true;
  }
}

export default new BokkingWindow();