export const Centre = (props) => {
  const {
    centreActive,
    tempMotivationSections,
    centres,
    onChooseCentres
  } = props;
  
  return (
    <>
      {centreActive &&
        <>
        <div className="bg-come-class">
          <div className="profile-capture-wrapper">
            <h2 className="ttl-2">{ tempMotivationSections.centre_preferred_centre_text }</h2>
          </div>
        </div>
        <div className="profile-capture-wrapper">
          <div className="profile-capture-form">
            <div className="option-2-item-wrapper">
              {tempMotivationSections.profile_motivation_statements?.centres?.length > 0 && tempMotivationSections.profile_motivation_statements.centres.map(item => {
                return (
                  <div key={item.id} className="list-option-item">
                    <div
                      className={`option-item ${centres.includes(item.id) ? 'active' : ''}`}
                      onClick={() => onChooseCentres(item.id)}
                    >
                      <input
                        className="form-control"
                        type="checkbox"
                        readOnly={true}
                        checked={centres.includes(item.id) ? true : false}
                      />
                      <label>{item.name_en}</label>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        </>}
    </>
  )
}