import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cancelBookingAction, setOpenBookingWindow, bookingAction } from '../../redux/actions/Booking/BookingAction';

const useModalBooking = () => {
  const dispatch = useDispatch();
  const open = useSelector(state => state.booking.isOpen);
  const isShowClassBookedPage = useSelector(state => state.booking.isShowClassBookedPage);
  const isShowCancelBooking = useSelector(state => state.booking.isShowCancelBooking);

  const handleCloseDialog = () => {
    dispatch(setOpenBookingWindow(false));
  }

  const onShowModalBooking = () => {
    dispatch(setOpenBookingWindow(true));
  }

  const onCloseBooking = () => {
    dispatch(setOpenBookingWindow(false));
    dispatch(cancelBookingAction(false));
    dispatch(bookingAction(false));
  }

  return {
    open,
    handleCloseDialog,
    onShowModalBooking,
    isShowClassBookedPage,
    isShowCancelBooking,
    onCloseBooking,
  }
}

export default useModalBooking;