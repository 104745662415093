import { useRef, useEffect, useState, Fragment } from 'react';
import React from 'react';
import HelpPage from '../../components/HelpPage';

import { useHistory } from 'react-router-dom';
import '../../../sass/public/HelpCentre.scss';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Accordion,AccordionSummary,AccordionDetails  } from "@mui/material";
import useHelpPage from '../../hooks/HelpPage/useHelpPage';
import { TrackChangesSharp } from '@mui/icons-material';
import useCheckMobileTablet from "../../../utils/useCheckMobileTablet";

const Help = (props) => {
    const history = useHistory();
    const scriptRef = useRef(null);
    const { isMobile, isTablet } = useCheckMobileTablet();
    const {helpData} = useHelpPage();
    const [videoLibraryLoaded, setVideoLibraryLoaded] = useState(false);

    useEffect(() => {
      if (helpData?.video?.length && !videoLibraryLoaded) {
          setVideoLibraryLoaded(true);

          const script = document.createElement("script");
          script.setAttribute("src", 'https://static.cdn-ec.viddler.com/js/arpeggio/v3/build/main-built.js');

          document.body.appendChild(script);
          scriptRef.current = script;
      }

      return (() => {
        if (scriptRef.current) {
          window.viddlerAutoEmbedsRun = false;
        }
      });
    }, [helpData]);
    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    const [activeTab, setActiveTab] = useState(0);
    const FaqItem = (item, index) => {
        return (
            <>
            {helpData?.faq?.map((item, index) => (
                <Accordion square  expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} className='ttl-help' key={index}>
                    <AccordionSummary aria-controls={'panel' + { index } + 'd - content'} id={'panel' + { index } + 'd-header'}>
                    <Typography>{item.category_name}</Typography>
                    </AccordionSummary>
                        {item?.question_and_answer?.map((item2, index2 ) => (
                            <AccordionDetails key={index2}>
                                <div className='ttl-sub-content'>{item2.faq_question}</div>
                                <Typography dangerouslySetInnerHTML={{__html: item2.faq_answer}}>
                                    {/* {item.faq_answer} */}
                                </Typography>
                            </AccordionDetails>
                            )
                        ) }
                </Accordion>
                ))}
            </>
        )
    }

    const onClickVideoTab = (index) => {
      const video = document.getElementsByTagName('video');

      for (let i=0; i<video.length; i++) {
        video[i].pause();
      }

      setActiveTab(index);
    }

    return (
        <HelpPage history={history} helpData={helpData}>
            <div className="help-page-wrapper">
                {/* <div className="video-item">
                    <h3 className="video-title">
                        Overview
                    </h3>
                    <div className="video-wrapper">
                        <div className="viddler-auto-embed" data-embed-id="1Sb0TXPrS1" data-video-id="d3721e4c" data-width="100%" data-height={isMobile ? "160" : "540"} data-hide-controls-until-play="1" data-default-resolution="1080p" data-hd="true"></div>
                    </div>
                </div> */}
                {/* <div className="video-item">
                    <h3 className="video-title">
                        How to book a class using app 
                    </h3>
                    <div className="video-wrapper">
                        <div className="viddler-auto-embed" data-embed-id="0Q7C622YHo" data-video-id="18391865" data-width="100%" data-height={isMobile ? "160" : "540"} data-hide-controls-until-play="1" data-default-resolution="1080p" data-hd="true"></div>
                    </div>
                </div> */}
                <div className='help-centre'>
                    <h2 className='ttl-2'>Help centre</h2>
                    <p className='txt-sub'>
                    Find all the information you need to make the most out of your course by watching our videos and reading through the Frequently Asked Questions (FAQs) below.
                    <br/><br/>
                    If you have any other questions or feedback, contact your Teaching Centre.
                    </p>
                </div>
                <div className='video-wrapper'>
                    <h3 className='ttl-3'>Videos</h3>
                    {helpData?.video?.length > 0 ? (
                        <div className='video-inner'>
                            <div className='video-content'>
                                {helpData?.video?.map((item, index) => (
                                    <div className={`tab-content-item ${activeTab === index ? 'show' : ''}`} key={index}>
                                        <div className="video-wrapper">
                                            <div className="viddler-auto-embed" data-embed-id="1Sb0TXPrS1" data-video-id={item.url} data-width="100%" data-height={isMobile ? "160" : "300"} data-hide-controls-until-play="1" data-default-resolution="1080p" data-hd="true"></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <ul className='video-list'>
                            {helpData?.video?.map((item, index) => (
                                <li className={`tab-item ${activeTab === index ? 'active' : ''}`} onClick={() => onClickVideoTab(index)} key={index}>
                                    {item.title}
                                </li>
                            ))} 
                            </ul>
                        </div>
                    ) : ('')}
                </div>
                {!isTablet? (<h3 className='ttl-fqa ttl-3'>Frequently Asked Questions (FAQs)</h3>) : (<h3 className='ttl-fqa ttl-3'>FAQs</h3>)}
                <div className="help-accordion">
                    {FaqItem()}
                </div>
            </div>

        </HelpPage>
    );
};

export default Help;