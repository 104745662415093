import React, { useEffect, useRef } from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import PrivatePage from '../../../components/PrivatePage';
import IconRight from '../../../../assets/svg/arrow-navbar.svg';
import { logout } from '../../../redux/actions/auth';
import { getRoute } from '../../../components/Utilities';

import '../../../../sass/private/AccountSettingMenu/AccountSettingMenu.scss'
import { Button } from '@material-ui/core';

const listMenu = [
  {
    name: 'Settings',
    path: 'SETTING',
  },
  {
    name: 'Account and credits',
    path: 'MYACCOUNT',
  },
  {
    name: 'Achievements',
    path: 'ACHIEVEMENTSPAGE',
  },
  {
    name: 'Contact centres',
    path: 'CONTACT',
  },
  {
    name: 'Community',
    path: 'COMMUNITYPAGE',
  },
  {
    name: 'Help',
    // url: process.env.PUBLIC_URL + getRoute("HELPPAGE"),
    // target: '_blank',
    path: 'HELPPAGE',
  }
  // {
  //   name: 'Terms',
  //   path: 'SETTING',
  // },
  // {
  //   name: 'Privacy',
  //   path: 'SETTING',
  // }
]

const linkMenu = [
  {
    label: 'Terms',
    url: 'https://www.britishcouncil.org/terms',
  },
  {
    label: 'Privacy',
    url: 'https://www.britishcouncil.org/privacy-cookies',
  },
  // {
  //   label: 'Getting started',
  //   url: 'https://bcmyclass-material-public.s3.eu-west-1.amazonaws.com/MyClass_UserGuide_Dec21.pdf',
  // },
  // {
  //   label: 'Frequently asked questions',
  //   url: 'https://bcmyclass-material-public.s3.eu-west-1.amazonaws.com/MyClass_FAQs_Dec21.pdf',
  // },
  {
    label: 'Accessibility statement',
    url: 'https://bcmyclass-material-public.s3.eu-west-1.amazonaws.com/Accessibility-statement_2022.pdf',
  },
  {
    label: 'Cookies Settings',
    url: process.env.PUBLIC_URL + getRoute("COOKIESSETTINGS"),
    name: '_self',
    disabled: !(window.isNativeAppAllowTracking !== false),
  },
].filter((item) => item.disabled !== true);

const showVersion = false;

const AccountSettingMenu = (props) => {
  const { logout, history } = props;
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    }
  }, [])

  const redirectProfile = (link) => {
    history.push(getRoute(link));
  }

  const handleLogout = () => {
    logout().then(() => {
      if (isMounted.current) {
        history.push(getRoute("ROOT"));
      }
    })
  };

  const windowOpen = (url, name = "_blank") => {
    window.open(url, name);
  };

  return(
    <PrivatePage className="account-setting-menu" isShowMainNavigation={true}>
      <div className="divider"></div>
      <div className="setting-menu-title">Welcome to your account</div>
      <div className="item-setting" onClick={() => redirectProfile('PROFILE')}>
        <div className="title-setting">Profile</div>
        <div className="icon-right">
          <img src={IconRight} alt="icon right"/>
        </div>
      </div>

      {
        listMenu.map((item, index) => (

          <div className="item-setting" key={index} onClick={() => {
            if (item.target) {
              windowOpen(item.url, item.target);
            }
            else {
              redirectProfile(item.path);
            }
          }}>
            <div className="title-setting">{item.name}</div>
            <div className="icon-right">
              <img src={IconRight} alt="icon right"/>
            </div>
          </div>
        ))
      }
      {
        linkMenu.map((item, index) => (
          <div className="item-setting" key={index} onClick={() => windowOpen(item.url, item.name)}>
            <div className="title-setting">{item.label}</div>
            <div className="icon-right">
              <img src={IconRight} alt="icon right"/>
            </div>
          </div>
        ))
      }

      {showVersion && <div className="--verison">verison 1.2</div>}

      <div className="logout">
        <button className="btn-logout" onClick={handleLogout}>Log out</button>
      </div>



     
    </PrivatePage>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout
    },
    dispatch
  );
}

export default withRouter(connect(null, mapDispatchToProps)(AccountSettingMenu));

