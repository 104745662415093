import { useEffect, useState } from "react";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import { setStep, updateSection } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import InterestBanner from '../../../../assets/image/interest_background.png';
import ProfileCaptureApi from '../../../redux/services/profileCaptureApi';
import { handleGoBack } from "../../../../utils/helper";

const InterestPage = (props) => {
  const { history, dispatch, step, previousStep, nextStep, centreId, sections, pageOrder, setSubmitStep, setSubmitFlag, profileId, motivationId, translations } = props
  const [tempMotivationSections, setTempMotivationSections] = useState([])
  const [formValid, setFormValid] = useState(false)

  const onNext = () => {
    dispatch(setStep(nextStep))
    setSubmitStep(step)
    setSubmitFlag(true)
  }

  const onChangeText = (value) => {
    if (!value) value = ''
    const data = { ...tempMotivationSections, comment: value }
    setTempMotivationSections(data)
    dispatch(updateSection({ type: step, data: data }))
  }

  const onSelect = (id, value) => {
    const data = tempMotivationSections.profile_motivation_statements.map(data => {
      if (data.id === id)
        return { ...data, checked: value }
      return data
    })
    setTempMotivationSections(prevState => ({ ...prevState, profile_motivation_statements: data }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: data } }))
  }

  const validateFormValid = (data) => {
    let examTypes = data?.profile_motivation_statements.filter(item => item.checked === true) ?? [];
    if(examTypes.length > 0 || (typeof data.comment != 'undefined' && data.comment != '')) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }

  // Validation
  useEffect(() => {
    if (tempMotivationSections && tempMotivationSections.profile_motivation_statements?.length > 0) {  
      let interests = tempMotivationSections?.profile_motivation_statements.filter(item => item.checked == true) ?? [];
      if(interests.length > 0 || (typeof tempMotivationSections.comment != 'undefined' && tempMotivationSections.comment != '')) {
        setFormValid(true);
      } else {
        setFormValid(false);
      }
    }

  }, [tempMotivationSections])

  useEffect(() => {
    if (sections.length > 0) {
      const data = sections.filter(item => item.type === step)[0]
      setTempMotivationSections(data)
    }
  }, [sections, step])

  return (
    <ProfileCapturePage history={history} dispatch={dispatch} previousStep={previousStep} pageTitle={tempMotivationSections?.motivation_name || ''} centreId={centreId} step={step} profileId={profileId} bannerMaster={InterestBanner}>
      {tempMotivationSections?.motivation_text && 
      <div className="rate-important-top">
        <div className="profile-capture-wrapper">
          <p className="rate-ttl">{tempMotivationSections?.motivation_text}</p>
        </div>
      </div>}
      <div className="profile-capture-wrapper">
        <div className="profile-capture-form">
          <label className="lbl-other">{ translations['[motivate_list_heading]'] }</label>
          <div className="option-2-item-wrapper">
            {tempMotivationSections?.profile_motivation_statements?.length > 0 && tempMotivationSections?.profile_motivation_statements.map(item => {
              return (
                <div className="list-option-item" key={item.id}>
                  <div className={`option-item ${item.checked ? 'active' : ''}`}  onClick={() => onSelect(item.id, !item.checked)}>
                    <input className="form-control" type="checkbox" checked={item.checked ? true : false} readOnly={true}/>
                    <label>{item.interest_name}</label>
                  </div>
                </div>
              )
            })
            }
          </div>
        </div>
      </div>
      <div className="other-reason-inner">
        <div className="other-reason-w">
          <div className="type-other-social">
            <label className="lbl-other">{tempMotivationSections?.motivation_comment_text}</label>
            <input
                type="text"
                className="form-control type-input"
                placeholder={ translations['[text_type_here]'] }
                onChange={e => onChangeText(e.target.value)}
                value={tempMotivationSections?.comment || ''}
              />
          </div>
          <div className="select-options-inner">
            <div className="page">{pageOrder}</div>
            <div>
              <button className="btn-back-options button" onClick={() => handleGoBack(dispatch, previousStep, profileId, step, tempMotivationSections?.id)}>{ translations['[button_back]'] }</button>  
              {formValid ? 
              <button className="btn-select-options next active" type="button" onClick={onNext}>{ translations['[button_next]'] }</button>
              :
              <button className="btn-select-options" type="button">{ translations['[button_select_option]'] }</button>
              }
            </div>
          </div>
        </div>
      </div>
    </ProfileCapturePage>
  )
}

export default InterestPage;