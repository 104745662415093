import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class CourseDetailsPageApi {
  async getCourseDetails(student_id, centre_id, course_id, course_type) {
    try {
      let url = `course-detail?student_id=${student_id}&centre_id=${centre_id}&course_id=${course_id}&course_type=${course_type}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }   
  }

  async addFavorite(course_id) {
    try {
      let url = `add-favorite-class/${course_id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }   
  }

  async removeFavorite(course_id) {
    try {
      let url = `delete-favorite-class/${course_id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }   
  }
}

export default new CourseDetailsPageApi();