import Clock from '../../../components/icon/Clock';
import MapPin from '../../../components/icon/MapPin';
import User from '../../../components/icon/User';

export default function LocationTeacherDisplay(props) {
  const time = props.time || "";
  const attendedTime = props.attended_time || "";
  const location = props.location || props.centre || "";
  const room = props.room || "";
  const teacher = props.teacher || "";

  return (
    <span className="location-teacher-display">
      {time !== "" &&
        <div>
          <Clock className="secondary" />
          <span>{
            attendedTime ? (time + attendedTime) : time
          }</span>
        </div>
      }
      {location !== "" &&
        <div>
          <MapPin className="secondary" />
          <span>{location + (room? (', ' + room): '')}</span>
        </div>
      }
      {teacher !== "" &&
        <div>
          <User className="secondary" />
          <span>{teacher}</span>
        </div>
      }
    </span>
  );
}
