import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProgressAction, getProgressProductList, getProgressPathwayAction, getProgressPerformanceAction, getProgressMilestonesAction, getProgressproficiencyAction, getProgressPaceAction } from '../../redux/actions/ProgressPage/ProgressPageAction';
import { getCurrentUser } from '../../../utils/currentUser';

const useProgressPage = () => {
  const data = useSelector(state => state.progress.data);
  const productProgressList = useSelector(state => state.progress.productList);
  const pathwayList = useSelector (state => state.progress.pathwayList);
  const performanceList = useSelector (state => state.progress.performanceList);
  const milestonesList = useSelector (state => state.progress.milestonesList);
  const proficiencyData = useSelector (state => state.progress.proficiencyData);
  const paceChart = useSelector (state => state.progress.paceChart);
  const [productId, setProductId] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [lazyLoading, setLazyLoading] = useState(true);
  const [chartIsLoading, setChartIsLoading] = useState(true);
  const [typeChart, setTypeChart] = useState('1W');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProgressProductList()).then((response) => {
      if (response?.success) {
        // setIsLoading(false)
        if (Array.isArray(response.data) && response.data.length) {
          onChangeOption("", response.data[0].productGroupId);
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const onChangeOption = (name, id) => {
    if (id !== productId) {
      setIsLoading(true)
      Promise.all([
        dispatch(getProgressAction(id)),
        dispatch(getProgressPathwayAction(id)),
        dispatch(getProgressPerformanceAction(id)),
        dispatch(getProgressMilestonesAction(id)),
        dispatch(getProgressproficiencyAction()),
        dispatch(getProgressPaceAction(id, typeChart))
      ]).then(()=> setIsLoading(false))
      setProductId(id);
    }
  }

  const onSetTypeChart = (type) => {
    setTypeChart(type);
  }

  return {
    data,
    productList: productProgressList.map(({name: name, productGroupId: id, ...rest}) => ({
      name,
      id,
      ...rest,
    })),
    pathwayList: pathwayList.map(({title: title, ...rest}) => ({
      title,
      ...rest,
    })),
    // performanceList: performanceList.map(({title: title, ...rest}) => ({
    //   title,
    //   ...rest,
    // })),
    performanceList,
    milestonesList,
    proficiencyData,
    paceChart,
    productId,
    onChangeOption,
    isLoading,
    lazyLoading,
    chartIsLoading,
    typeChart,
    onSetTypeChart,
  }
}

export default useProgressPage;