import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class MyclassFriend {
  async getMyclassFriend() {
    try {
      const responseData = await makeRequest(
        getAPIEndpoint() + `list-friend`,
        'get',
        null,
        true,
    );
    return Promise.resolve(responseData);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async sendEmailInvitation(email) {
    try {
      let url = `add-friend?email=${email}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async handleActionInvitation(friend, action) {
    try {
      let url = `friend-request/${friend.id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        {action},
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new MyclassFriend();