import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addSkillAssessment } from '../../../redux/actions/assessment';
import { getRoute } from '../../../components/Utilities';
import LatestScore from './LatestScore';
import PerformanceCard from './PerformanceCard';
import '../../../../sass/private/Progress/LatestScores.scss';

const Performance = (props) => {
    const {isMobile, score, performanceList} = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const onRedirectPage = (id) => {
        dispatch(addSkillAssessment(id));
        history.push(getRoute("ASSESSMENT"));
    };
    const onItemClick = (id) => {
        history.push(`/assessment/${id}`);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    variableWidth: true,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    variableWidth: true,
                },
            },
        ],
    };

    const generatePerformance = () => {
        if (!performanceList) return '';
        const listItems = Object.keys(performanceList).map((key, idx) =>
            <div className="assessments-list-block" key={idx}>
                {key != "" ? <p className={`block-title ${performanceList[key].length === 0 ? 'ttl-no-data' : ''}`}>{performanceList[key].length === 0 ?  `Latest ${key.toLocaleLowerCase()} assessments` : `Latest ${key.toLocaleLowerCase()} assessments`}<div className='no-data'>You have no {key.toLocaleLowerCase()} assessments yet. </div></p> : <p></p>}
                
                <div className="row">
                    {performanceList[key]?.map((it, index) => {
                        return (
                            <div className="col" key={index}>
                                <PerformanceCard item={it} skill={key.toLocaleLowerCase()} onItemClick={onItemClick}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
        return listItems;
    }

    const generatePerformancemb = () => {
        if (!performanceList) return '';
        const listItems = Object.keys(performanceList).map((key) =>
            performanceList[key]?.map((it, index) => {
                return (
                    <PerformanceCard key={index} item={it} skill={key.toLocaleLowerCase()} onItemClick={onItemClick}/>
                )
            })
        );
        return listItems;
    }
    return (
        <div className="block-content block-content-performance">
            <div className={`content-wrapper-performance ${performanceList?.length === 0 ? "hidden-line" : ''}`}>
                 <h4 className="section-title">Performance</h4>
                 {performanceList && (

                    <div className="content-wrapper assessments-list">
                        <div className='performance-inner'>
                            {!isMobile && (
                                <>
                                    {generatePerformance()}
                                </>
                            )}
                            {isMobile && (
                                <>
                                    {performanceList?.length == 0 ? <p className='block-title'>Latest assessments </p>: <p className='block-title ttl-no-data'>Latest assessments <div className='no-data'>You have no assessments yet. </div> </p>}
                                    <div className="slider-wrapper">
                                    <Slider
                                            {...settings}
                                        >
                                            {generatePerformancemb()}
                                        </Slider>
                                        
                                    </div>
                                </>
                            )}
                        </div>
                        <Button
                            variant="contained"
                            className={`btn btn-default btn-progress-primary ${performanceList?.length === 0 ? "hidden-button" : ''}`}
                            onClick={() => onRedirectPage(0)}
                        >
                            View all
                        </Button>
                    </div>
                 )}
 
            </div>
            
            {score && (
                    <div className="content-wrapper latest-scores-inner">
                        <LatestScore score={score} isMobile={isMobile}/>
                    </div>
                )
            }
            {/* {score.map(i => i.lessonScore.map(j => {
                j.score !== "" && (
                    <div className="content-wrapper latest-scores-inner">
                        <LatestScore score={score} isMobile={isMobile}/>
                    </div>
                )
            }))} */}
        </div>
    );
};

export default Performance;