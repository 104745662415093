import '../../../../sass/private/Home/BookedClassesMobile.scss'
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Collapse, FormControlLabel, Switch, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ItemLessonMobile } from './ItemLessonMobile';
import { useDispatch, useSelector } from 'react-redux';
import { myScheduleCalendarAction } from '../../../redux/actions/Calendar/CalendarAction';
import { setOpenBookingWindow } from '../../../redux/actions/Booking/BookingAction';
import useModalBooking from '../../../hooks/ModalBooking/useModalBooking';
import BookingPage from '../Booking/BookingPage';
import ClassBookedPage from '../Booking/ClassBooked';
import CancelBooking from '../Booking/Cancel';
import Modal from '../../private/Dialog/Modal';
import { getRoute } from '../../../components/Utilities';
import CircularProgress from '../Common/CircularProgress';
import PastClassesMobile from './PastClassesMobile';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Question from '../../../components/icon/Question';
import ModuleBookingMobile from '../Module/ModuleBookingMobile';

export const BookedClassesMobile = (props) => {
  const { landingData, isLoadingLandingData, reloadData, dataReloadTokenOthers, handleTabClick } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  
  const mySchedule = useSelector(state => state.calendar.mySchedule);

  const [isLoadingSchedule, setIsLoadingSchedule] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isMounted && dataReloadTokenOthers) {
      getSchedule(false);
    }
  }, [dataReloadTokenOthers]);

  useEffect(() => {
    getSchedule();
    setIsMounted(true);
  }, []);

  const getSchedule = (showLoading = true) => {
    if (showLoading) {
      setIsLoadingSchedule(true);
    }

    dispatch(myScheduleCalendarAction()).then((response) => {
      if (showLoading && response?.success) {
        setIsLoadingSchedule(false);
      }
    });
  };

  const {
    open,
    handleCloseDialog,
    isShowClassBookedPage,
    isShowCancelBooking,
    onCloseBooking
  } = useModalBooking();

  const onClickLesson = (lessonTimeId) => {
    history.push(`/home/${lessonTimeId}`);
    dispatch(setOpenBookingWindow(true));
  }

  const showCircularProgress = isLoadingLandingData || isLoadingSchedule;

  const onClickBookClass = () => {
    history.push(getRoute("BOOKING"));
  };
  const onClickHelp = () => {
    window.open(process.env.PUBLIC_URL + getRoute("HELPPAGE"), "_blank");
  };

  const actualReloadData = () => {
    reloadData();
    getSchedule();
  };

  // const actualMySchedule = (mySchedule || []).slice(1); // remove 1st upcoming class(next class) from My schedule display
  const actualMySchedule = mySchedule || [];

  const [moduleID, setModuleID] = useState();
  const [isOpenModuleDetail, setIsOpenModuleDetail] = useState(false);
  const handleOpenModuleDetails = (id) => {
    setIsOpenModuleDetail(true);
    setModuleID(id)
  }
  const handleCloseModuleDialog = () => {
    setIsOpenModuleDetail(false);
  }
  
  return (
  <div style={{'minHeight': "100px"}}>
    {showCircularProgress && <CircularProgress />}
    {!showCircularProgress && <div className="booked-classes">
      {
        !landingData.nextClass && actualMySchedule.length === 0 ? (
          <div className="booked-classes__section">
            <div div className="--title">Your booked classes (0)</div>
            <p className="body-text">You do not have any modules booked.</p>
            <div className="booked-classes__section__buttons">
              {landingData?.module_tab?.show_module_tab && 
                <Button variant="contained" className="btn btn-book-mobile" onClick={() => handleTabClick('Modules')}>+ Book a module</Button>
              }
              <Button variant="contained" className="btn btn-book-mobile" onClick={onClickBookClass}>+ Book a class</Button>
            </div>
          </div>
        ) : (
          <>
            {/* <div className="booked-classes__section">
              <div className="--title">Your next class</div>
              <div className="--list-section">
                <ItemLessonMobile
                  nameLesson={landingData?.nextClass?.title}
                  time={landingData?.nextClass?.infoLines?.title}
                  teacher={landingData?.nextClass?.infoLines?.centreTeacherName}
                  borderColor={landingData?.nextClass?.colorHex}
                  lessonTimeId={landingData?.nextClass?.lessonTimeId}
                  showJoinNow={landingData?.nextClass?.actionArea?.showButtonJoinClass}
                  joinLink={landingData?.nextClass?.actionArea?.linkJoinClass}
                  joinNowText={landingData?.nextClass?.actionArea?.textJoinClassForLessonBlock}
                  onClickLesson={onClickLesson}
                  showIconUsers={landingData?.nextClass?.iconSet?.friend?.showIcon}
                  showIconStar={landingData?.nextClass?.iconSet?.wishList?.showIcon}
                  showIconLevel={landingData?.nextClass?.otherLevel}
                  showIconWorkplace={landingData?.nextClass?.iconSet?.workPlace?.showIcon}
                  showIconTick={landingData?.nextClass?.ticked}
                  availabilityText={landingData?.nextClass?.infoLines?.availability}
                  showAlertIcon={landingData?.nextClass?.infoLines?.fullWarningIcon}
                  module={landingData?.nextClass?.module}
                  theme={landingData?.nextClass?.theme_object}
                />
              </div>
            </div> */}

            {actualMySchedule.length > 0 && <div className="booked-classes__section">
              <div className="--title">Your booked classes ({landingData?.bookedClassNumber || 0})</div>
              <div className="--list-section">
                {
                  actualMySchedule.map((item, index) => (
                    <ItemLessonMobile
                      key={index}
                      nameLesson={item?.title}
                      time={item?.infoLines?.title}
                      teacher={item?.infoLines?.centreTeacherName}
                      borderColor={item?.colorHex}
                      lessonTimeId={item?.lessonTimeId}
                      showJoinNow={item?.actionArea?.showButtonJoinClass}
                      joinLink={item?.actionArea?.linkJoinClass}
                      joinNowText={item?.actionArea?.textJoinClassForLessonBlock}
                      onClickLesson={onClickLesson}
                      showIconUsers={item.iconSet?.friend?.showIcon}
                      showIconStar={item.iconSet?.wishList?.showIcon}
                      showIconLevel={item.otherLevel}
                      showIconWorkplace={item.iconSet?.workPlace?.showIcon}
                      showIconTick={item.ticked}
                      availabilityText={item.infoLines?.availability}
                      showAlertIcon={item.infoLines?.fullWarningIcon}
                      module={item.module}
                      theme={item.theme_object}
                      themeBook={item.is_booked_with_module}
                    />
                  ))
                }
              </div>
            </div>}
          </>
        )
      }
      
      <div className='collapse-past-class' onClick={() => {setIsChecked((prev) => !prev)}}>
        <span>Your past classes ({landingData?.pastClassesNumber || 0})</span>
        <span className='expand-past-class'>
          {isChecked ? 'Hide classes' : 'Show classes'} 
          {isChecked ? <ExpandLess/> : <ExpandMore/>}
        </span>
      </div>
      
      <Collapse className='past-class-mobile' in={isChecked}>
        <PastClassesMobile dataReloadTokenOthers={dataReloadTokenOthers} />
      </Collapse>   
    </div>}
    <Modal
      open={open}
      handleCloseDialog={handleCloseDialog}
      useThemeClasses={true}
      className="booking-modal"
    >
      {(!isShowClassBookedPage && !isShowCancelBooking) &&
        <BookingPage isShowHeader={true} onCloseBooking={onCloseBooking} reloadData={actualReloadData} handleOpenModuleDialog={handleOpenModuleDetails} />
      }
      {(isShowClassBookedPage && !isShowCancelBooking) &&
        <ClassBookedPage onRedirectPage={onCloseBooking} reloadData={actualReloadData} />
      }
      {(isShowCancelBooking && !isShowClassBookedPage) &&
        <CancelBooking onCloseBooking={onCloseBooking} reloadData={actualReloadData} />
      }
    </Modal>
    <Modal
        open={isOpenModuleDetail}
				useThemeClasses={true}
        handleCloseDialog={handleCloseModuleDialog}
        className={`booking-modal`}
      >
      <ModuleBookingMobile id={moduleID} reloadData={actualReloadData} handleCloseDetails={handleCloseModuleDialog} /> 
    </Modal>
    <Button variant="contained" className="btn-help" onClick={onClickHelp}>
      <Question />
    </Button>
    
  </div>
  )
}

export default BookedClassesMobile