import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useQuery from '../../../utils/useQuery';
import { resetFeedback } from '../../redux/actions/Feedback/FeedbackAction';


const useRedirectFeedback = () => {
  const dispatch = useDispatch();
  const [openFeedbackTwo, setOpenFeedbackTwo] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [openCloseConfirmation, setOpenCloseConfirmation] = useState(false);
  const [openFeedbackOne, setOpenFeedbackOne] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [isRated, setIsRated] = useState(false);
  
  const handleCloseFeedbackOne = () => {
    if (isRated) {
      setOpenCloseConfirmation(true);      
    } else {
      setOpenFeedbackOne(false);
    }
  }
  
  const handleCloseFeedbackTwo = () => {
    setOpenCloseConfirmation(true);
  }
  
  const handleCloseComplete = () => {
    setOpenComplete(false);
    setCurrentId(null);
  }
  
  const goToFeedBackTwo = () => {
    setOpenFeedbackOne(false);
    setOpenFeedbackTwo(true);
  }
  
  const goToComplete = () => {
    setOpenComplete(true);
    setOpenFeedbackTwo(false);
  }
  
  const onCloseConfirm = () => {
    setOpenFeedbackOne(false);
    setOpenFeedbackTwo(false);
    setOpenComplete(false);
    setOpenCloseConfirmation(false);
    dispatch(resetFeedback());
    setCurrentId(null);
    setIsRated(false);
  }
  
  const onContinue = () => {
    setOpenCloseConfirmation(false);
  }
    
  return {
    openFeedbackTwo,
    openComplete,
    isRated,
    openCloseConfirmation,
    openFeedbackOne,
    currentId,
    setOpenFeedbackOne,
    setIsRated,
    setCurrentId,
    handleCloseFeedbackOne,
    handleCloseFeedbackTwo,
    handleCloseComplete,
    goToFeedBackTwo,
    goToComplete,
    onCloseConfirm,
    onContinue
  }
}

export default useRedirectFeedback;