import { ActionTypes } from '../actions/types';

const initialState = {
  data: [],
  skill: [],
  product: [],
  dataFilters: {
      skill: 0,
      product: 0
  },
  currentSkill: 0
};

export default function assessmentReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case ActionTypes.GET_ASSESSMENT_SUCCESS:
            return {...state, data: payload.data};

        case ActionTypes.GET_ASSESSMENT_FAIL:
            return {error: payload};

        case ActionTypes.GET_SKILL_SUCCESS:
            return {...state, skill: payload.data}; 
        case ActionTypes.GET_SKILL_FAIL:
            return {error: payload};
        case ActionTypes.GET_PRODUCT_SUCCESS:
            return {...state, product: payload.data}; 
        case ActionTypes.GET_PRODUCT_FAIL:
            return {error: payload};
        case ActionTypes.ADD_OPTION_ASSESSMENT_SUCCESS:
            return {...state, dataFilters: payload.data}
        case ActionTypes.ADD_SKILL_ASSESSMENT_SUCCESS:
            return {...state, currentSkill: payload.data}
        default:
            return state;
    }
}