import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import practiceApi from "../../redux/services/practiceApi";
import { updateSection } from "../../redux/actions/ProfileCapture/profileCaptureAction";

const usePractice = () => {
  const TODO = 'toDo';
  const INPROGRESS = 'inProgress';
  const DONE = 'done';
  
  const [isLoading, setIsLoading] = useState(true)  
  const [isLoadingMoreToDo, setIsLoadingMoreToDo] = useState(false)  
  const [isLoadingMoreInProgress, setIsLoadingMoreInProgress] = useState(false)  
  const [isLoadingMoreDone, setIsLoadingMoreDone] = useState(false)  
  const [showLoadMoreButtonToDo, setShowLoadMoreButtonToDo] = useState(false)
  const [showLoadMoreButtonInProgress, setShowLoadMoreButtonInProgress] = useState(false)
  const [showLoadMoreButtonDone, setShowLoadMoreButtonDone] = useState(false)
  const [pageToDo, setPageToDo] = useState(1)
  const [pageInProgress, setPageInProgress] = useState(1)
  const [pageDone, setPageDone] = useState(1)
  const [practiceList, setPracticeList] = useState([])
  
  const getData = async () => {
    setIsLoading(true);
    await practiceApi.getPracticeList().then(result => {
      if(result?.data?.done?.hasShowMore) {
        setShowLoadMoreButtonDone(true);
      }
      if(result?.data?.inProgress?.hasShowMore) {
        setShowLoadMoreButtonInProgress(true);
      }
      if(result?.data?.toDo?.hasShowMore) {
        setShowLoadMoreButtonToDo(true);
      }
      setPageToDo(1);
      setPageInProgress(1);
      setPageDone(1);
      setPracticeList(result.data ?? [])
      setIsLoading(false)
    }).catch(e => {
      setIsLoading(false)
    })
  }
  
  const loadMore = async (type) => {
    let page = '';
    switch(type) {
      case TODO:
        page = pageToDo + 1;
        setIsLoadingMoreToDo(true);
        setPageToDo(page);
        break;
      case INPROGRESS:
        page = pageInProgress + 1;
        setIsLoadingMoreInProgress(true);
        setPageInProgress(page);
        break;
      case DONE:
        page = pageDone + 1;
        setIsLoadingMoreDone(true);
        setPageDone(page);
        break;
    }
    await practiceApi.getPracticeListLoadMore(type, page).then(result => {
      setPracticeList(practiceList)
      switch(type) {
        case TODO:
          result?.data?.lessons.map((item) => {
            practiceList.toDo.lessons.push(item);
          })
          setIsLoadingMoreToDo(false);
          setShowLoadMoreButtonToDo(result?.data?.hasShowMore);
          break;
        case INPROGRESS:
          result?.data?.lessons.map((item) => {
            practiceList.inProgress.lessons.push(item);
          })
          setIsLoadingMoreInProgress(false);
          setShowLoadMoreButtonInProgress(result?.data?.hasShowMore);
          break;
        case DONE:
          result?.data?.lessons.map((item) => {
            practiceList.done.lessons.push(item);
          })
          setIsLoadingMoreDone(false);
          setShowLoadMoreButtonDone(result?.data?.hasShowMore);
          break;
      }
    }).catch(e => {
      switch(type) {
        case TODO:
          setIsLoadingMoreToDo(false);
          break;
        case INPROGRESS:
          setIsLoadingMoreInProgress(false);
          break;
        case DONE:
          setIsLoadingMoreDone(false);
          break;
      }
    })
  }
  
  useEffect(() => {
    getData();
  }, [])
  
  return {
    isLoading,
    isLoadingMoreToDo,
    isLoadingMoreInProgress,
    isLoadingMoreDone,
    practiceList,
    loadMore,
    showLoadMoreButtonToDo,
    showLoadMoreButtonInProgress,
    showLoadMoreButtonDone,
    TODO,
    INPROGRESS,
    DONE,
    getData
  }
}

export default usePractice;