import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import '../../../../sass/private/Progress/_pace.scss';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { getProgressPaceAction } from '../../../redux/actions/ProgressPage/ProgressPageAction';
import CircularProgress from '../Common/CircularProgress';


const Pace = (props) => {
  const dispatch = useDispatch();

  const {productId, typeChart, paceChart, onSetTypeChart, totalChart, upArr} = props;
  const [formatData, setFormatData] = useState([]);
  const [maxValue, setMaxValue] = useState(5);
  const [currentRate, setCurrentRate] = useState(1);

  const tabList = [ "1W", "4W", "12W"];
  const [chartIsLoading, setChartIsLoading] = useState(false);
 

  useEffect(() => {
    setFormatData([]);
      if (typeChart) {
        dispatch(getProgressPaceAction(productId, typeChart));
        setChartIsLoading(true);
      }
  }, [typeChart]);

  useEffect(() => {
    if (paceChart) {}
    else {
      return false;
    }
    let finalData = [];
    if (typeChart === '1W') {
      Object.keys(paceChart?.chart).forEach((dateKey, index) => {
        finalData.push(paceChart?.chart[dateKey]);
        setChartIsLoading(false);
      });
    } else if (typeChart === '4W') {
      let tempData2 = [];
      Object.keys(paceChart?.chart).forEach((dateKey, index) => {
        tempData2.push(paceChart?.chart[dateKey]);
        if (paceChart?.chart[dateKey]?.weekday_short === 'Sun' || index === Object.keys(paceChart?.chart).length - 1) {
          finalData.push(tempData2);
          tempData2 = [];
          setChartIsLoading(false);
        }
      });
    } else if (typeChart === '12W') {
      let tempData3 = [];
      let k = 0;
      paceChart?.chart.forEach((item, index) => {
        if (k < 4 && k < 3) {
          tempData3.push(item);
          k++;
          setChartIsLoading(false);
        } else if (k === 3) {
          tempData3.push(item);
          finalData.push(tempData3);
          tempData3 = [];
          k = 0;
        }
      });
    }
    setFormatData(finalData);
    let max = 4;
    if (paceChart?.chart && Array.isArray(paceChart?.chart)) {
      max = Math.max(...paceChart?.chart.map(o => o.count));
    } else if (paceChart?.chart) {
      max = Math.max(...Object.values(paceChart?.chart).map(o => o.count));
    }
    setCurrentRate(Math.ceil(max / 4) > 1 ? Math.ceil(max / 4) : 1);
  }, [paceChart]);

  // if (!paceChart) {
  //   return "";
  // }
  return (
    <div className="block-pace">
      
      <p className="block-title">Pace</p>
        <div className="row">
          <div className="col">
            <div className="progress-pace">
    
            {/* {chartIsLoading && <CircularProgress />} */}
            
              <div className={`progress-pace-item__inner`}>
                {typeChart === '1W' ? (
                  <>
                    <div className="progress-pace-item__wrapper">
                      <div className="progress-pace-list">
                        <div className="tll-pace ttl-class">Classes attended </div>
                        {[...Array(6).keys()].map((key, index) => (
                          <div className="progresspace-item tll" key={index}><span className="progresspace-number">{key * currentRate}</span></div>
                        ))}
                      </div>
                      <div className="progress-pace-inner">
                        {formatData.map((item, index) => (
                          <div key={index} className="progress-pace-list week">
                            <div className="tll-pace">{item.weekday_short}</div>
                            <div className="progresspace-item">
                              <span className={`progresspace-number ${item.count > 0 ? "active" : ''}`} style={{ height: ((item.count / currentRate) * 21) + 'px' }}>
                              {item.count > 0 ? <span className="hover" MouseOver={item.count}>{item.count}</span> : ""}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={`ttl-attended ${upArr ? "active" : ''}`}>{totalChart}{` class${totalChart > 1 ? "es" : ""} attended this week`}<span className="icon-down"></span></div>
                  </>
                  
                ) : typeChart === '4W' ? (
                  <>
                      <div className="progress-pace-item__wrapper progress-pace-4w pace-4w">
                          <div className="progress-pace-list progress-tll">
                            <div className="tll-pace ttl-class">Classes attended</div>
                            {[...Array(6).keys()].map((key, index) => (
                              <div className="progresspace-item tll" key={index}><span className="progresspace-number">{key * currentRate}</span></div>
                            ))}
                          </div>
                          {formatData.map((item, index) => (
                            <div key={index} className="progress-pace-inner">
                              {Array.isArray(item) ? item?.map((it, i) => (
                                <div key={i} className="progress-pace-list week">
                                  <div className="tll-pace">{it.weekday_short === 'Mon' ? 'M' : ''}</div>
                                  <div className="progresspace-item">
                                    <span className={`progresspace-number ${it.count > 0 ? "active" : ''}`} style={{ height: ((it.count / currentRate) * 20) + 'px' }}>
                                      {it.count > 0 ? <span className="hover" MouseOver={it.count}>{it.count}</span> : ""}
                                    </span>
                                  </div>
                              </div>
                              )) : ''}
                            </div>
                          ))}
                        </div>
                        <div className={`ttl-attended ${upArr ? "active" : ''}`}>{totalChart}{` class${totalChart > 1 ? "es" : ""} attended this period`}<span className="icon-down"></span></div>
                  </>
                  
                ) : (
                  <>
                    <div className="progress-pace-item__wrapper progress-pace-4w progress-pace-12w">
                      <div className="progress-pace-list">
                        <div className="tll-pace ttl-class">Classes attended</div>
                        {[...Array(6).keys()].map((key, index) => (
                          <div className="progresspace-item tll" key={index}><span className="progresspace-number">{key * currentRate}</span></div>
                        ))}
                      </div>
                      {formatData.map((item, index) => (
                        <div key={index} className="progress-pace-inner">
                          {Array.isArray(item) ? item?.map((it, i) => (
                            <div key={i} className="progress-pace-list week">
                              <div className="tll-pace">{i === 1 ? 'Weeks ' + ((index * 4) + 1) + '-' + ((index * 4) + 4) : ''}</div>
                              <div className="progresspace-item">
                                <span className={`progresspace-number ${it.count > 0 ? "active" : ''}`} style={{ height: ((it.count / currentRate) * 21) + 'px' }}>
                                  {it.count > 0 ? <span className="hover" MouseOver={it.count}>{it.count}</span> : ""}
                                </span>
                              </div>
                              
                          </div>
                          )) : ''}
                        </div>
                      ))}
                    </div>
                    <div className={`ttl-attended ${upArr ? "active" : ''}`}>{totalChart}{` class${totalChart > 1 ? "es" : ""} attended this period`}<span className="icon-down"></span></div>
                  </>
                )}
              </div>
              <div className="progress-pace-item-note">
                <div className="progress-pace-item__tab tabs-list">
                   {tabList.map((item) => (
                      <>
                        <button
                        key={item.id}
                        className={`tab-item ${typeChart === item ? "active" : ""}`}
                        type="button"
                        disabled={chartIsLoading}
                        onClick={() => onSetTypeChart(item)}
                        >
                        {item}
                        </button>
                        {/* {formatData.map((item, index) => (
                          <>
                            <div key={index} className={`ttl-attended ${item.total > 0  ? "active" : ''}`}>{totalChart} classes attended this week<span className="icon-down"></span></div>
                          </>
                        ))} */}
                        {/* <div key={index} className={`ttl-attended ${totalChart > 0  ? "active" : ''}`}>{totalChart} classes attended this week<span className="icon-down"></span></div> */}
                        
                      </>
                   ))}
                </div>
                
              </div>
            </div>
          </div>
        </div>
     
    
      
     
      
    </div>
  )
}

export default Pace;