import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class CoursePageApi {
  async getSideBarSection(id, level_id) {
    try {
      let url;
      if (id && level_id) {
        url = `course-menu?student_id=${id}&level_id=${level_id}`;
      }
      if (id && !level_id) {
        url = `course-menu?student_id=${id}`;
      }
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    } 
  }

  async getCourseContent(content_type, content_id, student_id, centre_id) {
    try {
      let url = `course-content?content_type=${content_type}&content_id=${content_id}&student_id=${student_id}&centre_id=${centre_id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )

      return Promise.resolve(responseData)
    } catch (err) {
        return Promise.reject(err)
    }   
  }

  async getBookingContentDetail(json) {
    const { content_type, id, date, centre_id, lessonTaken, friend, wishList, availability } = json
    let url = `booking-content-detail?content_type=${content_type}&content_id=${id}&centre_id=${centre_id}&lessonTaken=${lessonTaken}&friend=${friend}&wishList=${wishList}&availability=${availability}`;

    if (date) {
      url += '&date=' + date;
    }

    try {
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )

      return Promise.resolve(responseData)
    } catch (err) {
        return Promise.reject(err)
    }
  }
}

export default new CoursePageApi();