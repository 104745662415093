import { Button } from '@material-ui/core';
import iconArrowLeft from '../../../../assets/svg/arrowleft.svg'
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';

import '../../../../sass/private/profile/ProfileWraper.scss';

const ProfileWraper = (props) => {
  const { onClose, title, className} = props;
  const { isTablet } = useCheckMobileTablet();

  const onClosePage = () => {
    onClose();
  }

  return (
    <div className={`profile-wraper ${className}`}>
      {isTablet && 
        <div className="profile-wraper__mobile-header">
          <button type='button' className='btn-back' onClick={onClosePage}>
            <img 
              src={iconArrowLeft} 
              alt="iconleft" 
              className="iconBack"/>
          </button>
          <div className="profile">{title}</div>
        </div>
      }

      {
        !isTablet && 
        <div className="profile-wraper__header">
          {title && <div className="line-blue"></div>}
          {title && <h2 className="profile-wraper__title">{title}</h2>}
        </div>
      }
      
      
      <div className="profile-wraper__main">
        {props.children}
      </div>
    </div>
  )
}

export default ProfileWraper;