import React from 'react';
import PropTypes from 'prop-types';
import '../../../../sass/private/profile/ProfileItem.scss';
import moment from 'moment';

const ProfileItem = (props) => {
  const { name, value, isStyleValue, isStyleBg, isStyleKey } = props;
  return (
    <div className={`profile-item ${isStyleBg ? 'custom-background' : ''}`}>
      <div className={`profile-item__name ${isStyleKey ? 'highlight' : ''}`}>{name}</div>
      <div className={`profile-item__value numberid ${isNaN(value) ? "" : "credit-number"} ${isStyleValue ? 'highlight' : ''}`}>{value}</div>
    </div>
  )
}

ProfileItem.propTypes = {
  name: PropTypes.string,
  isStyleValue: PropTypes.bool
}

ProfileItem.defaultProps = {
  name: "",
  value: "",
  isStyleValue: false,
  isStyleBg: false,
  isStyleKey: false,
}

export default ProfileItem;