import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import PrivatePage from '../../../components/PrivatePage';
import imageDownload from '../../../../assets/image/download.png';
import AssessmentItem from './AssessmentItem';
import useAssessmentPage from '../../../hooks/AssessmentPage/useAssessmentPage';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';

import '../../../../sass/private/AssessmentPage/AssessmentPage.scss';

const AssessmentDetails = (props) => {
  const { history } = props;
  const { isTablet } = useCheckMobileTablet()
  const hookProps = useAssessmentPage();
  const { data } = hookProps;
  const [ id, setId ] = useState(0);
  const [dataAssessment, setDataAssessment] = useState([]);
  useEffect(() => {
    const { pathname } = window.location;
    setId(pathname.split('/')[pathname.split('/').length - 1])
  }, [])

  useEffect(() => {
    if (data) {
      const dataDetails = data && data.filter(item => item.id === JSON.parse(id));
      setDataAssessment(dataDetails)
    }
  }, [data, id]);

  const onGoBack = () => {
    history.goBack();
  }

  const content = useMemo(() => {
    return dataAssessment.length > 0 && Array.isArray(dataAssessment[0].scores) &&
      dataAssessment[0].scores.map((item, index) => {
        return (
          <div key={index}
            className={`
              assessment-detail__item
            `}
            style={{ backgroundColor: item.colorHex }}
          >
            <div className="item-left">{item.name}</div>
            <div className="item-right">{item.scoreName}</div>
          </div>
        )
      });
  }, [dataAssessment])

  return (
    <PrivatePage 
      className="page-assessment assessment-detail" 
      title="Assessment" 
      isShowMenuClose={true} 
      onBack={onGoBack}>
      <div className="assessment">
        {!isTablet && 
          <div className="assessment-title">
            <div className="line-blue"></div>
            <h2 className="title">Assessment details</h2>
          </div>
        }
        <div className="navbar-main">
          {!isTablet && 
            <div className="btn-back__block" onClick={onGoBack}>
              <button>Back to assessment</button>
            </div>
          }
          <div className="assessment-content">
            <AssessmentItem data={(dataAssessment.length > 0 && dataAssessment[0]) || {}} isHideIconArrow />
            <div className="assessment-detail__row">
              <div className="assessment-detail__text">Area</div>
              <div className="assessment-detail__text">Assessment</div>
            </div>
            {content}
            {dataAssessment.length > 0 && dataAssessment[0].assessmentCriteriaFile &&
              <div className="assessment-criteria">
                <a href={dataAssessment[0].assessmentCriteriaFile || ""} target="_blank" rel="noreferrer noopener">Download assessment criteria</a>
              </div>
            }
            <div className="teacher-comment__block">
              <div className="assessment-detail__text">Teachers comment</div>
              <div className="teacher-comment">
                <div className="teacher-block">
                  <div className="teacher-block__image">
                    <img src={dataAssessment.length > 0 && dataAssessment[0].teacherImage} alt="Teacher profile picture"/>
                  </div>
                  <div className="teacher-block__name">
                    {dataAssessment.length > 0 && dataAssessment[0].teacher}
                  </div>
                </div>
                <div className="teacher-block__name --awesome">{dataAssessment.length > 0 && dataAssessment[0].comment}</div>
              </div>
            </div>
            {(dataAssessment.length > 0 && dataAssessment[0].pdf) && <div className="download-btn">
              <a rel="noopener noreferrer" href={dataAssessment[0].pdf} target="_blank">Download .pdf</a>
              <img src={imageDownload} alt="" />
            </div>}
          </div>
        </div>
      </div>
    </PrivatePage>
  )
}

export default withRouter(AssessmentDetails);