import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssessment, getSkillList, getProductList, addOptionAssessment, addSkillAssessment } from '../../redux/actions/assessment';

const useAssessmentPage = () => {
  const [ data, setData ] = useState([]);
  const [isFetchApi, setIsFetchApi] = useState(true);
  const [dataFilter, setDataFilter] = useState({
    skill: '',
    product: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const dispatchGetAssessment = (id, skillId, productId) => dispatch(getAssessment(id, skillId, productId));
  const assessmentData = useSelector(state => state.assessment.data);
  const skillData = useSelector(state => state.assessment.skill);
  const productData = useSelector(state => state.assessment.product);
  const dataUser = useSelector(state => state.auth);
  const dataOptionFilter = useSelector(state => state.assessment.dataFilters);
  const currentSkill = useSelector(state => state.assessment.currentSkill);
  const { responseUserData } = dataUser.user || {};

  useEffect(() => {
    setDataFilter(dataOptionFilter);
  }, [dataOptionFilter])

  useEffect(() => {
    if (assessmentData && assessmentData.length > 0) {
      setData(assessmentData);
    }
  }, [assessmentData])

  useEffect(() => {
    if(responseUserData && assessmentData && assessmentData.length === 0 && isFetchApi) {
      const dispatchGetSkill = (id) => dispatch(getSkillList(id));
      const dispatchGetProduct = (id) => dispatch(getProductList(id));
      console.log('currentSkill', currentSkill)
      dispatchGetAssessment(responseUserData.id, currentSkill, 0).then(response => {
        if (response.success) {
          setIsFetchApi(false)
          setData(response.data);
        }
      });
      dispatchGetSkill(responseUserData.id);
      dispatchGetProduct(responseUserData.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseUserData, assessmentData])
  useEffect(() => {
    onChangeOption('Skill', currentSkill)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSkill])
  const onChangeOption = (name, value) => {
    const valueSelected = value >= 0 ? value : null;
    setIsLoading(true);
    const dispatchAddOption = (data) => dispatch(addOptionAssessment(data))
    if (name === 'Skill') {
      dispatch(addSkillAssessment(valueSelected))
      dispatchAddOption({...dataFilter, skill: valueSelected});
      dispatchGetAssessment(responseUserData.id, valueSelected, dataFilter.product).then(response => {
        if (response.success) {
          setData(response.data);
        }
        setIsLoading(false);
      });
    } else {
      dispatchAddOption({...dataFilter, product: valueSelected});
      dispatchGetAssessment(responseUserData.id, dataFilter.skill, valueSelected).then(response => {
        if (response.success) {
          setData(response.data);
          setIsLoading(false);
        }
      });
    }
  }

  return {
    data,
    skillData,
    onChangeOption,
    productData,
    dataFilter,
    isLoading
  }
}

export default useAssessmentPage;