import PrivatePage from '../../../components/PrivatePage';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from "query-string";
import moment from 'moment';
import CircularProgress from '../Common/CircularProgress';
import myCourseApi from '../../../redux/services/myCourseApi';
import ProgressBarPathway from '../Common/ProgressBarPathway';
import MobileBookingListView from "./MobileBookingListView";
import { useEffect, useState } from 'react';

import "../../../../sass/private/MobileBooking/ListView.scss";
import { getRoute } from '../../../components/Utilities';
import { setOpenBookingWindow } from '../../../redux/actions/Booking/BookingAction';
import ItemClass from "./ItemClass";
import { useDispatch } from 'react-redux';

const MobileCourseDetailPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = queryString.parse(history.location.search);
  const { id, content_type } = params;
  const dataFilter = useSelector(state => state.calendar.filter);
  const dateOfWeek = useSelector(state => state.calendar.filter.dateOfWeek);
  const dateOfWeekActiveIndex = useSelector(state => state.calendar.dateOfWeekActiveIndex);

  let date;

  if (dateOfWeek.length) {
    date = moment(dateOfWeek[dateOfWeekActiveIndex]).format("YYYY-MM-DD");
  }

  const onBack = () => {
    history.goBack();
  }

  const [ isLoadingCourse, setLoadingCourse ] = useState(true);
  const [courseInfo, setCourseInfo] = useState();
  const getCourseDetail = () => {
    const { centre, lessonTaken, friend, wishList, availability } = dataFilter;
      const json = {
        content_type: content_type,
        id: id,
        date: date,
        centre_id: centre,
        lessonTaken: lessonTaken,
        friend: friend,
        wishList: wishList,
        availability: availability
      }
    return myCourseApi.getBookingContentDetail(json).then(response => {
      if (response?.success) {
        setCourseInfo(response.data);
        setLoadingCourse(false);
      }
    });
  };

  useEffect(() => {
    getCourseDetail();
  }, []);

  const lessonJoinedCount = parseInt(courseInfo?.progress_bar?.lesson_joined_count) || 0;
  const themeLessonCount = parseInt(courseInfo?.progress_bar?.theme_lessons_count) || 0;

  return (
    <PrivatePage 
      className="page-course-detail"
      isShowMenuBack={true}
      title="Lessons"
      backTitle="Back"
      isShowMainNavigation={false}
      onBack={onBack}
    >
      <div className="mobile-booking">
        {isLoadingCourse && <CircularProgress />}
        {!isLoadingCourse && courseInfo && <MobileBookingListView>
          <div className="list-view__list-items">
            <div className="list-item-lesson lesson-theme" style={{borderColor: '#C4C4C4'}}>
              <div className="flex-container">
                <div className="list-item-lesson__mintdash"></div>
                <p className="list-item-lesson__name secondary">{courseInfo.name || ""}</p>
                <p className="list-item-lesson__name category">{courseInfo.product_category_name || ""}</p>
                {content_type ==="theme" && <div className="course-pathway">
                  <ProgressBarPathway
                    label=""
                    total={themeLessonCount}
                    completed={lessonJoinedCount}
                    width="40px"
                    height="16px"
                    isHideLable={true} 
                    isHideInfo={true}
                    colorActive="#00EDC3"
                    colorDefault="#23085A"
                    colorProgress="#99EDD1"
                    completedText={lessonJoinedCount + " of " + themeLessonCount + " lesson" + (themeLessonCount > 1? 's': '') + " attended"}
                    showCompletedIcon={false}
                  />
                </div>}
              </div>
            </div>
          </div>
          {Array.isArray(courseInfo.classes_available) && courseInfo.classes_available.map((item, index) =>
            <ItemClass key={index} item={item}/>
          )}
        </MobileBookingListView>}
      </div>
    </PrivatePage>
  )
};

export default MobileCourseDetailPage;