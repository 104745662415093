import SvgIcon from "@material-ui/core/SvgIcon";

export default function Question(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" style={{ fill: "none" }} {...props}>
      
        <g clipPath="url(#clip0_2506_636)">
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.09375 8.99959C9.32885 8.33126 9.7929 7.7677 10.4037 7.40873C11.0145 7.04975 11.7326 6.91853 12.4309 7.0383C13.1292 7.15808 13.7626 7.52112 14.2188 8.06312C14.6751 8.60512 14.9248 9.29112 14.9237 9.99959C14.9237 11.9996 11.9237 12.9996 11.9237 12.9996"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <circle cx="12" cy="17" r="1" fill="currentColor" />
        <defs>
            <clipPath id="clip0_2506_636">
                <rect width="24" height="24" fill="currentColor" />
            </clipPath>
        </defs>
    </SvgIcon>
  );
}
