import { useState, useEffect } from 'react';
import moment from 'moment';
import '../../../../sass/private/CalendarPage/DateList.scss';

function showListDateOfWeek(date, startDay, endDay) {
  return date?.filter((item, index) => {
    if ((index >= startDay) && (index <= endDay)) {
      return item;
    }
    return '';
  })
}

const DateList = (props) => {
  const [showDate, setShowDate] = useState([]);
  const { dateOfWeek } = props;
  useEffect(() => {
    if (dateOfWeek && dateOfWeek.length > 0) {
      let showdateOfWeek = [...dateOfWeek];
      showdateOfWeek = showListDateOfWeek(showdateOfWeek, 0, 6)
      setShowDate(showdateOfWeek);
    }
  }, [dateOfWeek]);

  const date = moment(new Date()).format('YYYY-MM-DD');
  const currentNumberDate = moment(date).isoWeekday();
  const currentDate = moment(dateOfWeek[currentNumberDate - 1]).format("YYYY-MM-DD");

  return (
    <div className="date-list">
      <div className="btn-prev">
      </div>
      {showDate?.map((item, index) => {
        return <div className={"date-text" + (((index === currentNumberDate - 1) && currentDate === date) ? ' active' : '')} key={index}>
          {moment(item).format('dddd') + ' ' + moment(item).format('DD')}
        </div>
      })

      }
      <div className="btn-next">
      </div>
    </div>
  )
}

export default DateList;