import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCommunication, updateCommunication } from '../../redux/actions/communicationAction';
import { getCurrentUser } from '../../../utils/currentUser';

const useCommunicationPage = () => {
  const [data, setData] = useState([]);
  const [dataMethods, setDataMethods] = useState([]);
  const dispatch = useDispatch();
  const communicationData = useSelector(state => state.communicationData.data);
  const infoMessageUpdate = useSelector(state => state.communicationData.updateInfo);
  const currentUser = getCurrentUser();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [updateResultsLoaded, setUpdateResultsLoaded] = useState(false);

  useEffect(() => {
    const user = getCurrentUser();
    if (user.responseUserData.id) {
      dispatch(getCommunication(user.responseUserData.id)).then((response) => {
        if (response?.success) {
          setDataLoaded(true);
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (dataLoaded && communicationData && communicationData.setting) {
      setData(communicationData.setting)
      
      setDataMethods(communicationData.methods.filter(item => {
        item.isChecked = item.isChecked === 0 ? false : true;
        return item.active !== 0;
      }));
    }
  }, [dataLoaded, communicationData]);

  const handleSelectData = (dataOption, id, select) => {
    return dataOption && dataOption.filter(item => {
      if (item.id === id) {
        item.notification.filter(itemOptions => {
          itemOptions.methods.filter(option => {
            if (!option.isDisable) {
              option.isChecked = select;
            }
            return option;
          })
          return itemOptions;
        })
      }
      return item;
    });
  }

  const onSelect = (id) => {
    const newData = [...data];
    const dataHandle = handleSelectData(newData, id, true);
    setData(dataHandle);
  }
  const onDeselect = (id) => {
    const newData = [...data];
    const dataHandle = handleSelectData(newData, id, false);
    setData(dataHandle);
  }

  async function getAllMethodCheckOrUnCheck(data, idOption, isCheckAll) {
    const newData = [...data]
    let arr = [];
    newData.filter(item => {
      item.notification && item.notification.filter(data => {
        data.methods.filter(method => {
          if (method.id === idOption) {
            arr.push(method);
          }
          return method;
        })
        return data;
      })
      return item;
    })

    let result = await checkHasFalseValue(arr);
    const newDataMethods = [...dataMethods];
    newDataMethods.filter(item => {
      if (item.id === idOption) {
        item.isChecked = isCheckAll ? !result : result;
        return item;
      }
      return item;
    })
    setDataMethods(newDataMethods);
    return result;
  }

  async function checkAllMethod(newData, idOption) {
    const isChecked = await getAllMethodCheckOrUnCheck(newData, idOption, true);
    newData.filter(item => {
      item.notification && item.notification.filter(data => {
        data.methods.filter(method => {
          if (idOption === method.id) {
            method.isChecked = !isChecked;
          }
          return method;
        })
        return data;
      })
      return item;
    })
    return newData;
  }

  const onSelectOption = async (id, idItem, idOption) => {
    const newData = [...data];
    if (idItem === 'all') {
      const newDataCustom = await checkAllMethod(newData, idOption);
      setData(newDataCustom);
    } else {
      const newDataFindId = newData.find(x => x.id === id);
      const newDataItem = newDataFindId.notification.find(x => x.id === idItem);
      const newDataOption = newDataItem.methods;
      newDataOption && newDataOption.filter((option) => {
        if (idOption === option.id) {
          option.isChecked = !option.isChecked;
          let idOptionAll = option.id;
          getAllMethodCheckOrUnCheck(newData, idOptionAll, false);
          return option;
        }
        return option;
      })
      setData(newData);
    }
  }

  function checkHasFalseValue(data) {
    let result = true;
    for(let i = 0; i < data.length; i ++) {
      if (data[i].isChecked === false) {
        result = false;
        break;
      }
    }
    return result;
  }

  const onUpdate = () => {
    let checked= [];
    let unchecked = [];
    const newData = [...data];
    newData.filter(item => {
      item && item.notification && item.notification.filter(value => {
        const idNotification = value.id;
        value && value.methods && value.methods.filter(method => {
          if (method.isChecked) {
            checked.push(idNotification + '_' + method.id);
          } else {
            unchecked.push(idNotification + '_' + method.id);
          }
          return method;
        })
        return value;
      })
      return item;
    })

    dispatch(updateCommunication(currentUser.responseUserData.id, checked.toString(), unchecked.toString())).then(() => {
      setUpdateResultsLoaded(true);
    });
  }

  return {
    data,
    onSelect,
    onDeselect,
    onSelectOption,
    onUpdate,
    dataMethods,
    infoMessageUpdate,
    dataLoaded,
    updateResultsLoaded,
  }
}

export default useCommunicationPage;