import { useEffect, useState } from "react";
import homeApi from "../../redux/services/homeApi";

const useProgress = () => {
  const INPROGRESS = "inProgress";
  const COMPLETED = "completed";

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMoreCompleted, setIsLoadingMoreCompleted] = useState(false);
  const [isLoadingMoreInProgress, setIsLoadingMoreInProgress] = useState(false);

  const [showLoadMoreButtonCompleted, setShowLoadMoreButtonCompleted] =
    useState(false);
  const [showLoadMoreButtonInProgress, setShowLoadMoreButtonInProgress] =
    useState(false);

  const [pageCompleted, setPageCompleted] = useState(1);
  const [pageInProgress, setPageInProgress] = useState(1);
  const [progressList, setProgressList] = useState([]);

  const getData = async () => {
    setIsLoading(true);
    await homeApi
      .getProgressCheck()
      .then((result) => {
        if (result?.data?.inProgress?.hasShowMore) {
          setShowLoadMoreButtonInProgress(true);
        } else {
          setShowLoadMoreButtonInProgress(false);
        }
        if (result?.data?.completed?.hasShowMore === true) {
          setShowLoadMoreButtonCompleted(true);
        } else {
          setShowLoadMoreButtonCompleted(false);
        }
        setPageCompleted(1);
        setPageInProgress(1);
        setProgressList(result.data ?? []);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const loadMore = async (type) => {
    let page = "";
    switch (type) {
      case COMPLETED:
        page = pageCompleted + 1;
        setIsLoadingMoreCompleted(true);
        setPageCompleted(page);
        break;
      case INPROGRESS:
        page = pageInProgress + 1;
        setIsLoadingMoreInProgress(true);
        setPageInProgress(page);
        break;
      default:
        page = pageInProgress + 1;
        setIsLoadingMoreInProgress(true);
        setPageInProgress(page);
    }
    await homeApi
      .getMoreProgressCheck(type, page)
      .then((result) => {
        setProgressList(progressList);
        switch (type) {
          case COMPLETED:
            result?.data?.themes.map((item) => {
              progressList.completed.themes.push(item);
            });
            setIsLoadingMoreCompleted(false);
            setShowLoadMoreButtonCompleted(result?.data?.hasShowMore);
            break;
          case INPROGRESS:
            result?.data?.themes.map((item) => {
              progressList.inProgress.themes.push(item);
            });
            setIsLoadingMoreInProgress(false);
            setShowLoadMoreButtonInProgress(result?.data?.hasShowMore);
            break;
          default:
            result?.data?.themes.map((item) => {
              progressList.inProgress.themes.push(item);
            });
            setIsLoadingMoreInProgress(false);
            setShowLoadMoreButtonInProgress(result?.data?.hasShowMore);
        }
      })
      .catch((e) => {
        switch (type) {
          case COMPLETED:
            setIsLoadingMoreCompleted(false);
            break;
          case INPROGRESS:
            setIsLoadingMoreInProgress(false);
            break;
          default:
            setIsLoadingMoreCompleted(false);
            setIsLoadingMoreInProgress(false);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    isLoading,
    isLoadingMoreCompleted,
    isLoadingMoreInProgress,
    progressList,
    loadMore,
    showLoadMoreButtonCompleted,
    showLoadMoreButtonInProgress,
    INPROGRESS,
    COMPLETED,
    getData,
  };
};

export default useProgress;
