import { useEffect, useState } from "react";
import useProfileCapture from "../../../hooks/ProfileCapture/useProfileCapture";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import BannerMaster from '../../../../assets/image/motivation_background.png';
import { setComponentData, setFinalSectionText, setLastStep, setMotivationsData, setProfileId, setSectionData, setStep, setStepOrder, setStoreListExamEdited, submitMotivations } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import ProfileCaptureApi from '../../../redux/services/profileCaptureApi';

const ReasonPage = (props) => {
  const { history, dispatch, previousStep, centreId, profileId, step, motivationId, translations } = props
  const [validRating, setValidRating] = useState(false)
  const [tempMotivation, setTempMotivation] = useState([])
  const { isLoading, motivationData, openMotivationPage } = useProfileCapture()
  const { motivations: motivationFromStore } = useSelector(state => state.profilecapture)
  const [loadingOnNextBtn, setLoadingOnNextBtn] = useState(false)

  const onRating = (id, value) => {
    const newTemp = tempMotivation.map(data => {
      if (data.id === id)
        return { ...data, value: value }
      return data
    })
    setTempMotivation(newTemp)
    dispatch(setMotivationsData(newTemp))
  }

  const onNext = async () => {
    setLoadingOnNextBtn(true)

    //Submit motivation reason
    let data = {
      centre_id: centreId,
      motivations: tempMotivation.map(item => { return { id: item.id, rating: item.value } })
    }
    if (profileId) data.profile_id = profileId
    const result = await submitMotivations(data)
    let examSection = result?.sections?.filter(item => item.type === 'exam')[0]
    let listExamEdited = {};
    if(examSection) {
      examSection.profile_motivation_statements.map((examType) => {
        if(examType.checked) {
          examType.exams.map((exam) => {
            if(exam.checked) {
              listExamEdited[exam.id] = examType.id;
            }
          })
        }
      })
    }

    // Set data into store
    dispatch(setProfileId(result.profile_id))
    dispatch(setSectionData(result.sections))
    dispatch(setComponentData(result.components))
    dispatch(setFinalSectionText(result.final_comment_text))
    dispatch(setStoreListExamEdited(listExamEdited));

    // Define next step
    const nextStep = result.steps[0]
    dispatch(setStep(nextStep))
    dispatch(setLastStep(nextStep));

    // Set step list
    const stepOrder = ['welcome', 'reason', ...result.steps, 'complete']
    dispatch(setStepOrder(stepOrder))

    setLoadingOnNextBtn(false)
    window.scrollTo(0, 0);
  }

  // Validation for next step
  useEffect(() => {
    if (tempMotivation && tempMotivation.length > 0) {
      // At least rating IMPORTANT OR VERY IMPORTANT
      const isValid = 
        (tempMotivation.filter(item => item.value === 3).length > 0 ||
        tempMotivation.filter(item => item.value === 4).length > 0) && 
        tempMotivation.filter(item => typeof item.value != 'undefined' && item.value !== 0).length === tempMotivation.length
      isValid && setValidRating(true)
      !isValid && setValidRating(false)
    }
  }, [tempMotivation])

  // Get motivation from store or api
  useEffect(() => {
    if (motivationFromStore.length > 0) {
      setTempMotivation(motivationFromStore)
    } else {
      if (motivationData.motivations && motivationData.motivations.length > 0) {
        const data = motivationData.motivations.map(item => { return { ...item, value: 0 } })
        setTempMotivation(data)
      }
    }
  }, [motivationData, motivationFromStore])

  useEffect(() => {
    openMotivationPage(centreId, profileId)
  }, [])

  return (
    <ProfileCapturePage history={history} dispatch={dispatch} previousStep={previousStep} pageTitle={motivationData.main_title || ''} step={step} centreId={centreId} profileId={profileId} bannerMaster={BannerMaster}>
      {isLoading && <CircularProgress />}
      {!isLoading &&
      <>
      {motivationData.main_text && <div className="rate-important-top">
        <div className="profile-capture-wrapper">
          <p className="rate-ttl">{motivationData.main_text}</p>
        </div>
      </div>}
      <div className="rate-important-inner rate-learing-en">
        <div className="profile-capture-wrapper">
          <div className="rate-ttl-select">
            <div className="rate-list-ttl ttl"></div>
            <div className="rate-list-column">
              <div className="ttl">{ translations['[skill_option_not_important]'] }</div>
              <div className="ttl">{ translations['[skill_option_slight_important]'] }</div>
              <div className="ttl">{ translations['[skill_option_important]'] }</div>
              <div className="ttl">{ translations['[skill_option_very_important]'] }</div>
            </div>
          </div>
        {tempMotivation.length > 0 && tempMotivation.map(item => {
          return (
            <div className="rate-list-inner" key={item.id}>
              <div className="rate-list-ttl rate-list">{item.motivation_phrase}</div>
              <div className="rate-list-column">
                <div className={`rate-list ${item.value === 1 ? 'active' : ''}`}
                  onClick={() => onRating(item.id, 1)}
                >
                  <div className="rate-list-items"></div>
                </div>
                <div className={`rate-list ${item.value === 2 ? 'active' : ''}`}
                  onClick={() => onRating(item.id, 2)}
                >
                  <div className="rate-list-items"></div>
                </div>
                <div className={`rate-list ${item.value === 3 ? 'active' : ''}`}
                  onClick={() => onRating(item.id, 3)}
                >
                  <div className="rate-list-items"></div>
                </div>
                <div className={`rate-list ${item.value === 4 ? 'active' : ''}`}
                  onClick={() => onRating(item.id, 4)}
                >
                  <div className="rate-list-items"></div>
                </div>
              </div>
            </div>
          )
        })}
          <div className="other-reason-w">
            <div className="select-options-inner">
              <div className="page">{translations['[label_page]']} 1</div>
              {validRating ?
                <div>
                  <button className="btn-select-options next active" type="button" onClick={onNext}>
                    {loadingOnNextBtn ? <CircularProgress/> : translations['[button_next]']}
                  </button>
                </div> :
                <button className="btn-select-options" type="button">{ translations['[button_select_option]'] }</button>
              }
            </div>
          </div>
        </div>
      </div>
      </>
      }
    </ProfileCapturePage>
  )
}

export default ReasonPage;