import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router";
import ProfileItem from './ProfileItem';
import useProfilePage from '../../../hooks/ProfilePage/useProfilePage';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import ProfileWraper from './ProfileWraper';
import CircularProgress from '../Common/CircularProgress';

import '../../../../sass/private/profile/Profile.scss';

const ProfilePage = (props) => {
  const { history } = props;
  const { isTablet } = useCheckMobileTablet();
  const { user, userItem, onUpLoadImage, onDeletedAvatar, isProcessingAvatar } = useProfilePage();
  const avatar = useSelector(state => state.auth.avatar);

  const content = useMemo(() => {
    return userItem && userItem.map((item, index) => {
      return <ProfileItem
        key={index}
        name={item.key.split('_').join(' ')}
        value={item.value}
      />
    })
  }, [userItem]);

  const onClose = () => {
    isTablet ? history.goBack() : props.onClose()
  }

  return (
  <ProfileWraper 
    onClose={onClose} 
    title="Personal profile" 
    showTitle="Personal profile"
    isShowBack
    isHideBtnBottom
    className={'profile'}
  >
    <div className="profile-content">
      {
        isTablet && 
        <div className="wrap-avatar-mobile">
          <div className="profile-avatar-mobile">
            <div className="profile-avatar-mobile__img">
              {avatar !== "" && <img src={avatar} alt="avatar" />}
            </div>
            <div className="profile-avatar-mobile__info">
              <div className="profile-name-mobile">{user.name_en} {user.surname_en}</div>
              <div className="profile-action-mobile">
              {isProcessingAvatar && <CircularProgress />}
              {!isProcessingAvatar && <>
                <span onClick={onDeletedAvatar} className="action-remove">Remove</span>
                <label htmlFor="upload-photo" className="action-upload">Upload</label>
                <input type={'file'} onChange={(e) => onUpLoadImage(e)} id="upload-photo" style={{ display: 'none' }}/>
              </>}
              </div>
            </div>
          </div>
        </div>
      }

      {
        !isTablet &&
        <div className="profile-avatar">
          <div className="profile-avatar__img">
            {avatar !== "" && <img src={avatar} alt="avatar" />}
          </div>
          <div className="profile-avatar__info">
            <div className="profile-name">
              <span>{user.name_en}</span>
              <span> {user.surname_en}</span>
            </div>
            <div className="profile-action">
              {isProcessingAvatar && <CircularProgress />}
              {!isProcessingAvatar && <>
                <span onClick={onDeletedAvatar} className="action-remove">Remove</span>
                <label htmlFor="upload-photo" className="action-upload">Upload</label>
                <input type={'file'} onChange={(e) => onUpLoadImage(e)} id="upload-photo" style={{ display: 'none' }}/>
              </>}
            </div>
          </div>
        </div>
      }
      <div className="profile-subtitle">
        Please upload a small photo of yourself (in .jpg format). The photo will be seen only by the teacher, to help with class management. Go to settings to change or remove it at any time.
      </div>
      {content}
    </div>

  </ProfileWraper>
  )
}

export default withRouter(ProfilePage);