import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class ModalAudioApi {
  async getAudioDetails(id) {
    try {
      
      const {success, data} = await makeRequest(
        getAPIEndpoint() + `lesson-audio?lesson_audio_id=${id}`,
        'get',
        null,
        true,
      );

      if(success) {
        return Promise.resolve(data);
      }
      throw data


    } catch(error) {
      return Promise.reject(error);
    }
  }
}

export default new ModalAudioApi();