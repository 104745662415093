import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileAction, updateAvatar, deleteAvatar } from '../../redux/actions/Profile/ProfileAction';
import { getCurrentUser, setAvatarCurrentUser } from '../../../utils/currentUser';
import { Profile } from '../../../models/Profile';
import AuthService from "../../redux/services/auth.service";
import { setAvatar } from "../../redux/actions/auth";

const useProfilePage = () => {
  const [user, setUser] = useState({});
  const [userItem, setUserItem] = useState([]);
  const dispatch = useDispatch();
  const dataAccount = useSelector(state => state.profile.data);

  const [isProcessingAvatar, setIsProcessingAvatar] = useState(false);

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.responseUserData) {
      const dataProfile = new Profile(currentUser.responseUserData);
      const result = Object.entries(dataProfile).map(([key, value]) => ({key,value}));
      setUser(currentUser.responseUserData);
      setUserItem(result);
    }
  }, []);

  useEffect(() => {
    const user = getCurrentUser();
    if (user.responseUserData.id) {
      dispatch(getProfileAction(user.responseUserData.id));
    }
  }, [dispatch]);

  const onUpLoadImage = (e) => {
    e.preventDefault();

    if (e.target.files.length) {
      setIsProcessingAvatar(true);
    }
    else {
      return false;
    }

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    dispatch(updateAvatar(formData, user.id)).then(res => {
      if (res?.success) {
        getUpdatedAvatar();
      }
      else {
        setIsProcessingAvatar(false);
      }
    });
  }

  const onDeletedAvatar = () => {
    setIsProcessingAvatar(true);

    dispatch(deleteAvatar(user.id)).then(res => {
      if (res?.success) {
        getUpdatedAvatar();
      }
      else {
        setIsProcessingAvatar(false);
      }
    })
  }

  const getUpdatedAvatar = async () => {
    const response = await AuthService.whoami();

    if (response?.success) {
      const avatar = response.data?.avatar;

      setAvatarCurrentUser(avatar); // set new avatar to localStorage
      dispatch(setAvatar(avatar)); // set new avatar to Redux store
    }

    setIsProcessingAvatar(false);
  }

  return {
    dataAccount,
    user,
    userItem,
    onUpLoadImage,
    onDeletedAvatar,
    isProcessingAvatar,
  }
}

export default useProfilePage;