import E from './E';
import P from './P';
import I from './I';
import U from './U';
import A from './A';
import C2 from './C2';

const Level = (props) => {
  switch (props.code) {
    case "E":
      return <E />;
    case "P":
      return <P />;
    case "I":
      return <I />;
    case "U":
      return <U />;
    case "A":
      return <A />;
    case "C2":
      return <C2 />;
    default:
      return <></>;
  }
}

export default Level;
