import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import { setStep, setStoreListExamEdited, updateSection } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import ModalExam from "./ModalExam";
import ExamBanner from '../../../../assets/image/exam_background.png';
import ProfileCaptureApi from '../../../redux/services/profileCaptureApi';
import { handleGoBack } from "../../../../utils/helper";

const ExamPage = (props) => {
  const { history, dispatch, step, previousStep, nextStep, centreId, sections, pageOrder, profileId, setSubmitStep, setSubmitFlag, motivationId, translations } = props
  const [tempMotivationSections, setTempMotivationSections] = useState([])
  const [canGoNext, setCanGoNext] = useState(false)
  const [examTypeIds, setExamTypeIds] = useState([])
  const [examTypeData, setExamTypeData] = useState([])
  const [examData, setExamData] = useState({})
  const [formValid, setFormValid] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentExamTypeId, setCurrentExamTypeId] = useState(0)
  const [currentExamId, setCurrentExamId] = useState(0)
  const [isExamTakenBefore, setIsExamTakenBefore] = useState(false)
  const [oldExamData, setOldExamData] = useState({})
  const [isUpdateExamData, setIsUpdateExamData] = useState(false)
  const storeListExamEdited = useSelector(state => state.profilecapture.listExamEdited);

  const onSelectExamType = (id) => {
    const data = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === id) {
        return { ...item, checked: !item.checked }
      }
      return item
    })

    setTempMotivationSections(prevState => ({ ...prevState, profile_motivation_statements: data }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: data } }))
  }

  const onChangeExamComment = (value) => {
    setTempMotivationSections(prevState => ({ ...prevState, exam_comment: value }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, exam_comment: value } }))
  }

  const onGoNextExam = () => {
    let checkedExamIds = [];
    const examOption = tempMotivationSections.profile_motivation_statements.filter(item => item.checked === true)
    const listIds = examOption.map(item => {
      checkedExamIds.push(item.id);
      if (item.checked) {
        return item.id        
      }
    })
    if (examOption.length == 0 && typeof tempMotivationSections.exam_comment != 'undefined') {
      dispatch(setStep(nextStep))
      setSubmitStep(step)
      setSubmitFlag(true)
    }
    else {
      let dataRemoveExams = [];
      let examType = [];
      let dataRefreshed = [];
      Object.keys(storeListExamEdited).map((key) => {
        let examId = key;
        let examTypeId = storeListExamEdited[key];
        if(checkedExamIds.includes(examTypeId) == false) {
          dataRemoveExams.push(examId);
          if(examType.length == 0) {
            examType = tempMotivationSections.profile_motivation_statements.filter(item => {
              return item.id == examTypeId;
            })[0] ?? []; 
          }
        }
      })
      let examTypeId = examType.id;
      
      tempMotivationSections.profile_motivation_statements.map(data => {
        if (data.id == examTypeId) {
          data.exams.map(exam => {
            if (dataRemoveExams.includes(exam.id) == false) {
              let examId = exam.id;
              let examData = examType.exams.filter(item => {
                return item.id == examId;
              })[0] ?? [];
              
              let clearObject = clearDataExam(examData);
              
              dataRefreshed.push({...examData, ...clearObject});
              
              delete storeListExamEdited[examId];
              if(storeListExamEdited.length == 0) {
                setFormValid(false);
              }
              dispatch(setStoreListExamEdited(storeListExamEdited));
            } else {
              dataRefreshed.push(exam);
            }
          })
        }
      })
      
      const dataNewTempMotivations = tempMotivationSections.profile_motivation_statements.map(item => {
        if (item.id == examTypeId) {
          return { ...item, exams: dataRefreshed }
        }
        return item
      })
      
      setTempMotivationSections(prevState => ({
        ...prevState,
        profile_motivation_statements: dataNewTempMotivations
      }))
      
      setExamTypeIds([...listIds])
      setExamTypeData([...examOption])
    }
  }
  
  const clearDataExam = (examData) => {
    let clearObject = {
      checked: false,
      prev_result: ''
    };

    if(examData.profile_exam_motivation_statements) {
      const dataExamMotivations = examData.profile_exam_motivation_statements.map(item => {
        return { ...item, ...{checked: false, response_text: null}}
      });

      clearObject['profile_exam_motivation_statements'] = dataExamMotivations;
    }

    if(examData.previous_attempt_times_array) {
      const dataAttemptTime = examData.previous_attempt_times_array.map(item => {
        return { ...item, checked: false}
      })

      clearObject['previous_attempt_times_array'] = dataAttemptTime;
    }

    
    if(examData.previous_attempt_array) {
      const dataAttempt = examData.previous_attempt_array.map(item => {
        return { ...item, checked: false}
      })

      clearObject['previous_attempt_array'] = dataAttempt;
    }

    
    if(examData.target_date_array) {
      const dataDate = examData.target_date_array.map(item => {
        return { ...item, checked: false}
      })

      clearObject['target_date_array'] = dataDate;
    }

    if(examData.target_score_array) {
      const dataScore = examData.target_score_array.map(item => {
        return { ...item, checked: false}
      })

      clearObject['target_score_array'] = dataScore;
    }
    
    return clearObject;
  }
  
  const handleGoBackExamDetail = () => {
    if (examTypeIds.length > 0){
      setExamTypeIds([])
      setExamTypeData([]) 
    } else {
      handleGoBack(dispatch, previousStep, profileId, step, tempMotivationSections?.id)
    }
  }

  const onChooseExam = (typeId, examId) => {
    // Update data for Modal
    const exam = examTypeData.find(item => item.id === typeId)
    const updateData = exam.exams.map(item => {
      if (item.id === examId) {
        return { ...item, checked: true }
      }
      return item
    })
    const data = updateData.find(item => item.id === examId)
    setExamData(data)
    setIsUpdateExamData(false);
    // Prepare data for statement
    const statementItems = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === typeId) {
        return { ...item, exams: updateData }
      }
      return item
    })
    setOldExamData(statementItems);
    setCurrentExamTypeId(typeId)
    setCurrentExamId(examId)
    // This one is for 2nd screen
    setExamTypeData(statementItems)
    // Update global state
    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: statementItems
    }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: statementItems } }))
    // Show modal
    setShowModal(true)
    setIsExamTakenBefore(false);
  }
  const cancelExam = (typeId, examId) => {
    let examType = tempMotivationSections.profile_motivation_statements.filter(item => {
      return item.id === typeId;
    })[0] ?? [];

    if(examType) {
      let exam = examType.exams.filter(item => {
        return item.id == examId;
      })[0] ?? [];
  
      deleteExam(exam, typeId, examId);
      delete storeListExamEdited[examId];
      if(storeListExamEdited.length == 0) {
        setFormValid(false);
      }
      dispatch(setStoreListExamEdited(storeListExamEdited));
    }
  }

  const deleteExam = (examData, examTypeId, examId) => {
    let clearObject = clearDataExam(examData);

    const updatedExamTypeData = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id == examTypeId) {
        let profileMotifvationExams = item.exams.map(exam => {
          if (exam.id == examId) {
            return { ...exam, ...clearObject}
          }

          return exam
        })

        return { ...item, exams: profileMotifvationExams}
      }
      return item
    })

    setExamTypeData(updatedExamTypeData)

    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: updatedExamTypeData
    }))

    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: updatedExamTypeData } }))
  }

  const handleHideModal = () => {
    setShowModal(false)
    if(typeof storeListExamEdited[examData.id] === 'undefined') {
      
      //clear data on redux
      let examTypeId = examData.id_profile_exam_type;
      let examId = examData.id;
      deleteExam(examData, examTypeId, examId);
      
      
    } else if(isUpdateExamData && storeListExamEdited[examData.id] && oldExamData) {
      //case ignore update
      setExamTypeData(oldExamData)

      setTempMotivationSections(oldExamData);

      dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: oldExamData } }))
    }
  }

  const customGoBack = () => {
    if (examTypeIds.length > 0)
      setExamTypeIds([])
    else
      dispatch(setStep(previousStep))
  }

  const onGoNextSection = () => {
    dispatch(setStep(nextStep))
    setSubmitStep(step)
    setSubmitFlag(true)
  }

  // Function of ModalExam
  const onSelectMotivation = (typeId, examId, id) => {
    const data = examData.profile_exam_motivation_statements.map(item => {
      if (item.id === id)
        return { ...item, checked: !item.checked}
      return item
    })
    setExamData(prevState => ({
      ...prevState,
      profile_exam_motivation_statements: data
    }))
    const updatedExamTypeData = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === typeId) {
        return { ...item, exams: item.exams.map(exam => {
          if (exam.id === examId)
            return { ...exam, profile_exam_motivation_statements: data }
          return exam
        })}
      }
      return item
    })
    setIsUpdateExamData(true);
    setExamTypeData(updatedExamTypeData)
    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: updatedExamTypeData
    }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: updatedExamTypeData } }))
  }

  const onChangeMotivationComment = (typeId, examId, id, value) => {
    const data = examData.profile_exam_motivation_statements.map(item => {
      if (item.id === id)
        return { ...item, response_text: value}
      return item
    })
    setExamData(prevState => ({
      ...prevState,
      profile_exam_motivation_statements: data
    }))
    setIsUpdateExamData(true);
    const updatedExamTypeData = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === typeId) {
        return { ...item, exams: item.exams.map(exam => {
          if (exam.id === examId)
            return { ...exam, profile_exam_motivation_statements: data }
          return exam
        })}
      }
      return item
    })
    setExamTypeData(updatedExamTypeData)
    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: updatedExamTypeData
    }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: updatedExamTypeData } }))
  }

  const onSelectTargetDate = (typeId, examId, value) => {
    const data = examData.target_date_array.map(item => {
      if (item.value === value)
        return { ...item, checked: true}
      return { ...item, checked: false }
    })
    setExamData(prevState => ({
      ...prevState,
      target_date_array: data
    }))
    setIsUpdateExamData(true);
    const updatedExamTypeData = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === typeId) {
        return { ...item, exams: item.exams.map(exam => {
          if (exam.id === examId)
            return { ...exam, target_date_array: data }
          return exam
        })}
      }
      return item
    })
    setExamTypeData(updatedExamTypeData)
    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: updatedExamTypeData
    }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: updatedExamTypeData } }))
  }

  const onSelectTargetScore = (typeId, examId, value) => {
    const data = examData.target_score_array.map(item => {
      if (item.value === value)
        return { ...item, checked: true}
      return { ...item, checked: false }
    })
    setIsUpdateExamData(true);
    setExamData(prevState => ({
      ...prevState,
      target_score_array: data
    }))
    const updatedExamTypeData = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === typeId) {
        return { ...item, exams: item.exams.map(exam => {
          if (exam.id === examId)
            return { ...exam, target_score_array: data }
          return exam
        })}
      }
      return item
    })
    setExamTypeData(updatedExamTypeData)
    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: updatedExamTypeData
    }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: updatedExamTypeData } }))
  }

  const onSelectPrevAttempt = (typeId, examId, value) => {
    if (value === 1) {
      setIsExamTakenBefore(true)
    } else {
      setIsExamTakenBefore(false)
    } 
    
    const dataAttempts = examData.previous_attempt_array.map(item => {
      if (item.value === value)
        return { ...item, checked: true}
      return { ...item, checked: false }
    })

    let updateObject = {
      'previous_attempt_array': dataAttempts
    };

    if(examData.previous_attempt_times_array && value == 0) {  
      const dataAttemptTimes = examData.previous_attempt_times_array.map(item => {
        return { ...item, checked: false }
      })

      updateObject['previous_attempt_times_array'] = dataAttemptTimes;
    }

    setIsUpdateExamData(true);
    setExamData(prevState => ({
      ...prevState,
      ...updateObject
    }))
    const updatedExamTypeData = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === typeId) {
        return { ...item, exams: item.exams.map(exam => {
          if (exam.id === examId)
            return { ...exam, ...updateObject }
          return exam
        })}
      }
      return item
    })
    setExamTypeData(updatedExamTypeData)
    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: updatedExamTypeData
    }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: updatedExamTypeData } }))
  }

  const onSelectPrevAttemptTime = (typeId, examId, value) => {
    const data = examData.previous_attempt_times_array.map(item => {
      if (item.value === value)
        return { ...item, checked: true}
      return { ...item, checked: false }
    })
    setIsUpdateExamData(true);
    setExamData(prevState => ({
      ...prevState,
      previous_attempt_times_array: data
    }))
    const updatedExamTypeData = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === typeId) {
        return { ...item, exams: item.exams.map(exam => {
          if (exam.id === examId)
            return { ...exam, previous_attempt_times_array: data }
          return exam
        })}
      }
      return item
    })
    setExamTypeData(updatedExamTypeData)
    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: updatedExamTypeData
    }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: updatedExamTypeData } }))
  }

  const onChangePrevAttemptComment = (typeId, examId, value) => {
    setExamData(prevState => ({
      ...prevState,
      prev_result: value
    }))
    const updatedExamTypeData = tempMotivationSections.profile_motivation_statements.map(item => {
      if (item.id === typeId) {
        return { ...item, exams: item.exams.map(exam => {
          if (exam.id === examId)
            return { ...exam, prev_result: value }
          return exam
        })}
      }
      return item
    })

    setExamTypeData(updatedExamTypeData)
    setIsUpdateExamData(true);
    setTempMotivationSections(prevState => ({
      ...prevState,
      profile_motivation_statements: updatedExamTypeData
    }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: updatedExamTypeData } }))
  }

  useEffect(() => {
    if (sections.length > 0) {
      const data = sections.filter(item => item.type === step)[0]
      setTempMotivationSections(data)
    }

  }, [sections, step])

  useEffect(() => {

  }, [step])

  useEffect(() => {
    if (tempMotivationSections && tempMotivationSections.profile_motivation_statements?.length > 0) {  
      let examTypes = tempMotivationSections?.profile_motivation_statements.filter(item => item.checked == true) ?? [];
      if(examTypes.length > 0 || (typeof tempMotivationSections.exam_comment != 'object' && (tempMotivationSections.exam_comment != '' || tempMotivationSections.exam_comment != 'null'))) {
        setCanGoNext(true);
      } else {
        setCanGoNext(false);
      }
    }

  }, [tempMotivationSections])

  return (
    <ProfileCapturePage history={history} dispatch={dispatch} step={step} previousStep={previousStep} pageTitle={tempMotivationSections?.motivation_name || ''} centreId={centreId}  profileId={profileId} customGoBack={customGoBack} bannerMaster={ExamBanner} handleGoBackExamDetail={handleGoBackExamDetail}>
      {tempMotivationSections.motivation_text && 
      <div className="rate-important-top">
        <div className="profile-capture-wrapper">
          <p className="rate-ttl">{tempMotivationSections.motivation_text}</p>
        </div>
      </div>}
      {examTypeIds.length === 0 &&
      <>
      <div className="profile-capture-wrapper">
        <div className="profile-capture-form">
          <div className="option-2-item-wrapper">
            {tempMotivationSections?.profile_motivation_statements?.length > 0 && tempMotivationSections.profile_motivation_statements.map(item => {
              return (
                <div className="list-option-item" key={item.id}>
                  <div className={`option-item ${item.checked ? 'active' : ''}`}  onClick={() => onSelectExamType(item.id)}>
                    <input className="form-control" type="checkbox" checked={item.checked ? true : false} readOnly={true}/>
                    <label>{item.exam_type_name}</label>
                  </div>
                </div>
              )
            })
            }
          </div>
        </div>
      </div>
      <div className="other-reason-inner">
        <div className="other-reason-w">
           <div className="type-other-social">
             <span className="ttl-other">{ translations['[exam_other]'] }</span>
              <input
                type="text"
                className="form-control type-input"
                placeholder={tempMotivationSections.exam_type_comment_text}
                onChange={e => onChangeExamComment(e.target.value)}
                value={tempMotivationSections.exam_comment || ''}
              />
          </div>
          <div className="select-options-inner">
            <div className="page">{pageOrder}</div>
            <div>
              <button className="btn-back-options button" onClick={() => handleGoBack(dispatch, previousStep, profileId, step, tempMotivationSections?.id)}>{ translations['[button_back]'] }</button>  
              {canGoNext ? 
              <button className="btn-select-options next active" type="button" onClick={onGoNextExam}>{ translations['[button_next]'] }</button>
              :
              <button className="btn-select-options" type="button">{ translations['[button_select_option]'] }</button>
              }
            </div>
          </div>
        </div>
      </div>
      </>
      }
      {examTypeIds.length > 0 && examTypeData.length > 0 &&
      <>
      {examTypeData.map(item => {
        return (
          <>
          {item.exams.length > 0 && 
          <>
            {item.checked && <div className="profile-capture-wrapper" key={item.id}>
            <div className="profile-capture-form profile-capture-plan">
              <label className="lbl-other">{item.exam_type_name}</label>
              {item.exams.map(exam => {
                return (
                  <div className="option-2-item-wrapper">
                    <div className="list-option-item" key={exam.id}>
                      <div className={`option-item ${exam.checked ? 'active' : ''} `} >
                        <input className="form-control" type="checkbox" checked={exam.checked ? true : false} readOnly={true}/>
                        <label className="item-exam">
                          <span className="title-exam" onClick={() => onChooseExam(item.id, exam.id)}>{exam.exam_name}</span>
                          <div   className="group-edit-cancel">
                            <span className="txt-cancel" onClick={() => cancelExam(item.id, exam.id)} style={{display: exam.checked ? '' : 'none'}}>{ translations['[option_cancel]'] }</span>
                            <span className="txt-edit" onClick={() => onChooseExam(item.id, exam.id)} style={{visibility: exam.checked ? 'visible' : 'hidden'}}>{ translations['[option_edit]'] }</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>}
          </>
          }
          </>
        )
      })}
      <div className="other-reason-inner">
        <div className="other-reason-w">
          <div className="select-options-inner">
            <div className="page">{pageOrder}</div>
            <div>
              <button className="btn-back-options button" onClick={() => handleGoBackExamDetail()}>{ translations['[button_back]'] }</button>
              {Object.keys(storeListExamEdited).length > 0 && [...new Set(Object.values(storeListExamEdited))].length == tempMotivationSections?.profile_motivation_statements?.filter(item => item.checked === true).length ? 
              <button className="btn-select-options next active" type="button" onClick={onGoNextSection}>{ translations['[button_next]'] }</button> :
              <button className="btn-select-options" type="button">{ translations['[button_select_option]'] }</button>
              }
            </div>
          </div>
        </div>
      </div>
      </>
      }
      <ModalExam
        onClose={handleHideModal}
        setShowModal={setShowModal}
        open={showModal}
        data={examData}
        currentExamId={currentExamId}
        currentExamTypeId={currentExamTypeId}
        onSelectMotivation={onSelectMotivation}
        onChangeMotivationComment={onChangeMotivationComment}
        onSelectTargetDate={onSelectTargetDate}
        onSelectTargetScore={onSelectTargetScore}
        onSelectPrevAttempt={onSelectPrevAttempt}
        isExamTakenBefore={isExamTakenBefore}
        onSelectPrevAttemptTime={onSelectPrevAttemptTime}
        onChangePrevAttemptComment={onChangePrevAttemptComment}
        setFormValid={setFormValid}
        formValid={formValid}
        dispatch={dispatch}
        storeListExamEdited={storeListExamEdited}
        tempMotivationSections={tempMotivationSections}
        setIsExamTakenBefore={setIsExamTakenBefore}
        translations={translations}
      />
    </ProfileCapturePage>
  )
}

export default ExamPage;