import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowDown(props) {
  return (
    <SvgIcon viewBox="0 0 14 8" style={{fill: 'none'}} {...props}>
      <path d="M1 0.999999L7 7L13 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
    
  );
}
