import React from 'react';
import { Button, FormGroup } from '@material-ui/core';
import { getRoute } from '../../components/Utilities';
import PublicPage from '../../components/PublicPage';
import '../../../sass/public/Public.scss';
import Logo from '../../../assets/image/british_council_english_white.svg';

const ResetPasswordActived = (props) => {

  const onReturnLogin = () => {
    const { history } = props;
    history.push(getRoute("ROOT"));
  }
  return (
    <PublicPage className="reset-password">
      <div className="login-header">
        <div className="my-class">
          <img src={Logo} alt="British Council" />
        </div>
        <div className="line-blue"></div>
        <div className='wrap-title'>
          <h5 className="login-header__title">Welcome to <br/>British Council English</h5>
          <p className="login-header__text">What will you learn today?</p>
        </div>
      </div>
      
      <div className="form-login-block">
        <form className="form">
          <div className="form-title">Password has been reset</div>
          <div className="line"></div>
          <div className="form-ders">You have successfully changed your password. You can now log in with your new password.</div>
          <FormGroup noValidate autoComplete="off" className="form-login">
            <div className="btn-block">
              <Button
                variant="contained"
                onClick={onReturnLogin}
                className="btn btn-indigo"
                style={{ marginLeft: 'auto', padding: '15px 0'}}
              >Go to log in</Button>
            </div>
          </FormGroup>
        </form>
      </div>
      
    </PublicPage>
  )
}

export default ResetPasswordActived;