import { ActionTypes } from '../types';
import ProgressPageApi from '../../services/progressPageApi';

export const getProgressAction = (productId) => (dispatch) => {
  return ProgressPageApi.getprogress(productId).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_PROGRESS_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_PROGRESS_FAIL,
        payload: 'Get profile fail!'
      })
    }
  })
}

export const getProgressProductList = (id) => (dispatch) => {
  return ProgressPageApi.getProducts().then(
    (response) => {
      if (response && response.success) {
        dispatch({
            type: ActionTypes.GET_PRODUCT_PROGRESS_SUCCESS,
            payload:  response.data,
        });

        return response;
      } else {
        dispatch({
          type: ActionTypes.GET_PRODUCT_PROGRESS_FAIL,
          payload: 'Get product fail!'
      });
      }

      return response;
    }
  );
};

export const getProgressPathwayAction = (productId) => (dispatch) => {
  return ProgressPageApi.getPathWay(productId).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_PATHWAY_PROGRESS_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_PATHWAY_PROGRESS_FAIL,
        payload: 'Get pathway fail!'
      })
    }
  })
}

export const getProgressPerformanceAction = (productId) => (dispatch) => {
  return ProgressPageApi.getPerformance(productId).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_PATHWAY_PERFORMANCE_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_PATHWAY_PERFORMANCE_FAIL,
        payload: 'Get performance fail!'
      })
    }
  })
}

export const getProgressMilestonesAction = (productId) => (dispatch) => {
  return ProgressPageApi.getMilestones(productId).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_PATHWAY_MILESTONES_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_PATHWAY_MILESTONES_FAIL,
        payload: 'Get milestones fail!'
      })
    }
  })
}

export const getProgressproficiencyAction = (productId) => (dispatch) => {
  return ProgressPageApi.getProficiency(productId).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_PATHWAY_PROFICIENCY_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_PATHWAY_PROFICIENCY_FAIL,
        payload: 'Get proficiency fail!'
      })
    }
  })
}

export const getProgressPaceAction = (productId,paceType) => (dispatch) => {
  return ProgressPageApi.getPacechart(productId,paceType).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_PROGRESS_PACE_SUCCESS,
        payload: response.data

      })
    } else {
      dispatch({
        type: ActionTypes.GET_PROGRESS_PACE_FAIL,
        payload: 'Get pace fail!'
      })
    }
  })
}