import '../../../../sass/private/OnOffSwitch/OnOffSwitch.scss';

const OnOffSwitch = (props) => {
  const { status, onChangeToogle, disabled } = props;
  return (
    <div className="OnOffSwitch">
      <div
        className={`toggle ${status ? 'active' : ''}`}
        tabIndex={0}
        onClick={disabled? ()=>{}: onChangeToogle}
        onKeyPress={disabled? ()=>{}: onChangeToogle}
      >
        <div className="toggle-icon"></div>
      </div>
      <div className="label">{status ? 'On' : 'Off'}</div>
    </div>
  )
}

export default OnOffSwitch;