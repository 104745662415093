import React from 'react';
import ProgressBar from './ProgressBar';
import { colorChartBarActive, colorChartBarDefault, colorChartBarProgress } from '../../../components/Colors';

const GrammarItem = (props) => {
    const {isMobile, title, total, url, data} = props;

    const item = {
        inProgress: 0,
    }
    return (
        <div className='grammar-item'>
            <p className="grammar-item__title">{title}</p>
            <div className="grammar-item__barchart">
                    <ProgressBar
                        total={total?.total}
                        completed={total?.completed}
                        inProgress={item.inProgress}
                        width={!isMobile ? '20px' : '30px'}
                        height="16px"
                        colorActive={colorChartBarActive}
                        colorDefault={colorChartBarDefault}
                        colorProgress={colorChartBarProgress}
                        showNumber={true}
                        data={data}
                        isMobile={isMobile}
                    />
                <span className="grammar-item__score">
                    {total.percentage}%
                </span>
            </div>
        </div>
    );
};

export default GrammarItem;