import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class helpApi {

  async getHelp() {
    try {
      let url = `help`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}


export default new helpApi();