import { ActionTypes } from '../../actions/types';

const initialState = {
  filterData: {},
  filter: {
    centre: 0,
    dateOfWeek: [], // JS Date of Mon - Sun
    productId: 0,
    timeslot: 0,
    teacherId: 0,
    lessonTypeId: "all-0",
    lessonTaken: 0,
    friend: 0,
    wishList: 0,
    availability: 0,
    filterReady: false,
    timeout: 0, // for delay to get data
  },
  bookingCard: {},
  mySchedule: [],
  passClass: [],
  passClassCurrentPage: 0,
  passClassHasMore: false,
  getBookingTimestamp: 0,
  dateOfWeekActiveIndex: ((new Date().getDay() - 1) < 0)? 6: (new Date().getDay() - 1), // active index of dateOfWeek above, Sun = 6
}

export default function calendarReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_FILTER_CALENDAR_SUCCESS:
      return {
        ...state,
        filterData: payload,
      }
    case ActionTypes.GET_FILTER_CALENDAR_FAIL:
    case ActionTypes.GET_BOOKING_CALENDAR_FAIL:
    case ActionTypes.GET_SCHEDULE_CALENDAR_FAIL:
    case ActionTypes.GET_PASS_CLASS_CALENDAR_FAIL:
      return {
        ...state,
        message: payload
      }
    case ActionTypes.GET_BOOKING_CALENDAR_SUCCESS:
      const { data, timestamp } = payload;

      if (parseInt(timestamp) === state.getBookingTimestamp) {
        return {
          ...state,
          bookingCard: data,
        }
      }
      else {
        return state;
      }
    case ActionTypes.SET_GET_BOOKING_TIMESTAMP:
      return {
        ...state,
        getBookingTimestamp: payload || 0,
      }
    case ActionTypes.SET_ACTIVE_DAY_OF_WEEK:
      return {
        ...state,
        dateOfWeekActiveIndex: payload,
      }
    case ActionTypes.SELECT_DATE_OF_WEEK:
      return {
        ...state,
        filter: {...state.filter,
          dateOfWeek: payload.dateOfWeek,
          timeout: payload.timeout || 0,
        },
      }
    case ActionTypes.GET_SCHEDULE_CALENDAR_SUCCESS:
      return {
        ...state,
        mySchedule: payload
      }
    case ActionTypes.GET_PASS_CLASS_CALENDAR_SUCCESS:
      return {
        ...state,
        passClass: [...state.passClass, ...((Array.isArray(payload.items) && payload.items) || [])],
        passClassHasMore: payload.hasMore || false,
        passClassCurrentPage: state.passClassCurrentPage + 1,
      }
    case ActionTypes.RESET_PASS_CLASS:
      return {
        ...state,
        passClass: initialState.passClass,
        passClassHasMore: initialState.passClassHasMore,
        passClassCurrentPage: initialState.passClassCurrentPage,
      }
    case ActionTypes.UPDATE_FILTER:
      return {
        ...state,
        filter: {...state.filter, ...payload, timeout: 0},
      }
    case ActionTypes.RESET_NON_DESKTOP_FILTER:
      return {
        ...state,
        filter: {...state.filter,
          lessonTaken: initialState.lessonTaken,
          friend: initialState.friend,
          wishList: initialState.wishList,
          availability: initialState.availability,
        },
      }
    case ActionTypes.RESET_NON_MOBILE_FILTER:
      return {
        ...state,
        filter: {...state.filter,
          productId: initialState.productId,
          timeslot: initialState.timeslot,
          teacherId: initialState.teacherId,
          lessonTypeId: initialState.lessonTypeId,
        },
      }
    default:
      return state;
  }
}
