import OnOffSwitch from '../Common/OnOffSwitch';
import iconClose from '../../../../assets/svg/close.svg';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { updateFilterAction } from '../../../redux/actions/Calendar/CalendarAction';


const AdvancedFilters = (props) => {
	const dispatch = useDispatch();
	const { hideFilter } = props;
	const filter = useSelector(state => state.calendar.filter);
	const [availability, setAvailability] = useState(filter.availability);
	const [lessonTaken, setLessonTaken] = useState(filter.lessonTaken);
	const [wishList, setWishList] = useState(filter.wishList);
	const [friend, setFriend] = useState(filter.friend);

	const TRUE = 1;
	const FALSE = 0;

	const onToggle = (originalValue, setter) => {
		setter(originalValue? FALSE: TRUE);
	};

	const filters = [
		{
			label: "Availability",
			desc: "Only show classes with availability",
			key: 'availability',
			value: availability,
			onToggle: () => onToggle(availability, setAvailability),
			reset: () => setAvailability(FALSE),
		},
		{
			label: "Lesson taken",
			desc: "Show classes which I have already taken",
			key: 'lessonTaken',
			value: lessonTaken,
			onToggle: () => onToggle(lessonTaken, setLessonTaken),
			reset: () => setLessonTaken(FALSE),
		},
		{
			label: "Wishlist",
			desc: "Only show classes added to the wishlist",
			key: 'wishList',
			value: wishList,
			onToggle: () => onToggle(wishList, setWishList),
			reset: () => setWishList(FALSE),
		},
		{
			label: "Friends going",
			desc: "Only show classes booked by my friends",
			key: 'friend',
			value: friend,
			onToggle: () => onToggle(friend, setFriend),
			reset: () => setFriend(FALSE),
		},
	];

	const reset = () => {
		filters.forEach((item) => {
			item.reset();
		});
	};

	const apply = () => {
		const json = {};

		filters.forEach((item) => {
			json[item.key] = item.value;
		});

		dispatch(updateFilterAction(json));
		hideFilter(false);
	};

	return (
		<div className="advanced-filters">
			<div className="advanced-filters__heading">
				<span className="advanced-filters__label">Advanced filters</span>
				<img
					className="advanced-filters__close"
					src={iconClose}
					alt="Close"
					onClick={() => hideFilter(false)}
				/>
			</div>
			{filters.map((item) =>
				<div className="filters-item" key={item.label}>
					<div className="filters-item__item-label">{item.label}</div>
					<div className="filters-item__item-label">{item.desc}</div>
					<div className="filters-item__item-options">
						<OnOffSwitch status={item.value === TRUE} onChangeToogle={item.onToggle} />
					</div>
				</div>
			)}
			<div className="advanced-filters__footer">
				<Button
					className="btn MuiButton-contained btn-default btn-default--orange"
					onClick={() => reset()}
				>
					Reset
				</Button>
				<Button
					className="btn MuiButton-contained btn-default"
					onClick={() => apply()}
				>
					Apply
				</Button>
			</div>
		</div>
	)
}

export default AdvancedFilters;