export const ClassType = (props) => {
  const {
    classTypeActive,
    onChooseClassType,
    classType
  } = props;
  
  return (
    <>
    {classTypeActive && <div className="profile-capture-wrapper">
      <div className="profile-capture-form">
        <div className="option-item-wrapper option-radio-wrapper">
          <div
            className="list-option-item"
            onClick={() => onChooseClassType('online')}
          >
            <div className={`option-item ${classType === 'online' ? 'active' : ''}`}>
              <input
                className="form-control"
                type="radio"
                readOnly={true}
                checked={classType === 'online' ? true : false}
              />
              <label>Online</label>
            </div>
          </div>
          <div
            className="list-option-item"
            onClick={() => onChooseClassType('f2f')}
          >
            <div className={`option-item ${classType === 'f2f' ? 'active' : ''}`}>
              <input
                className="form-control"
                type="radio"
                readOnly={true}
                checked={classType === 'f2f' ? true : false}
              />
              <label>Face-to-face</label>
            </div>
          </div>
          <div
            className="list-option-item"
            onClick={() => onChooseClassType('both')}
          >
            <div className={`option-item ${classType === 'both' ? 'active' : ''}`}>
              <input
                className="form-control"
                type="radio"
                readOnly={true}
                checked={classType === 'both' ? true : false}
              />
              <label>Both</label>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </>
  )
}