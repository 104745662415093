import React from 'react';
import '../../../../sass/private/CirclePercent/CircularProgressBar.scss';
import keyIcon from '../../../../assets/image/key.png';

class CircularProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const sqSize = this.props.sqSize;
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - dashArray * this.props.completeProgress / 100;
    const text = this.props.text || ((100 - this.props.percentage)/10) + '%';
    const rotate = this.props.completeProgress / 100 * 360 - 90;
    return (
      <svg
          width={this.props.sqSize}
          height={this.props.sqSize}
          viewBox={viewBox}>
          <circle
            className="circle-background"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: (dashArray - dashOffset) || 0
            }}
            transform={`rotate(${rotate} ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
            strokeWidth={`${this.props.strokeWidth}px`} />
          <circle
            className="circle-progress"
            cx={this.props.sqSize / 2}
            cy={this.props.sqSize / 2}
            r={radius}
            strokeWidth={`${this.props.strokeWidth}px`}
            transform={`rotate(-90 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
            style={{
              strokeDasharray: dashArray,
              strokeDashoffset: dashOffset || 0
            }} />
          <img src={keyIcon} alt="Content locked"/>
          <text
            className="circle-text"
            x="50%"
            y="50%"
            dy=".3em"
            textAnchor="middle">
            {`${text}`}
          </text>
      </svg>
    );
  }
}

CircularProgressBar.defaultProps = {
  sqSize: 100,
  percentage: 0,
  strokeWidth: 3
};

export default CircularProgressBar;