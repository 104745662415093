import React, { Component, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { setAvatar } from "./js/redux/actions/auth";
import { clearMessage } from "./js/redux/actions/message";
import { history } from './js/components/History';
import { CssBaseline } from '@material-ui/core';

import { resetNonDesktopFilterAction, resetNonMobileFilterAction } from './js/redux/actions/Calendar/CalendarAction';
import useCheckMobileTablet from './utils/useCheckMobileTablet';

import Login from './js/pages/public/Login';
import Dashboard from './js/pages/private/Dashboard';
import ForgotPassword from './js/pages/public/ForgotPassword';
import AccountActivation from './js/pages/public/AccountActivation';
import AccountActived from './js/pages/public/AccountActived';
import ResetPassword from './js/pages/public/ResetPassword';
import ResetPasswordActived from "./js/pages/public/ResetPasswordActived";
import NotFound from "./js/pages/public/NotFound";
import CookiesSettings from "./js/pages/public/CookiesSettings";
import ImpersonateStudent from './js/pages/public/ImpersonateStudent';

import ProfilePage from './js/pages/private/Profile/ProfilePage';
import AssessmentPage from './js/pages/private/AssessmentPage/AssessmentPage';
import AssessmentDetails from './js/pages/private/AssessmentPage/AssessmentDetails';
import SettingPage from './js/pages/private/Settings';
import ContactPage from "./js/pages/private/Contact/ContactPage";
import AccountSettingMenu from './js/pages/private/AccountSettingMenu';
import StudentAccount from './js/pages/private/StudentAccount';
import Home from './js/pages/private/Home/Home';
import ProgressPage from './js/pages/private/Progress/ProgressPage';
import MyCoursePage from './js/pages/private/MyCourse/MyCoursePage';
import MyCourseDetailsPage from './js/pages/private/MyCourse/MyCourseDetailsPage';
import PathwayDetails from './js/pages/private/Progress/PathwayDetails';
import TeacherPage from './js/pages/private/Teacher/TeacherPage';

import { getCurrentUser } from './utils/currentUser';
import { getRoute, getTarget } from './js/components/Utilities.js';

import LandingRoute from './router/LandingRoute';
import LoginRoute from './router/LoginRoute';
import ProtectedRoute from './router/ProtectRoute';

import CommunityPage from "./js/pages/private/Community/communityPage";
import MobileBooking from './js/pages/private/MobileBooking';
import TeacherDetail from './js/pages/private/MobileBooking/TeacherDetailPage';
import MobileCourseDetailPage from "./js/pages/private/MobileBooking/MobileCourseDetailPage";
import Maintenance from "./js/pages/public/Maintenance";
import CourseMap from "./js/pages/public/CourseMap/CourseMap";
import CourseMapDetail from "./js/pages/public/CourseMap/CourseMapDetail";
import CourseMapLessonDetail from "./js/pages/public/CourseMap/CourseMapLessonDetail";
import MainNavigator from "./js/pages/public/ProfileCapture/MainNavigator";
import ValidateOnlinePage from "./js/pages/public/ProfileCapture/ValidateOnlinePage";
import useQuery from "./utils/useQuery";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AchievementPage from "./js/pages/private/Achievement/AchievementPage";
import Help from "./js/pages/public/Help";

class App extends Component {
  constructor(props) {
    super(props);

    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    // init avatar from localStorage to redux store
    const currentUser = getCurrentUser();
    this.props.dispatch(setAvatar(currentUser?.responseUserData?.avatar));

    const allowTracking = window.isNativeAppAllowTracking !== false;

    if (allowTracking) {
      const cookie = document.createElement("script");

      cookie.type = "text/javascript";
      cookie.charset = "UTF-8";
      cookie.dataset.documentLanguage = true;
      cookie.dataset.domainScript = "a902e9be-a500-47f5-b438-11a4fb2e1e31";
      cookie.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";

      document.body.appendChild(cookie);

      const cookieFunction = document.createElement("script");

      cookieFunction.type = "text/javascript";
      cookieFunction.text = "function OptanonWrapper() { }";

      document.body.appendChild(cookieFunction);
    }

    const isNativeAppIos = window.isNativeAppIos === true;

      const tagManager = document.createElement("script");

      tagManager.text = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
      "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
      "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
      "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
      "})(window,document,'script','dataLayer','GTM-PW7Q689');";

      document.body.appendChild(tagManager);

    if (allowTracking && !isNativeAppIos) {
      const hotjar = document.createElement("script");

      hotjar.text = "(function(h,o,t,j,a,r){" +
      "h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};" +
      "h._hjSettings={hjid:2393157,hjsv:6};" +
      "a=o.getElementsByTagName('head')[0];" +
      "r=o.createElement('script');r.async=1;" +
      "r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;" +
      "a.appendChild(r);" +
      "})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";

      document.head.appendChild(hotjar);
    }
  }

  render() {
    const currentUser = getCurrentUser();

    return (
      <CssBaseline>
        <BrowserRouter history={history} basename={process.env.PUBLIC_URL}>
          <Switch>
            <LandingRoute exact path={'/'} component={() => { }} user={currentUser}/>
            <LoginRoute exact sensitive path={getRoute('ROOT')} component={Login} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('DASHBOARD')} component={Dashboard} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('PROFILE')} component={ProfilePage} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('ASSESSMENT')} component={AssessmentPage} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('ASSESSMENTDETAILS')} component={AssessmentDetails} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('SETTING')} component={SettingPage} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('CONTACT')} component={ContactPage} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('MYACCOUNT')} component={StudentAccount} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('ACCOUNTSETTINGMENU')} component={AccountSettingMenu} user={currentUser} />
            <ProtectedRoute sensitive path={getRoute('HOME')} component={Home} user={currentUser} />
            <ProtectedRoute sensitive path={getRoute('COMMUNITYPAGE')} component={CommunityPage} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('PROGRESS')} component={ProgressPage} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('PROGRESSDETAIL')} component={PathwayDetails} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('MYCOURSEDETAILS')} component={MyCourseDetailsPage} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('MYCOURSE')} component={MyCoursePage} user={currentUser} />
            <ProtectedRoute sensitive path={getRoute('TEACHERS')} component={TeacherPage} user={currentUser} />
            <ProtectedRoute exact sensitive path={getRoute('ACHIEVEMENTSPAGE')} component={AchievementPage} user={currentUser} />
            <ProtectedRoute sensitive path={getRoute('HELPPAGE')} component={Help} user={currentUser} />
            
            {/* MOBILE */}
            <ProtectedRoute sensitive path={getRoute('BOOKING')} component={MobileBooking} user={currentUser} />
            <ProtectedRoute sensitive path={getRoute('COURSEDETAIL')} component={MobileCourseDetailPage} user={currentUser} />
            <ProtectedRoute sensitive path={getRoute('TEACHERDETAIL')} component={TeacherDetail} user={currentUser} />

            {/* END MOBILE */}

            <Route exact sensitive path={getRoute('FORGOTPASSWORD')} component={ForgotPassword}/>
            <Route exact sensitive path={getRoute('ACCOUNTACTIVATION')} component={AccountActivation}/>
            <Route exact sensitive path={getRoute('ACCOUNTACTIVED')} component={AccountActived}/>
            <Route exact sensitive path={getRoute('RESETPASSWORD')} component={ResetPassword}/>
            <Route exact sensitive path={getRoute('RESETPASSWORDACTIVED')} component={ResetPasswordActived}/>
            <Route exact sensitive path={getRoute('COOKIESSETTINGS')} component={CookiesSettings} />
            <Route exact sensitive path={getRoute('IMPERSONATE_STUDENT')} component={ImpersonateStudent}/>

            <Route exact sensitive path={getRoute('MYFRIENDS')} component={MyFriendsHook} />
            <Route exact sensitive path={getRoute('REDIRECTFEEDBACK')} component={FeedbackHook} />
            <Route exact sensitive path={getRoute('NOTIFICATIONLIST')}>
              <Redirect to={getRoute('HOME') + '?target=' + getTarget('NOTIFICATIONLIST')} />
            </Route>

            <Route exact sensitive path={getRoute('COURSEMAP')} component={CourseMap}/>
            <Route exact sensitive path={getRoute('COURSEMAPDETAIL')} component={CourseMapDetail}/>
            <Route exact sensitive path={getRoute('COURSEMAPLESSONDETAIL')} component={CourseMapLessonDetail}/>
            <Route exact sensitive path={getRoute('PROFILECAPTURE')} component={MainNavigator}/>
            <Route exact sensitive path={getRoute('PROFILECAPTUREVALIDATE')} component={ValidateOnlinePage}/>
            <Route exact sensitive path={getRoute('MAINTENANCE')} component={Maintenance} />
          
            
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </BrowserRouter>
        <AppHook />
      </CssBaseline>
    );
  }
}

const AppHook = () => {
  const { isTablet } = useCheckMobileTablet();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      if (isTablet) {
        resetNonMobileFilterAction();
      }
      else {
        resetNonDesktopFilterAction();
      }
    }
  }, [isTablet]);

  return <></>;
}

const MyFriendsHook = () => {
  const { isTablet } = useCheckMobileTablet();

  return (
    <Redirect to={getRoute(isTablet? 'SETTING': 'HOME') + '?target=' + getTarget('MYFRIENDS')} />
  )
}

const FeedbackHook = () => {
  const { feedbackId } = useParams();

  return (
    <Redirect to={getRoute('HOME') + '?target=' + getTarget('NOTIFICATIONLIST')+'&open='+getTarget('FEEDBACK')+'&feedbackId='+feedbackId}/>
  )
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);