import SvgIcon from '@material-ui/core/SvgIcon';

export default function A(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" style={{fill: 'none'}} {...props}>
      <path d="M15.8333 17.667L9.99999 13.5003L4.16666 17.667V4.33366C4.16666 3.89163 4.34225 3.46771 4.65481 3.15515C4.96737 2.84259 5.3913 2.66699 5.83332 2.66699H14.1667C14.6087 2.66699 15.0326 2.84259 15.3452 3.15515C15.6577 3.46771 15.8333 3.89163 15.8333 4.33366V17.667Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.6055 11H11.3477L10.8477 9.69922H8.55859L8.08594 11H6.85938L9.08984 5.27344H10.3125L12.6055 11ZM10.4766 8.73438L9.6875 6.60938L8.91406 8.73438H10.4766Z" fill="currentColor"/>
    </SvgIcon>
  );
}
