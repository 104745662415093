import { Button, CircularProgress } from "@material-ui/core";
import { memo, useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import ProgressViewItem from "./ProgressViewItem";
import PracticeListLoading from "./PracticeListLoading";
import useProgress from "../../../../hooks/HomePage/useProgress";
import { useSocket } from "../../../../hooks/HomePage/useSocket";

const ProgressListView = (props) => {
  const { handleOpenProgressModal, handleOpenReviewModal, isOpenProgressDetail } = props;
  const [message, setMessage] = useState("");

  const {
    isLoading,
    isLoadingMoreCompleted,
    isLoadingMoreInProgress,
    progressList,
    loadMore,
    showLoadMoreButtonCompleted,
    showLoadMoreButtonInProgress,
    COMPLETED,
    INPROGRESS,
    getData,
    // reloadData,
  } = useProgress();

  useEffect(() => {
    if (isOpenProgressDetail == false) {
      getData();
    }
  }, [isOpenProgressDetail]);
  useSocket({
    callBack: (payload) => {
      // reloadData()
      getData()
      // alert(payload.message);
      setMessage(payload.message);
    }
  });
  return (
    <div className="calendar-view practice-view">
      <div className="calendar-view__header">
        {/* <div className="error">
          {message}
        </div> */}
        <div className="title">
          Achieve a score of 70% or higher to earn your badge and certificate
          for a theme
        </div>
      </div>
      <div className="practice-lists practice-lists--progress">
        <>
          {isLoading ? (
            <div className="practices">
              <p className="heading">
                <Skeleton
                  animation="wave"
                  variant="text"
                  className="skeleton-heading"
                />
              </p>
              <div className="items">
                <PracticeListLoading></PracticeListLoading>
              </div>
            </div>
          ) : (
            <>
              <div className="practices">
                <p className="heading">
                  In Progress ({progressList?.inProgress?.total ?? 0})
                </p>
                {(progressList?.inProgress?.themes?.length === 0 || !progressList?.inProgress) ? (
                  <p className="message-not-found">You have no Progress checks available yet. </p>
                ) : ''}
                {progressList?.inProgress?.themes?.length > 0 && (
                  <>
                    <div className="items">
                      {progressList?.inProgress?.themes?.map((item, index) => (
                        <ProgressViewItem
                          url={item.button_url}
                          handleOpenProgressModal={handleOpenProgressModal}
                          handleOpenReviewModal={handleOpenReviewModal}
                          buttonTitle={item.button_text}
                          title={item.product_type_name}
                          subTitle={item.theme_name}
                          desc={item.hint}
                          grade={item.grade}
                          color={item.color}
                          id={item.lesson_id}
                          lessonType={item.lessonType}
                          key={index}
                          classes={"progress-check-item"}
                          item={item}
                        />
                      ))}
                      {isLoadingMoreInProgress && (
                        <PracticeListLoading></PracticeListLoading>
                      )}
                    </div>
                    <div className="btn-show-more-container">
                      {showLoadMoreButtonInProgress && (
                        <>
                          {!isLoadingMoreInProgress ? (
                            <Button
                              variant="contained"
                              onClick={() => loadMore(INPROGRESS)}
                              className="btn btn-show-more"
                            >
                              Load more
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>

              <div className="practices">
                <p className="heading">
                  Completed ({progressList?.completed?.total ?? 0})
                </p>
                {(progressList?.completed?.themes?.length === 0 || !progressList?.completed) ? (
                  <p className="message-not-found">You have not completed any Progress checks, with a score of 70% or higher, yet.</p>
                ) : ''}
                {progressList?.completed?.themes?.length > 0 && (
                  <>
                    <div className="items">
                      {progressList?.completed?.themes?.map((item, index) => (
                        <ProgressViewItem
                          url={item.review_activity_url}
                          handleOpenProgressModal={handleOpenProgressModal}
                          handleOpenReviewModal={handleOpenReviewModal}
                          buttonTitle={item.button_text}
                          title={item.product_type_name}
                          subTitle={item.theme_name}
                          desc={item.hint}
                          grade={item.grade}
                          color={item.color}
                          id={item.lesson_id}
                          lessonType={item.lessonType}
                          key={index}
                          classes={"progress-check-item progress-check-item--completed"}
                          credentialPage={item.button_url}
                          // reviewurl={item.review_activity_url}
                          item={item}
                        />
                      ))}
                      {isLoadingMoreCompleted && (
                        <PracticeListLoading></PracticeListLoading>
                      )}
                    </div>
                    <div className="btn-show-more-container">
                      {showLoadMoreButtonCompleted && (
                        <>
                          {!isLoadingMoreCompleted ? (
                            <Button
                              variant="contained"
                              onClick={() => loadMore(COMPLETED)}
                              className="btn btn-show-more"
                            >
                              Load more
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default memo(ProgressListView);
