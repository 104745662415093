import { useState } from 'react';
import PrivatePage from '../../../components/PrivatePage';
import AdvancedSelects from '../Common/Select';
import CustomSelect from '../Common/CustomSelect';
import useCheckMobileTablet
 from '../../../../utils/useCheckMobileTablet';
import '../../../../sass/private/Progress/ProgressPage.scss';


const ProgressWrapper = (props) => {
  const { onChangeOption, productList, productId, isLoading, courseCode, hideProductTabs } = props;
  const { isTablet } = useCheckMobileTablet()
  const setSelectedOption = (opt) => {
		onChangeOption("product",opt.id)
	}

  const userData = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  let selectedProduct = {};

  if (Array.isArray(productList)) {
    selectedProduct = productList.find((item) => item.id === productId) || {};
  }
  return (
    <PrivatePage className={`ProgressPage-block ${props.className ? props.className : ''}`} isShowMainNavigation={true}>
      <div className="ProgressPage">
        <div className="line"></div>
        <header>
          <div className="header-left">
            <div className="sub-title">{courseCode} {userData?.responseUserData?.levelName}</div>
            <h2 className="title">Progress tracking</h2>
          </div>

          {/* { isTablet ? (
            <div className="custom-select-product">
              <span className="titleSelect">Product</span>
              <CustomSelect 
              options={productList}
              selected={selectedProduct}
              setSelectedOption={setSelectedOption}
              />
            </div>
            ) : (
              <div className="header-right">  
                <AdvancedSelects data={productList} label="" value={productId} onChangeOption={onChangeOption} nameSelect="centre"/>
              </div>
            )
          } */}
          
          {(!hideProductTabs && productList.length > 1) ? 
            <div className="header-select-product">
              {productList.map(item => {
                return <button type='button' disabled={isLoading} className={item.id === productId ? 'active' : ''} onClick={() => setSelectedOption({id:item.id})} key={item.id}>{item.name}</button>
              })}
            </div>
          : ''}


        </header>
        <main className="progress-main">
          <div className="main-content">
            {props.children}
          </div>
        </main>
      </div>
    </PrivatePage>
  )
}

export default ProgressWrapper;