import { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#23085A',
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '15px 12px',
    margin: '5px 0',
  },
  arrow: {
    width: '10px',
    height: '10px',
    marginTop: '-5px !important',
    marginBottom: '-5px !important',
    '&::before': {
      background: '#23085A',
      transform: 'none',
      borderRadius: '20px',
    },
  },
}))(Tooltip);

const CustomTooltip = (props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleKeyOnClick = (e) => {
    if (!props.controlOnClick)
      e.stopPropagation(); // prevent parent event
  };

  return (
    <StyledTooltip
      PopperProps={{
        disablePortal: true,
      }}
      open={open}
      onClose={handleTooltipClose}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
      title={props.title}
      {...props}
    >
      <span onClick={handleKeyOnClick} onMouseOver={handleTooltipOpen} onMouseOut={handleTooltipClose}>{props.children}</span>
    </StyledTooltip>
  )
}

export default CustomTooltip;
