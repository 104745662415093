import {combineReducers} from "redux";
import auth from "./auth";
import message from "./message";
import assessment from './assessment';
import communicationData from './communicationReducer';
import settingPassword from './settingPasswordReducer';
import booking from './Booking/bookingReducer';
import profile from './Profile/profileReducer';
import studentAccount from './StudentAccount/studentAccountReducer';
import landing from './Home/HomeReducer';
import calendar from './Calendar/CalendarReducer';
import bookingWindow from './BookingWindow/BookingWindowReducer';
import contactList from './Contact/ContactReducer';
import progress from './ProgressPage/ProgressReducer';
import teacherList from './Teacher/TeacherReducer';
import myClassFriend from './MyClassFriend/MyClassFriendReducer';
import course from './Course/CourseReducer';
import courseDetails from "./Course/CourseDetailsReducer";
import notification from './Notification/NotificationReducer';
import feedback from './Feedback/FeedbackReducer';
import coursemap from './CourseMap/CourseMapReducer';
import profilecapture from './ProfileCapture/ProfileCaptureReducer';
import achievement from "./Achievements/AchievementsReducer";
import help from "./Help/HelpReducer";

export default combineReducers({
    auth,
    message,
    assessment,
    communicationData,
    settingPassword,
    booking,
    profile,
    studentAccount,
    home: landing,
    calendar,
    bookingWindow,
    contactList,
    progress,
    teacherList,
    myClassFriend,
    course,
    courseDetails,
    notification,
    feedback,
    coursemap,
    profilecapture,
    achievement,
    help,
});