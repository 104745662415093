import '../../../../sass/private/Home/ItemLessonMobile.scss'
import { Button } from '@material-ui/core';
import { windowOpen } from '../../../components/Utilities';

import LocationTeacherDisplay from '../Common/LocationTeacherDisplay';
import AvailabilityDisplay from '../Common/AvailabilityDisplay';

import Users from '../../../components/icon/Users';
import Star from '../../../components/icon/Star';
import Level from '../../../components/icon/Level/index';
import Workplace from '../../../components/icon/Workplace';
import Check from '../../../components/icon/Check';
import ReactHtmlParser from 'react-html-parser';
import Layers from '../../../components/icon/Layers';
import Iconnews from "../../../../assets/image/new-icon.png";

export const ItemLessonMobile = (props) => {
  const {
    nameLesson, 
    time,
    teacher,
    borderColor,
    onClickLesson,
    lessonTimeId,
    showJoinNow,
    joinLink,
    joinNowText,
    showIconUsers,
    showIconStar,
    showIconLevel,
    showIconWorkplace,
    showIconTick,
    availabilityText,
    showAlertIcon,
    module,
    theme,
    themeBook
  } = props

  const cateColor = theme?.left_font_color;
  const cateBorderColor = theme?.product_category_color === '#23085A' ? '#fff' : 'transparent';
  const typeColor = theme?.right_font_color;
  const newIcon = theme?.is_new;
  return (
    <div 
      className="item-lesson lesson-theme-booked booking-text-mob"
      style={{borderLeft: `6px solid ${theme.pathway_color ? theme.pathway_color : 'gray'}`}}
      onClick={() => onClickLesson(lessonTimeId)}
    >
      {newIcon == 1 ? <div className='module-view-item__iconnew'>
        <img className="icon-new icon-new-mb" src={Iconnews} alt="New Icon" />
      </div>  : "" }
      {theme &&
        <div className="text booking-text">
          <span className="cate" style={{backgroundColor: theme?.product_category_color, color: cateColor, borderColor: cateBorderColor}}>{theme?.product_category_name}</span>
          <span className="cate" style={{backgroundColor: theme?.product_type_color, color: typeColor}}>{theme?.product_type_name}</span>
        </div>
      }
      <div className="name-lesson secondary">{ReactHtmlParser(nameLesson)}</div>
      <div>
        <LocationTeacherDisplay time={time} {...teacher} />
        <AvailabilityDisplay availabilityText={availabilityText} showAlertIcon={showAlertIcon} />
      </div>
      {showJoinNow && 
        <Button 
          variant="contained" 
          className="btn btn-join-mobile secondary"
          onClick={(e) => windowOpen(joinLink, e)}
        >
          {joinNowText}
        </Button>
      }
      <div className="icon-block secondary">
        {showIconUsers && <Users />}
        {showIconStar && <Star />}
        {showIconLevel && <Level code={showIconLevel} />}
        {showIconWorkplace && <Workplace />}
        {showIconTick && <Check />}
        {module &&  (themeBook ? <div><Layers /> </div>: <div></div> )}
      </div>
    </div>
  )
}

export default ItemLessonMobile