import { memo, useEffect, useState } from 'react';
import ProfileWraper from '../Profile/ProfileWraper';
import Accordion from './Accordion';
import modalAudioApi from '../../../redux/services/modalAudioApi';

import '../../../../sass/private/Booking/ModalAudio.scss';
import AudioPlayer from '../Common/AudioPlayer';

const ModalAudio = (props) => {
  const { onCloseModalAudio, idAudio, nameAudio } = props;
  const [myData, setMyData] = useState({})
  const [srcAudio, setSrcAudio] = useState("")
  const [playing, setPlaying] = useState(false) 

  const onClose = () => {
    setPlaying(false)
    onCloseModalAudio()
  }

  const onChangeAccordion = (id) => {
    let newData = {...myData}

    if(myData.active) {
      newData = {...myData, active: false, title: "Show transcript"}

    }
    else {
      newData = {...myData, active: true, title: "Hide transcript"}
    }
    setMyData(newData)
  }

  useEffect(() => {
    idAudio && modalAudioApi.getAudioDetails(idAudio).then(data => {
      setMyData({
        title: "Show transcript",
        desc: data.transcript,
        active: false,
      })
      setSrcAudio(data.audioLink)
    })
  },[idAudio])

  return (
    <ProfileWraper 
      onClose={onClose} 
      title={`Listen again - ${nameAudio}`}
      isHideBtnBottom 
      isShowClose
      className="modal-audio">
      <div className="audio-details">
        <Accordion data={myData} onChangeAccordion={onChangeAccordion}/>

        <div className="line"></div>

        {
          srcAudio && <AudioPlayer src={srcAudio} playing={playing} onChangePlay={setPlaying}/>
        }
        

      </div>
    </ProfileWraper>
  )
}

export default memo(ModalAudio);