import { ActionTypes } from '../../actions/types';

const initialState = {
  data: {},
  productList: [],
  pathwayList: [],
  performanceList: [],
  milestonesList: [],
  proficiencyData: {},
}

export default function progressReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_PROGRESS_SUCCESS:
      return {
        ...state,
        data: payload,
      }
    case ActionTypes.GET_PROGRESS_FAIL:
    case ActionTypes.GET_PRODUCT_PROGRESS_FAIL:
      return {
        ...state,
        message: payload
      }
    case ActionTypes.GET_PRODUCT_PROGRESS_SUCCESS:
      return {
        ...state,
        productList: payload,
      }
    case ActionTypes.GET_PATHWAY_PROGRESS_SUCCESS:
      return {
        ...state,
        pathwayList: payload
      }
    case ActionTypes.GET_PATHWAY_PERFORMANCE_SUCCESS:
      return {
        ...state,
        performanceList: payload,
      }
    case ActionTypes.GET_PATHWAY_PERFORMANCE_FAIL:
      return {
        ...state,
        message: payload
      }
    case ActionTypes.GET_PATHWAY_PROFICIENCY_SUCCESS:
      return {
        ...state,
        proficiencyData: payload,
      }
    case ActionTypes.GET_PATHWAY_PROFICIENCY_FAIL:
      return {
        ...state,
        message: payload
      }
    case ActionTypes.GET_PATHWAY_MILESTONES_SUCCESS:
      return {
        ...state,
        milestonesList: payload,
      }
    case ActionTypes.GET_PATHWAY_MILESTONES_FAIL:
      return {
        ...state,
        message: payload
      }

      case ActionTypes.GET_PROGRESS_PACE_SUCCESS:
        return {
          ...state,
          paceChart: payload,
        }
      case ActionTypes.GET_PROGRESS_PACE_FAIL:
        return {
          ...state,
          message: payload
        }

    case ActionTypes.GET_PATHWAY_PROGRESS_FAIL:
      return {
        ...state,
        message: payload
      }
    default:
      return state;
  }
}