const CourseMapMenuItem = (props) => {
  const { menuList, clickOnSubMenu, currentPath, accordionOpen, handleAccordionOpen, handleKeypress, menuName } = props
  function toggleAccordion() {
    return handleAccordionOpen(!accordionOpen)
  }
  function activeItem(item) {
    return currentPath === item.product_category_name
  }
  
  return (
    <ul className="menu-list">
      {menuList.length > 0 && menuList.map((item, index) => {
        return (
          <li tabIndex={0} key={index} onKeyPress={(e) => handleKeypress(e, item.product_category_name, menuName)}>
            <div className={`menu-list__item ${activeItem(item) ? 'active' : ''}`} style={{
              borderColor: item.color_code ? item.color_code : '#23085a'
            }}
             onClick={() => {
              clickOnSubMenu(item.product_category_name, menuName); 
              if (activeItem(item)) { toggleAccordion() }
            } }>
              <div className="menu-list__item--text">
                <img src={item.icon} width="24px" height="24px" alt={''} />
                {item.product_category_name} <span>{item.items ? `(${item.items.length})` : ""}</span>
              </div>
              {activeItem(item) &&
                <svg onClick={toggleAccordion} className={accordionOpen ? 'open' : ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 15L12 9L6 15" stroke="#00EDC3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              }

            </div>
          </li>
        )
      })}
    </ul>
  )
}

export default CourseMapMenuItem