import { ActionTypes } from '../../actions/types';

const initialState = {
  data: {},
  productList: [],
  error: '',
  creditHistoryData: [],
  isShowMore: false
};

export default function studentAccountReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.GET_ACCOUNT_CREDITS_SUCCESS:
      return { ...state, data: payload }
    case ActionTypes.GET_PRODUCT_ACCOUNT_SUCCESS:
      return { ...state, productList: payload };
    case ActionTypes.GET_PRODUCT_ACCOUNT_FAIL:
    case ActionTypes.GET_ACCOUNT_CREDITS_FAIL:
    case ActionTypes.GET_CREDITS_HISTORY_FAIL:
      return { ...state, error: payload };
    case ActionTypes.GET_CREDITS_HISTORY_SUCCESS:
      let mapCreditHistoryData = [...state.creditHistoryData, ...payload.creditHistories];
      return {...state, creditHistoryData: mapCreditHistoryData, isShowMore: payload.hasMore}
    case ActionTypes.REDUCE_CREDITS_HISTORY:
      let reducedList = state.creditHistoryData.slice(0, 20);
      return {...state, creditHistoryData: reducedList, isShowMore: true}
    case ActionTypes.CLEAR_CREDITS_HISTORY:
      return {...state, creditHistoryData: [], isShowMore: false}
    default:
      return state;
  }
}