import React, { useState, useEffect } from 'react';
import ProfileWraper from '../Profile/ProfileWraper';
import { withRouter, useHistory } from 'react-router';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';

import { getTarget } from '../../../components/Utilities';
import useQuery from '../../../../utils/useQuery';

import Navbar from './Navbar';
import Commmunication from './Communication';
import MyClassFriend from './MyClassFriend';
import MyPassword from './MyPassword';

import '../../../../sass/private/AccountSetting/AccountSetting.scss';
import NavbarMobile from './NavbarMobile';


const menuListInitialMb = [
  {
    value: 'Communication',
    active: false,
    id: 1,
    component: <Commmunication />
  },
  {
    value: 'Friends',
    active: false,
    id: 2,
    component: <MyClassFriend />
  },
  {
    value: 'Password',
    active: false,
    id: 3,
    component: <MyPassword />
  }
];

const menuListInitial = [
  {
    name: 'Communication',
    active: true,
    id: 1,
  },
  {
    name: 'Friends',
    active: false,
    id: 2,
  },
  {
    name: 'Password',
    active: false,
    id: 3,
  }
];

const SettingPage = (props) => {
  const { activePage } = props;
  const [menuData, setMenuData] = useState(menuListInitial);
  const [activeId, setActiveId] = useState(1);
  const history = useHistory();
  const { isTablet } = useCheckMobileTablet();
  const queryParam = useQuery();

  const [menuDataMb, setMenuDataMb] = useState(menuListInitialMb);

  const onClose = () => {
    isTablet ? history.goBack() : props.onClose()
  }

  useEffect(() => {
    menuData.map(item => {
      if (item.active) {
        setActiveId(item.id);
      }
      return item;
    })
  }, [menuData]);

  useEffect(() => {
    if (queryParam.target === getTarget("MYFRIENDS")) {
      isTablet? onClickMenuMb(2): onClickMenu(2);
      history.replace(history.location.pathname);
    }
    if (activePage) {
      isTablet? onClickMenuMb(2): onClickMenu(2);
    }
  }, []);

  const onClickMenu = (id) => {
    const newMenuData = [...menuData];
    newMenuData.filter(item => {
      if (item.id === id) {
        item.active = true;
        setActiveId(id);
      } else {
        item.active = false;
      }
      return item;
    });
    setMenuData(newMenuData);
  }

  const onClickMenuMb = (id) => {
    const newMenuData = [...menuDataMb];
    newMenuData.filter(item => {
      if (item.id === id) {
        item.active = !item.active;
      } else {
        item.active = false;
      }
      return item;
    });
    setMenuDataMb(newMenuData);
  }

  return (
    <ProfileWraper 
      onClose={onClose} 
      showTitle="Settings"
      title="Settings"
      isShowBack 
      isHideBtnBottom
      className="setting-page"
    >
      {isTablet ?
      <div className="setting">
        <NavbarMobile menuLists={menuDataMb} onClickMenu={onClickMenuMb} />
      </div> :
      <div className="setting">
        <Navbar menuLists={menuData} onClickMenu={onClickMenu} />
        {activeId === 1 && <Commmunication />}
        {activeId === 2 && <MyClassFriend />}
        {activeId === 3 && <MyPassword />}
      </div>}
    </ProfileWraper>
    
  )
}

export default withRouter(SettingPage);