import { ActionTypes } from '../types';
import NotificationApi from '../../services/notificationApi';

export const notificationAction = (page, limit) => (dispatch, getState) => {
  return NotificationApi.getNotification(page, limit).then(response => {
    const state = getState();
    const notificationData = state.notification?.data?.notifications || [];
    if (response && response.success) {
      response.data.notifications = [...notificationData, ...response.data.notifications]
      dispatch({
        type: ActionTypes.GET_NOTIFICATION_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_NOTIFICATION_FAIL,
        payload: 'Get notification fail!'
      })
    }
  })
}

export const resetNotificationState = (recall = true) => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_NOTIFICATION,
    payload: ''
  })
  recall && dispatch(notificationAction(1, 10));
}

export const dismissItem = (index, id) => (dispatch) => {
  dispatch({
    type: ActionTypes.DISMISS_NOTIFICATION,
    payload: index
  })
  return NotificationApi.dismissNotiItem(id);
}
