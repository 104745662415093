import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class ProfileApi {
  async getProfile(id) {
    try {
      let url = `account-info?id=${id}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async updateAvatar(imagePreviewUrl, id) {
    try {
      let url = `update-avatar?id=${id}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'post',
          imagePreviewUrl,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async deleteAvatar(id) {
    try {
      let url = `delete-avatar?id=${id}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'delete',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
}

export default new ProfileApi();