import React, {Fragment} from 'react';
import '../../../../sass/private/StudentAccount/CreditHistory.scss';
import ReactHtmlParser from 'react-html-parser';

const CreditHistory = (props) => {
  const { data, onShowMore, isShowMore, onShowLess, isShowLess } = props;
  return (
    <Fragment>
      <table id="CreditHistory">
        <thead>
          <tr>
            <th>Lesson</th>
            <th>Credits</th>
            <th>Balance</th>
          </tr>
        </thead>
        {data && data.map((item, index) => {
          return (
            <tbody key={index}>
              <tr>
                <td>
                  {/* <tr> */}
                    {item.item && item.item.map((value, index) => {
                      return <p key={index}>{ReactHtmlParser(value)}</p>
                    })}
                  {/* </tr> */}
                </td>
                <td style={{ color: `${item.credit < 0 ? 'var(--color-error)' : '#00953B'}` }}>{item.credit}</td>
                <td>{item.balance}</td>
              </tr>
            </tbody>
          )
        })}
      </table>
      <div className="credit-footer">
      <div className="btn-block">
          {isShowLess && <button className="btn btn-showless" onClick={onShowLess}>Show less</button>}
          {isShowMore && <button className="btn btn-loadmore" onClick={onShowMore}>Show more</button>}
        </div>
      </div>
    </Fragment>
  )
}

export default CreditHistory;