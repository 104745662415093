import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (rest.user && rest.user.credentials.expires_in) {
              return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
            } else {
              return (<Component {...props} {...rest} />);
            }
        }}
    />
);
export default LoginRoute;
