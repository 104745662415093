import  "../../../../sass/private/Common/AudioPlayer.scss";
import pauseIcon from '../../../../assets/svg/pause.svg'
import playIcon from '../../../../assets/svg/play.svg'
import unmuteIcon from '../../../../assets/svg/unmute.svg'
import volumexIcon from '../../../../assets/svg/volumex.svg' 
import { useState, useEffect, useRef } from "react";

const convertSecondToHms = (seconds) => {
  if (!seconds) {
    return "00:00";
  }

  const dateStr =  new Date(seconds * 1000).toISOString();
 
  if (seconds >= 3600) {
    return dateStr.substr(11, 8);
  }

  return dateStr.substr(14, 5);
};

const AudioPlayer = (props) => {
  const { src, playing, onChangePlay } = props;
  
  const [playerRef, setPlayerRef] = useState(new Audio(src));
  playerRef.preload = "metadata";

  // const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [currentTime, setCurrentTime] = useState("00:00");
  const [progress, setProgress] = useState(0);

  const duration = convertSecondToHms(playerRef.duration)
  
  const onTimeUpdateHandler = (evt) => {
    if (!evt.target) {
      return;
    }
    
    const time = convertSecondToHms(evt.target?.currentTime);
    time !== currentTime && setCurrentTime(time);
    
    const percent = evt.target?.currentTime / evt.target?.duration * 100;
    setProgress(percent);
  }
  
  const onPlayerEndedHandler = () => {
    onChangePlay(false)
  }

  const togglePlayer = () => onChangePlay(!playing);
  const toggleMuted = () => setMuted(!muted);

  useEffect(() => {
    setPlayerRef(new Audio(src));
  }, [src])
  
  useEffect(() => {
      playing ? playerRef.play() : playerRef.pause();
      playerRef.muted = muted;

      return () => {
        playerRef.current?.pause();
      };
    },
    // eslint-disable-next-line
    [playing, muted]
  );

  useEffect(() => {
    const $player = playerRef;
    setProgress(0);
    setCurrentTime("00:00");
    $player.addEventListener("ended", onPlayerEndedHandler);
    $player.addEventListener("timeupdate", onTimeUpdateHandler);

    return () => {
      $player.removeEventListener("ended", onPlayerEndedHandler);
      $player.removeEventListener("timeupdate", onTimeUpdateHandler);
    };
    // eslint-disable-next-line
  }, [playerRef]);

  return (
    <div className="audio-player">
      <div className="audio-player__wrapper">
        <div
          className="audio-player__progress-bar"
          style={{width: `${progress}%`}}
        >
        </div>
        <div className="audio-player__control">
          { src && 
            (
              <img
                src={playing ? pauseIcon : playIcon}
                alt={playing ? "Pause" : "Play"}
                onClick={togglePlayer}
              />
            )
          }
        </div>
        {
          !src ?
          (
          <div className="audio-player__right">
            <span>Something went wrong</span>
          </div>
          ) :
          (
            <div className="audio-player__right">
              <span>{ currentTime }</span>
              <span>/</span>
              <span>{ duration }</span>
            </div>
          )
        }
      </div>
      <img
        className="audio-player__mute"
        src={muted ? volumexIcon : unmuteIcon}
        alt={muted ? "Muted" : "Unmute"}
        onClick={toggleMuted}
      />
    </div>
  )
}

export default AudioPlayer;
