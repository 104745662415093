import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class CommunicationApi {
  async getCommunication(id) {
    try {
      const responseData = await makeRequest(
        getAPIEndpoint() + `communicationSetting?id=${id}`,
        'get',
        null,
        true,
    );
    return Promise.resolve(responseData);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async updateCommunication(id, checked, unchecked) {
    try {
      const data = {
        id,
        checked,
        unchecked
      }
      const responseData = await makeRequest(
        getAPIEndpoint() + `comms/update`,
        'post',
        data,
        true,
      );
      return Promise.resolve(responseData);
    } catch(error) {
      return Promise.reject(error);
    }
  }
}

export default new CommunicationApi();