import { Button } from '@material-ui/core';
import iconArrowLeft from '../../../../assets/svg/arrowleft.svg'
import iconClose from '../../../../assets/svg/close.svg';

import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';

import '../../../../sass/private/profile/ProfileWraper.scss';
import MainMenu from '../HeaderMenu/MainMenu';

const ProfileWraper = (props) => {
  const { 
    onClose, 
    title, 
    showTitle,
    className, 
    isHideBtnBottom, 
    isShowClose, 
    isShowBack,
    showHeader,
    isShowHeader
  } = props;
  const { isTablet } = useCheckMobileTablet();

  const onClosePage = () => {
    onClose();
  }

  return (
    <div className={`profile-wraper ${className}`}>
      {isTablet && isShowBack &&
        <div className="profile-wraper__mobile-header">
          <button type='button' className='btn-back' onClick={onClosePage}>
            <img 
              src={iconArrowLeft} 
              alt="iconleft" 
              className="iconBack"/>
          </button>
          <div className="profile">{title}</div>
        </div>
      }

      {!isTablet && showTitle &&
        <div className="profile-wraper__header">
          {showTitle && <div className="line-yellow"></div>}
          {showTitle && <h2 className="title">{showTitle}</h2>}
        </div>
      }

      { showHeader && 
        <div className="profile-wraper__header-modal">
          <div className="line"></div>
          {showHeader}
        </div>}

      {isTablet && isShowClose &&
        <div className="profile-wraper__mobile-header">
          <div className="profile">{title}</div>
          <img 
            onClick={onClosePage}
            src={iconClose} 
            alt="iconClose" 
            className="iconClose"/>
        </div>
      }
      {isTablet && isShowHeader &&
        <MainMenu />
      }
      
      <div className="profile-wraper__main">
        {!isTablet && isShowClose !== false && <Button variant="contained" onClick={onClosePage} className="btn btn-return btn-top btn-close">Close</Button>}
        
        {props.children}
        {isHideBtnBottom ? null : <Button variant="contained" onClick={onClosePage} className="btn btn-return btn-bottom">Close</Button>}
      </div>
    </div>
  )
}

export default ProfileWraper;