let CONFIG = {};

if (process.env.NODE_ENV === 'development') {
  CONFIG = {
    apiUrl: '/v2/student/resource/api/',
    googleMapKey: 'AIzaSyCo3sU1KF-rckmU3-VhO66S2LPlR7TQsnA',
  }
} else {
  CONFIG = {
    apiUrl: '/v2/student/resource/api/',
    googleMapKey: 'AIzaSyCSI6tU25nfS-DJZzXqBS4mQ6-siuKjsTA',
  }
}

export default Object.freeze(CONFIG);
