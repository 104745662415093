import { useEffect, useState } from "react";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import BannerCreate from '../../../../assets/image/bnr-create.png';
import { setProfileCaptureFinalText, setStep } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import { handleGoBack } from "../../../../utils/helper";

const FinalPage = (props) => {
  const {
    history,
    dispatch,
    step,
    previousStep,
    nextStep,
    centreId,
    profileId,
    pageOrder,
    setSubmitStep,
    setSubmitFlag, 
    setFinalText,
    lastText,
    commentFinal,
    translations
  } = props

  const onComplete = () => {
    dispatch(setStep(nextStep))
    setSubmitStep(step)
    setSubmitFlag(true)
  }

  const onChangeText = (value) => {
    setFinalText(value); 
    dispatch(setProfileCaptureFinalText(value));
  }

  return (
    <ProfileCapturePage history={history} dispatch={dispatch} previousStep={previousStep} step={step} centreId={centreId} profileId={profileId}>
      <div className="create-profile-inner create-profile-would">
        <div className="profile-capture-wrapper">
          <h1 className="ttl-1">{lastText}</h1>
        </div>
        <div className="create-tell-inner create-tell-completed">
          <div className="tell-us-right profile-capture-wrapper">
              {/* <img className="img-create" src={BannerCreate} alt="" /> */}
            </div>
            <div className="profile-capture-wrapper">
              <div className="tell-us-left">
                <textarea
                  className="textarea-final"
                  rows="5"
                  cols="45"
                  placeholder={ translations['[final_screen_textbox]'] }
                  value={commentFinal ?? ''}
                  onChange={e => onChangeText(e.target.value)}
                />
                <div className="select-options-inner">
                  <div className="page">{pageOrder}</div>
                  <div>
                    <button className="btn-back-options button" onClick={() => handleGoBack(dispatch, previousStep, profileId, step)}>{ translations['[button_back]'] }</button>  
                    <button
                      type="button"
                      className="btn btn-start"
                      onClick={onComplete}
                    >
                      { translations['[button_complete]'] }
                    </button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </ProfileCapturePage>
  )
}

export default FinalPage;