import Container from './Container';
import { getString } from './Utilities';

function Page(props) {
  return (
    <Container dir={props.dir ? props.dir : ''} className={"page " + getString(props.className)}>{props.children}</Container>
  )
}

export default Page;
