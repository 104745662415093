import { useEffect, useState } from 'react';
import useFeedback from '../../../hooks/Feedback/useFeedback';
import { useDispatch, useSelector } from 'react-redux';
import { setFeedbackScreenTwo, addFbScreenTwoId, handlePostFeedbacks } from '../../../redux/actions/Feedback/FeedbackAction';
import CustomRating from './CustomRating';
import Helmet from 'react-helmet';


const TEXTAREA_MAX_LENGTH = 100;

const FbQuestion2 = (props) => {
  const dispatch = useDispatch();
  const {feedbackScreenOne, feedbackScreenTwo} = useSelector(state => state.feedback);
  const {lessonTimeId} = props;
  const onComplete = () => {
    props.onComplete();
  }
  const [currentId, setCurrentId] = useState(1);
  const {feedbackDataScreenTwo, openFeedbackScreenTwo} = useFeedback();
  const [currentRatingValue, setCurrentRatingValue] = useState(0);
  const [currentChoice, setCurrentChoice] = useState([]);
  const [currentNote, setCurrentNote] = useState("");

  useEffect(async() => {
    if (lessonTimeId) {
      await openFeedbackScreenTwo(lessonTimeId);
      dispatch(addFbScreenTwoId(lessonTimeId));
    }
  }, [lessonTimeId]);
  
  const handleChangeSingleChoice = (event) => {
    if (event.target.value)
      setCurrentChoice([event.target.value])
  }

  const handleMultipleChoice = (event) => {
    if (event.target.checked) {
      setCurrentChoice([...currentChoice, event.target.value]);
    }
    else {
      setCurrentChoice(currentChoice.filter(item => item !== event.target.value));
    }
  };

  const handleChangeText = (event) => {
    setCurrentNote(event.target.value);
  }

  const onNext = (id, templateName) => {
    dispatch(setFeedbackScreenTwo(
      {
        question_id: id,
        template_name: templateName,
        value: templateName === 'Text entry' ? currentNote : currentChoice,
        note: templateName === 'Text entry' ? "" : currentNote
      }
    ));
    setCurrentChoice([]);
    setCurrentNote("");
    setCurrentRatingValue(null);
    setCurrentId(currentId + 1);
  };

  const onRating = (value) => {
    if (value === currentRatingValue) {
      setCurrentChoice([]);
      setCurrentRatingValue(null);
    } else {
      setCurrentChoice([value]);
      setCurrentRatingValue(value);
    }
  };

  // To make sure all answers are stored into redux
  useEffect(() => {
    if (currentId >= feedbackDataScreenTwo?.length && feedbackScreenTwo?.questions?.length === feedbackDataScreenTwo?.length) {
      dispatch(handlePostFeedbacks(feedbackScreenOne, feedbackScreenTwo));
      onComplete();
    }
  }, [feedbackScreenTwo]);

  const buttonText = (position, total) =>{
    return (position + 1) === total ? "Submit": "Next";
  }

  return (
    <>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
    </Helmet>
    <div className="feedback-wrapper">
      <div className="feedback feedback-2">
        <header>
          <div className="line"></div>
          <h1>Help us improve British Council English</h1>
        </header>
        <main className="main">
          <div className="main-content">
          {feedbackDataScreenTwo?.map((item, index) => {
            const display = index + 1 === currentId ? "block" : "none";

            return (
            <>
            {item.template_name === "Single selection" && 
              <div className="main-desr" key={index} style={{display: "inline-block", display: display}}>
                <div className="question">{item.question_text}</div>
                <div className='single-list'>
                  <ul>
                  {Object.keys(item.options).map((key, i) => {
                    return (
                      <li key={i}>
                        <p className="single-list__item">
                          <input type="radio" id={item.options[key]} name="option" value={key} onChange={handleChangeSingleChoice}/>
                          <label for={item.options[key]} className="single-list__item_name">{item.options[key]}</label>
                        </p>
                      </li>
                    )
                  })}
                  </ul>
                </div>
                {item.show_textbox &&
                <>
                  <label for="question-note" className="question-note">{item.textbox_text}</label>
                  <textarea id="question-note" name="question-note" rows="4" cols="50" placeholder="Please fill in here" 
                    onChange={handleChangeText} style={{resize: "none"}} maxLength={TEXTAREA_MAX_LENGTH}/>
                  <div className="words-remain">{currentNote.length}/{TEXTAREA_MAX_LENGTH}</div>
                </>
                }
                <button className={(item.is_mandatory && currentChoice.length <= 0) ? 
                  "btn-next disabled": "btn-next"} onClick={() => onNext(item.question_id, item.template_name)}>
                    {buttonText(index, feedbackDataScreenTwo.length)}
                </button>
              </div>
            }
            {item.template_name === 'Multiple selection' &&
              <div className="main-desr" key={index} style={{display: "inline-block", display: display}}>
                <div className="question">{item.question_text}</div>
                <div className='multiple-list'>
                  <ul>
                  {Object.keys(item.options).map((key, i) => {
                    return (
                      <li key={i}>
                        <div className="multiple-list__item">
                          <input type="checkbox" id={item.options[key]} name={item.options[key]} value={key} onChange={handleMultipleChoice}/>
                          <label for={item.options[key]} className="multiple-list__item_name">{item.options[key]}</label>
                        </div>
                      </li>
                    )
                  })}
                  </ul>
                </div>
                {item.show_textbox &&
                <>
                  <label for="question-note" className="question-note">{item.textbox_text}</label>
                  <textarea id="question-note" name="question-note" rows="4" cols="50" placeholder="Please fill in here" 
                    onChange={handleChangeText} style={{resize: "none"}} maxLength={TEXTAREA_MAX_LENGTH}/>
                  <div className="words-remain">{currentNote.length}/{TEXTAREA_MAX_LENGTH}</div>
                </>
                }
                <button className={(item.is_mandatory && currentChoice.length <= 0) ? 
                  "btn-next disabled": "btn-next"} onClick={() => onNext(item.question_id, item.template_name)}>
                    {buttonText(index, feedbackDataScreenTwo.length)}
                </button>
              </div>
            }
            {item.template_name === 'True/false' &&
            <div className="main-desr" key={index} style={{display: "inline-block", display: display}}>
              <div className="question">{item.question_text}</div>
                <div className="yesno-list">
                  <ul>
                  {Object.keys(item.options).map((key, i) => {
                    return (
                      <li key={i}>
                        <div className="single-list__item">
                          <input type="radio" id={item.options[key]} name="yesno" value={key} onChange={handleChangeSingleChoice}/>
                          <label for={item.options[key]} className="single-list__item_name">{item.options[key]}</label>
                        </div>
                      </li>
                    )
                  })}
                  </ul>
                </div>
                {item.show_textbox &&
                <>
                  <label for="question-note" className="question-note">{item.textbox_text}</label>
                  <textarea id="question-note" name="question-note" rows="4" cols="50" placeholder="Please fill in here" 
                    onChange={handleChangeText} style={{resize: "none"}} maxLength={TEXTAREA_MAX_LENGTH}/>
                  <div className="words-remain">{currentNote.length}/{TEXTAREA_MAX_LENGTH}</div>
                </>
                }
                <button className={(item.is_mandatory && currentChoice.length <= 0) ? 
                  "btn-next disabled": "btn-next"} onClick={() => onNext(item.question_id, item.template_name)}>
                    {buttonText(index, feedbackDataScreenTwo.length)}
                </button>
            </div>
            }
            {
              item.template_name === 'Rating' &&
              <div className="main-desr" key={index} style={{display: "inline-block", display: display}}>
                <div className="question">{item.question_text}</div>
                <div className="rating-list">
                  <div className="rating_text">
                    <div className="start_text">{item.start_text}</div>
                    <div className="end_text">{Object.values(item.options).length <= 5 ? item.end_text : ""}</div>
                  </div>
                  <div className="rating">
                    {Object.values(item.options).map((val, index) => {
                      return <CustomRating key={index} value={val} onRating={onRating} currentRatingValue={currentRatingValue}/>
                    })}
                  </div>
                  {Object.values(item.options).length > 5 &&
                    <div className="rating_text">
                      <div className="start_text"></div>
                      <div className="end_text">{item.end_text}</div>
                    </div>
                  }
                </div>
                {item.show_textbox &&
                <>
                  <label for="question-note" className="question-note">{item.textbox_text}</label>
                  <textarea id="question-note" name="question-note" rows="4" cols="50" placeholder="Please fill in here" 
                    onChange={handleChangeText} style={{resize: "none"}} maxLength={TEXTAREA_MAX_LENGTH}/>
                  <div className="words-remain">{currentNote.length}/{TEXTAREA_MAX_LENGTH}</div>
                </>
                }
                <button className={(item.is_mandatory && currentChoice.length <= 0) ? 
                  "btn-next disabled": "btn-next"} onClick={() => onNext(item.question_id, item.template_name)}>
                    {buttonText(index, feedbackDataScreenTwo.length)}
                </button>
              </div>
            }
            {
              item.template_name === 'Text entry' &&
              <div className="main-desr" key={index} style={{display: "inline-block", display: display}}>
                <div className="question template_5">{item.question_text}</div>
                {item.show_textbox &&
                <>
                  <label for="question-note" className="question-note">{item.textbox_text}</label>
                  <textarea id="question-note" name="question-note" rows="10" cols="50" placeholder="Optional comments" 
                    onChange={handleChangeText} style={{resize: "none"}}/>
                </>
                }
                <button className={(item.is_mandatory && currentNote.length <= 0) ? 
                  "btn-next disabled": "btn-next"} onClick={() => onNext(item.question_id, item.template_name)}>
                    {buttonText(index, feedbackDataScreenTwo.length)}
                </button>
              </div>
            }
            </>
            );
          })
          }
          </div> 
        </main>
      </div>
    </div>
    </>
  );
}

export default FbQuestion2;