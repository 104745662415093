import { ActionTypes } from '../../actions/types';

const initialState = {
  step: 'welcome',
  lastStep: 'welcome',
  guid: 0,
  centreId: 0,
  languageId: 1,
  profileId: 0,
  motivations: [],
  sections: [],
  components: [],
  stepOrder: [
    'welcome',
    'reason',
  ],
  lastText: '',
  commentFinal: '',
  listExamEdited: {},
  languages: [],
  translations: {}
}

export default function profileCaptureReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_PROFILE_CAPTURE_CENTRE:
      return {
        ...state,
        centreId: payload,
      }
    case ActionTypes.SET_PROFILE_CAPTURE_STEP_ORDER:
      // const stepOrder = [...state.stepOrder, ...payload]
      return {
        ...state,
        // stepOrder: [...new Set(stepOrder)]
        stepOrder: payload
      }
    case ActionTypes.SET_PROFILE_CAPTURE_STEP:
      return {
        ...state,
        step: payload
      }
    case ActionTypes.SET_PROFILE_CAPTURE_PROFILE_ID:
      return {
        ...state,
        profileId: payload,
      }
    case ActionTypes.SET_PROFILE_CAPTURE_GUID:
      return {
        ...state,
        guid: payload,
      }
    case ActionTypes.SET_PROFILE_CAPTURE_MOTIVATIONS:
      return {
        ...state,
        motivations: payload
      }
    case ActionTypes.SET_PROFILE_CAPTURE_SECTIONS:
      return {
        ...state,
        sections: payload
      }
    case ActionTypes.SET_PROFILE_CAPTURE_COMPONENTS:
      return {
        ...state,
        components: payload
      }
    case ActionTypes.UPDATE_PROFILE_CAPTURE_SECTION:
      const newUpdates = state.sections.map(obj => {
        if (obj.type === payload.type)
          return payload.data
        return obj
      })
      return {
        ...state,
        sections: newUpdates
      }
    case ActionTypes.SET_PROFILE_CAPTURE_LAST_SECTION_TEXT:
      return {
        ...state,
        lastText: payload
      }
    case ActionTypes.SET_STORE_LIST_EXAM_EDITED:
      return {
        ...state,
        listExamEdited: payload
      }
    case ActionTypes.SET_PROFILE_CAPTURE_FINAL_TEXT:
      return {
        ...state,
        commentFinal: payload
      }
    case ActionTypes.SET_LANGUAGE_ID:
      return {
        ...state,
        languageId: payload
      }
    case ActionTypes.SET_LANGUAGES:
      return {
        ...state,
        languages: payload
      }
    case ActionTypes.SET_LAST_STEP:
      return {
        ...state,
        lastStep: payload
      }
    case ActionTypes.SET_TRANSLATIONS:
      return {
        ...state,
        translations: payload
      }
    case ActionTypes.RESET_PROFILE_CAPTURE:
      return initialState
    default:
      return state;
  }
}