import { Button, CircularProgress } from '@material-ui/core';
import { memo } from 'react';
import usePractice from '../../../../hooks/Practice/usePractice';
import CustomCircularProgress from '../../Common/CircularProgress';
import PracticeViewItemMobile from './PracticeViewItemMobile';
import { Skeleton } from "@mui/material";
import PracticeListView from './PracticeListView';
import PracticeListLoadingMobile from './PracticeListLoadingMobile';

const PracticeListViewMobile = (props) => {
  const {
    handleOpenPracticeModal
  } = props;
  
  const {
    isLoading,
    isLoadingMoreToDo,
    isLoadingMoreInProgress,
    isLoadingMoreDone,
    practiceList,
    loadMore,
    showLoadMoreButtonToDo,
    showLoadMoreButtonInProgress,
    showLoadMoreButtonDone,
    TODO,
    INPROGRESS,
    DONE
  } = usePractice();
  return (
    <>
      {isLoading ? (
        <div className="PracticeViewItemMobile booked-classes">
          <div className="booked-classes__section">
            <div className="--title"><Skeleton animation="wave" variant="text" className="skeleton-heading" /></div>
            <div className="--list-section">
              <PracticeListLoadingMobile></PracticeListLoadingMobile>
            </div>
          </div>
        </div>
      ) : 
      (
        <div className="PracticeViewItemMobile booked-classes">
          {practiceList?.inProgress?.lessons?.length == 0 && practiceList?.toDo?.lessons?.length == 0 && practiceList?.done?.lessons?.length == 0 && 
            <div className='container-not-found'>
              <h3 className='message'>Once you complete your first lesson your practice activities will be listed here</h3>
            </div>
          }
          
          {practiceList?.inProgress?.lessons?.length > 0 && 
            <div className="booked-classes__section">
              <div className="--title">In progress ({practiceList?.inProgress?.total ?? 0})</div>
              <div className="--list-section">
                {practiceList?.inProgress && practiceList?.inProgress?.lessons?.map((practice, index) => (
                  <PracticeViewItemMobile
                    key={`${index}${practice.lesson_id}`}
                    handleOpenPracticeModal={handleOpenPracticeModal}
                    buttonTitle={'Continue'}
                    title={practice.productType}
                    subTitle={practice.title}
                    date={practice.lastAttendDateMobile}
                    numberDidAsssessment={practice.finish}
                    totalAssessment={practice.total}
                    score={practice.score}
                    id={practice.lesson_id}
                    lessonType={practice.lessonType}
                    practiceType={practice.practiceType}
                  />
                ))}
                {isLoadingMoreInProgress && <PracticeListLoadingMobile></PracticeListLoadingMobile>}
              </div>
            </div>
          }
          
          <div className="btn-show-more-container">
            {showLoadMoreButtonInProgress && 
              <>
                {!isLoadingMoreInProgress ? (
                  <Button variant="contained" onClick={() => loadMore(INPROGRESS)} className="btn btn-show-more">Load more</Button>                      
                ) : ''}
              </>
            }
          </div>
          
          {practiceList?.toDo?.lessons?.length > 0 && 
            <div className="booked-classes__section">
              <div className="--title">To-do ({practiceList?.toDo?.total ?? 0})</div>
              <div className="--list-section">
                {practiceList?.toDo && practiceList?.toDo?.lessons?.map((practice, index) => (
                  <PracticeViewItemMobile
                  key={`${index}${practice.lesson_id}`}
                    handleOpenPracticeModal={handleOpenPracticeModal}
                    buttonTitle={'Continue'}
                    title={practice.productType}
                    subTitle={practice.title}
                    date={practice.lastAttendDateMobile}
                    numberDidAsssessment={practice.finish}
                    totalAssessment={practice.total}
                    score={practice.score}
                    id={practice.lesson_id}
                    lessonType={practice.lessonType}
                    practiceType={practice.practiceType}
                  />
                ))} 
                {isLoadingMoreToDo && <PracticeListLoadingMobile></PracticeListLoadingMobile>}
              </div>
          </div>
          }
          
          <div className="btn-show-more-container">
            {showLoadMoreButtonToDo && 
              <>
                {!isLoadingMoreToDo ? (
                  <Button variant="contained" onClick={() => loadMore(TODO)} className="btn btn-show-more">Load more</Button>                      
                ) : ''}
              </>
            }
          </div>
          
          {practiceList?.done?.lessons?.length > 0 && 
            <div className="booked-classes__section">
              <div className="--title">Done ({practiceList?.done?.total ?? 0})</div>
              <div className="--list-section">
                {practiceList?.done && practiceList?.done?.lessons?.map((practice, index) => (
                  <PracticeViewItemMobile
                    key={`${index}${practice.lesson_id}`}
                    handleOpenPracticeModal={handleOpenPracticeModal}
                    buttonTitle={'Continue'}
                    title={practice.productType}
                    subTitle={practice.title}
                    date={practice.lastAttendDateMobile}
                    numberDidAsssessment={practice.finish}
                    totalAssessment={practice.total}
                    score={practice.score}
                    id={practice.lesson_id}
                    lessonType={practice.lessonType}
                    practiceType={practice.practiceType}
                  />
                ))} 
                {isLoadingMoreDone && <PracticeListLoadingMobile></PracticeListLoadingMobile>}
              </div>
          </div>
          }
          <div className="btn-show-more-container">
            {showLoadMoreButtonDone && 
              <>
                {!isLoadingMoreDone ? (
                  <Button variant="contained" onClick={() => loadMore(DONE)} className="btn btn-show-more">Load more</Button>                      
                ) : ''}
              </>
            }
          </div>
        </div>
      )}
    </>
  )
}

export default memo(PracticeListViewMobile);