import React from 'react';
import PropTypes from 'prop-types';

import bookIcon from '../../../../assets/image/book-open.png';
import arrowIcon from '../../../../assets/image/arrow-white.png';
import penIcon from '../../../../assets/image/Pen.png';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';


import '../../../../sass/private/AssessmentPage/AssessmentItem.scss';

const AssessmentItem = (props) => {
  const {
    data: {level, category, date, teacher, averageScore, id},
    isHideIconArrow,
    onRedirectPageDetail
  } = props;

  const { isTablet } = useCheckMobileTablet();


  return (
    <div
      className="assessment-item"
      tabIndex={0}
      onClick={() => onRedirectPageDetail(id)}
      onKeyPress={() => onRedirectPageDetail(id)}
    >
      <div className="assessment-item__left">
        <div className="">
          {category && <img src={category === 'Speaking' ? bookIcon : penIcon} alt={category === 'Speaking' ? "Speaking assessment" : "Writing assessment"} />}
        </div>
        <div className="assessment-text">
          <p>{level}</p>
          {
            isTablet ? (
              <p>{category} <br /> {date}</p>
            ) : 
            <p>{category} {date}</p>
          }
          <p>{teacher}</p>
        </div>
      </div>
      <div className={`assessment-item__right`}>
        <div className="assessment-score">{averageScore?averageScore : '0'}</div>
        {!isHideIconArrow && <div className="assessment-arrow"><img src={arrowIcon} alt="Arrow icon"/></div>}
      </div>
    </div>
  )
}

AssessmentItem.propTypes = {
  data: PropTypes.object,
  onRedirectPageDetail: PropTypes.func,
  isHideIconArrow: PropTypes.bool
}

AssessmentItem.defaultProps = {
  data: {
    level: '',
    category: '',
    date: '',
    teacher: '',
    scores: 0
  },
  onRedirectPageDetail: () => {},
  isHideIconArrow: false
}

export default AssessmentItem;