import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class CourseLevelApi {
  async getCourseLevel(student_id) {
    try {
      let url = `course-level?student_id=${student_id}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
        return Promise.reject(err)
    }
  }
}

export default new CourseLevelApi();
