import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!rest.user) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      } else {
        if (rest.user.credentials.expires_in) {
          return (<Component {...props} {...rest} />);
        }
      }
    }}
  />
);
export default ProtectedRoute;