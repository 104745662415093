import { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarGlobalState } from '../../pages/private/Calendar/CalendarGlobalState';
import { myScheduleCalendarAction, passClassesCalendarAction, resetPassClassAction } from '../../redux/actions/Calendar/CalendarAction';
import { getCurrentUser } from '../../../utils/currentUser';

const useCalendarPage = (idActiveNavbarCalendar, dataReloadTokenOthers) => {
  const globalContext = useContext(CalendarGlobalState);
  const {state, actions} = globalContext;
  const menuData = state.menuData;
  const setMenuData = actions.setMenuData;
  const dispatch = useDispatch();
  const landing = useSelector(state => state.home.landing);
  const mySchedule = useSelector(state => state.calendar.mySchedule);
  const passClass = useSelector(state => state.calendar.passClass);
  const passClassCurrentPage = useSelector(state => state.calendar.passClassCurrentPage);
  const passClassHasMore = useSelector(state => state.calendar.passClassHasMore);

  const [isLoadingSchedule, setIsLoadingSchedule] = useState(true);
  const [isLoadingPassClasses, setIsLoadingPassClasses] = useState(true);

  const [dataReloadTokenSelf, setDataReloadTokenSelf] = useState(0);

  useEffect(() => {
    const menuFilter = filterMenu(menuData, (menuData.find((item) => item.active)?.id) || 1);
    setMenuData(menuFilter);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (dataReloadTokenSelf) { // run only if > 0, i.e. default state
      getTabData();
    }
  }, [dataReloadTokenSelf]);

  useEffect(() => {
    getTabData(false);
  }, [dataReloadTokenOthers]);

  useEffect(() => {
    // on active tab change, reset all before taking actions
    resetPassClass();
    setIsLoadingSchedule(true);
    setIsLoadingPassClasses(true);

    getTabData();

    return () => {
      resetPassClass();
    };
  }, [idActiveNavbarCalendar]);

  const resetPassClass = () => {
    dispatch(resetPassClassAction());
  };

  const getTabData = (showLoading = true) => {
    if (idActiveNavbarCalendar === 2) {
      getSchedule(showLoading);
      getPassClasses(showLoading);
    }
    // else if (idActiveNavbarCalendar === 3) {
    //   getPassClasses(showLoading);
    // }
  };

  const getSchedule = (showLoading = true) => {
    if (showLoading) {
      setIsLoadingSchedule(true);
    }

    dispatch(myScheduleCalendarAction()).then((response) => {
      if (showLoading && response?.success) {
        setIsLoadingSchedule(false);
      }
    });
  };

  const getPassClasses = (showLoading = true) => {
    if (showLoading) {
      setIsLoadingPassClasses(true);
    }

    dispatch(passClassesCalendarAction(passClassCurrentPage + 1)).then((response) => {
      if (showLoading && response?.success) {
        setIsLoadingPassClasses(false);
      }
    });
  };

  const filterMenu = (menuData, id) => {
    return menuData.filter(item => {
      if(item.id === id) {
        item.active = true;
        globalContext.actions.setIdActiveNavbarCalendar(item.id)
      } else {
        item.active = false;
      }
      
      return item;
    });
  }

  const onClickMenu = (id) => {
    const newMenu = [...menuData];
    const menuFilter = filterMenu(newMenu, id);
    setMenuData(menuFilter);
  }

  const reloadData = () => {
    setDataReloadTokenSelf(new Date().getTime());
  };

  return {
    menuData,
    onClickMenu,
    dataSchedule: mySchedule,
    dataPassClass: passClass,
    isLoadingSchedule,
    isLoadingPassClasses,
    dataReloadToken: dataReloadTokenSelf,
    reloadData,
    showMorePastClass: passClassHasMore? getPassClasses: false,
  }
}

export default useCalendarPage;