import { Fragment, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import CourseMapPage from "../../../components/CourseMapPage"
import useCourseMap from "../../../hooks/CourseMap/useCourseMap"
import CircularProgress from '../../private/Common/CircularProgress';
import CourseMapMenuItem from "./CourseMapMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { TextField, InputAdornment } from "@mui/material";
import { deepSearchItems } from "../../../../utils/helper";
import useQuery from "../../../../utils/useQuery";
import IconsPrint from '../../../../assets/image/printer.png';
import LogoPrint from '../../../../assets/image/logo_british.png';
import iconArrowUp from '../../../../assets/svg/arrowlefts.svg'
import iconArrowUpHover from '../../../../assets/svg/arrowlefts-hover.svg'
import iconSearch from '../../../../assets/svg/search-mediums.svg'
import { Skeleton } from "@mui/material";

import '../../../../sass/public/CourseMap.scss';
import { setMenuName, setTheme, setThemeName } from "../../../redux/actions/CourseMap/CourseMapAction";

const CourseMapDetail = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { themeList, openCourseMapDetail, levelName, isLoading, pdfLink, lessonInfo } = useCourseMap()
  const courseMapLevel = useSelector(state => state.coursemap.level)
  const zoneIdFromStore = useSelector(state => state.coursemap.zoneId)
  const activeTheme = useSelector(state => state.coursemap.themeName)
  const menuName = useSelector(state => state.coursemap.menuName)
  const { zone_id: zoneId } = useQuery()
  const { levelId } = useParams()
  const [currentListItem, setCurrentListItem] = useState([])
  const [currentColor, setCurrentColor] = useState('#fd811f')
  const [currentPath, setCurrentPath] = useState(null)
  const [searchKeyword, setSearchKeyword] = useState(null)
  const [searchResult, setSearchResult] = useState([])
  const [searchOpen, setSearchOpen] = useState(false)
  const [accordionOpen, setAccordionOpen] = useState(true)

  useEffect(() => {
    openCourseMapDetail(levelId, zoneId)
  }, [levelId, zoneId])

  useEffect(() => {
    if (themeList.length > 0 && activeTheme) {
      clickOnSubMenu(activeTheme, menuName)
    }
    if (themeList.length > 0 && !activeTheme) {
      setCurrentPath(themeList[0].items[0].product_category_name)
      setCurrentListItem(themeList[0].items[0].items)
      setCurrentColor(themeList[0].items[0].color_code)
      // clickOnSubMenu(themeList[0].items[0].product_category_name)
    }
  }, [themeList, activeTheme])
  

  const onGoBack = () => {
    dispatch(setThemeName(''))
    dispatch(setMenuName(''))
    history.goBack()
  }
  const clickOnMenu = (menuName) => {
    // if (menuName !== 'MyClass'  && (menuName !== 'Other Products')) {
    //   const data = themeList.filter(item => item.menu_name === menuName)[0]
    //   if (data.color_code) setCurrentColor(data.color_code)
    //   else setCurrentColor('#23085a')
    //   if (data.items) setCurrentListItem(data.items)
    //   else setCurrentListItem([])
    //   if (data.menu_name) setCurrentPath(data.menu_name)
    //   else setCurrentPath([])
    // }
  }

  const clickOnSubMenu = (productCategoryName, menuName) => {
    const pathwayData = themeList.filter(item => item.menu_name === menuName)[0]
    const data = pathwayData.items.filter(item => item.product_category_name === productCategoryName)[0]
    if (data.color_code) setCurrentColor(data.color_code)
    else setCurrentColor('#23085a')
    if (data && data.items) setCurrentListItem(data.items)
    else setCurrentListItem([])
    if (data.product_category_name) setCurrentPath(data.product_category_name)
    else setCurrentPath([])
    dispatch(setThemeName(productCategoryName))
    dispatch(setMenuName(menuName))
  }

  const setSearchQuery = (value) => {
    setSearchKeyword(value)
    if (!value) setSearchResult([])
    else {
      const result = deepSearchItems(themeList, 'theme_name', (k, v) => v.toLowerCase().includes(value.toLowerCase()))
      setSearchResult(result)
    }
  }

  const handleSearchClick = (e) => {
    setSearchOpen(value => !value);
  };
  const toggleAccordion = (e) => {
    setAccordionOpen(e);
  };

  const redirect = (contentId) => {
    const url = `/course-map/lesson/${contentId}` + 
      ((zoneId && typeof zoneId !== undefined) ? `?zone_id=${zoneId}` : '')
    history.push(url)
  }
  
  const handleKeypress = (e, productCategoryName, menuName) => {
    if(e.charCode == 13) {
      clickOnSubMenu(productCategoryName, menuName);      
    }
  };
  
  const handleKeypressItem = (e, id) => {
    if(e.charCode == 13) {
      redirect(id);
    }
  };

  return (
    <CourseMapPage handleSearchClick={handleSearchClick} history={history}>
      <div className="course-map-detail__wrapper course-map__wrapper detail">
        <div className="course-map-detail_top-level">
          {(zoneIdFromStore || zoneIdFromStore === null)  && <div className="back-area" onClick={onGoBack}>
            <div className="back-area__arrow">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 14 4 9l5-5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 20v-7a4 4 0 0 0-4-4H4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <a href="#">Back</a>
          </div>}
          <div className="print__img-logo">
            <img src={LogoPrint} alt="" />
          </div>
          <div className="title-area">
            <h2 className="sub-title">{isLoading && <Skeleton animation="wave" variant="text" className="skeleton-sub-title" />}Course map for</h2>
            <h1 className="title">
              {isLoading && <Skeleton animation="wave" variant="text" width={491} height={80} />}
              {levelName}
            </h1>
          </div>
          {searchOpen}
          <div className={`search-box ${searchOpen ? 'mobile-open' : ''}`}>
          {isLoading && <Skeleton animation="wave" variant="text" height={82} width={250} className="skeleton-search" />}
          {!isLoading &&  <TextField tabIndex={0}
              id="search-bar"
              className="text"
              onInput={(e) => {
                setSearchQuery(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <button style={{
                      backgroundColor: '#23085a',
                      borderRadius: '50%',
                      height: '50px',
                      width: '50px',

                    }}>
                      <img src={iconSearch} alt="Search" />
                    </button>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder="Search"
              size="small"
              disabled={isLoading}
            />}
           
          </div>
          <div className="icon-print icon-print-right" tabIndex={0} onKeyPress={(e) => {if(e.charCode == 13) window.print()}}>
            {!searchKeyword && 
              <>
                {isLoading && <Skeleton animation="wave" variant="text" className="skeleton-print" />}
                {!isLoading && <img src={IconsPrint} tabIndex={0} onClick={() => window.print()} alt="Print preview" />}
              </>
            }
          </div>
        </div>
        <div className="course-map__content">
          {!searchKeyword ?
            <>
              <div className={`menu ${currentListItem.length > 0 ? 'selected' : ''} ${accordionOpen || (themeList.some(i => i.menu_name.includes(currentPath)) && currentPath !== 'Pathways') ? 'accordion-open' : ''}`}>
                {/* {isLoading && <CircularProgress />} */}
                
                {isLoading && <Skeleton animation="wave" variant="text" className="skeleton-item-menu"/>}
                {!isLoading && themeList && themeList.length > 0 &&
                  themeList.map((item, index) => {
                    const menuList = item.items ?? []
                    if(menuList.length > 0) {                
                      return (
                        <div className={`menu-block ${currentPath === item.menu_name ? 'active' : ''}`} key={index}>
                          <div className={`menu-name ${item.menu_name.replace(' ', '').toLowerCase()}`} tabIndex={0} onClick={() => clickOnMenu(item.menu_name)}>
                            {item.menu_name}
                          </div>
                          <CourseMapMenuItem menuList={menuList} handleAccordionOpen={toggleAccordion} accordionOpen={accordionOpen} currentPath={currentPath} 
                          clickOnSubMenu={clickOnSubMenu} redirect={redirect}
                          handleKeypress={handleKeypress}
                          menuName={item.menu_name}
                          />
                        </div>
                      )
                    }
                  })
                }
              </div>
              <div>
                <h1 className="print__title-detail">{menuName != 'MyClass' ? currentPath : 'MyClass '+ currentPath} </h1>
                <h2 className="print__title-detail">{menuName != 'MyClass' ? currentPath : 'Myclass '+ currentPath}</h2>
                <div className="item-grid">
                  {isLoading && <Skeleton animation="wave" variant="text" className="skeleton-list-item" />}
                  {isLoading && <Skeleton animation="wave" variant="text" className="skeleton-list-item" />}
                  {isLoading && <Skeleton animation="wave" variant="text" className="skeleton-list-item" />}
                  {isLoading && <Skeleton animation="wave" variant="text" className="skeleton-list-item" />}
                  {isLoading && <Skeleton animation="wave" variant="text" className="skeleton-list-item" />}
                  {isLoading && <Skeleton animation="wave" variant="text" className="skeleton-list-item" />}

                  {currentListItem.length > 0 && currentListItem.map(item => {
                    return (
                      <div className="item" style={{"--data-color": currentColor}} tabIndex={0} key={item.id} onKeyPress={((e) => handleKeypressItem(e, item.id))} onClick={(() => redirect(item.id))}>
                        <div className="img">
                          <img
                            src={item.image}
                            style={{ borderBottom: "8px solid " + currentColor }}
                            alt={item.theme_name}
                          />
                        </div>
                        <div className="text-sub-ttl-list">
                          <h3 className="text">
                            {item.theme_name}
                          </h3>
                          <div className="text-sub-ttl">
                            <div className="txt-sub">
                              {menuName == 'MyClass' ? item.productTypeName : item.productTypeName}
                            </div>
                            <img src={iconArrowUp} alt="" className="icon-rowup"/>
                            <img src={iconArrowUpHover} alt="" className="hover-iconup" />
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              {/* {!isLoading &&
                <a href={pdfLink ? pdfLink : '#'} target="_blank" style={{ textDecoration: 'none' }}>
                  <div className="download-btn">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#4wott1qa5a)" stroke="#23085A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="m8 17 4 4 4-4M12 12v9" />
                        <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29" />
                      </g>
                    </svg>
                    <p>Download printable map</p>
                    <p className="download-btn--link">CourseMap.PDF</p>
                  </div>
                </a>
              } */}
            </> :
            <div className="item-full-grid">
              <div className="title title-result">
                We have found {searchResult.length} {searchResult.length > 1 ? 'results' : 'result'} for
                <div className="keyword">{" " + searchKeyword}</div>
              </div>
              <div className="item-grid">
                {searchResult.length > 0 && searchResult.map((item, index) => {
                  return (
                    <div className="item" key={index} tabIndex={0} onKeyPress={((e) => handleKeypressItem(e, item.id))} onClick={(() => redirect(item.id))}>
                      <div className="img">
                        <img src={item.image} style={{ borderColor: currentColor }} alt={item.theme_name} />
                      </div>
                      <div className="text">{item.theme_name}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="m9 18 6-6-6-6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          }
        </div>
      </div>
    </CourseMapPage>
  )
}

export default CourseMapDetail