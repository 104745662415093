import { Fragment } from 'react';
import iconGrid from '../../../../assets/image/grid.png';
import iconList from '../../../../assets/svg/list.svg';
import AdvancedSelects from '../Common/Select';
import DatePicker from '../DatePicker/DatePicker';

import '../../../../sass/private/CalendarPage/SortSearch.scss';

const SortSearch = (props) => {
  const {
    isViewPage,
    weekList,
    idActive,
    onChangeWeekList,
    onShowCalendarView,
    dataTeacher,
    dataProductFilter,
    onChangeOption,
    isShowFilter,
    bookAheadLimit,
    isPendingUpdate,
    isLoadingUpdate,
  } = props;

  const isUpdating = isPendingUpdate || isLoadingUpdate;

  const contentAdvanced = () => {
    return (
      <Fragment>
          <div className="lesson">
            <div className="calendar-text">Product</div>
            <AdvancedSelects
              data={dataProductFilter}
              label=""
              value={0}
              onChangeOption={onChangeOption}
              nameSelect="productId"
              disabled={isUpdating}
            />
          </div>
          <div className="teacher">
            <div className="calendar-text">Teacher</div>
            <AdvancedSelects
              data={dataTeacher}
              label=""
              value={0}
              isMultiValue={true}
              isShowSearch={true}
              onChangeOption={onChangeOption}
              nameSelect="teacherId"
              disabled={isUpdating}
            />  
          </div>
      </Fragment>
    )
  }

  return (
    <div className="sort-search">
      <div className="sort-search__filter">
        {isShowFilter && 
          <div style={{ paddingBottom: '20px' }}>
            {contentAdvanced()}
          </div>
        }

        {isViewPage && 
          <ul>
            {weekList && weekList.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`${index === idActive ? 'active' : ''}`}
                  tabIndex={0}
                  onClick={() => onChangeWeekList(index)}
                  onKeyPress={() => onChangeWeekList(index)}
                >{item}</li>
              )
            })}
          </ul>
        }
        
        <div className="sort-search__left">
          <div className="view-option">

            <div className="sort-search__text">Switch view:</div>

            <div
              className="item-option"
              tabIndex={0}
              onClick={() => onShowCalendarView(false)}
              onKeyPress={() => onShowCalendarView(false)}
            >
              <img src={iconGrid} alt="" className="icon"/>
              <div
                className="sort-search__text"
              >Class calendar</div>
            </div>

            <div
              className="item-option"
              tabIndex={0}
              onClick={() => onShowCalendarView(true)}
              onKeyPress={() => onShowCalendarView(true)}
            >
              <img src={iconList} alt="" className="icon"/>
              <div
                className="sort-search__text"
              >Class list</div>
            </div>
          </div>

          <div className="date-picker">
            <DatePicker bookAheadLimit={bookAheadLimit} disabled={isLoadingUpdate} />
          </div>
        </div>
      </div> 
      
    </div>
  )
}

export default SortSearch;