import { ActionTypes } from '../types';
import AccountCreditsApi from '../../services/accountCreditsApi';

export const getAccountCredits = (id, productId) => (dispatch) => {
  return AccountCreditsApi.getAccountCredits(id, productId).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_ACCOUNT_CREDITS_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_ACCOUNT_CREDITS_FAIL,
        payload: 'Get profile fail!'
      })
    }
  })
}

export const getProductList = (id) => (dispatch) => {
  return AccountCreditsApi.getProducts(id).then(
    (response) => {
      if (response && response.success) {
        dispatch({
            type: ActionTypes.GET_PRODUCT_ACCOUNT_SUCCESS,
            payload:  response.data,
        });

        return response;
      } else {
        dispatch({
          type: ActionTypes.GET_PRODUCT_ACCOUNT_FAIL,
          payload: 'Get product fail!'
      });
      }

      return response;
    }
  );
};

export const getCreditHistory = (id, productId, page) => (dispatch) => {
  return AccountCreditsApi.getCreditHistory(id, productId, page).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_CREDITS_HISTORY_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_CREDITS_HISTORY_FAIL,
        payload: 'Get credit history fail!'
      })
    }
  })
}

export const reduceCreditHistory = () => (dispatch) => {
  dispatch({
    type: ActionTypes.REDUCE_CREDITS_HISTORY
  });
}

export const clearCreditHistory = () => async (dispatch) => {
  dispatch({
    type: ActionTypes.CLEAR_CREDITS_HISTORY
  });
  
  return [];
}

