import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class achievementApi {
  async getAchievements(productId) {
    try {
        let url = `certificates/info`;
        if(productId != 0) {
          url = `${url}/${productId}`;
        }
        
        const responseData = await makeRequest(
            getAPIEndpoint() + url,
            'get',
            null,
            true,
        );
        return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
  
  async download(productId) {
    try {
      let url = `certificates/download/${productId}`;
      
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
          true,
      );
      
      return Promise.resolve(responseData);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getProgressCheckAchievement() {
    try {
      let url = `progress-check?useInAchievement=true`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new achievementApi();