import axios from "axios";
import { route, target } from './Config';
import CONFIG from '../../config/config';
import moment from "moment";

let is_refresh_token = false;

export function getString(string) {
  return string || "";
}

export function getBoolean(boolean) {
  return boolean || false;
}

function itemExist(item) {
  return item || item === 0;
}

export function getFilteredArray(array) {
  return Array.isArray(array) ? array.filter(itemExist) : [];
}

export function cloneByJSON(object) {
  return JSON.parse(JSON.stringify(object));
}

export function getRoute(key) {
  return getString(route[key]);
}

export function getTarget(key) {
  return getString(target[key]);
}

export const getAPIEndpointByCentre = () => {
  const prefix = localStorage.getItem("apiEndpointPrefixByCentre") || "";
  
  return prefix + (CONFIG.apiUrl || '/v2/student/resource/api/');
};

export const getAPIEndpoint = () => {
  const prefix = localStorage.getItem("apiEndpointPrefix") || "";

  return prefix + (CONFIG.apiUrl || '/v2/student/resource/api/');
};

const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const makeRequest = async (url, method = 'get', data = null, requiredCredentials = false, hasFile = false) => {
    // Maintenance mode check
    // const isUnderMaintenance = await isMaintenanceMode()
    // if (isUnderMaintenance) {
    //     window.location.href = process.env.PUBLIC_URL + getRoute("MAINTENANCE")
    // }

    let user = JSON.parse(localStorage.getItem('user'));
    let session = {};

    if (user) {
        session = user.credentials;
    }

    if (Date.now() > session.expires_time) {
        const requestRefreshToken = {
            "grant_type": "refresh_token",
            "refresh_token": session.refresh_token,
            "client_id": session.client_id,
            "client_secret": session.client_secret,
            "scope": [],
            "is_remember_me": user.is_remember_me || false,
            "isNativeApp": user.isNativeApp || false
        };
        if (is_refresh_token == false) {
            is_refresh_token = true;
            try {
                const response = await axios({
                    method: 'post',
                    url: getAPIEndpoint() + 'oauth/token',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: requestRefreshToken,
                });
    
                if (response.status === 200) {
                    const responseData = response.data;
                    session = {
                        expires_in: responseData.expires_in,
                        expires_time: Date.now() + (responseData.expires_in * 1000),
                        access_token: responseData.access_token,
                        refresh_token: responseData.refresh_token,
                        client_id: session.client_id,
                        client_secret: session.client_secret,
                    };
                    user.credentials = session;
                    localStorage.setItem("user", JSON.stringify(user));
                    is_refresh_token = false;
                } else {
                    return false;
                }
            } catch (err) {
                const status = err?.response?.status;
    
                if (status === 401) {
                    localStorage.clear();
                    window.location.href = process.env.PUBLIC_URL + getRoute("ROOT");
                }
                return false;
            }
        } 
    }

    if (is_refresh_token == true) {
        await sleep(2000);
        user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            session = user.credentials;
        }
    }

    let headers = true !== hasFile ? {
        'Content-Type': 'application/json',
    } : {};

    if (true === requiredCredentials) {
        headers['Authorization'] = `Bearer ${session.access_token}`;
    }

    let request = {
        method: method,
        url: url,
        headers: headers,
    };

    if (data) {
        request.data = data;

        if (true === hasFile) {
            const formData = new FormData();
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }
            request.data = formData;
        }
    }

    try {
        const response = await axios(request);

        if (response.status === 200) {
            return response.data;
        } else {
            return false;
        }
    } catch (err) {
        const status = err?.response?.status;

        if (status === 401) {
            localStorage.clear();
            window.location.href = process.env.PUBLIC_URL + getRoute("ROOT");
        }

        return err?.response?.data;
    }
};

export const makeRequestDownload = async (url, method = 'get', data = null, requiredCredentials = false, hasFile = false) => {
  let user = JSON.parse(localStorage.getItem('user'));
  let session = {};

  if (user) {
      session = user.credentials;
  }

  if (Date.now() > session.expires_time) {
    const requestRefreshToken = {
        "grant_type": "refresh_token",
        "refresh_token": session.refresh_token,
        "client_id": session.client_id,
        "client_secret": session.client_secret,
        "scope": [],
        "is_remember_me": user.is_remember_me || false,
        "isNativeApp": user.isNativeApp || false
    };
    if (is_refresh_token == false) {
        is_refresh_token = true;
        try {
            const response = await axios({
                method: 'post',
                url: getAPIEndpoint() + 'oauth/token',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: requestRefreshToken,
            });

            if (response.status === 200) {
                const responseData = response.data;
                session = {
                    expires_in: responseData.expires_in,
                    expires_time: Date.now() + (responseData.expires_in * 1000),
                    access_token: responseData.access_token,
                    refresh_token: responseData.refresh_token,
                    client_id: session.client_id,
                    client_secret: session.client_secret,
                };
                user.credentials = session;
                localStorage.setItem("user", JSON.stringify(user));
                is_refresh_token = false;
            } else {
                return false;
            }
        } catch (err) {
            const status = err?.response?.status;

            if (status === 401) {
                localStorage.clear();
                window.location.href = process.env.PUBLIC_URL + getRoute("ROOT");
            } else if(status === 503) {
                window.location.href = process.env.PUBLIC_URL + getRoute("MAINTENANCE")
            }
            return false;
        }
    } 
  }

  if (is_refresh_token == true) {
      await sleep(2000);
      user = JSON.parse(localStorage.getItem('user'));
      if (user) {
          session = user.credentials;
      }
  }

  let headers = true !== hasFile ? {
      'Content-Type': 'application/json',
  } : {};

  if (true === requiredCredentials) {
      headers['Authorization'] = `Bearer ${session.access_token}`;
  }

  let request = {
      method: method,
      url: url,
      headers: headers,
      responseType: 'blob'
  };

  if (data) {
      request.data = data;

      if (true === hasFile) {
          const formData = new FormData();
          for (const [key, value] of Object.entries(data)) {
              formData.append(key, value);
          }
          request.data = formData;
      }
  }

  try {
      const response = await axios(request);

      if (response.status === 200) {
        return response;
      }
  } catch (err) {
      const status = err?.response?.status;

      if (status === 401) {
          localStorage.clear();
          window.location.href = process.env.PUBLIC_URL + getRoute("ROOT");
      } else if(status === 503) {
          window.location.href = process.env.PUBLIC_URL + getRoute("MAINTENANCE")
      }

      return err?.response?.data;
  }
}

export const isNumber = (n) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }; 

const overlay = {
    backgroundColor: 'rgba(0, 157, 140, 0.8)',
    zIndex: '1001',
    top: '0px'
};
  
export const customStyles = {
content: {
    position: 'absolute',
    padding: 0,
    border: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    left: '50%',
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    maxWidth: '1100px',
    maxHeight: '787px',
    borderRadius: '0px 0px 50px 0px',
    backgroundColor: '#D2EAF8',
    boxShadow: '0 2px 4px 0 rgba(184,184,184,0.14)',
},
overlay: overlay
};

export const isCurrentDate = (date) => {
	return moment(date).isSame(moment(), 'day');
};

export const groupBy = (arr, fn) => {
    if (!arr) {
      return null;
    }

    return arr
      .map(typeof fn === "function" ? fn : val => val[fn])
      .reduce((acc, val, i) => {
        acc[val] = (acc[val] || []).concat(arr[i]);
        return acc;
      }, {});
  };

export const windowOpen = (url, event) => {
  window.open(url, "_blank");

  event?.stopPropagation();
};

export const isMaintenanceMode = async () => {
    try {
        const url = getAPIEndpoint() + 'maintenance-mode'
        const response = await axios(url)
        return response.data?.data?.status ?? 0
    } catch (e) {
        return 0
    }
}