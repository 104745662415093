import moment from 'moment';
import DateList from './DateList';
import useCalendarCard from '../../../hooks/CalendarPage/useCalendarCard';
import CalendarItem from './CalendarItem';
import '../../../../sass/private/CalendarPage/CalendarCard.scss';

const CalendarCard = (props) => {
  const { data, dateOfWeek, setStartayActive, startDayActive } = useCalendarCard();
  const { bookAheadLimit, dateRangeTo, onRedirectBookingWidow } = props;
  const date = moment(new Date()).format('YYYY-MM-DD');
  const currentNumberDate = moment(date).isoWeekday();
  const currentDate = dateOfWeek.length > 0 && moment(dateOfWeek[currentNumberDate - 1]).format("YYYY-MM-DD");

  const dateEnd = new Date(); // Now
  dateEnd.setDate(dateEnd.getDate() + bookAheadLimit);
  dateEnd.setHours(0, 0, 0, 0)

  function renderItem(items, onRedirectBookingWidow) {
    if (items.length === 0) {
      return;
    }
    return items && items.map((item, index) => {

      const is_after_date = (dateEndofWeek, dateLimit) => dateEndofWeek < dateLimit; // compare dates
      is_after_date(new Date(dateRangeTo), dateEnd)

      return is_after_date && <CalendarItem
        key={index}
        title={item.productType}
        subTitle={item.title}
        timeRange = {item.startTime + ' - ' + item.endTime}
        borderColor={item.colorHex}
        hasFriend={item.iconSet?.friend?.showIcon}
        favorite={item.iconSet?.wishList?.showIcon}
        isWpe={item.iconSet?.workPlace?.showIcon}
        ticked={item.ticked}
        isBooked={item.booked}
        isPastEndTime={item.isPastEndTime || false}
        lessonTimeId={item.lessonTimeId}
        onRedirectBookingWidow={onRedirectBookingWidow}
        bgColor={item.colorBlockBackground}
        otherLevel={item.otherLevel}
        isLocked={item.isLocked}
        showAlertIcon={item.infoLines?.fullWarningIcon}
        availabilityText={item.infoLines?.availability || ""}
        module={item.module}
        theme={item.theme_object}
      />
    }) 
  }

  const hasResults = Array.isArray(data) && data.length > 0;

  const content = hasResults && data.map((card, index) => {
    const objKeys = Object.keys(card);
    return <div key={index} className="calendar-card__content">
      <div className="time">{card.time}</div>
      {Array.isArray(objKeys) && [...objKeys, ...new Array(8 - objKeys.length)].map((item, index) => {
        return <div
          className={((index === currentNumberDate - 1) && currentDate === date) ? 'active' : ''}
          key={index}
          >{item && renderItem(typeof card[item] === "object" && card[item], onRedirectBookingWidow)}
        </div>
      })}
    </div>
  })

  return (
    <div className="calendar-block">
      {hasResults &&
        <>
          <DateList dateOfWeek={dateOfWeek} setStartayActive={setStartayActive} />
          <div className="calendar-card">{content}</div>
        </>
      }
      {!hasResults &&
        <div className="calendar-no-results">
          <p>No results found</p>
          <p>Try changing the filters to find what you’re looking for.</p>
        </div>
      }
    </div>
  )
}

export default CalendarCard;