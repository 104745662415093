import CustomRadio from "../Common/CustomRadio";

const ChoiceOptions = (props) => {
	const { selectedTime, setSelectedTime, options, hasBorder, name } = props;

	return (options.map(opt => {
		return (
		<CustomRadio
			name={name}
			hasBorder={hasBorder}
			key={opt.value}
			id={`${opt.value}-${opt.text}`}
			value={opt.value}
			selectedValue={selectedTime}
			onChange={(val) => setSelectedTime(val)}
		>
			{opt.text}
		</CustomRadio>)
	}));
}

export default ChoiceOptions;