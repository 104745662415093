import { Skeleton } from "@mui/material";
import { memo } from 'react';

const ModuleListLoading = () => {
  return (
    <div className={`module-view-item`}>
      <div className="module-view-item__img">
        <Skeleton animation="wave" variant="text" className="skeleton-img" />
      </div>
      <div className="module-view-item__info">
        <div className="module-view-item__category">
          <Skeleton animation="wave" variant="text" className="skeleton-category" />
        </div>
        <h3 className="module-view-item__title">
          <Skeleton animation="wave" variant="text" className="skeleton-title" />
        </h3>
        <ul className="module-view-item__list">
          <li>
            <Skeleton animation="wave" variant="text" className="skeleton-li" />
          </li>
          <li>
            <Skeleton animation="wave" variant="text" className="skeleton-li" />
          </li>
          <li>
            <Skeleton animation="wave" variant="text" className="skeleton-li" />
          </li>
        </ul>
      </div>
    </div>
  )
};


export default memo(ModuleListLoading);