import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useState } from 'react';
import { getCurrentUser } from '../../../utils/currentUser';
import { resetPassword } from '../../redux/actions/settingPasswordAction';
import { useDispatch, useSelector } from 'react-redux';

const useSettingPage = () => {
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  const infoResetPassword = useSelector(state => state.settingPassword.infoReset);
  const [resetInProgress, setResetInProgress] = useState(false);
  const [resetResultsLoaded, setResetResultsLoaded] = useState(false);

  const validateFormik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      rePassword: '',
    },
    validationSchema: Yup.object({}).shape({
      password: Yup
      .string()
      .required('Password required')
      .min(
        8, 
        "Passwords must be at least 8 characters in length, have at least one uppercase and one lowercase letter, and one number"
      )
      .matches(
        /(?!.*(?:<))(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/,
        "Passwords must be at least 8 characters in length, have at least one uppercase and one lowercase letter, and one number"
      ),
      rePassword: Yup
        .string()
        .required('Password required')
        .oneOf([Yup.ref('password'), null], ('Passwords are not the same.'))
    }),
    onSubmit: (values) => {
      setResetInProgress(true);
      const userId = currentUser.responseUserData.id;
      dispatch(resetPassword(userId, values)).then((response) => {
        setResetResultsLoaded(true);
        setResetInProgress(false);
      });
    },
  });

  return {
    validateFormik,
    infoResetPassword,
    resetInProgress,
    resetResultsLoaded,
  }
}

export default useSettingPage;