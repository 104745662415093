import '../../../../sass/private/Common/CustomSelect.scss';
import { useState, useRef, useLayoutEffect } from 'react';
import useClickOutside from '../../../hooks/Common/useClickOutside';

const CustomSelect = (props) => {
  const { options, selected, setSelectedOption } = props;
  const [isActive, setIsActive] = useState(false);
  const [top, setTop] = useState(0);
  const selectRef = useRef(null);

  useClickOutside(selectRef, () => {
    setIsActive(false);
    freezeScreen(false);
  })

  useLayoutEffect(() => {
    const { current: { offsetTop, clientHeight } } = selectRef;
    const elTop = offsetTop + clientHeight + 5;

    setTop(elTop);
  }, [])

  const handleClick = (opt) => {
    setSelectedOption(opt);
    setIsActive(false);
    freezeScreen(false);
  }

  const freezeScreen = (isToggle) => {
    document.body.classList.toggle("block-scroll", isToggle);
  }

  const toggleSelect = () => {
    setIsActive(!isActive);
    freezeScreen(!isActive);
  }

  return (
    <div
      className={isActive ? `custom-select active` : `custom-select` }
      ref={selectRef}
    >
      <div className="custom-select__selected" onClick={toggleSelect}>{ selected && selected.name }</div>
      { isActive && 
        <ul
          className="custom-select__select-items"
          style={{ 'top': top}}
        >
          {options?.map(opt => {
            return (
              <li
                key={opt.id}
                className={selected.id === opt.id ? 'active' : ''}
                onClick={() => handleClick(opt)}
              >
                { opt?.name }
              </li>
            )
          })}
        </ul>
      }
    </div>
  )
}

export default CustomSelect;