import { Skeleton } from "@mui/material";
import { memo, useEffect, useState } from 'react';

const PracticeListLoadingMobile = (props) => {
  
  return (
    <>
    <div 
      className="item-practice lesson-theme"
    >
      <div className="left-practice">
        <p className='heading'><Skeleton animation="wave" variant="text" className="skeleton-title" /></p>
        <p className='date'><Skeleton animation="wave" variant="text" className="skeleton-subtitle" /></p>
      </div>
      <div className="right-practice icon-block secondary">
      </div>
    </div>
    {/* <div 
      className="item-practice lesson-theme"
    >
      <div className="left-practice">
        <p className='heading'><Skeleton animation="wave" variant="text" className="skeleton-title" /></p>
        <p className='date'><Skeleton animation="wave" variant="text" className="skeleton-subtitle" /></p>
      </div>
      <div className="right-practice icon-block secondary">
      </div>
    </div> */}
    </>
  )
};


export default memo(PracticeListLoadingMobile);