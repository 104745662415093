import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class progressPageApi {
  async getprogress(productId) {
    try {
      let url = `progress-index?productTypeGroupId=${productId}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getProducts() {
    try {
      const responseData = await makeRequest(
        getAPIEndpoint() + `product-group`,
        'get',
        null,
        true,
    );
    return Promise.resolve(responseData);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getPathWay(productId) {
    try {
      let url = `progress-pathways?productId=${productId}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getPerformance(productId) {
    try {
      let url = `assessment-latest-assessments?productTypeGroupId=${productId}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getMilestones(productId) {
    try {
      let url = `progress-milestones?productId=${productId}`;
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      );
      return Promise.resolve(responseData);
    }catch (err) {
      return Promise.reject(err);
    }
  }

  async getProficiency() {
    try {
      let url = `progress-proficiency?level=`;
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      );
      return Promise.resolve(responseData);
    }catch (err) {
      return Promise.reject(err);
    }
  }

  async getPacechart(productId,paceType) {
    try {
      let url = `progress-pace?productId=${productId}&paceType=${paceType}`;
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      );
      return Promise.resolve(responseData);
    }catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new progressPageApi();