import { Button } from '@material-ui/core';
import { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Volumn from '../../../../components/icon/Volume'
import { getColorBorlineByScore } from '../../../../../utils/helper';


const PracticeViewItem = (props) => {
  const {handleOpenPracticeModal, buttonTitle, classes, title, subTitle, date, numberDidAsssessment, totalAssessment, score, id, lessonType, practiceType} = props;
  
  return (
    <div
      className={`PassClassViewItem PracticeViewItem lesson-theme ${classes}`}
    >
      <div className="view-left">
        <div className="text">{title}</div>
        <div className="textTitle secondary">
          <span>{ReactHtmlParser(subTitle)} {practiceType == 'audio' ? <Volumn></Volumn> : ''}</span>
        </div>
        <div className="text">{date}</div>
      </div>
      <div className="view-center">
        <div className="icon-block secondary information-progress">
          <div className='number-progress'>{numberDidAsssessment >= 0 && totalAssessment > 0 ? `${numberDidAsssessment}/${totalAssessment}` : ''}</div>
          {practiceType != 'audio' && 
            <div className='circle-score-practice' style={{"border": `3px solid ${getColorBorlineByScore(score)}`}}>{score == 0 ? '' : score}</div>
          }
        </div>
      </div>
      <div className="view-right">
        <Button variant="contained" className={`btn btn-booked btn-start btn-practice secondary`} onClick={() => handleOpenPracticeModal(id, lessonType)}>{buttonTitle ?? 'Practice'}</Button>
      </div>
    </div>
  )
}

export default memo(PracticeViewItem);