import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bookingClassAction } from '../../redux/actions/BookingWindow/BookingWindowAction';
import { getCurrentUser } from '../../../utils/currentUser';
import { setBookedWindow, bookingAction, cancelBookingAction, setOpenBookingWindow } from '../../redux/actions/Booking/BookingAction';

const useClassBooked = (reloadData) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [messageError, setMessageError] = useState('');
  const [data, setData] = useState({});
  const [isShowCard, setIsShowCard] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const currentUser = getCurrentUser();
    const pathname = history.location.pathname;
    const patnameSplit = pathname.split('/');
    const lessonTimeId = parseInt(patnameSplit[patnameSplit?.length -1]);

    if (isNaN(lessonTimeId)) { // invalid ID, stop all and close modal
      dispatch(setOpenBookingWindow(false));
      dispatch(cancelBookingAction(false));
      dispatch(bookingAction(false));
    }
    else {
      const userId = currentUser.responseUserData.id;
      dispatch(bookingClassAction(userId, lessonTimeId)).then(response => {
        if (response && response.success) {
          setData(response.data);
          dispatch(setBookedWindow(true));

          if (reloadData) {
            reloadData();
          }
        } else {
          setMessageError(response?.message);
          dispatch(setBookedWindow(false));
        }
        setIsLoading(false);
      })
    }
    // eslint-disable-next-line
  }, []);

  return {
    messageError,
    data,
    isShowCard,
    isLoading,
  }
}

export default useClassBooked;