import AlertTriangle from '../../../../assets/svg/alert-triangle.svg';

export default function AvailabilityDisplay(props) {
  const availabilityText = props.availabilityText || "";
  const showAlertIcon = props.showAlertIcon || false;

  return (
    <>
      {availabilityText !== "" &&
        <div className="availability-display">
          {showAlertIcon && <img src={AlertTriangle} alt="Alert" />}
          <span>{availabilityText}</span>
        </div>
      }
    </>
  );
}
