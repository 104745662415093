import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class feedbackApi {
  async getFbScreenOne(lessonTimeId) {
    try {
      let url = `class-fb-screen-one?lesson_time_id=${lessonTimeId}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
        return Promise.reject(err)
    }
  }

  async getFbScreenTwo(lessonTimeId) {
    try {
      let url = `class-fb-screen-two?lesson_time_id=${lessonTimeId}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
        return Promise.reject(err)
    }
  }

  async postFbScreenOne(body) {
    try {
      let url = `send-class-fb-screen-one`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        body,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
        return Promise.reject(err)
    }
  }

  async postFbScreenTwo(body) {
    try {
      let url = `send-class-fb-screen-two`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        body,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
        return Promise.reject(err)
    }
  }
}

export default new feedbackApi();