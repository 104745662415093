import { ActionTypes } from '../../actions/types';

const initialState = {
  details: {}
}

export default function courseDetailsReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload,
      }

    case ActionTypes.ADD_FAVORITE_SUCCESS: 
      return {
        ...state,
        details: {...state.details, favorite: true}
      }

    case ActionTypes.REMOVE_FAVORITE_SUCCESS: 
      return {
        ...state,
        details: {...state.details, favorite: false}
      }
    case ActionTypes.UPDATE_HAS_LISTEN_LESSON: 
      return {
        ...state,
        details: {...state.details, hasListenAudio: payload}
      }
    default:
      return state;
  }
}
