import { ActionTypes } from '../../actions/types';

const initialState = {
  data: {},
  isError: false,
  msgError: '',
  checkCancel: {}

}

export default function bookingWindowReducer(state = initialState, action) {
  const { type, payload } = action;
  switch(type) {
    case ActionTypes.ADD_BOOKING_WINDOW_START:
      return {
        ...state,
        isError: false
      }
    case ActionTypes.ADD_BOOKING_WINDOW_SUCCESS:
      return {
        ...state,
        data: payload,
        isError: false
      }
    case ActionTypes.ADD_BOOKING_WINDOW_FAIL:
      return {
        ...state,
        msgError: payload,
        isError: true
      }
    case ActionTypes.GET_REFUND_CREDIT_SUCCESS:
      return {
        ...state,
        checkCancel: payload
      }
    default:
      return state;
  }
}
