import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class newsFeed{
    async getNewsFeed(newsFeedId){
        try {
            let url = `news-feed?news_feed_id=${newsFeedId}`;
            const responseData = await makeRequest(
                getAPIEndpoint() + url,
                'get',
                null,
                true,
            );
            return Promise.resolve(responseData);
          } catch (err) {
              return Promise.reject(err);
          }
    }

    async getNewsFeedAtLogin() {
        try {
            let url = `news-feed-at-login`;
            const responseData = await makeRequest(
                getAPIEndpoint() + url,
                'get',
                null,
                true,
            );
            return Promise.resolve(responseData);
          } catch (err) {
              return Promise.reject(err);
          }
    }
}

export default new newsFeed();