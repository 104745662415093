import '../../../../sass/private/MobileBooking/MobileBooking.scss';
import PrivatePage from '../../../components/PrivatePage';
import CalendarContextProvider from '../Calendar/CalendarGlobalState';
import MobileBooking from './MobileBooking';

const MobileBookingPage = () => {
	return (
		<PrivatePage
			className={`ProgressPage-block booking-page`}
			isShowMainNavigation={true}
		>
			<CalendarContextProvider>
				<MobileBooking/>
			</CalendarContextProvider>
		</PrivatePage>
	)
}

export default MobileBookingPage;