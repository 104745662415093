import { useDispatch } from "react-redux";
import { setStep } from "../js/redux/actions/ProfileCapture/profileCaptureAction";
import profileCaptureApi from "../js/redux/services/profileCaptureApi";

export function deepSearchItems(object, key, predicate) {
  let ret = [];
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
      ret = [...ret, object];
  }
  if (Object.keys(object).length) {
      for (let i = 0; i < Object.keys(object).length; i++) {
          let value = object[Object.keys(object)[i]];
          if (typeof value === "object" && value != null) {
              let o = deepSearchItems(object[Object.keys(object)[i]], key, predicate);
              if (o != null && o instanceof Array) {
                  ret = [...ret, ...o];
              }
          }
      }
  }
  return ret;
}

export const handleGoBack = (dispatch, previousStep, profileId, step, motivationId) => {
  dispatch(setStep(previousStep))
  window.scrollTo(0, 0);
  if(profileId) {
    profileCaptureApi.profileLogBack({
      'profile_id': profileId,
      'type': step.includes('motivation') ? 'motivation' : step,
      'motivation_id': motivationId
    });
  }
}

export const getColorBorlineByScore = (score) => {
  if(!score) {
    return '#C8C8C8';
  }

  if(score >= 7) {
    return '#5DEB4B';
  } else {
    return '#FF8200';
  }
}