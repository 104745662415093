import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Collapse } from '@material-ui/core';
import "../../../../sass/private/MobileBooking/MobileBooking.scss";
import MobileBookingControl from "./MobileBookingControl";
import MobileBookingWeekView from "./MobileBookingWeekView";
import MobileBookingListView from "./MobileBookingListView";
import CustomMenuTab from "../Common/CustomMenuTab";
import ItemClass from "./ItemClass";
import ItemTeacher from "./ItemTeacher";
import useCalendarWraper from "../../../hooks/CalendarPage/useCalendarWraper";
import useMobileBookingPage from '../../../hooks/BookingPage/useMobileBookingPage';
import AdvancedFilters from "./AdvancedFilters";
import ChoiceOptions from "./ChoiceOptions";
import BookingPage from '../Booking/BookingPage';
import ClassBookedPage from '../Booking/ClassBooked';
import CancelBooking from '../Booking/Cancel';
import Modal from '../../private/Dialog/Modal';
import useModalBooking from '../../../hooks/ModalBooking/useModalBooking';
import { setOpenBookingWindow } from '../../../redux/actions/Booking/BookingAction';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MobileCourseItem from "./MobileCourseItem";

import CircularProgress from '../Common/CircularProgress';
import myCourseApi from "../../../redux/services/myCourseApi";
import ModuleBookingMobile from '../Module/ModuleBookingMobile';

const MobileBooking = (props) => {

	const history = useHistory();

	const {
		open,
		handleCloseDialog,
		isShowClassBookedPage,
		isShowCancelBooking,
		onCloseBooking
	} = useModalBooking();

	const {
		tabs,
		TABS,
		timeOpts,
		activeTab,
		selectedTime,
		showAdvancedFilter,
		filteredListView,
		redirect,
		toggleFilter,
		handleTabClick,
		setSelectedTime,
		reloadData,
		dataReloadToken,
		dataReloadTokenOthers,
	} = useMobileBookingPage();

	const isTabTime = activeTab === TABS.TIME;
	const isTabTeacher = activeTab === TABS.TEACHER;
	const isTabContent = activeTab === TABS.CONTENT;
	
	const actualOnCloseBooking = () => {
		onCloseBooking();

		if (isTabTeacher || isTabContent) {
		history.goBack();
		}
	};
	const [moduleID, setModuleID] = useState();
  const [isOpenModuleDetail, setIsOpenModuleDetail] = useState(false);
	const {
		idActive,
		onChangeWeekList,
		dataOptionAddress,
		onChangeOption,
		bookingCard,
		dataFilter,
		bookAheadLimit,
		isPendingUpdate,
		isLoadingUpdate,
	} = useCalendarWraper(dataReloadToken, dataReloadTokenOthers);

	const isUpdating = isPendingUpdate || isLoadingUpdate;
	const [isChecked, setIsChecked] = useState(false);
	let weekViewActives = [];
	let weekViewHighlights = [];

	if (!isUpdating && bookingCard) {
		const bookingCardKey = Object.keys(bookingCard);

		weekViewActives = bookingCardKey.filter((key) => bookingCard[key]?.availableClass > 0);

		if (isTabTime) {
		weekViewHighlights = bookingCardKey.filter((key) => {
			if (Array.isArray(bookingCard[key]?.class)) {
				return bookingCard[key]?.class.some((lesson) => {
					return (lesson && lesson.booked && lesson.isPastEndTime !== true) || false;
				});
			}

			return false;
		});
		}
		if (isTabTeacher || isTabContent) {
			weekViewHighlights = bookingCardKey.filter((key) => {
				return bookingCard[key]?.haveBooked || false;
			});
		}
	}

	let tabContentList = [];
	if(isTabTime && typeof filteredListView.dataCalendarViewAvailable != 'undefined') {
		tabContentList = filteredListView.dataCalendarViewAvailable.concat(filteredListView.dataCalendarViewFull);
	} else {
		tabContentList = (Array.isArray(filteredListView) && filteredListView) || [];    
	}
	const dataCalendarViewPast = filteredListView?.dataCalendarViewAvailable?.filter(item => {
		return item.isPast;
	});
	const dataCalendarViewAvailableNow = filteredListView?.dataCalendarViewAvailable?.filter(item => {
		return item.isPast === false;
	});

	const handleOpenModuleDetails = (id) => {
    setIsOpenModuleDetail(true);
    setModuleID(id)
  }
  const handleCloseModuleDialog = () => {
    setIsOpenModuleDetail(false);
  }
	return (
		<div className="mobile-booking">
			<div className="mobile-booking__wrapper">
				<MobileBookingControl
					showFilter={toggleFilter}
					centreList={dataOptionAddress}
					onChangeOption={(id) => {
						onChangeOption("centre", id);
					}}
        			selectedCentre={dataFilter.centre}
					isUpdating={isUpdating}
				/>
				<MobileBookingWeekView
					idActive={idActive}
					actives={weekViewActives}
					highlights={weekViewHighlights}
					onChangeWeekList={onChangeWeekList}
					bookAheadLimit={bookAheadLimit}
					isPendingUpdate={isPendingUpdate}
					isLoadingUpdate={isLoadingUpdate}
				/>
			</div>
			<MobileBookingListView>
				<CustomMenuTab
					tabs={tabs}
					activatedTab={activeTab}
					handleTabClick={handleTabClick}
					disabled={isUpdating}
				/>
				 {
					isTabTime &&
					<div className="list-view__time-options">
						<ChoiceOptions
							name="time"
							options={timeOpts}
							selectedTime={selectedTime}
							setSelectedTime={setSelectedTime}
						/>
					</div>
				}
				{
					isTabTeacher &&
					<div className="list-view__heading">
						Available teachers
					</div>
				}
				{
					isTabContent &&
					<div className="list-view__heading-course">
          			</div>
				}
				<div className="list-view__list-items">
					{isUpdating && <CircularProgress />}
					{!isUpdating &&
						<>
							{tabContentList.length === 0 &&
								<div className="list-view__no-item">
									<p>No results found</p>
									<p>Try changing the filters to find what you’re looking for.</p>
								</div>
							}
							{tabContentList.length > 0 &&
								<>
									{typeof filteredListView.dataCalendarViewAvailable != 'undefined' && filteredListView.dataCalendarViewAvailable.length > 0 && dataCalendarViewAvailableNow.length > 0 ? (
										<>
											<div className="content-time">
												<p className="heading">Available</p>
												{isTabTime && dataCalendarViewAvailableNow.map((item, index) =>
													<ItemClass key={index} item={item} />                    
												)}
											</div>
										</>
									) : ''}
					
									{typeof filteredListView.dataCalendarViewFull != 'undefined' && filteredListView.dataCalendarViewFull.length > 0 ? (
										<>
											<div className="content-time">
												<p className="heading">Fully booked</p>
												{isTabTime && filteredListView.dataCalendarViewFull.map((item, index) =>
													<ItemClass classes='full' key={index} item={item} />                    
												)}
											</div>
										</>
									) : ''}
									{typeof filteredListView.dataCalendarViewAvailable != 'undefined' && filteredListView.dataCalendarViewAvailable.length > 0 && dataCalendarViewPast.length > 0 ? (
										<div className="content-time">
											<div className='collapse-past-class' onClick={() => {setIsChecked((prev) => !prev)}}>
												<span>Past classes</span>
												<span className='expand-past-class'>
													{isChecked ? <ExpandLess></ExpandLess> : <ExpandMore></ExpandMore>}
												</span>
											</div>
											
											<Collapse className='past-class-mobile' in={isChecked}>
												{isTabTime && dataCalendarViewPast.map((item, index) =>
													<ItemClass key={index} item={item} />                    
												)}
											</Collapse>
										</div>
									) : ''}
										
									{isTabTeacher && tabContentList.map((item, index) =>
										<ItemTeacher
											key={index}
											isTeacherView={true}
											item={item}
											redirect={redirect}
											showViewDetail
										/>
									)}
									{isTabContent && tabContentList.map((item, index) =>
										<MobileCourseItem
											item={item}
											key={index}
											lesson_name={item.name}
											lesson_category={item.product_category_name}
											lesson_count={parseInt(item.classes_available_count) || 0}
											lesson_id={item.id}
											lesson_type={item.lesson_type}
											content_type={item.type}
										/>
									)}
								</>
							}
						</>
					}
				</div>
			</MobileBookingListView>
			{
				showAdvancedFilter &&
				<AdvancedFilters hideFilter={toggleFilter} /> 
			}

			<Modal
				open={open}
				handleCloseDialog={handleCloseDialog}
				useThemeClasses={true}
				className="booking-modal"
			>
				{(!isShowClassBookedPage && !isShowCancelBooking) &&
					<BookingPage isShowHeader={true} onCloseBooking={actualOnCloseBooking} reloadData={reloadData} handleOpenModuleDialog={handleOpenModuleDetails} />
				}
				{(isShowClassBookedPage && !isShowCancelBooking) &&
					<ClassBookedPage onRedirectPage={actualOnCloseBooking} reloadData={reloadData} />
				}
				{(isShowCancelBooking && !isShowClassBookedPage) &&
					<CancelBooking onCloseBooking={actualOnCloseBooking} reloadData={reloadData} />
				}
			</Modal>
			<Modal
        open={isOpenModuleDetail}
				useThemeClasses={true}
        handleCloseDialog={handleCloseModuleDialog}
        className={`booking-modal`}
      >
				<ModuleBookingMobile id={moduleID} reloadData={reloadData} handleCloseDetails={handleCloseModuleDialog} /> 
      </Modal>
		</div>
		
	)
}

export default MobileBooking;
