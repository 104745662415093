import { ActionTypes } from './types';

import AccountSettingApi from "../services/accountSettingApi";

export const resetPassword = (id, data) => (dispatch) => {
  return AccountSettingApi.resetPassword(id, data).then(
      (response) => {
          if (response && response.success) {
            dispatch({
                type: ActionTypes.SETTING_RESET_PASSWORD_SUCCESS,
                payload: response,
            });
          } else {
            dispatch({
                type: ActionTypes.SETTING_RESET_PASSWORD_FAIL,
                payload: response.message,
            });
          }
        return Promise.resolve(response);
      }
  );
};