import React from 'react';

import Page from './Page';
import Container from './Container';
import { getString } from './Utilities';
import Footer from '../pages/private/Footer/Footer';

export default class PublicPage extends React.Component {
  render() {
    return (
      <Page className={"public-page " + getString(this.props.className)}>
        <header className="header">
          <Container className="page-content">
            {this.props.children}
          </Container>
        </header>
        <Footer />
      </Page>
    )
  }
}
