
const CustomRating = (props) => {
  const {value, index, onRating, currentRatingValue} = props;
  const handleOnClick = (value) => {
    onRating(value);
  }

  return (
    <div className={currentRatingValue === value ? `rating-num active` : `rating-num`} key={index} onClick={() => handleOnClick(value)}>
      <span>{value}</span>
    </div>
  );
}

export default CustomRating;