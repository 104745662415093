import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDayOfWeekAction } from '../../../redux/actions/Calendar/CalendarAction';

export const CalendarGlobalState = createContext();

const menuListInitial = [
  {
    name: 'My schedule',
    active: true,
    id: 2,
    hide: false,
  },
  {
    name: 'Calendar',
    active: false,
    id: 1,
    hide: false,
  },
  {
    name: 'Modules',
    active: false,
    id: 3,
    hide: true,
  },
  {
    name: 'Practice',
    active: false,
    id: 4,
    hide: false,
  },
  {
    name: 'Progress check',
    active: false,
    id: 5,
    hide: false,
  },
];

export default function CalendarContextProvider(props) {
  const [isViewCalendar, setIsViewCalendar] = useState(false);
  const [idActiveNavbarCalendar, setIdActiveNavbarCalendar] = useState(-1);
  const [menuData, setMenuData] = useState(menuListInitial);

  const dispatch = useDispatch();
  const idActiveWeekList = useSelector(state => state.calendar.dateOfWeekActiveIndex);
  const landing = useSelector(state => state.home.landing);

  const setIdActiveWeekList = (day) => {
    dispatch(setActiveDayOfWeekAction(day));
  };

  const state = {
    isViewCalendar,
    idActiveNavbarCalendar,
    idActiveWeekList,
    menuData
  }
  const actions = {
    setIsViewCalendar,
    setIdActiveNavbarCalendar,
    setIdActiveWeekList,
    setMenuData
  }
  
  useEffect(() => {
    if(landing) {     
      menuData[2].name = `Modules (${landing.module_tab?.booked_module_number ?? 0})`;
      menuData[3].name = `Practice (${landing.practiceNumber ?? 0})`;
      menuData[4].name = `Progress check (${landing.progressCheckNumber ?? 0})`;
      if (landing?.module_tab?.show_module_tab) {
        menuData[2].hide = false;
      }
      setMenuData(menuData); 
    }
  }, [landing])
  
  return (
    <CalendarGlobalState.Provider value={{state, actions}}>
      {props.children}
    </CalendarGlobalState.Provider>
  )
}