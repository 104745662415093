import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class AuthService {
    async login(username, password, isRemember) {
        try {
            const data = {
                email: username,
                password: password,
                isNativeAppIos: window.isNativeAppIos || false,
                isNativeAppAndroid: window.isNativeAppAndroid || false,
                deviceToken: window.deviceToken || "",
                is_remember_me: isRemember || false,
                isNativeApp: window.isNativeApp || false
            };

            localStorage.clear();

            const responseData = await makeRequest(
                getAPIEndpoint() + 'auth/login',
                'post',
                data,
            );

            if (responseData.success) {
                const tokenData = {
                    grant_type: "password",
                    client_id: responseData.data?.client_id,
                    client_secret: responseData.data?.client_secret,
                    username: username,
                    password: password,
                    scope: [],
                    is_remember_me: isRemember || false,
                    isNativeApp: window.isNativeApp || false
                };

                if (responseData.data && responseData.data.url) {
                    localStorage.setItem("apiEndpointPrefix", responseData.data.url);
                }

                const responseTokenData = await makeRequest(
                    getAPIEndpoint() + 'oauth/token',
                    'post',
                    tokenData,
                );

                const token = {
                    ...responseTokenData,
                    ...{
                        client_id: responseData.data.client_id,
                        client_secret: responseData.data.client_secret,
                    },
                };

                if (token) {
                    const credentials = {
                        expires_in: token.expires_in,
                        expires_time: Date.now() + (token.expires_in * 1000),
                        access_token: token.access_token,
                        refresh_token: token.refresh_token,
                        client_id: token.client_id,
                        client_secret: token.client_secret,
                    };

                    await localStorage.setItem("user", JSON.stringify({
                        credentials: credentials,
                        first_name: '',
                        last_name: '',
                        email: '',
                        role: '',
                        group: '',
                        fullname: '',
                    }));

                    const responseUserData = await this.whoami();

                    if (true === responseUserData.success) {
                        const userData = {
                            credentials: credentials,
                            first_name: responseUserData.data.surname_en,
                            last_name: responseUserData.data.name_en,
                            email: responseUserData.data.email,
                            role: '',
                            group: '',
                            fullname: responseUserData.data.name_en + ' ' + responseUserData.data.surname_en,
                            levelName: responseUserData.data.levelName,
                            responseUserData: responseUserData.data,
                            is_remember_me: isRemember || false,
                            isNativeApp: window.isNativeApp || false,
                            isImpersonating: false,
                        };
                        localStorage.setItem("user", JSON.stringify(userData));

                        return Promise.resolve(userData);
                    } else {
                        return Promise.reject();
                    }
                }
            }else{
                if(responseData.data.url != null && responseData.data.url != ""){
                    window.location.replace(responseData.data.url);
                }

            }
        } catch (err) {
            return Promise.reject(err);
        }

        return Promise.reject();
    }

    async logout() {
        try {
            localStorage.clear();
            return Promise.resolve();
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async resetPassword(email) {
        try {
            const data = {"email": email};
            const response = await makeRequest(
                getAPIEndpoint() + 'auth/forgot-password',
                'post',
                data
            );
            return Promise.resolve(response);
        } catch (err) {
            return Promise.reject(err);
        }
    }

    whoami() {
        return makeRequest(
            getAPIEndpoint() + 'whoami',
            'get',
            null,
            true,
        );
    }

    getImpersonateToken(studentId) {
        const data = {"student_id": studentId};
        return makeRequest(
            getAPIEndpoint() + 'get-student-token',
            'post',
            data,
            true,
        );
    }
}

export default new AuthService();