import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'initial',

    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 157, 140, 0.8)"
    },

    "& .MuiDialog-paper": {
      maxWidth: '100%',
      width: 'calc(100% - 200px)',
      position: 'initial',
      height: '100%',
      maxHeight: 'calc(100% - 110px)',
      backgroundColor: '#D2EAE8',
      borderRadius: '0 0 50px 0'
    }
  },
  rootMobile: {
    position: 'initial',
    
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 157, 140, 0.8)"
    },
    
    "& .MuiDialog-paper": {
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      position: 'initial',
      maxHeight: 'calc(100% - 100px)',
      backgroundColor: '#D2EAE8',
      borderRadius: '0 0 30px 0',
      margin: '0'
    },
    "& .MuiDialog-scrollPaper": {
      alignItems: 'flex-start',
      paddingTop: '58px'
    }
  },

  rootMobileContact: {
    "& .MuiDialog-paper": {
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: '0'
    }
  },

  rootMobileClasses: {
    "& .MuiDialog-paper": {
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: '0',
      backgroundColor: 'unset',
      borderRadius: '0'
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "white"
    },
  }
}));

const Modal = (props) => {
  const { isTablet } = useCheckMobileTablet();
  const {open, handleCloseDialog, useThemeContact, useThemeClasses, className} = props;
  const classes = useStyles();
  return (
    <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="draggable-dialog-title"
        className={`
          ${ isTablet ? classes.rootMobile : classes.root}
          ${useThemeContact? classes.rootMobileContact: ""}
          ${useThemeClasses? classes.rootMobileClasses: ""}
          ${className || ""}
        `}
      >
        {props.children}
      </Dialog>
  )
}

export default Modal;