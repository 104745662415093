import { ActionTypes } from '../../actions/types';
import { MOBILE_BOOKING_TABS } from '../../../hooks/BookingPage/useMobileBookingPage';

const initialState = {
  isShowClassBookedPage: false,
  isShowCancelBooking: false,
  isOpen: false,
  isBookedStatus: false,
  mobileBooking: {
    activeTab: MOBILE_BOOKING_TABS.TIME,
  }
}

export default function bookingReducer(state = initialState, action) {
  const { type, payload } = action;
  switch(type) {
    case ActionTypes.ADD_BOOKING_SUCCESS:
      return {
        ...state,
        isShowClassBookedPage: payload
      }
    case ActionTypes.CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        isShowCancelBooking: payload
      }
    case ActionTypes.OPEN_BOOKING_SUCCESS:
      return {
        ...state,
        isOpen: payload
      }
    case ActionTypes.ADD_BOOKED_SUCCESS:
      return {
        ...state,
        isBookedStatus: payload
      }
    case ActionTypes.MOBILE_BOOKING_SET_ACTIVE_TAB:
      return {
        ...state,
        mobileBooking: {
          ...state.mobileBooking,
          activeTab: payload,
        }
      }
    default:
      return state;
  }
}
