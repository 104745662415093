import arrow from '../../../../assets/svg/accordion-arrow-green.svg';
import arrowMobile from '../../../../assets/svg/accordion-arrow-blue.svg';
import '../../../../sass/private/Booking/Accordion.scss';
import ReactHtmlParser from 'react-html-parser';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';

const Accordion = (props) => {
  const {
    data: {title, desc, subDesc, options, id, active, avatar, teacherName},
    onChangeAccordion
  } = props;

  const { isTablet } = useCheckMobileTablet();

  return (
    <div>
      <div className={`accordion ${active ? 'active' : ''}`}>
        <div className="accordion-header" onClick={() => onChangeAccordion(id)}>
          <div>
            <h3>{title}</h3>
            {(avatar || teacherName) &&
              <div className="avatar-block">
                {avatar &&
                  <div className="avatar-img">
                    <img src={avatar} alt="Teacher profile picture" />
                  </div>
                }
                <div className="avatar-name">{teacherName}</div>
              </div>
            }
          </div>
          {isTablet && <img className="arrow-icon" src={arrowMobile} alt="Toggle" tabIndex={0} onKeyPress={() => onChangeAccordion(id)} />}
          {!isTablet && <img className="arrow-icon" src={arrow} alt="Toggle" tabIndex={0} onKeyPress={() => onChangeAccordion(id)} />}
        </div>
        <div className="accordion-content">
            <div>
              {ReactHtmlParser(desc)}
            </div>
            {subDesc && <div>{subDesc}</div>}
            {options?.length > 0 && (
              <ul>
                {options.map((option, index) => {
                  return <li key={index}>{option}</li>
                })}
              </ul>
            )}
        </div>
      </div>

      
    </div>
  )
}

export default Accordion;