import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProgressCheckAchievementAction } from '../../redux/actions/Achievement/AchievementAction';
import { getProgressAction } from '../../redux/actions/ProgressPage/ProgressPageAction';
import helpApi from '../../redux/services/helpApi';
import { getHelpAction } from '../../redux/actions/Help/HelpAction';

const useHelpPage = () => {
  const helpData = useSelector(state => state.help.helpData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHelpAction()).then((response) => {
      if (response?.success) {
        // setIsLoading(false)
        if (Array.isArray(response.data) && response.data.length) {
          onChangeOption("", response.data[0].productGroupId);
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const onChangeOption = (name, id) => {
   
      // setIsLoading(true)
      Promise.all([
        dispatch(getHelpAction()),
      ])
      
    
  }


  return {
    helpData,
  }

}

export default useHelpPage;