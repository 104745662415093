import { ActionTypes } from './types';

import AssessmentApi from "../services/assessmentApi";

export const getAssessment = (id, skillId, productId) => (dispatch) => {
    return AssessmentApi.getAssessment(id, skillId, productId).then(
        (response) => {
            dispatch({
                type: ActionTypes.GET_ASSESSMENT_SUCCESS,
                payload: {data: response.data},
            });

            return Promise.resolve(response);
        },
        (error) => {
            dispatch({
                type: ActionTypes.GET_ASSESSMENT_FAIL,
            });

            dispatch({
                type: ActionTypes.SET_MESSAGE,
                payload: 'Get assessment failed!',
            });

            return Promise.reject();
        },
    );
};

export const getSkillList = (id) => (dispatch) => {
  return AssessmentApi.getSkill(id).then(
      (response) => {
          dispatch({
              type: ActionTypes.GET_SKILL_SUCCESS,
              payload: {data: response.data},
          });

          return Promise.resolve(response);
      },
      (error) => {
          dispatch({
              type: ActionTypes.GET_SKILL_FAIL,
          });

          dispatch({
              type: ActionTypes.SET_MESSAGE,
              payload: 'Get skill failed!',
          });

          return Promise.reject();
      },
  );
};

export const getProductList = (id) => (dispatch) => {
    return AssessmentApi.getProducts(id).then(
        (response) => {
            dispatch({
                type: ActionTypes.GET_PRODUCT_SUCCESS,
                payload: {data: response.data},
            });
  
            return Promise.resolve(response);
        },
        (error) => {
            dispatch({
                type: ActionTypes.GET_PRODUCT_FAIL,
            });
  
            dispatch({
                type: ActionTypes.SET_MESSAGE,
                payload: 'Get product failed!',
            });
  
            return Promise.reject();
        },
    );
};

export const addOptionAssessment = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.ADD_OPTION_ASSESSMENT_SUCCESS,
        payload: {data},
    });
}
export const addSkillAssessment = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.ADD_SKILL_ASSESSMENT_SUCCESS,
        payload: {data},
    });
}