import React, { Component } from 'react';
import { connect } from 'react-redux';
import PrivatePage from '../../components/PrivatePage';

class Dashboard extends Component {

  render() {
    return (
      <PrivatePage className="page-dashboard">
        Dashboard page
      </PrivatePage>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Dashboard);
