import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Page from './Page';
import Container from './Container';
import { getString, getRoute } from './Utilities';
import UserMenu from '../pages/private/HeaderMenu/UserMenu';
import MainMenu from '../pages/private/HeaderMenu/MainMenu';
import useCheckMobileTablet from '../../utils/useCheckMobileTablet';
import iconArrowLeft from '../../assets/svg/arrowleft.svg';
import iconClose from '../../assets/svg/close.svg';
import { useHistory } from 'react-router';
import MainMenuMobile from '../pages/private/HeaderMenu/MainMenuMobile';
import Footer from '../pages/private/Footer/Footer';

const PrivatePage = (props) => {
  const  {user}  = useSelector(state => state.auth)
  const history = useHistory()
  const { isTablet } = useCheckMobileTablet();
  const {
    isShowMenuBack,
    backTitle,
    isShowMenuClose, 
    title, 
    onBack, 
    isShowMainNavigation
  } = props
  

  const onBackPage = () => {
    onBack()
  }

  const showMenu = () => {
    if(isTablet && isShowMenuBack) {
      return (
        <div className="mobile-header-back">
          <div className="mobile-header-back__left">
            <button type='button' className='btn-back' onClick={onBackPage}>
              <img 
                src={iconArrowLeft} 
                alt="iconleft" 
                className="iconBack"
              />
            </button>
            {
              backTitle && <span  onClick={onBackPage}>{backTitle}</span>
            }
          </div>
          <div className="profile mobile-header-back__center">{title}</div>
          <div className="mobile-header-back__right"></div>
        </div>
      )
    } else if(isTablet && isShowMenuClose) {
      return (
        <div className="mobile-header-back">
          <div className="mobile-header-back__left"></div>
          <div className="profile mobile-header-back__center">{title}</div>
          <div className="mobile-header-back__right">
            {
              isShowMenuClose &&
              <img 
                onClick={onBackPage}
                src={iconClose} 
                alt="iconClose" 
                className="iconBack"
              />
            }
          </div>
        </div>
      )
    }
    else {
      return (<MainMenu />)
    }
  }


  useEffect(() => {
    if (!user) {
      history.push(getRoute('ROOT'));
    }

    //eslint-disable-next-line
  },[])
 
  return (
    <Page className={"private-page " + getString(props.className)}>
      {!isTablet &&  <UserMenu />}

      {showMenu()}
      
      <Container className="page-content">{props.children}</Container>

      {isTablet && isShowMainNavigation && <MainMenuMobile />}

      {!isTablet && <Footer />}
      
    </Page>
  )
}

export default PrivatePage