import { ActionTypes } from '../../actions/types';

const initialState = {
  landing: {},
  newsData: [],
  newsFeedData: {},
  newsFeedLogin: {},
  progressCheck: {},
}

export default function landingReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_LANDING_SUCCESS:
      return {
        ...state,
        landing: {...state.landing, ...payload},
      }
    case ActionTypes.GET_LANDING_FAIL:
      return {
        ...state,
        message: payload
      }

    case ActionTypes.GET_NEWS_SUCCESS: 
      return {
        ...state,
        newsData: payload
      }
    
    case ActionTypes.GET_NEWSFEED_SUCCESS:
      return {
        ...state,
        newsFeedData: payload
      }

    case ActionTypes.GET_NEWSFEED_LOGIN:
      return {
        ...state,
        newsFeedLogin: payload
      }

    default:
      return state;
  }
}
