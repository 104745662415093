import {createStore, applyMiddleware} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from "redux-thunk";
import combinedReducer from "../reducers";
import { ActionTypes } from "../actions/types";

const middleware = [thunk];

const appReducer = combinedReducer;

const rootReducer = (state, action) => {
    if (action.type === ActionTypes.LOGOUT) {
        return appReducer(undefined, action); // reset all reducers to their initial states
    }
    else {
        return appReducer(state, action);
    }
};

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleware)),
);

export default store;