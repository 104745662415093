import { ActionTypes } from '../types';
import FeedbackApi from '../../services/feedbackApi';
import { resetNotificationState } from '../Notification/NotificationAction';

export const getFeedbackScreenOne = async (lessonTimeId) => {
  return await FeedbackApi.getFbScreenOne(lessonTimeId).then((response) => response.data);
}

export const getFeedbackScreenTwo = async (lessonTimeId) => {
  return await FeedbackApi.getFbScreenTwo(lessonTimeId).then((response) => response.data);
}

export const setFeedbackScreenOne = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_QUESTION_SCREEN_ONE_FEEDBACK,
    payload: data
  })
}

export const setFeedbackScreenTwo = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_QUESTION_SCREEN_TWO_FEEDBACK,
    payload: data
  })
}

export const handlePostFeedbacks = (fbData1, fbData2) =>  async(dispatch) => {
  async function onSuccess(fbData2) {
    await FeedbackApi.postFbScreenTwo(fbData2).then(response => {
      if (response && response.success) {
        dispatch({
          type: ActionTypes.FEEDBACK_SUCCESS,
          payload: "Thanks for your feedback"
        })
        dispatch(resetNotificationState());
      } else {
        dispatch({
          type: ActionTypes.FEEDBACK_FAILED,
          payload: "Send feedback failed. Please try again later"
        })
      }
    })
  }
  function onError() {
    dispatch({
      type: ActionTypes.FEEDBACK_FAILED,
      payload: "Send feedback failed. Please try again later"
    })
  }
  try {
    const success = await FeedbackApi.postFbScreenOne(fbData1);
    return onSuccess(fbData2);
  } catch (error) {
    return onError();
  }
}

export const addFbScreenTwoId = (lessonTimeId) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_QUESTION_SCREEN_TWO_ID,
    payload: lessonTimeId
  })
}

export const resetFeedback = () => (dispatch) => {
  dispatch({type: ActionTypes.RESET_FEEDBACK});
}
