import { ActionTypes } from '../../actions/types';

const initialState = {
  data: []
}

export default function contactReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_CONTACT_SUCCESS:
      return {
        ...state,
        data: payload,
      }
    case ActionTypes.GET_CONTACT_FAIL:
      return {
        ...state,
        message: payload
      }
    default:
      return state;
  }
}
