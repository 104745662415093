import { useState } from 'react';

import PrivatePage from '../../../components/PrivatePage';
import Navbar from '../Settings/Navbar';
import NavBarLeft from './NavBarLeft';
import LessonDetails from './LessonDetails';
import { getRoute } from '../../../components/Utilities';
import { useHistory } from 'react-router';
import queryString from "query-string";
import backIcon from '../../../../assets/image/arrow-left.png';
import starIcon from '../../../../assets/image/star.png';
import useMyCourseDetails from '../../../hooks/MyCoursePage/useMyCourseDetails'
import useMyCourse from '../../../hooks/MyCoursePage/useMyCourse';
import useModalBooking from '../../../hooks/ModalBooking/useModalBooking';
import BookingPage from '../Booking/BookingPage';
import ClassBookedPage from '../Booking/ClassBooked';
import CancelBooking from '../Booking/Cancel';
import Modal from '../Dialog/Modal';
import CourseDetailsPageApi from '../../../redux/services/myCourseDetailsApi';
import CircularProgress from '../Common/CircularProgress';


import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import ModalAudio from '../Booking/ModalAudio';

import ModalAudioDesktop from '../Booking/ModalAudioDesktop';
import '../../../../sass/private/MyCourse/MyCoursePage.scss';
import ModuleBooking from '../Module/ModuleBooking';
import { Button } from '@material-ui/core';

const MyCourseDetailsPage = (props) => {
  const history = useHistory();
  const { isTablet } = useCheckMobileTablet()

  const {
    courseLevel,
    courseSideBar,
    c1Id,
    c2Id,
    c3Id,
    c3Type,
    courseLevelSelected,
    c2UniqueId
  } = useMyCourse()

  const {
    courseDetails, 
    selectedIdAudio,
    audioName,
    id,
    onChangeCentre,
    openModalAudio,
    onCloseModalAudio,
    onAddFavorite,
    onRemoveFavorite,
    isLoading,
    centreId,
    setCourseType,
    setCourseId,
    getData
  } = useMyCourseDetails()

  const {
    open,
    handleCloseDialog,
    isShowClassBookedPage,
    isShowCancelBooking,
    onCloseBooking,
  } = useModalBooking();

  const onClickMenu = (id) => {
    history.push(`/mycourse?levelId=${id}`)
  }

  const onGoBack = () => {
    const param = queryString.parse(history.location.search);
    const historyGoCount = parseInt(param.historyGo) || 0;

    if (historyGoCount === 0) {
      history.push(`/mycourse?centreId=${centreId}&c1Id=${c1Id}&c2Id=${c2Id}&c3Id=${c3Id}&c3Type=${c3Type}&levelId=${courseLevelSelected}${c2UniqueId ? "&c2UniqueId=" + c2UniqueId : ""}`);
    }
    else {
      historyGo(historyGoCount);
    }
  }

  const historyGo = (count) => {
    props.history.go(parseInt(count) || 0);
  }

  const onSideBarClick = (c1, c2, c3, type, c2UniqueId) => {
    history.push(`/mycourse?&c1Id=${c1}&c2Id=${c2}&c3Id=${c3}&c3Type=${type}&levelId=${courseLevelSelected}${c2UniqueId ? "&c2UniqueId=" + c2UniqueId : ""}`)
  }

  const actualHandleCloseDialog = () => {
    handleCloseDialog();
    historyGo(-1);
  }

  const actualOnCloseBooking = () => {
    onCloseBooking();
    historyGo(-1);
  }
  const [moduleID, setModuleID] = useState();
  const [isOpenModuleDetail, setIsOpenModuleDetail] = useState(false);
  const [isOpenProgressDetail, setIsOpenProgressDetail] = useState();
  const [progressUrl, setProgressUrl] = useState('');
  const handleOpenModuleDetails = (id) => {
    setIsOpenModuleDetail(true);
    setModuleID(id)
  }
  const handleCloseModuleDialog = () => {
    setIsOpenModuleDetail(false);
  }
  const handleOpenProgressModal = (url) => {
    setProgressUrl(url);
    setIsOpenProgressDetail(true);
  };
  const handleCloseProgressModal = () => {
    setIsOpenProgressDetail(false); 
  }
  
  return (
    <PrivatePage 
      isShowMenuBack={true} 
      title="Practice activities"
      onBack={() => historyGo(-1)}
      className="MyCoursePage"
    >
      <Navbar menuLists={courseLevel} onClickMenu={onClickMenu} />
      {isLoading && <CircularProgress />}
      {!isLoading && <>
        {!isTablet ? (
          <div className="main-content">
            <div>
              <NavBarLeft 
                c1Id={c1Id} 
                c2Id={c2Id}
                c3Id={c3Id}
                c2UniqueId={c2UniqueId}
                courseSideBar={courseSideBar}
                onSideBarClick={onSideBarClick}
              />
            </div>
              
            <div className="main-right">
              <div className="lesson-detail__top">
                <div className="lesson-detail__left">
                  <img src={backIcon} alt="" onClick={onGoBack} />
                  <span tabIndex={0} onKeyPress={onGoBack} onClick={onGoBack}>Back</span>
                </div>
                {courseDetails?.display_favorite && <>
                  {courseDetails.favorite?
                  <div
                    className="lesson-detail__right"
                    style={{cursor: "pointer"}}
                    tabIndex={0}
                    onClick={onRemoveFavorite}
                    onKeyPress={onRemoveFavorite}
                  >
                    <span>Remove from wishlist</span>
                    <img src={starIcon} style={{background: "yellow", borderRadius: "50%", border: "1px solid black"}} alt=""/>
                  </div> : 
                  <div
                    className="lesson-detail__right"
                    style={{cursor: "pointer"}}
                    tabIndex={0}
                    onClick={onAddFavorite}
                    onKeyPress={onAddFavorite}
                  >
                    <span>Add to wishlist</span>
                    <img src={starIcon} alt=""/>
                   </div>}
                </>}
                
              </div>
              <LessonDetails 
                courseDetails={courseDetails} 
                onChangeCentre={onChangeCentre}
                onClickAudio={openModalAudio}
                isTablet={false}
                centreId={centreId}
                getData={getData}
              />
            </div>
          </div>
        ) : (
          <LessonDetails
            courseDetails={courseDetails}
            onClickAudio={openModalAudio}
            isTablet={true}
            getData={getData}
          />
        )}
      </>}

      {selectedIdAudio === "" &&
        <Modal
          open={open}
          handleCloseDialog={actualHandleCloseDialog}
          className="booking-modal"
        >
          {(!isShowClassBookedPage && !isShowCancelBooking) &&
            <BookingPage onCloseBooking={actualOnCloseBooking} handleOpenModuleDialog={handleOpenModuleDetails} />
          }
          {(isShowClassBookedPage && !isShowCancelBooking) &&
            <ClassBookedPage onRedirectPage={actualOnCloseBooking} />
          }
          {(isShowCancelBooking && !isShowClassBookedPage) &&
            <CancelBooking onCloseBooking={actualOnCloseBooking} />
          }
        </Modal>
      }
      <Modal
        open={isOpenModuleDetail}
        handleCloseDialog={handleCloseModuleDialog}
        className={`module-booking-modal`}
      >
        
        <Button variant="contained" onClick={handleCloseModuleDialog} className="btn btn-return btn-top">Close</Button>
        <div className="external-box">
          <ModuleBooking
            id={moduleID}
            handleOpenProgressModal={handleOpenProgressModal}
            handleCloseModuleDialog={handleCloseModuleDialog}
          />
        </div>
      </Modal>
      <Modal
        open={isOpenProgressDetail}
        handleCloseDialog={handleCloseProgressModal}
      >
        <Button variant="contained" onClick={handleCloseProgressModal} className="btn btn-return btn-top">Close</Button>
        <div className="external-box">
          <iframe src={progressUrl} width="100%" height="100%" title="Progress"/>
        </div>
      </Modal>
      {selectedIdAudio !== "" &&
        <Modal
          open={open}
          handleCloseDialog={onCloseModalAudio}
          useThemeClasses={isTablet}
        >
          {isTablet &&
            <ModalAudio 
              onCloseModalAudio={onCloseModalAudio} 
              idAudio={selectedIdAudio} 
              nameAudio={audioName}
            />
          }
          {!isTablet &&
            <ModalAudioDesktop
              onCloseModalAudio={onCloseModalAudio}
              idAudio={selectedIdAudio}
              nameAudio={audioName}
            />
          }
        </Modal>
      }
    
    </PrivatePage>
  )
}

export default MyCourseDetailsPage;
