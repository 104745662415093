import { Button } from '@material-ui/core';
import { memo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import CircularProgressBar from '../../Common/CircularProgressBar';


const ProgressViewItem = (props) => {
  const {handleOpenProgressModal,handleOpenReviewModal, buttonTitle, classes, title, subTitle, desc, grade, url, credentialPage, color, item} = props;
  const handleClick = (url) => {
    window.open(url, "_blank");
  }
  return (
    <div
      className={`PassClassViewItem PracticeViewItem lesson-theme ${classes}`}
      style={{'borderLeftColor': color ? color : '#009D8C'}}
    >
      <div className="view-left">
        <div className="text">{title}</div>
        <div className="textTitle secondary">
          <span>{ReactHtmlParser(subTitle)}</span>
        </div>
        <div className="text textDesc">{desc}</div>
      </div>
      <div className="view-center">
        <div className="icon-block secondary information-progress">
          <CircularProgressBar
            strokeWidth="3"
            sqSize="52"
            completeProgress={grade}
            percentage={100 - (grade*10)}
          />
        </div>
      </div>
      <div className="view-right">
        {credentialPage ?
          <>  
            <Button variant='contained' className={`btn btn-booked btn-start btn-practice secondary`} onClick={()=> handleClick(credentialPage)}>Share</Button>
            <span className='btn-link' onClick={() => handleOpenReviewModal(item)}>Review activities</span>
          </> 
        : ( 
          <>
            <Button variant="contained" className={`btn btn-booked btn-start btn-practice secondary`} onClick={() => handleOpenProgressModal(item)}>{buttonTitle ?? 'Progress Check'}</Button>
          </>
        )}
      </div>
    </div>
  )
}

export default memo(ProgressViewItem);