import { ActionTypes } from '../types';
import CoursePageApi from '../../services/myCourseApi';
import CourseLevelApi from '../../services/courseLevelApi';

export const getSideBarAction = (id, level_id) => (dispatch) => {
  return CoursePageApi.getSideBarSection(id, level_id).then(response => {
    if(response && response.success) {

      dispatch({
        type: ActionTypes.GET_COURSE_SIDEBAR_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_COURSE_SIDEBAR_FAIL,
        payload: 'Get course sidebar failed'
      })
    }
  })
}

export const getCourseContentAction = (content_type, content_id, student_id, centre_id) => (dispatch) => {
  return CoursePageApi.getCourseContent(content_type, content_id, student_id, centre_id).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_COURSE_CONTENT_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_COURSE_CONTENT_FAIL,
        payload: 'Get course content failed'
      })
    }
  })
}

export const getCourseLevel = (student_id) => (dispatch) => {
  return CourseLevelApi.getCourseLevel(student_id).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_COURSE_LEVEL_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_COURSE_LEVEL_FAIL,
        payload: 'Get course level failed'
      })
    }
  })
}
