import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class teacherListApi {
  async getTeacherList(json) {
    const {centreId, status, teacherId, dateFrom, dateTo, lessonTaken, friend, wishList, availability} = json;
    let url = `teacher-list?centre=${centreId}&is_teacher_taught_before=${status}&lessonTaken=${lessonTaken}&friend=${friend}&wishList=${wishList}&availability=${availability}`;

    if (!isNaN(parseInt(teacherId))) {
      url += '&teacherId=' + teacherId;
    }

    if (dateFrom && dateTo) {
      url += '&dateFrom=' + dateFrom + '&dateTo=' + dateTo;
    }

    try {
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
}

export default new teacherListApi();