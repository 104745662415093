export const Timeslot = (props) => {
  const {
    timeSlotActive,
    onChooseClassTime,
    classTime,
    timeSlots,
    onPickTimeSlots,
    translations
  } = props;
  
  let morningTranslate = translations['[text_prefer_morning]'];
  let afternoonTranslate = translations['[text_prefer_afternoon]'];
  let eveningTranslate = translations['[text_prefer_evening]'];
  
  return (
    <>
      {timeSlotActive && 
        <>
        {/* <div className="bg-come-class">
          <div className="profile-capture-wrapper">
            <h2 className="ttl-2">{ tempMotivationSections.centre_when_text}</h2>
          </div>
        </div> */}
        <div className="profile-capture-wrapper">
          <div className="profile-capture-form">
            <div className="option-2-item-wrapper">
              <div className="list-option-item">
                <div
                  className={`option-item ${classTime === 'any' ? 'active' : ''}`}
                  onClick={() => onChooseClassTime('any')}
                >
                  <input
                    className="form-control"
                    type="checkbox"
                    readOnly={true}
                    checked={classTime === 'any' ? true : false}
                  />
                  <label>{ translations['[prefer_daytime]'] }</label>
                </div>
              </div>
              <div className="list-option-item">
                <div
                  className={`option-item ${classTime === 'specific' ? 'active' : ''}`}
                  onClick={() => onChooseClassTime('specific')}
                >
                  <input
                    className="form-control"
                    type="checkbox"
                    readOnly={true}
                    checked={classTime === 'specific' ? true : false}
                  />
                  <label>{ translations['[prefer_specific_daytime]'] }</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {classTime === 'specific' &&
        <div className="other-reason-inner">
          <div className="other-reason-w">
            <div className="time-slots-inner">
              <label className="lbl-other">{ translations['[text_select_time_slots]'] }</label>
              <div className="time-slots-list">
                <div
                  className={`time-slots ${timeSlots.includes(11) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(11)}
                >
                  <div className="time-slots-items">
                    <div className="ttl-day">{ translations['[label_short_monday]'] }</div>
                    <div className="least-slots">
                      { morningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(21) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(21)}
                >
                  <div className="time-slots-items">
                    <div className="ttl-day">{ translations['[label_short_tuesday]'] }</div>
                    <div className="least-slots">
                      { morningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(31) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(31)}
                >
                  <div className="time-slots-items">
                    <div className="ttl-day">{ translations['[label_short_wed]'] }</div>
                    <div className="least-slots">
                      { morningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(41) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(41)}
                >
                  <div className="time-slots-items">
                    <div className="ttl-day">{ translations['[label_short_thursday]'] }</div>
                    <div className="least-slots">
                      { morningTranslate }

                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(51) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(51)}
                >
                  <div className="time-slots-items">
                    <div className="ttl-day">{ translations['[label_short_friday]'] }</div>
                    <div className="least-slots">
                      { morningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(61) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(61)}
                >
                  <div className="time-slots-items">
                    <div className="ttl-day">{ translations['[label_short_saturday]'] }</div>
                    <div className="least-slots">
                      { morningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(71) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(71)}
                >
                  <div className="time-slots-items">
                    <div className="ttl-day">{ translations['[label_short_sunday]'] }</div>
                    <div className="least-slots">
                      { morningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(12) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(12)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { afternoonTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(22) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(22)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { afternoonTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(32) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(32)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { afternoonTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(42) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(42)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { afternoonTranslate } 
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(52) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(52)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { afternoonTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(62) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(62)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { afternoonTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(72) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(72)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { afternoonTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(13) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(13)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { eveningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(23) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(23)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { eveningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(33) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(33)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { eveningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(43) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(43)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { eveningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(53) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(53)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { eveningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(63) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(63)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { eveningTranslate }
                    </div>
                  </div>
                </div>
                <div
                  className={`time-slots ${timeSlots.includes(73) ? 'active' : ''}`}
                  onClick={() => onPickTimeSlots(73)}
                >
                  <div className="time-slots-items">
                    <div className="least-slots">
                      { eveningTranslate }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
        </>}
    </>
  )
}