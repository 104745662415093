import React from "react";

import ProgressPathways from "./ProgressPathways";
import Grammar from "./Grammar";
import useCheckMobileTablet from "../../../../utils/useCheckMobileTablet";
import ActivityPace from "./ActivityPace";

const Activity = (props) => {
  const { data, onRedirectPage, showGrammarWidget } = props;
  const { isMobile } = useCheckMobileTablet();
  
  const grammarUrl = data?.widget_url || "";
  
  return (
    <div className="block-content block-content--activity">
      {/* <h4 className="section-title">Activity</h4> */}
      {/* 
      waiting client confirm
      <ActivityPace isMobile={isMobile}/> 
      */}
      {data?.pathways && (
        <ProgressPathways
          onRedirectPage={onRedirectPage}
          pathways={data.pathways}
        />
      )}
      {(data?.grammar && showGrammarWidget) ? (
        <Grammar
          grammarUrl = {grammarUrl}
          grammar={data?.grammar}
          isMobile={isMobile}
        />
      ) : ''}
    </div>
  );
};

export default Activity;
