import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import CONFIG from '../../../../config/config';
import mapIcon from '../../../../assets/image/map-pin.png';

class MapCentre extends Component {
  static defaultProps = {
    center: {
      lat: 22.2763004,
      lng: 114.1642990
    },
    zoom: 16
  };

  render() {
    const CustomMaker = () => <div><img src={mapIcon} alt="Location"/></div>;
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: CONFIG.googleMapKey }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <CustomMaker
            lat={this.props.center.lat}
            lng={this.props.center.lng}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default MapCentre;