import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from '../Common/ProgressBar';
import ProgressSkill from './ProgressSkill';
import LineChart from './LineChart';
import CircularProgressBar from '../Common/CircularProgressBar';
import { Button } from '@material-ui/core';
import { getRoute } from '../../../components/Utilities';
import PathWays from './Pathways';
import useProgressPage from '../../../hooks/ProgressPage/useProgressPage';
import ProgressWrapper from './ProgressWrapper';
import ModuleProgressModular from "../Home/ModuleProgressModular";
import barChartIcon from '../../../../assets/image/bar-chart-2.png';
import speakIcon from '../../../../assets/image/mic.png';
import writeIcon from '../../../../assets/image/writing.png';
import grammarIcon from '../../../../assets/image/grammar.png';
import pathwayIcon from '../../../../assets/image/pathway.png';
import CircularProgress from '../Common/CircularProgress';
import Achievements from "./Achievements";
import Proficiency from "./Proficiency";
import Performance from "./Performance";
import Activity from "./Activity";
import useCheckMobileTablet from "../../../../utils/useCheckMobileTablet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pace from "./Pace";


const ProgressPage = () => {
  const { data, onChangeOption, productList, productId, isLoading, performanceList, milestonesList, proficiencyData, typeChart, paceChart, onSetTypeChart } = useProgressPage();

  const history = useHistory();
  const { isMobile } = useCheckMobileTablet()
  const onRedirectPage = () => {
    history.push(getRoute('PROGRESSDETAIL'));
  }

  const widgetLevel = data?.widget_level || "";
  const grammarUrl = data?.widget_url || "";
  useEffect(() => {
    if (!isLoading) {
      window.scrollTo(0, 0);
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
    }
  }, [data, isLoading]);
  return (
    <ProgressWrapper onChangeOption={onChangeOption} productList={productList} productId={productId} isLoading={isLoading} courseCode={data?.student_level?.course_code}>
      {isLoading && <CircularProgress />}
      {!isLoading && 
      <>
      <div className="block-content block-lesson">
        <h4 className="section-title">Activity</h4>
        <div className="main-content__row --lesson lesson">
          {/* <h4 className="block-title">Lesson target for </h4> */}
          {/* <div className="product-name">{product.productTypeName || ""} lesson target</div> */}
          {/* <img src={barChartIcon} alt="" /> */}
          <Pace isMobile={isMobile} productId={productId} typeChart={typeChart} paceChart={paceChart} onSetTypeChart={onSetTypeChart} totalChart={paceChart?.total} upArr={paceChart?.up_arrow} isLoading={isLoading} />
        </div>
        {data.moduleWidget?.modular?.length > 0 &&
        <div className="main-content__bar__2">
          <ModuleProgressModular data={data.moduleWidget.modular} productId={productId} isLockedAll={data.moduleWidget.notStarted} isMobile={isMobile}/>
        </div>
        }
        {/* <div className="main-content__bar">
          {data.lessonCountWidget?.length > 0 && data.lessonCountWidget.map((item, index) => {
            return (
              <div className="item" key={index} style={{ marginBottom: '30px' }}>
                {item.lessonTotal &&
                  <ProgressBar
                    total={item.lessonTotal}
                    count={item.lessonComplete}
                    label={item.membershipTypeName}
                    isLocked={item.notStarted}
                  />
                }
              </div>
            )
          })
          }
        </div> */}
        {/* <div className="chart-list">
          {data.progress?.map(item => {
            const lastLessonNumber = item.lastLessonNumber || 0;
            const skillType = item.skillType || "";

            return <ProgressSkill
              key={item.skillId}
              title={skillType}
              subTitle={'Suggestion'}
              description={item.suggestion}
              listScore={item.lessonScore}
              icon={skillType === 'Speaking' ? speakIcon : writeIcon}
              averageScore={item.averageScore}
              barInfo={`Last ${lastLessonNumber} ` + skillType.toLowerCase() + ' assessment' + (lastLessonNumber > 1? "s": '')}
            />
          })}

          <div className="pathway">
            <div className="pathway-header">
              <div>
                <div className="pathway-header__title">Pathways</div>
                <img src={pathwayIcon} alt="" />
              </div>
            </div>
            <div>
              {data?.pathways?.map((item, index) => {
                return <PathWays
                  key={index}
                  title={item.title}
                  name={item.name}
                  total={item.totalTheme}
                  completed={item.completeTheme}
                  inProgress={item.inProgress}
                />
              })}
            </div>
            <Button variant="contained" className="btn btn-default" onClick={onRedirectPage}>Details</Button>
          </div>

          {data?.show_grammar_widget && <div className="grammar">
            <div className="grammar-header">
              <div>
                <div className="grammar-header__title">{widgetLevel !== ""? (widgetLevel + " g"): "G"}rammar practice</div>
                <img src={grammarIcon} alt="" />
              </div>
              <div>
                <div className="grammar-header__text">Total completion</div>
                
                <CircularProgressBar
                  strokeWidth="3"
                  sqSize="52"
                  completeProgress={data?.grammar?.totalPercentage}
                  percentage={100 - (data?.grammar?.totalCompletion*10)}
                />
              </div>
            </div>
            <div className="subheader">
              <a target="_blank" href={grammarUrl} className="grammar-url">You can do these activities any time.</a>
            </div>
            <div className="grammar-chart">
              {data?.grammar?.score?.map((item, index) => {
                return <LineChart 
                          key={index} 
                          label={item.name} 
                          percent={item.percentage}
                          score={item.score}
                        />
              })}
            </div>
          </div>}
        </div> */}
      </div>
      {/* {data.moduleWidget?.modular?.length > 0 &&
        <div className="main-content__bar__2">
          <ModuleProgressModular data={data.moduleWidget.modular} isLockedAll={data.moduleWidget.notStarted} isMobile={isMobile}/>
        </div>
        } */}
      
      <Activity showGrammarWidget={data?.show_grammar_widget} onRedirectPage={onRedirectPage} data={data} />
      {(data?.progress || performanceList?.length) ?
        <Performance score={data?.progress} isMobile={isMobile} performanceList={performanceList}/>
      :''}
      <Achievements isMobile={isMobile}  milestonesList={milestonesList}/>
      <Proficiency isMobile={isMobile}  proficiencyData={proficiencyData}/>
      </>  
    }
    </ProgressWrapper>
  )
}

export default ProgressPage;