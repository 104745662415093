import Contact from './Contact'
import ProfileWraper from './ProfileWraper';
import { useHistory } from 'react-router-dom';



const ContactPage = () => {

  const history = useHistory()

  const onClose = () => {
    history.goBack()
  }


  return (
    <ProfileWraper onClose={onClose} title={`${'Contact centres'}`}>
      <Contact />
    </ProfileWraper>
    
  )
}

export default ContactPage