import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountCredits, getProductList, getCreditHistory, reduceCreditHistory, clearCreditHistory } from '../../redux/actions/StudentAccount/StudentAccountAction';
import { getCurrentUser } from '../../../utils/currentUser';

const useStudentAccount = (productTypeId = null) => {
  const productData = useSelector(state => state.studentAccount.productList);
  const studentCreditData = useSelector(state => state.studentAccount.data);
  const creditHistoryData = useSelector(state => state.studentAccount.creditHistoryData);
  const isShowMore = useSelector(state => state.studentAccount.isShowMore);

  const dispatch = useDispatch();

  const [productId, setProductId] = useState(-1);
  const [userId, setUserID] = useState(0);
  const [creditHistories, setCreditHistories] = useState([]);
  const [pagination, setPagegination] = useState(1);
  const [isShowLess, setIsShowLess] = useState(false);

  useEffect(() => {
    const currentUser = getCurrentUser();
    const id = currentUser?.responseUserData?.id;
    if(id) {
      if(productTypeId) {
        dispatch(getProductList(id))
        onChangeProduct("", productTypeId);
      } else if (currentUser && currentUser.responseUserData) {
        setUserID(id);
        dispatch(getProductList(id)).then((response) => {
          if (response?.success) {
            if (Array.isArray(response.data) && response.data.length) {
              onChangeProduct("", response.data[0].id);
            }
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [dispatch, productTypeId]);

  useEffect(() => {
    setCreditHistories(creditHistoryData);
  }, [creditHistoryData]);

  const onChangeProduct = (name, value) => {
    if (value !== productId) {
      setProductId(value);
      dispatch(clearCreditHistory()).then((reponse) => {
        setPagegination(1);
        dispatch(getAccountCredits(userId, value))
        dispatch(getCreditHistory(userId, value, 1));
      });
    }
  }

  const onShowMore = () => {
    let page = pagination + 1;
    setPagegination(page);
    dispatch(getCreditHistory(userId, productId, page));
    setIsShowLess(true);
  }

  const onShowLess = () => {
    setPagegination(1);
    setIsShowLess(false);
    dispatch(reduceCreditHistory());
  }

  return {
    productData,
    onChangeProduct,
    studentCreditData,
    creditHistories,
    onShowMore,
    isShowMore,
    productId,
    isShowLess,
    onShowLess
  }
}

export default useStudentAccount;