import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import useProfileCapture from "../../../hooks/ProfileCapture/useProfileCapture";
import { setCentre, setLanguages } from '../../../redux/actions/ProfileCapture/profileCaptureAction';
import { useSelector } from 'react-redux';
import profileCaptureApi from '../../../redux/services/profileCaptureApi';

const ModalListCentre = (props) => {
    const { onClose, open, dispatch,setShowModalListCentre } = props
    const [centreId, setCentreId] = useState(0)
    const [apiEndPointPrefix, setApiEndPointPrefix] = useState()
    // const [showModalListCentre, setShowModalListCentre] = useState(false)
    const { centreList, openProfileCapture } = useProfileCapture()
    const { translations } = useSelector(state => state.profilecapture)
    const onSelectCentre = async (e) => {
      if (e.target.value) {
        setCentreId(e.target.value)
        
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index]
        let option =  optionElement.getAttribute('data-api');
        setApiEndPointPrefix(option);
      }
    }

    const onSubmitModalListCentre = async () => {
        localStorage.setItem('current_centre', centreId) 
        if(apiEndPointPrefix) {
          localStorage.setItem('apiEndpointPrefixByCentre', apiEndPointPrefix)
        }
        dispatch(setCentre(+centreId))
        profileCaptureApi.getLanguages().then((result) => {
          let currentLanguage = localStorage.getItem('current_language');
          let data = result?.data.filter((item) => { return item.id == currentLanguage });
          if(data.length == 0) {          
            localStorage.setItem('current_language', 1);
          } else {
            localStorage.setItem('rtl', data[0].is_right_to_left ?? 0);           
          }
          window.location.reload();
        });      
        onClose()
    }
    useEffect(() => {
        openProfileCapture()
    }, [])
    return (
        <div>
            <Modal
                onClose={onClose}
                open={open}
                dir={localStorage.getItem('rtl') == 1 ? 'rtl' : ''}
            >
                <div className="wrap-modal-pass">
                <div className="head-modal">
                    <h5>{translations['[label_for_staff_use]']}</h5>
                </div>
                <div className='wrap-form-pass'>
                    <div className="select-area">
                    {centreList && centreList.length > 0 && 
                    <select name="select-lang" value={centreId} onChange={onSelectCentre}>
                        <option value="">{translations['[option_choose_centre]']}...</option>
                    {centreList.map(item => {
                        return (
                        item.name_en && <option key={item.id} data-api={item.prefix_api} value={item.id}>{item.name_en}</option>
                        )
                    })}
                    </select>
                    }
                    </div>
                    <FormControl className='group-btn-pass' fullWidth margin='normal'>
                    <Button
                        color='primary'
                        type='submit'
                        onClick={onSubmitModalListCentre}
                    >
                        {translations['[button_submit]']}
                    </Button>
                    <Button
                        color='primary'
                        type='submit'
                        onClick={onClose}
                    >
                        {translations['[option_cancel]']}
                    </Button>
                    </FormControl>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
ModalListCentre.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
export default ModalListCentre;