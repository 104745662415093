import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContactList } from '../../redux/actions/Contact/ContactAction';

const useContact = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.contactList.data);
  useEffect(() => {
    dispatch(getContactList())
    // eslint-disable-next-line
  }, []);

  return {
    data
  }
}

export default useContact;