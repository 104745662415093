import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import AdvancedSelects from '../Common/Select';
import '../../../../sass/private/MyCourse/BookClass.scss';
import BookClassItem from './BookClassItem';
import { Collapse } from '@material-ui/core';

const BookClass = (props) => {
  const { listCentres, classes = [], onChangeCentre, isAttended, openBookingWindow, centreId } = props
  const classPerPage = 5;
  const [classPageNo, setClassPageNo] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [bookAgain, setBookAgain] = useState(false);
  const [collapseBtn, setCollapseBtn] = useState('Book again');

  useEffect(() => {
    setPagination(1);
  }, [classes]);

  const setPagination = (pageNo) => {
    setClassPageNo(pageNo);
    setShowMore(pageNo * classPerPage < classes.length);
  };

  const onClickShowMore = () => {
    setPagination(classPageNo + 1);
  };

  const onClickBookAgain = () => {
    if (bookAgain) {
      setBookAgain(false);
      setCollapseBtn('Book again');
    } else {
      setBookAgain(true);
      setCollapseBtn('Collapse');
    }
  };

  return (
    <div className="BookClass">
      <div className="book-wrapper">
        <div className="book-info">
          {isAttended ? (
            <>
          <div>
            <div className="book-title-attended">You've already taken this class</div>
            <div className="book-subtitle">You can book this class again</div>
          </div>
          <div className="book-item__collapse-btn">
            <Button variant="contained" className="btn btn-showmore" onClick={onClickBookAgain}>{collapseBtn}</Button>
          </div>
            </>
          ) : (
            <>
          <div>
            <div className="book-title">Book a class</div>
            <div className="book-subtitle">Choose a class led by an expert British Council teacher.</div>
          </div>
            </>
          )}
        </div>
        <Collapse in={bookAgain || !isAttended}>
        <div className="book-centre">
          {
              classes.length > 0? (
                <div className="teacher">
                  <div className="calendar-text">Centre</div>
                  <AdvancedSelects data={listCentres} label="" 
                  value={centreId} onChangeOption={onChangeCentre} nameSelect="teacher" />
                </div>
              ) : (
                <>
                <div className="teacher">
                  <div className="calendar-text">Centre</div>
                  <AdvancedSelects data={listCentres} label="" 
                  value={centreId} onChangeOption={onChangeCentre} nameSelect="teacher" />
                </div>
                <div className="book-nolesson">This lesson is not available to book yet.</div>
              </>
              )
            }
          {
            classes.length > 0 &&
              <div className="book-list">
                { classes.slice(0, classPageNo * classPerPage).map((classItem, index) => {
                  return <BookClassItem
                    key={index}
                    classItem={classItem}
                    openBookingWindow={openBookingWindow}
                  />
                }) }
                {showMore &&
                  <div className="book-item__btn">
                    <Button variant="contained" className="btn btn-showmore" onClick={onClickShowMore}>Show more</Button>
                  </div>
                }
              </div>
          }
        </div>
        </Collapse>
      </div>
    </div>
  )
}

export default BookClass;