import { ActionTypes } from '../../actions/types';

const initialState = {
  data: {}
}

export default function notificationReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: payload,
      }
    case ActionTypes.GET_NOTIFICATION_FAIL:
      return {
        ...state,
        message: payload
      }
    case ActionTypes.RESET_NOTIFICATION:
      return initialState
    case ActionTypes.DISMISS_NOTIFICATION:
      let arr = [...state.data.notifications];
      arr.splice(payload, 1);
      return {
        ...state,
        data: {
          ...state.data,
          notifications: arr
        },
        message: payload
      }
    default:
      return state;
  }
}
