import React, { useRef, useState } from "react";
import Slider from "react-slick";

const Proficiency = (props) => {
  const { isMobile, proficiencyData } = props;
  const [activeTab, setActiveTab] = useState(
    proficiencyData?.completing_level?.order || 0
  );
  const proficiencySlider = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "15px",
    initialSlide: proficiencyData?.completing_level?.order - 1 || 0
  };
  if (!proficiencyData) {
    return "";
  }
  return (
    <>
      {proficiencyData && (
        <div className="block-content block-content-proficiency">
          <h4 className="section-title">Proficiency</h4>
          <div className="wrapper">
            {proficiencyData?.completed_level ? (
              <div className="proficiency-progress">
                <span className="left"></span>
                <span className="point point--left">
                  {proficiencyData?.completed_level?.course_code}
                </span>
                <span className="middle">
                  <svg
                    width="104"
                    height="8"
                    viewBox="0 0 104 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect y="3" width="100" height="2" fill="#E4B706" />
                    <path
                      d="M104 4L98 7.4641L98 0.535899L104 4Z"
                      fill="#E4B706"
                    />
                  </svg>
                </span>
                <span className="point point--right">
                  {proficiencyData?.completing_level?.course_code}
                </span>
                <span className="right"></span>
                <div className="description">
                  You are completing{" "}
                  <strong>
                    {proficiencyData?.completing_level?.course_code}
                  </strong>
                </div>
              </div>
            ) : (
              // UI 2
              <div className="proficiency-progress proficiency-no-completed">
                <span className="middle">
                  <svg
                    width="104"
                    height="8"
                    viewBox="0 0 104 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect y="3" width="100" height="2" fill="#E4B706" />
                    <path
                      d="M104 4L98 7.4641L98 0.535899L104 4Z"
                      fill="#E4B706"
                    />
                  </svg>
                </span>
                <span className="point point--right">
                  {proficiencyData?.completing_level?.course_code}
                </span>
                <span className="right"></span>
                <div className="description">
                  You are completing{" "}
                  <strong>
                    {proficiencyData?.completing_level?.course_code}
                  </strong>
                </div>
              </div>
            )}

            <div className="proficiency-tabs">
              <h4 className="tabs-title">Learn about CEFR Levels</h4>
              {!isMobile && (
                <>
                  <div className="tabs-list">
                    {proficiencyData?.levels
                      ? Object.keys(proficiencyData?.levels)?.map(
                          (level, index) => {
                            return (
                              <button
                                className={`tab-item ${
                                  activeTab === Number(level) ? "active" : ""
                                }`}
                                onClick={() => setActiveTab(Number(level))}
                                type="button"
                                key={index}
                              >
                                {proficiencyData?.levels[level]?.course_code}
                              </button>
                            );
                          }
                        )
                      : ""}
                  </div>
                  <div className="tab-content">
                    {proficiencyData?.levels
                      ? Object.keys(proficiencyData?.levels)?.map(
                          (level, index) => (
                            <div
                              className={`tab-content-item ${
                                activeTab === Number(level) ? "show" : ""
                              }`}
                              key={index}
                            >
                              <div className="tab-content-wrapper">
                                <h4>
                                  {proficiencyData?.levels[level]?.course_code}{" "}
                                  {proficiencyData?.levels[level]?.name}
                                </h4>
                                <ul>
                                  {proficiencyData?.levels[
                                    level
                                  ]?.statements?.map((statement, j) => (
                                    <li key={j}>{statement}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )
                        )
                      : ""}
                  </div>
                </>
              )}
              {isMobile && (
                <div className="slider-wrapper">
                  <Slider {...settings}>
                    {proficiencyData?.levels
                      ? Object.keys(proficiencyData?.levels)?.map(
                          (level, index) => (
                            <div className="slider-item" key={index}>
                              <div className="tab-content-wrapper">
                                <h4>
                                  {proficiencyData?.levels[level]?.course_code}{" "}
                                  {proficiencyData?.levels[level]?.name}
                                </h4>
                                <ul>
                                  {proficiencyData?.levels[
                                    level
                                  ]?.statements?.map((statement, j) => (
                                    <li key={j}>{statement}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )
                        )
                      : ""}
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Proficiency;
