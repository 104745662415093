import { useState, useEffect, memo } from 'react';
import '../../../../sass/private/StudentAccount/ProductSummary.scss';
import { Doughnut } from 'react-chartjs-2';

const ProductSummary = (props) => {
  const { score, name, shortName, progressBar1, progressBar2, progressBar3 } = props;
  const [data, setData] = useState({});
  useEffect(() => {
    const dataChart = {
      datasets: [{
        data: [16.7, progressBar1, progressBar2, progressBar3, 0, 0, 0, 16.7],
        backgroundColor: [
          '#23085A',
          '#009D8C',
          '#AB0033',
          '#FFFFFF',
          '',
          '',
          '',
          '#23085A'
        ],
        borderWidth: 0,
      }]
    }
    setData(dataChart)
  }, [progressBar1, progressBar2, progressBar3])
  return (
    <div className="summary">
      <div className="summary-text">
        <p>Product summary</p>
        <h2>{name}</h2>
        <div className="annotation">
          <div className="annotation__item"><span className='green'></span>
            Classes attended or booked
          </div>
          <div className="annotation__item"><span className='red'></span>
            Classes missed or late cancellations
          </div>
          <div className="annotation__item"><span className='white'></span>
            Unused credit
          </div>
        </div>
        <p className="annotation-note">The figure in the centre is your weekly credit usage up to now.</p>
        <p className="annotation-note">A red border means you should increase your weekly rate in order to use up your credits before account expiry</p>
      </div>
      <div className="column"></div>
      <div className="doughnut-chart">
        <Doughnut
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            cutout: '70%',
            rotation: 180,
            events: []
          }}
          width={222}
          height={188}
        />
        <div className="chart-content">{score}</div>
      </div>
    </div>
  )
}

export default memo(ProductSummary);