import { ActionTypes } from '../types';
import CalendarApi from '../../services/calendarApi';

export const bookingCalendarAction = (json) => (dispatch) => {
  return CalendarApi.getBookingCalendar(json).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_BOOKING_CALENDAR_SUCCESS,
        payload: response,
      })
    } else {
      dispatch({
        type: ActionTypes.GET_BOOKING_CALENDAR_FAIL,
        payload: 'Get booking calendar fail!'
      })
    }
  })
}

export const getFiltersAction = () => (dispatch) => {
  return CalendarApi.getFilters().then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_FILTER_CALENDAR_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_FILTER_CALENDAR_FAIL,
        payload: 'Get filter centre fail!'
      })
    }
    return response;
  })
}

export const selectDateOfWeek = (dateOfWeek, timeout) => (dispatch) => {
  dispatch({
      type: ActionTypes.SELECT_DATE_OF_WEEK,
      payload: {
        dateOfWeek: dateOfWeek,
        timeout: timeout || 0,
      },
  });
}

export const myScheduleCalendarAction = (id) => (dispatch) => {
  return CalendarApi.getScheduleCalendar(id).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_SCHEDULE_CALENDAR_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_SCHEDULE_CALENDAR_FAIL,
        payload: 'Get schedule calendar fail!'
      })
    }
    return response;
  })
}

export const passClassesCalendarAction = (page) => (dispatch) => {
  return CalendarApi.getPassClassesCalendar(page).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_PASS_CLASS_CALENDAR_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_PASS_CLASS_CALENDAR_FAIL,
        payload: 'Get pass class calendar fail!'
      })
    }
    return response;
  })
}

export const updateFilterAction = (json) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_FILTER,
    payload: json,
  });
}

export const resetNonDesktopFilterAction = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_NON_DESKTOP_FILTER,
  });
}

export const resetNonMobileFilterAction = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_NON_MOBILE_FILTER,
  });
}

export const setGetBookingTimestampAction = (timestamp) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_GET_BOOKING_TIMESTAMP,
    payload: timestamp,
  });
}

export const setActiveDayOfWeekAction = (day) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ACTIVE_DAY_OF_WEEK,
    payload: day,
  });
}

export const resetPassClassAction = () => (dispatch) => {
  dispatch({
    type: ActionTypes.RESET_PASS_CLASS,
  });
}