import { useContext, useState, useEffect } from 'react';
import { CalendarGlobalState } from './CalendarGlobalState';
import useCalendarPage from '../../../hooks/CalendarPage/useCalendarPage';
import useCalendarView from  '../../../hooks/CalendarPage/useCalendarView';
import useModalBooking from '../../../hooks/ModalBooking/useModalBooking';
import iconArrowUp from '../../../../assets/svg/arrow-up.svg'

import CalendarWraper from './CalendarWraper';
import CalendarListView from './CalendarListView/CalendarListView';
import Navbar from '../Settings/Navbar';
import Modal from '../Dialog/Modal';
import BookingPage from '../Booking';
import ClassBookedPage from '../Booking/ClassBooked';
import CancelBooking from '../Booking/Cancel';
import CircularProgress from '../Common/CircularProgress';
import useMyCourseDetails from '../../../hooks/MyCoursePage/useMyCourseDetails';
import LessonDetails from '../MyCourse/LessonDetails';
import { getRoute } from '../../../components/Utilities';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ModalAudioDesktop from '../Booking/ModalAudioDesktop';
import ProfileWraper from '../Profile/ProfileWraper';
import PracticeListView from './CalendarListView/PracticeListView';
import ProgressListView from './CalendarListView/ProgressListView';
import { Button } from '@material-ui/core';
import useProgress from "../../../hooks/HomePage/useProgress";
import { useSocket } from "../../../hooks/HomePage/useSocket";
import { Skeleton } from '@mui/material';
import ModuleListView from '../Module/ModuleListView';
import ModuleBooking from '../Module/ModuleBooking';

const CalendarPage = (props) => {
  const globalState = useContext(CalendarGlobalState);
  const { state } = globalState;
  const history = useHistory();
  const { idActiveNavbarCalendar, isViewCalendar } = state;
  const { dataReloadTokenOthers, isLoadingLandingData, landingData } = props;
  const [isOpenLessonDetail, setIsOpenLessonDetail] = useState();
  const [isOpenProgressDetail, setIsOpenProgressDetail] = useState();
  const [progressUrl, setProgressUrl] = useState('');
  const [progressModalMessage, setProgressModalMessage] = useState('');
  const [message, setMessage] = useState("");
  const { menuData, onClickMenu, dataSchedule, dataPassClass, isLoadingSchedule, isLoadingPassClasses, dataReloadToken, reloadData, showMorePastClass } = useCalendarPage(idActiveNavbarCalendar, dataReloadTokenOthers);
  const [isOpenModuleBookingDetail, setIsOpenModuleBookingDetail] = useState(false);
  const {
    dataCalendarView,
    dataCalendarViewAvailable,
    dataCalendarViewFull,
    title,
    onNextPrevWeekList,
    onRedirectBookingWidow,
    isPastTime,
  } = useCalendarView();
  
  const {
    open,
    handleCloseDialog,
    isShowClassBookedPage,
    isShowCancelBooking,
    onCloseBooking
  } = useModalBooking();
  
  const {
    courseDetails, 
    selectedIdAudio,
    audioName,
    id,
    onChangeCentre,
    openModalAudio,
    onCloseModalAudio,
    onAddFavorite,
    onRemoveFavorite,
    isLoading,
    centreId,
    courseId, 
    setCourseId,
    setCourseType,
    getData
  } = useMyCourseDetails();

  const [moduleId, setModuleId] = useState(null);
  const {
    progressList,
  } = useProgress();

  const [showFilterAdvanced, setShowFilterAdvanced] = useState(false);
  
  const handleOpenModuleDialog = (id) => {
    setModuleId(id);
    setIsOpenModuleBookingDetail(true);
  }
  const handleCloseModuleDialog = () => {
    setIsOpenModuleBookingDetail(false);
  }
  const handleClosePracticeModal = () => {
    setIsOpenLessonDetail(false);
  }
  const handleCloseProgressModal = () => {
    setMessage("");
    setIsOpenProgressDetail(false); 
  }
  
  const handleOpenPracticeModal = (itemId, lessonType = 'lesson') => {
    setCourseId(itemId);
    setCourseType(lessonType);
    setIsOpenLessonDetail(true);
    history.push(getRoute('HOME'));
  };
  const handleOpenProgressModal = (item) => {
    if (item.modal_message != undefined) {
      setProgressModalMessage(item.modal_message);
    } else {
      setProgressModalMessage("");
    }
    if (typeof item === 'string') {
      setProgressUrl(refreshUrl(item));
    } else {
      setProgressUrl(refreshUrl(item.button_url));
    }

    setIsOpenProgressDetail(true);
    history.push(getRoute('HOME'));
  };

  const handleOpenReviewModal = (item)=>{
    setProgressModalMessage("");
    setProgressUrl(item.review_activity_url);
    setIsOpenProgressDetail(true);
    history.push(getRoute('HOME'));
  }

  const refreshUrl = (link) => {
    const url = new URL(link);
    const time = new Date().getTime().toString();

    if (url.search) {
      url.search += "&time=" + time;
    }
    else {
      url.search = "?time=" + time;
    }

    return url.origin + url.pathname + url.search + url.hash;
  }

  //  const message = () => {
  //     setMessage();
  //  }
  useEffect(() => {
    if (isOpenProgressDetail == false) {
      getData();
    }
  }, [isOpenProgressDetail]);

  useSocket({
    callBack: (payload) => {
      // reloadData()
      getData()
      if(payload.message){
        setProgressModalMessage("");
      }
      setMessage(payload.message);
    }
  });

  const actualReloadData = () => {
    if (props.reloadData) {
      props.reloadData();
    }

    reloadData();
  };

  return (
    <div className="calendar">
      <div className="select-section">
        
        {isLoadingLandingData ? 
          <Skeleton 
            animation="wave" 
            variant="text" 
            style={{width: '60%', height: '30px', transform: 'none', marginLeft: '5rem'}} 
          /> :
          <Navbar menuLists={menuData} onClickMenu={onClickMenu} />
        }
        {idActiveNavbarCalendar === 1 &&
          <div className="control-filter">
            <div
              className="control-filter__advanced"
              onClick={() => setShowFilterAdvanced(!showFilterAdvanced)}
              tabIndex={0}
              onKeyPress={() => setShowFilterAdvanced(!showFilterAdvanced)}
            >
              <img src={iconArrowUp} className={`${showFilterAdvanced ? 'icondown' : ''}`} alt={showFilterAdvanced? 'Hide': 'Show'} />
              <div
                className="--text"
              >{showFilterAdvanced ? 'Hide filters' : 'Advanced Search'}</div>
            </div>
          </div>
        }
      </div>
      {idActiveNavbarCalendar === 1 && 
        <CalendarWraper
          isViewPage={isViewCalendar}
          isShowFilter={showFilterAdvanced}
          title={title}
          dataCalendarView={dataCalendarView}
          dataCalendarViewFull={dataCalendarViewFull}
          dataCalendarViewAvailable={dataCalendarViewAvailable}
          onNextPrevWeekList={onNextPrevWeekList}
          onRedirectBookingWidow={onRedirectBookingWidow}
          dataReloadToken={dataReloadToken}
          dataReloadTokenOthers={dataReloadTokenOthers}
          isPastTime={isPastTime}
          handleOpenModuleDialog={handleOpenModuleDialog}
        />
      }
      {idActiveNavbarCalendar === 2 && 
        <>  
          <CalendarListView
            isLoadingSchedule = {isLoadingSchedule}
            isHideIconArrow={true}
            isSchedule = {true}
            data={dataSchedule}
            dataPassClass = {dataPassClass}
            onRedirectBookingWidow={onRedirectBookingWidow}
            showMorePastClass={showMorePastClass}
            isLoadingPassClasses={isLoadingPassClasses}
            landingData={landingData}
            onClickMenu={onClickMenu}
          />
        </>
      }
      {/* {idActiveNavbarCalendar === 3 && 
        <CalendarListView
          title={
            isLoadingPassClasses || dataPassClass.length > 0?
            "Here is a full list of your booked classes.":
            "You have not attended any classes yet."
          }
          isHideIconArrow={true}
          data={dataPassClass}
          pastclasses={true}
          onRedirectBookingWidow={onRedirectBookingWidow}
          showMorePastClass={showMorePastClass}
          isLoading={isLoadingPassClasses}
        />
      } */}
      {idActiveNavbarCalendar === 3 && 
        <ModuleListView 
          handleOpenModuleDialog={handleOpenModuleDialog}
          isOpenModuleBookingDetail={isOpenModuleBookingDetail}
          dataReloadTokenOthers={dataReloadToken}
        />
      }
      {idActiveNavbarCalendar === 4 && 
        <PracticeListView 
          handleOpenPracticeModal={handleOpenPracticeModal}
          isOpenLessonDetail={isOpenLessonDetail}
        />
      }
      {idActiveNavbarCalendar === 5 && 
        <ProgressListView 
          handleOpenProgressModal={handleOpenProgressModal}
          handleOpenReviewModal={handleOpenReviewModal}
          isOpenProgressDetail={isOpenProgressDetail}
          message={message}
        />
      }
      
      <Modal
        open={open}
        handleCloseDialog={handleCloseDialog}
        className={`booking-modal ${audioName && selectedIdAudio ? 'practice-audio-modal' : ''}`}
      >
        {audioName && selectedIdAudio ?
          (        
            <ModalAudioDesktop
              onCloseModalAudio={onCloseModalAudio}
              idAudio={selectedIdAudio}
              nameAudio={audioName}
            /> 
          )
        : (
          <>
            {(!isShowClassBookedPage && !isShowCancelBooking) &&
              <BookingPage onCloseBooking={onCloseBooking} reloadData={actualReloadData} handleOpenModuleDialog={handleOpenModuleDialog} />
            }
            {(isShowClassBookedPage && !isShowCancelBooking) &&
              <ClassBookedPage onRedirectPage={onCloseBooking} reloadData={actualReloadData} />
            }
            {(isShowCancelBooking && !isShowClassBookedPage) &&
              <CancelBooking onCloseBooking={onCloseBooking} reloadData={actualReloadData} />
            }
          </>
        )}
        
      </Modal>
      
      <Modal
        open={isOpenModuleBookingDetail}
        handleCloseDialog={handleCloseModuleDialog}
        className={`module-booking-modal`}
      >
        
        <Button variant="contained" onClick={handleCloseModuleDialog} className="btn btn-return btn-top">Close</Button>
        <div className="external-box">
            <ModuleBooking
              id={moduleId}
              handleOpenProgressModal={handleOpenProgressModal}
              handleCloseModuleDialog={handleCloseModuleDialog}
              reloadData={actualReloadData}
              handleOpenPracticeModal={handleOpenPracticeModal}
            />
        </div>
      </Modal>
      <Modal
        open={isOpenLessonDetail}
        handleCloseDialog={handleClosePracticeModal}
        className="practice-modal"
      >
        <ProfileWraper
          onClose={handleClosePracticeModal}
          title="Practice"
          isHideBtnBottom 
          isShowClose
          showHeader="Practice"
          className="practice-wraper">
        <> 
          {isLoading ? <CircularProgress></CircularProgress> : (
            <LessonDetails
              courseDetails={courseDetails} 
              onChangeCentre={onChangeCentre}
              onClickAudio={openModalAudio}
              isTablet={false}
              centreId={centreId}
              classes={"practice-profile"}
              isPracticeModal={true}
              handleClosePracticeModal={handleClosePracticeModal}
              setCourseType={setCourseType}
              setCourseId={setCourseId}
              getData={getData}
            />
          )}
        </>
        </ProfileWraper> 
      </Modal> 
      <Modal
          open={isOpenProgressDetail}
          handleCloseDialog={handleCloseProgressModal}
        >
          <Button variant="contained" onClick={handleCloseProgressModal} className="btn btn-return btn-top">Close</Button>
          <div className="external-box">
          {message
            ? <p className='test-new'>{message ? message : ''}</p>
            : <div className='test-new'>{progressModalMessage} </div>
          }
            <iframe src={progressUrl} width="100%" height="100%" title="Progress"/>
          </div>
        </Modal>
      
    </div>
  )
}

export default CalendarPage;