import React from 'react'
import iconPhone from '../../../assets/svg/phone2.svg';
import '../../../sass/public/AccountActiveFooter.scss';
import { useLocation } from 'react-router-dom';

const AccoutActiveFooter = (props) => {
  const { phone } = props;
  const location = useLocation();
  const activatePath = [
    "account-activated",
    "activation"
  ];

  return (
    <div className="account-activation__footer">
      <p className="footer-title">{ 
          activatePath.some(v => location.pathname.includes(v)) ? 
            "Activation problems?" : "Password problems?" 
        }
      </p>
      <div className="footer-content">
        <div className="footer-phone">
          <img src={iconPhone} alt="icon"/>
        </div>
        <div className="footer-desr">
          <p className="phone">{ phone }</p>
          <p className="last-des">Please contact our customer services team</p>
        </div>
      </div>
      
    </div>
  )
}

export default AccoutActiveFooter;