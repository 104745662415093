import LocationTeacherDisplay from '../Common/LocationTeacherDisplay';

import Users from '../../../components/icon/Users';
import Star from '../../../components/icon/Star';
import Level from '../../../components/icon/Level/index';
import Workplace from '../../../components/icon/Workplace';

import iconSpe from '../../../../assets/image/video.png'
import '../../../../sass/private/Home/ItemClassesMobile.scss'
import ReactHtmlParser from 'react-html-parser';
import Layers from '../../../components/icon/Layers';
import Iconnews from "../../../../assets/image/new-icon.png";

export const ItemClassesMobile = (props) => {
  const { 
    title, 
    time,
    teacher,
    colorHex, 
    defaultLessonParam,
    onClickClasses,
    actionAudio,
    showIconUsers,
    showIconStar,
    showIconLevel,
    showIconWorkplace,
    module,
    theme
  } = props;
  const cateColor = theme?.left_font_color;
  const cateBorderColor = theme?.product_category_color === '#23085A' ? '#fff' : 'transparent';
  const typeColor = theme?.right_font_color;
  const newIcon = theme?.is_new;
  return (
    <div 
      className="item-classes lesson-theme"
      style={{borderLeft: `6px solid ${theme.pathway_color ? theme.pathway_color : 'gray'}`}}
      onClick={() => onClickClasses(defaultLessonParam)}>
        {newIcon == 1 ? <div className='module-view-item__iconnew'>
          <img className="icon-new icon-new-mb" src={Iconnews} alt="New Icon" />
        </div>  : "" }
    <div className="row-1st booking-text-mob">
      <div className="item-classes__title">
        {theme &&
          <div className="text booking-text">
            <span className="cate" style={{backgroundColor: theme?.product_category_color, color: cateColor, borderColor: cateBorderColor}}>{theme?.product_category_name}</span>
            <span className="cate" style={{backgroundColor: theme?.product_type_color, color: typeColor}}>{theme?.product_type_name}</span>
          </div>
        }
        <div className="--header">{ReactHtmlParser(title)}</div>
        <div className="--datetime">
          <LocationTeacherDisplay time={time} {...teacher} />
        </div>
      </div>

      {actionAudio && <div className="item-classes__right">
        <img src={iconSpe} alt="iconSpe" />
      </div>}
    </div>
      <div className="row-2nd icon-block secondary">
        {showIconUsers && <Users />}
        {showIconStar && <Star />}
        {showIconLevel && <Level code={showIconLevel} />}
        {showIconWorkplace && <Workplace />}
        {module && <Layers />}
      </div>
    </div>
  )
}

export default ItemClassesMobile