import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class AccountSettingApi {
  async resetPassword(id, value) {
    try {
      const data = {
        id,
        old_password: value.currentPassword,
        password: value.password,
        password_confirmation: value.rePassword,
        isNativeApp: this.isNativeApp(),
      }
      const responseData = await makeRequest(
        getAPIEndpoint() + `resetNewPassword`,
        'post',
        data,
        true,
      );
      return Promise.resolve(responseData);
    } catch(error) {
      return Promise.reject(error);
    }
  }

  isNativeApp() {
    return window.isNativeApp === true;
  }
}

export default new AccountSettingApi();