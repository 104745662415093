import io from 'socket.io-client';
import Echo from "laravel-echo";


window.io = io;

export function createSocketConnection() {

  const host = localStorage.getItem("apiEndpointPrefix") || "";
  if (host.indexOf('.eu') !== -1) {
    return
  }
  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: "socket.io",
      host: host,
      transports: ["websocket"],
      // auth: {
      //   headers: {
      //     Authorization: `My Class ${token}`,
      //   },
      // },
    });
  }
}
