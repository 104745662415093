import achievementApi from '../../services/achievementApi';
import { ActionTypes } from '../types';

export const getAchievementsAction = async (productId) => {
    return await achievementApi.getAchievements(productId).then(response => response.data);;
}

export const downloadAction = async (productId) => {
  return await achievementApi.download(productId).then(response => response.data);
}

export const getProgressCheckAchievementAction = () => (dispatch) => {
  return achievementApi.getProgressCheckAchievement().then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_ACHIEVEMENT_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_ACHIEVEMENT_FAIL,
        payload: 'Get achievement fail!'
      })
    }
  })
}