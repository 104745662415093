import { CircularProgress } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileCaptureFinalText, setStepOrder } from '../../../redux/actions/ProfileCapture/profileCaptureAction';
import { getTranslations } from '../../../redux/actions/ProfileCapture/profileCaptureAction';
import { setFinalSectionText, setLanguageId, setLanguages, setSectionData, setStep, submitMotivations, updateSection } from '../../../redux/actions/ProfileCapture/profileCaptureAction';
import profileCaptureApi from '../../../redux/services/profileCaptureApi';
import { makeStyles } from '@material-ui/core/styles';
import { Select, MenuItem } from '@material-ui/core';

const ListLanguage = (props) => {
  const [isLoading, setIsLoading] = useState(0);
  const dispatch = useDispatch();
  const { step, lastStep, centreId, profileId, languages, motivations, sections, commentFinal } = useSelector(state => state.profilecapture)
  const languageId = localStorage.getItem('current_language');

  const loadOldDataToNewData = (section, result) => {
    let comment = section?.comment ?? '';
    let statements = [];
    let dataUserAnswered = {};
    let finalSections;

    switch(true) {
      case lastStep.includes('motivation'):
        statements = section.profile_motivation_statements.map((item) => {
          if(item.checked == true) {
            dataUserAnswered[item.id] = item.response_text;
          }
        });

        break;
      case lastStep == 'interest':
        statements = section.profile_motivation_statements.map((item) => {
          if(item.checked == true) {
            dataUserAnswered[item.id] = item.response_text;
          }
        });
        break;
      case lastStep == 'skill':
        statements = section.profile_motivation_statements.map((item) => {
          dataUserAnswered[item.id] = item.value;
        });
        break;
      case lastStep == 'subskill':
        statements = section.profile_motivation_statements.map((item) => {
          dataUserAnswered[item.id] = item.value;
        });
        break;
      case lastStep == 'learning':
        statements = section.profile_motivation_statements.map((item) => {
          dataUserAnswered[item.id] = item.value;
        });
        break;

      case lastStep == 'exam':
        comment = section.exam_comment;

        //build data user anwser
        statements = section.profile_motivation_statements.map((item) => {
          if(item.checked == true) {
            item.exams.map((exam) => {
              if(exam.checked == true) {
                let previous_attempt_array = [];
                exam.previous_attempt_array?.map((attempt_array) => {
                  if(attempt_array.checked == true) {
                    previous_attempt_array[attempt_array.value] = ''; 
                  }
                })

                let previous_attempt_times_array = [];
                exam.previous_attempt_times_array?.map((attempt_times_array) => {
                  if(attempt_times_array.checked == true) {
                    previous_attempt_times_array[attempt_times_array.value] = ''; 
                  }
                })

                let target_date_array = [];
                exam.target_date_array?.map((date_array) => {
                  if(date_array.checked == true) {
                    target_date_array[date_array.value] = ''; 
                  }
                })

                let profile_exam_motivation_statements = [];
                exam.profile_exam_motivation_statements?.map((exam_motivation_statements) => {
                  if(exam_motivation_statements.checked == true) {
                    profile_exam_motivation_statements[exam_motivation_statements.id] = ''; 
                  }
                })

                let examTypeId = item.id;
                if(dataUserAnswered.hasOwnProperty(examTypeId)) {
                  dataUserAnswered[examTypeId].push(
                    {
                      examId: exam.id,
                      'target_score_array': exam.target_score_array,
                      'prev_result': exam.prev_result,
                      'previous_attempt_array': previous_attempt_array,
                      'previous_attempt_times_array': previous_attempt_times_array,
                      'target_date_array': target_date_array,
                      'profile_exam_motivation_statements': profile_exam_motivation_statements,
                    }
                  );
                } else {
                  dataUserAnswered[examTypeId] = [
                    {
                      examId: exam.id,
                      'target_score_array': exam.target_score_array,
                      'prev_result': exam.prev_result,
                      'previous_attempt_array': previous_attempt_array,
                      'previous_attempt_times_array': previous_attempt_times_array,
                      'target_date_array': target_date_array,
                      'profile_exam_motivation_statements': profile_exam_motivation_statements,}
                  ];
                }
              }
            })
          }
        });
        //build data user anwser

        finalSections = result.sections.map((item) => {
          if(item.type == lastStep) {
            let items = item.profile_motivation_statements?.map((examType) => {
              if(dataUserAnswered.hasOwnProperty(examType.id)) {
                let oldExamIds = dataUserAnswered[examType.id].map((oldExam) => { return oldExam.examId });
                let exams = examType.exams;
                let dataFilled = exams.map((exam) => {
                  let key = oldExamIds.indexOf(exam.id);
                  if(key != -1) {
                    //found key
                    let oldData = dataUserAnswered[examType.id][key];

                    let previous_attempt_array = typeof exam.previous_attempt_array != 'undefined' && exam.previous_attempt_array.map((attempt_array) => {
                      if(oldData.previous_attempt_array.hasOwnProperty(attempt_array.value)) {
                        return {...attempt_array, ...{checked: true}}
                      }

                      return attempt_array;
                    })

                    let previous_attempt_times_array = typeof exam.previous_attempt_times_array != 'undefined' && exam.previous_attempt_times_array.map((attempt_times_array) => {
                      if(oldData.previous_attempt_times_array.hasOwnProperty(attempt_times_array.value)) {
                        return {...attempt_times_array, ...{checked: true}}
                      }

                      return attempt_times_array;
                    })

                    let profile_exam_motivation_statements = typeof exam.profile_exam_motivation_statements != 'undefined' && exam.profile_exam_motivation_statements.map((exam_motivation_statements) => {
                      if(oldData.profile_exam_motivation_statements.hasOwnProperty(exam_motivation_statements.id)) {
                        return {...exam_motivation_statements, ...{checked: true}}
                      }
                      
                      return exam_motivation_statements;
                    })

                    let target_date_array = typeof exam.target_date_array != 'undefined' && exam.target_date_array.map((date_array) => {
                      if(oldData.target_date_array.hasOwnProperty(date_array.value)) {
                        return {...date_array, ...{checked: true}}
                      }
                      
                      return date_array;
                    })

                    let target_score_array = oldData.target_score_array;
                    let prev_result = oldData.prev_result;

                    return {...exam, ...{
                      previous_attempt_array: previous_attempt_array,
                      previous_attempt_times_array: previous_attempt_times_array,
                      profile_exam_motivation_statements: profile_exam_motivation_statements,
                      target_date_array: target_date_array,
                      target_score_array: target_score_array,
                      prev_result: prev_result,
                      checked: true
                    }}
                  }

                  return exam;
                })

                return {...examType, ...{exams: dataFilled, checked: true}}
              }

              return examType;
            })

            return {...item, ...{profile_motivation_statements: items, comment: comment}}
          }
    
          return item;
        })

        return finalSections;
      case lastStep == 'local_operational':
        let centresChecked = [];
        let class_time = section.profile_motivation_statements?.class_time;
        let timeslots = section.profile_motivation_statements?.time_slots;
        let classType = section.profile_motivation_statements?.type;
        statements = section.profile_motivation_statements?.centres?.map((item) => {
          if(item.checked == true) {
            centresChecked[item.id] = '';
          }
        });
        finalSections = result.sections.map((item) => {
          if(item.type == lastStep) {
            let items = item.profile_motivation_statements?.centres?.map((centre) => {
              if(centresChecked.hasOwnProperty(centre.id)) {
                return {...centre, ...{checked: true}}
              }

              return centre;
            })
            
            let checked = item.profile_motivation_statements;
            checked.centres = items;
            checked.class_time = class_time;
            checked.time_slots = timeslots;
            checked.type = classType;
    
            return {...item, ...{profile_motivation_statements: checked, comment: comment}}
          }
    
          return item;
        })

        return finalSections;        
      default:
    }

    finalSections = result.sections.map((item) => {
      if(item.type == lastStep) {
        let checked = item.profile_motivation_statements.map((statement) => {
          if(dataUserAnswered.hasOwnProperty(statement.id)) {
            switch(true) {
              case lastStep.includes('motivation') || lastStep == 'interest':
                return {...statement, ...{checked: true, response_text: dataUserAnswered[statement.id]}}

              case lastStep == 'interest' || lastStep == 'skill' || lastStep == 'subskill' || lastStep == 'learning':
                return {...statement, ...{value: dataUserAnswered[statement.id]}}
              default:
            }
          }

          return statement;
        })

        return {...item, ...{profile_motivation_statements: checked, comment: comment}}
      }

      return item;
    })

    return finalSections;
  }

  const handleChangeLanguage = async (e) => {
    let {value} = e.target;
    dispatch(setLanguageId(value));
    localStorage.setItem('current_language', value)  
    let rtl = languages.filter((item) => { return item.id == value})[0].is_right_to_left;
    localStorage.setItem('rtl', rtl)
    let currentStep = step;
    dispatch(setStep('loading'))
    
    let stepsNotLoadSubmitMotivations = [
      'welcome',
      'reason',
      'loading',
      'complete',
    ];
    if(!stepsNotLoadSubmitMotivations.includes(step)) {
      let data = {
        centre_id: centreId,
        motivations: motivations.map(item => { return { id: item.id, rating: item.value } })
      }
      if (profileId) {
        data.profile_id = profileId 
      }
      const result = await submitMotivations(data)
      if(result) {
        let section = sections.filter(item => item.type === lastStep)[0]
        let finalSections = loadOldDataToNewData(section, result);

        dispatch(setSectionData(finalSections))
        dispatch(setFinalSectionText(result.final_comment_text))
        dispatch(setStep(currentStep))
        const stepOrder = ['welcome', 'reason', ...result.steps, 'complete']
        dispatch(setStepOrder(stepOrder))
        dispatch(getTranslations());
      }
    } else {
      setTimeout(function() {
        dispatch(setStep(currentStep))
      }, 500);
      dispatch(getTranslations());
    }
  }
  
  useEffect(() => {
    if(languages.length == 0) {    
      setIsLoading(1);  
      profileCaptureApi.getLanguages().then((result) => {
        dispatch(setLanguages(result?.data));
        setIsLoading(0);
      });      
    }
  }, [])

  return (
    <>
      {isLoading ? (
        <CircularProgress></CircularProgress>
      ) : (
        <Select className='select-custom'
          value={languageId??1}
          onChange={handleChangeLanguage}
        >
          {languages?.map(item => {
            return (
              <MenuItem
                key={item.id}
                value={item.id}
                dir={localStorage.getItem('rtl') == 1 ? 'rtl' : ''} className={`language-list`}
              >
                {item.display_name}
              </MenuItem>)
            })
          }
        </Select>
      )}
    </>
  )
}

export default ListLanguage;