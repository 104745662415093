import React from 'react';

const CommmunicationItem = (props) => {
  const { data, title, onSelect, onDeselect, id, onSelectOption, styles } = props;
  const onCheckOption = (id, itemId, optionId, optionDisable) => {
    if (optionDisable) {
      return;
    }
    onSelectOption(id, itemId, optionId);
  }
  const content = data && data.map((item, index) => {
    return (
      <div key={index} className="">
        {item.name && <h5 className="settingItem-title">{item.name}</h5>}
        <div className="settingItem-row">
          {item.methods && item.methods.map((option, index) => {
            return <div key={index} className={`select-block ${option.isDisable ? 'disabled' : ''}`}>
              <div className="select-box" onClick={() => onCheckOption(id, item.id, option.id, option.isDisable)}>
                <div className={`select-item ${!option.isDisable && option.isChecked === true ? 'active' : ''}`}></div>
              </div>
              <div className="select-text" onClick={() => onCheckOption(id, item.id, option.id, option.isDisable)}>{option.name}</div>
            </div>
          })}
        </div>
      </div>
    )
  });
 
  return (
    <div className="settingItem" style={styles}>
      {title && <div className="settingItem-row --row1">
        <div className="settingItem-row__account">{title}</div>
        {/* <div className="settingItem-row__select">
          <span onClick={() => onSelect(id)}>select all /</span>
          <span onClick={() => onDeselect(id)}> deselect</span>
        </div> */}
      </div>}
      {content}
    </div>
  )
}

export default CommmunicationItem;