import ProfileCaptureApi from '../../services/profileCaptureApi';
import { ActionTypes } from '../types';

export const getCentres = async () => {
  return await 
    ProfileCaptureApi.getCentres()
    .then(response => response.data)
}

export const getWelcomeSection = async () => {
  return await 
    ProfileCaptureApi.getWelcomescreen()
    .then(response => response.data);
}

export const getMotivations = async (centreId, profileId) => {
  return await
    ProfileCaptureApi.getMotivations(centreId, profileId)
    .then(response =>  response.data)
}

export const checkValidPassword = async (password) => {
  return await
    ProfileCaptureApi.checkValidPassword(password)
    .then(response => response.data.is_success)
}

export const submitMotivations = async (data) => {
  return await
    ProfileCaptureApi.submitMotivations(data)
      .then(response => response.data)
}

export const setCentre = centreId => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_CENTRE,
    payload: centreId
  })
}

export const setStep = step => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_STEP,
    payload: step
  })
}

export const setProfileId = profileId => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_PROFILE_ID,
    payload: profileId
  })
}

export const setGuid = guid => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_GUID,
    payload: guid
  })
}

export const setMotivationsData = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_MOTIVATIONS,
    payload: data
  })
}

export const setSectionData = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_SECTIONS,
    payload: data
  })
}

export const setComponentData = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_COMPONENTS,
    payload: data
  })
}

export const setFinalSectionText = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_LAST_SECTION_TEXT,
    payload: data
  })
}

export const setStepOrder = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_STEP_ORDER,
    payload: data
  })
}

export const updateSection = data => dispatch => {
  dispatch({
    type: ActionTypes.UPDATE_PROFILE_CAPTURE_SECTION,
    payload: data
  })
}

export const setStoreListExamEdited = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_STORE_LIST_EXAM_EDITED,
    payload: data
  })
}

export const setProfileCaptureFinalText = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_PROFILE_CAPTURE_FINAL_TEXT,
    payload: data
  })
}

export const setLanguageId = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_LANGUAGE_ID,
    payload: data
  })
}

export const setLanguages = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_LANGUAGES,
    payload: data
  })
}

export const setLastStep = data => dispatch => {
  dispatch({
    type: ActionTypes.SET_LAST_STEP,
    payload: data
  })
}

export const submitMotivation = (data, profileId) => {
  const profileMotivationStatements = data.profile_motivation_statements.map(item => {
    if (item.checked) {
      const responseText = item.response_text
      if (responseText)
        return {
          id: item.id,
          response_text: item.response_text
        }
      else
        return {
          id: item.id,
        }
    }
  }).filter(item => item?.id)
  const formattedData = {
    profile_id: profileId,
    motivations: [{
      id: data.id,
      type: "motivation",
      profile_motivation_statements: profileMotivationStatements,
      comment: data.comment || ""
    }]
  }
  return ProfileCaptureApi.submitSectionMotivation(formattedData)
}

export const submitExam = (data, profileId) => {
  const statements = []
  data.profile_motivation_statements.forEach(item => {
    if (item.checked) {
      item.exams.forEach(exam => {
        if (exam.checked) {
          const targetScore = exam.target_score_array?.find(target => target.checked) || 0
          const prevAttempt = exam.previous_attempt_times_array?.find(target => target.checked) || 0
          const targetDate = exam.target_date_array?.find(target => target.checked) || 0
          const statement = exam.profile_exam_motivation_statements?.filter(item => item.checked)
            .map(item => ({ id: item.id, response_text: item.response_text })) || {}
          statements.push({
            id: exam.id,
            target_score: targetScore?.value || 0,
            prev_attempts: prevAttempt?.value || 0,
            target_date: targetDate?.value || 0,
            prev_result: exam.prev_result,
            profile_exam_motivation_statements: statement
          })
        }
      })
    }
  })
  const formattedData = {
    profile_id: profileId,
    motivations: [{
      type: "exam",
      profile_motivation_statements: statements,
      exam_comment: data.exam_comment ?? ''
    }]
  }
  return ProfileCaptureApi.submitSectionExam(formattedData)
}

export const submitInterest = (data, profileId) => {
  const profileMotivationStatements = data.profile_motivation_statements.map(item => {
    if (item.checked) {
      return {
        id: item.id,
      }
    }
  }).filter(item => item?.id)
  if (data.comment)
    profileMotivationStatements.push({ user_interest: data.comment })

  const formattedData = {
    profile_id: profileId,
    motivations: [{
      type: "interest",
      profile_motivation_statements: profileMotivationStatements,
    }]
  }
  return ProfileCaptureApi.submitSectionInterest(formattedData)
}

export const submitLanguage = (data, profileId) => {
  const profileMotivationStatements = data.profile_motivation_statements.map(item => {
    if (item.value) {
      return {
        id: item.id,
        rating: item.value
      }
    }
  }).filter(item => item?.id)

  const formattedData = {
    profile_id: profileId,
    motivations: [{
      type: "skill",
      profile_motivation_statements: {
        skills: profileMotivationStatements,
      },
      language_comment: data.comment
    }]
  }
  return ProfileCaptureApi.submitSectionLanguage(formattedData)
}

export const submitSubskill = (data, profileId) => {
  const profileMotivationStatements = data.profile_motivation_statements.map(item => {
    if (item.value) {
      return {
        id: item.id,
        rating: item.value
      }
    }
  }).filter(item => item?.id)

  const formattedData = {
    profile_id: profileId,
    motivations: [{
      type: "subskill",
      profile_motivation_statements: {
        sub_skills: profileMotivationStatements
      },
      subskill_comment: data.comment
    }]
  }
  return ProfileCaptureApi.submitSectionSubskill(formattedData)
}

export const submitLearning = (data, profileId) => {
  const profileMotivationStatements = data.profile_motivation_statements.map(item => {
    if (item.value) {
      return {
        id: item.id,
        id_profile_importance: item.value
      }
    }
  }).filter(item => item?.id)

  const formattedData = {
    profile_id: profileId,
    motivations: [{
      type: "learning",
      profile_motivation_statements: profileMotivationStatements,
      learning_comment: data.comment || ''
    }]
  }
  return ProfileCaptureApi.submitSectionLearning(formattedData)
}

export const submitLocal = (data, profileId) => {
  const centres = data.profile_motivation_statements.centres.filter(item => item.checked)
    .map(item => { return { id: item.id } })
  let formattedData = {
    profile_id: profileId,
    motivations: [{
      type: "local_operational",
      profile_motivation_statements: {
        type: data.profile_motivation_statements.type,
        centres: centres
      },
      time_comment: data.comment,
    }]
  }
  if (data.profile_motivation_statements.class_time === 'specific' && data.profile_motivation_statements.time_slots?.length > 0) {
    formattedData.motivations[0].profile_motivation_statements.time_slots = data.profile_motivation_statements.time_slots
  }
  return ProfileCaptureApi.submitSectionLocal(formattedData)
}

export const submitFinal = (data, profileId) => {
  let formattedData = {
    profile_id: profileId,
    final_comment: data
  }
  return ProfileCaptureApi.submitSectionFinal(formattedData)
}

export const finalSubmit = (data, profileId) => {

}

export const resetAll = (isDeleted = false, profileId = 0) => dispatch => {
  isDeleted && profileId && ProfileCaptureApi.deleteProfile({ profile_id: profileId })
  dispatch({
    type: ActionTypes.RESET_PROFILE_CAPTURE,
    payload: null
  })
  dispatch(setCentre(localStorage.getItem('current_centre')))
}

export const getTranslations = (data) => dispatch => {
  ProfileCaptureApi.getTranslations().then(response => {
    if(response && response.success) { 
      dispatch({
        type: ActionTypes.SET_TRANSLATIONS,
        payload: response.data ?? {}
      })
    }
  })
}