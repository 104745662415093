import SvgIcon from '@material-ui/core/SvgIcon';

export default function Layers(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" style={{fill: 'none'}} {...props}>
      <path d="M8.00065 1.33325L1.33398 4.66659L8.00065 7.99992L14.6673 4.66659L8.00065 1.33325Z" stroke="currentColor" strokeWidth={props.strokeWidth ? props.strokeWidth : "2"} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.33398 11.3333L8.00065 14.6666L14.6673 11.3333" stroke="currentColor" strokeWidth={props.strokeWidth ? props.strokeWidth : "2"} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.33398 8L8.00065 11.3333L14.6673 8" stroke="currentColor" strokeWidth={props.strokeWidth ? props.strokeWidth : "2"} strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>


  );
}
