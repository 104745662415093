import React, { useMemo, useState } from 'react';
import { withRouter } from "react-router";
import PrivatePage from '../../../components/PrivatePage';
import SelectOption from './SelectOption';
import SelectSkillMobile from './SelectSkillMobile';

import AssessmentItem from './AssessmentItem';
import useAssessmentPage from '../../../hooks/AssessmentPage/useAssessmentPage';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';

import CustomSelect from '../Common/CustomSelect';


import '../../../../sass/private/AssessmentPage/AssessmentPage.scss';
import CircularProgress from '../Common/CircularProgress';

const AssessmentPage = (props) => {
  const { history } = props;
  const { isTablet } = useCheckMobileTablet()
  const hookProps = useAssessmentPage();
  const { data, skillData, onChangeOption, productData, dataFilter, isLoading } = hookProps;
  const dataOptionSkill = {
    options: skillData,
    label: 'Skill',
    placeholder: 'All skills'
  }
  const newOptionProductdata = productData.map(({prodcut_name: name, ...rest}) => ({
    name,
    ...rest,
  }));

  const dataOptionProduct = {
    options: newOptionProductdata,
    label: 'Product',
    placeholder: '',
  }

  const defaultOpt = { id: 0, name: "All product"};
  const listProductOption = productData.map(item => ({
    id: item.id,
    name: item.name ? item.name : item.prodcut_name
  }))

  const [selectedProduct, setProductOption] = useState((productData && productData[0]) || defaultOpt)

  const setSelectedOption = (opt) => {
		setProductOption(opt);
		onChangeOption("product",opt.id)
	}

  const onRedirectPageDetail = (id) => {
    history.push(`/assessment/${id}`);
  }

  const onBack = () => {
    history.goBack()
  }
  
  const content = data && data.map((item, index) => {
    return <AssessmentItem key={index} data={item} onRedirectPageDetail={onRedirectPageDetail} />
  });

  return (
    <PrivatePage 
      className="page-assessment" 
      isShowMenuBack={true} 
      title="Assessment" 
      onBack={onBack}
      isShowMainNavigation={false}
    >
      <div className="assessment">
        {!isTablet && 
          <div className="assessment-title">
            <div className="line-blue"></div>            
            <h2 className="title">Assessment</h2>
            <div className="text">See your assessment results and teacher feedback.</div>
          </div>
        }
        { isTablet && 
          <div className="custom-select-product">
            <span className="titleSelect">Product</span>
            <CustomSelect 
            options={listProductOption}
            selected={selectedProduct}
            setSelectedOption={setSelectedOption}
            />
          </div>
        }

        <div className="navbar-main">
          <div className="assessment-content">
            {!isTablet && 
              <div className="select-option__block">
                <SelectOption 
                  data={dataOptionSkill} 
                  onChangeOption={onChangeOption} 
                  value={dataFilter.skill} 
                />
                <SelectOption 
                  data={dataOptionProduct} 
                  onChangeOption={onChangeOption} 
                  value={dataFilter.product} 
                />
              </div>
            }
            {
              isTablet && 
                <SelectSkillMobile 
                  data={dataOptionSkill} 
                  onChangeOption={onChangeOption} 
                  value={dataFilter.skill}
                  />
            }
            {isLoading && <CircularProgress />}
            {!isLoading && (
              <div className="assessment-content-item">
                {content}
              </div>
            )} 
          </div>
        </div>
      </div>
    </PrivatePage>
  )
}

export default withRouter(React.memo(AssessmentPage));