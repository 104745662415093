import '../../../../sass/private/ProgressBar/ProgressBarMobile.scss';

const ProgressBarMobile = (props) => {
  const { label, total, count, isHideInfo, styles, isHideLable } = props;
  return (
    <div className="ProgressBarMobile">
      {!isHideLable && <div className="text">{label}</div>}
      <div className="progressbar-list__wrapper">
        <div className="progressbar-list">
          {[...Array(total)].map((item, index) => {
            return <div
              key={index}
              className={`
                progressbar-item
                ${index < count ? 'active' : ''}
                ${index === 0 ? 'first' : ''}
              `}
              style={styles}
            >
            </div>
          })}
      </div>  
        {!isHideInfo && <div className="text info">{count}/{total} lessons</div>}
      </div>
    </div>
  )
}

export default ProgressBarMobile;