import '../../../../sass/private/ProgressBar/ProgressBar.scss';

const ProgressBar = (props) => {
  const { label, total, count, isHideInfo, styles, isHideLable, customInfo, isLocked } = props;
  return (
    <div className="ProgressBar">
      {!isHideLable && <div className="text">{label}</div>}
      <div className="progressbar-list__wrapper">
        <div className="progressbar-list">
          {[...Array(total)].map((item, index) => {
            return <div
              key={index}
              className={`progressbar-item${index < count ? ' active' : ''}${index === 0 ? ' first' : ''}${index === total - 1 ? ' last' : ''}${isLocked ? ' lock' : ''}`}
              style={styles}
            >
            </div>
          })}
      </div>  
        {!isHideInfo && <div className="text info">{customInfo? customInfo: <>{count}/{total} lessons</>}</div>}
      </div>
    </div>
  )
}

export default ProgressBar;