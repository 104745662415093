import ItemClass from "./ItemClass";

const TeacherClasses = (props) => {
    const { classes } = props;

    return(
			<div className="teacher-detail__wrapper">
				{ classes.map((item, idx) => {
					return <ItemClass key={idx} item={item}/>
				})}
			</div>
    )
}

export default TeacherClasses;