import { useState, useEffect } from 'react'
import homeIcon from '../../../../assets/svg/home.svg'
import bookIcon from '../../../../assets/svg/calendar.svg'
import progressIcon from '../../../../assets/svg/bar-chart.svg'
import userIcon from '../../../../assets/svg/user.svg'
import { getRoute } from '../../../components/Utilities';
import '../../../../sass/private/Mobile/MainMenuMobile.scss'
import { useHistory } from 'react-router-dom'

const menuListInit =  [
  {
    name: 'Home',
    link: 'HOME',
    active: true,
    icon: homeIcon
  },
  {
    name: 'Booking',
    link: 'BOOKING',
    active: false,
    icon: bookIcon
  },
  {
    name: 'Progress',
    link: 'PROGRESS',
    active: false,
    icon: progressIcon,
    linkActiveList: ['PROGRESS', 'ASSESSMENT']
  },
  {
    name: 'Account',
    link: 'ACCOUNTSETTINGMENU',
    active: false,
    icon: userIcon
  }
]

const MainMenuMobile = () => {
  const history = useHistory()
  const [menuList, setMenuList] = useState(menuListInit);
  const onRedirect = link => {
    if (!link) return;
    history.push(getRoute(link));
    onChangeActiveLink(link)
  }

  useEffect(() => {
    const link = history.location.pathname;
    if (link) {
      const newLink = link.split('/')[1].toUpperCase();
      onChangeActiveLink(newLink.split('-').join(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname])

  const onChangeActiveLink = (link) => {
    const newListMenu = [...menuList];
    newListMenu.filter(item => {
      if (item && item.linkActiveList?.length > 0 && item.linkActiveList.includes(link)) {
        item.active = true;
      }
      else if (item && item.link === link) {
        item.active = true;
      } else {
        item.active = false;
      }
      return item;
    })
    setMenuList(newListMenu)
  }


  return (
    <div className="menu-header-mobile">
      {
        menuList.map((item, index) => (
          <div 
            key={index}
            className={`header-item-mobile ${item.active ? 'active' : ''} ${index !== 3 ? 'border-right' : ''}`}
            onClick={() => onRedirect(item.link)}
          >
            <img src={item.icon} alt={item.name} />
            <div>{item.name}</div>
          </div>
        ))
      }
    </div>
  )
}

export default MainMenuMobile