import { useEffect, useState } from "react";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import BannerCreate from '../../../../assets/image/bnr-create.png';

const CompletePage = (props) => {
  const { history, dispatch, step, centreId, profileId, translations } = props

  return (
    <ProfileCapturePage history={history} dispatch={dispatch} step={step} centreId={centreId} profileId={profileId}>
      <div className="create-profile-inner create-profile-would">
        <div className="profile-capture-wrapper">
          <h1 className="ttl-1">{ translations['[final_screen_text]'] }</h1>
        </div>
        <div className="create-tell-inner">
          <div className="tell-us-right profile-capture-wrapper">
              <img className="img-create" src={BannerCreate} alt="" />
            </div>
            <div className="profile-capture-wrapper">
              <div className="tell-us-left"></div>
            </div>
        </div>
      </div>
    </ProfileCapturePage>
  )
}

export default CompletePage;