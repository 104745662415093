
import useCalendarCard from '../../../hooks/CalendarPage/useCalendarCard';
import DatePicker from '../DatePicker/DatePicker';
import moment from 'moment';
import { useSelector } from 'react-redux';

const MobileBookingWeekView = (props) => {
	const { idActive, onChangeWeekList, highlights, bookAheadLimit, isPendingUpdate, isLoadingUpdate, actives, lesson_count } = props;
  const bookingCard = useSelector(state => state.calendar.bookingCard);
	const { dateOfWeek } = useCalendarCard();

  return (
		<div className="mobile-booking__week-view SelectedWeek">
			<div className="week-view__heading">
				<DatePicker isMobile bookAheadLimit={bookAheadLimit} disabled={isLoadingUpdate} />
			</div>
			{/* <ul className="week-view__week-days">
				{dateOfWeek.map((date)=> {
					return (
						<li key={date}>{moment(date).format('dd')}</li>
					)
				})}
			</ul> */}
			<ul className="week-view__week-days number">
				{dateOfWeek.map((date, index)=> {
					const dateString = moment(date).format('YYYY-MM-DD');
					const number = bookingCard[dateString]?.availableClass ?? '';
					var strNumber = parseInt(number) > 9 ?  "9+" :  number ;

					const isActive = actives.includes(dateString);
					const isHighlighted = highlights.includes(dateString);

					return (
						<li
							key={date}
							className={`${index === idActive? 'current-day': ''}${isHighlighted? ' highlight': ''}${isActive? ' active': ''}`}
							onClick={()=> onChangeWeekList(index)}
						>
							<div>{moment(date).format('dd')}</div><div className="ttl-week-day">{moment(date).format('DD')}</div>
							{parseInt(number) > 0 &&
								<div className="notification-number">{ strNumber }</div>
							}
						</li>
					)
				})}
			</ul>

		</div>
	)
}

export default MobileBookingWeekView;