import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import CourseMapPage from "../../../components/CourseMapPage"
import useCourseMap from "../../../hooks/CourseMap/useCourseMap"
import { Skeleton } from "@mui/material";
import Modal from "../../private/Dialog/Modal";
// import { useSelector } from "react-redux";
import useQuery from "../../../../utils/useQuery";
import IconsPrint from '../../../../assets/image/printer.png';

import '../../../../sass/public/CourseMap.scss';
import LogoPrint from '../../../../assets/image/logo_british.png';



const CourseMapLessonDetail = () => {
  const history = useHistory()
  const { lessonList, lessonInfo, openCourseMapLessonDetail, isLoading } = useCourseMap()
  const { contentId } = useParams()
  const [openModal, setOpenModal] = useState(false)
  const [currentDataModal, setCurrentDataModal] = useState({})
  // const zoneIdFromStore = useSelector(state => state.coursemap.zoneId)
  const { zone_id: zoneId } = useQuery()

  useEffect(() => {
    openCourseMapLessonDetail(contentId, zoneId)
  }, [])

  const onGoBack = () => {
    history.goBack()
  }

  const handleOpenModal = (item) => {
    setCurrentDataModal(item)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <CourseMapPage history={history}>
      <div className="lesson-detail__wrapper lesson-detail course-map__wrapper">
        <div className="course-map-detail_top-level">
          <div className="back-area" onClick={onGoBack}>
            <div className="back-area__arrow">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 14 4 9l5-5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20 20v-7a4 4 0 0 0-4-4H4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
            <a href="#">Back</a>
          </div>
          <div className="print__img-logo">
            <img src={LogoPrint} alt="" />
          </div>
          <div className="title-area">
            <h1 className="title">
              {isLoading && <Skeleton animation="wave" variant="text" />}
              {!isLoading && lessonInfo.level}
            </h1>
            <h2 className="level">
              <div>Theme: </div>
              <div className="value">
                {isLoading && <Skeleton animation="wave" variant="text" width={120} />}
                {!isLoading && lessonInfo.themeName}
              </div>
            </h2>
          </div>
          <div className="icon-print">
            <img src={IconsPrint} alt="" onClick={() => window.print()}  tabIndex={0} onKeyPress={(e) => {if(e.charCode == 13) window.print()}}/>
          </div>
        </div>
        <hr />
        {!isLoading && <div className="lesson-detail__content">

          <div className="lesson-detail__text">
            <p>
              {isLoading && <Skeleton animation="wave" variant="text" />}
              {!isLoading && lessonInfo.description}
            </p>
          </div>
          {lessonList?.length > 0 &&
            <div>
              <div className="lesson-detail__lesson-name">
                Lessons
                <span style={{ fontStyle: lessonInfo.productTypeNameInItalic ? "italic" : ""}}>
                  {lessonInfo.productTypeName}
                </span>
              </div>
              <div className="lesson-detail__lesson-grid">
                {lessonList.map((item, index) => {
                  return (
                    <div
                      className="lesson-detail__lesson-grid-item"
                      key={index}
                      tabIndex={0}
                      onKeyPress={(e) => {if(e.charCode == 13) handleOpenModal(item)}}
                      onClick={() => handleOpenModal(item)}
                      style={{"border-top": '8px solid'+lessonInfo.lessonBlockColorHex}}

                    >
                      <h2>{item.lessonName}</h2>
                      {item.aim &&
                        <div>
                          <div className="lesson-detail__lesson-grid-item--details">
                            <span style={{fontWeight: "bold"}}>Aim: </span>
                            {item.aim}
                          </div>
                        </div>
                      }
                      {item.focus && item.focus.length && item.focus.map((focus, i) => {
                        return (
                          <div className="lesson-detail__lesson-grid-item--details" key={i}>
                            <span style={{fontWeight: "bold"}}>{focus.subskillName}: </span>
                            {focus.subskillString}
                          </div>
                        )
                      })}
                      {item.task &&
                        <div>
                          <div className="lesson-detail__lesson-grid-item--details">
                            <span style={{fontWeight: "bold"}}>Task: </span>
                            {item.task}
                          </div>
                        </div>
                      }
                      
                    </div>
                  )
                })}
              </div>
            </div>
          }
          <Modal
            open={openModal}
            handleCloseDialog={handleCloseModal}
            useThemeContact={false}
            className="lesson-modal"
          >
            <div className="lesson-modal__item">
              <h2 className="title">
                {currentDataModal.lessonName}
              </h2>
              <div className="lesson-modal__content">
                <div
                  className="lesson-modal__content__wrapper"
                  style={{"border-top": '8px solid'+lessonInfo.lessonBlockColorHex}}
                >
                  {currentDataModal.aim &&
                    <div className="task">
                      <div className="item">
                        <span style={{fontWeight: "bold"}}>Aim: </span>
                        {currentDataModal.aim}
                      </div>
                    </div>
                  }
                  {currentDataModal.focus && currentDataModal.focus.length > 0 &&
                    <div className="focus">
                      <div className="item">
                        {currentDataModal.focus.map((item, index) => {
                          return (
                            <div key={index}>
                              <span style={{fontWeight: "bold"}}>{item.subskillName}: </span>
                              {item.subskillString}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  }
                  {currentDataModal.task &&
                    <div className="task">
                      <div className="item">
                        <span style={{fontWeight: "bold"}}>Task: </span>
                        {currentDataModal.task}
                      </div>
                    </div>
                  }
                </div>
              </div>
              <button className="btn btn-return btn-top btn-close" onClick={handleCloseModal}>Close</button>
            </div>
          </Modal>
        </div>}
      </div>
      <div id="print__page-footer"></div>
    </CourseMapPage>
  )
}

export default CourseMapLessonDetail