import { useState, useCallback, useEffect } from "react";
import { getQueryStringValue, setQueryStringValue } from "./queryString";

export function useQueryString(key, initialValue) {

  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

