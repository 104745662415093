import React, {Fragment} from 'react';
import '../../../../sass/private/StudentAccount/CreditHistory.scss';
import CircularProgress from '../../private/Common/CircularProgress';
import ReactHtmlParser from 'react-html-parser';

const CreditHistory = (props) => {
  const { data, onShowMore, isShowMore, onShowLess, isShowLess } = props;

  return (
    <Fragment>
      <table id="CreditHistory">
        <thead>
          <tr>
            <th>Transaction</th>
            <th>Date</th>
            <th>Difference</th>
            <th>Class</th>
            <th>Credits</th>
            <th>Balance</th>
          </tr>
        </thead>
        {data && data.map((item, index) => { 
          return (
            <tbody key={index}>
              <tr>
                <td>{item.transaction}</td>
                <td>{item.date}</td>
                <td>{item.difference}</td>
                <td id="hidden-class">{ReactHtmlParser(item.item)}
                  <p className="credit-detail">{
                  (item.classTime ?? "") +
                  (item.item ? ` - ${ReactHtmlParser(item.item)}` : '') +
                  (item.teacher ? ` - ${item.teacher}` : '')
                  }</p>
                </td>
                <td style={{ color: `${item.credit < 0 && 'var(--color-error)'}` }}>
                  <p className="number credit"> {item.credit} </p>
                </td>
                <td>
                  <p className="number balance"> {item.balance} </p>
                </td>
              </tr>
            </tbody>
          )
        })}
      </table>
      <div className="credit-footer">
        <div className="credit-footer__text">* Transaction carried out by our customer service staff</div>
        <div className="btn-block">
          {isShowLess && <button className="btn btn-showless" onClick={onShowLess}>Show less</button>}
          {isShowMore && <button className="btn btn-loadmore" onClick={onShowMore}>Show more</button>}
        </div>
      </div>
    </Fragment>
  )
}

export default CreditHistory;