import { memo, useEffect, useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import "../../../../sass/private/Module/ModuleListView.scss";

import ModuleListLoading from "./ModuleListLoading";
import ModuleViewItem from "./ModuleViewItem";
import useModule from "../../../hooks/HomePage/useModule";
import ModuleVideoPopup from "./ModuleVideoPopup";


const ModuleListView = (props) => {
  const { handleOpenModuleDialog, dataReloadTokenOthers } = props;
  
  const {
    isLoadingAvailable,
    isLoadingBooked,
    isLoadingMoreBooked,
    isLoadingMoreAvailable,
    moduleListAvailable,
    moduleListBooked,
    showLoadMoreButtonBooked,
    showLoadMoreButtonAvailable,
    totalBooked,
    totalAvailable,
    loadMore,
    BOOKED,
    AVAILABLE,
    reloadModuleData,

    isLoadCentre,
    onChangeOption,
    dataOptionAddress,
    centre
  } = useModule();
  
  useEffect(() => {
    if (dataReloadTokenOthers) {
      reloadModuleData();
    }
  }, [dataReloadTokenOthers]);
  const [openVideo, setOpenVideo] = useState(false);
  const [moduleID, setModuleID] = useState();
  const [videoModule, setVideoModule] = useState();
  const [videoLibraryLoaded, setVideoLibraryLoaded] = useState(false);
  const scriptRef = useRef(null);
  const handleOpenVideo = (id, theme_videos) => {
    setModuleID(id)
    setVideoModule(theme_videos);
    setOpenVideo(true);
  }
  const handleCloseVideo = () => {
    setVideoLibraryLoaded(false);
    setVideoModule([]);
    setOpenVideo(false);
  }
  const handleOpenModuleDetails = () => {
    setOpenVideo(false);
    setVideoModule([]);
    setVideoLibraryLoaded(false);
    handleOpenModuleDialog(moduleID);
  }
  useEffect(() => {
    if (videoModule?.length && !videoLibraryLoaded) {
        setVideoLibraryLoaded(true);

        const script = document.createElement("script");
        script.setAttribute("src", 'https://static.cdn-ec.viddler.com/js/arpeggio/v3/build/main-built.js');

        document.body.appendChild(script);
        scriptRef.current = script;
    }

    return (() => {
      if (scriptRef.current) {
        window.viddlerAutoEmbedsRun = false;

        scriptRef.current.remove();
      }
    });
  }, [videoModule]);
  return (
    <div className="calendar-view module-view">
      <div className="module-view__header">
        <h3 className="section-title">
          Modules
        </h3>
        <p className="section-desc">
          Explore in-demand skills with our 6-lesson modules. You’ll progressively build your ability with each lesson to achieve essential competencies in English. Plus, you’ll earn a digital credential that you can share for each completed module.
        </p>
      </div>
      <div className="module-lists">
        <>
          {isLoadingBooked ? (
            <div className="modules">
              <h3 className="block-title">
                <Skeleton
                  animation="wave"
                  variant="text"
                  className="skeleton-heading"
                />
              </h3>
              <div className="items">
                <ModuleListLoading></ModuleListLoading>
              </div>
            </div>
          ) : (
            <div className="modules">
              <h3 className="heading block-title">
                Your booked modules ({totalBooked ?? 0})
              </h3>
              {(moduleListBooked?.length === 0) ? (
                <p className="body-text message-not-found">You do not have any modules booked.</p>
              ) : ''}
              {moduleListBooked?.length > 0 && (
                <>
                  <div className="items">
                    {moduleListBooked.map((item, index) => (
                      <ModuleViewItem
                        key={index}
                        item={item}
                        handleOpenVideo={handleOpenVideo}
                        handleOpenModuleDialog={handleOpenModuleDialog}
                      />
                    ))}
                    {isLoadingMoreBooked && (
                      <ModuleListLoading/>
                    )}
                  </div>
                  {showLoadMoreButtonBooked && (
                    <div className="btn-show-more-container">
                      <>
                        {!isLoadingMoreBooked ? (
                          <Button
                            variant="contained"
                            onClick={() => loadMore(BOOKED)}
                            className="btn btn-show-more"
                          >
                            Load more
                          </Button>
                        ) : (
                          ""
                        )}
                      </>
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          <div className="modules">
            <div className="modules__heading">
              {!isLoadingAvailable ? 
                <h3 className="heading block-title">
                  Available ({totalAvailable ?? 0})
                </h3>
              : 
                <h3 className="block-title">
                  <Skeleton
                    animation="wave"
                    variant="text"
                    className="skeleton-heading"
                  />
                </h3>
              }
              <div className="modules__heading__right">
                Centre
                <select name="" id="" value={centre} disabled={isLoadingMoreAvailable || isLoadingAvailable || isLoadCentre} onChange={onChangeOption}>
                  {dataOptionAddress && dataOptionAddress.map(item => (
                    <option value={item.id} key={item.id} selected={item.id === centre}>{item.name}</option>
                  ))}
                </select>
              </div> 
            </div>
            {isLoadingAvailable && 
              <div className="items">
                <ModuleListLoading></ModuleListLoading>
              </div>
            }
            {!isLoadingAvailable && 
              <>
                {(moduleListAvailable?.length === 0) ? (
                  <p className="body-text message-not-found mb-0">There are no modules available at the moment.</p>
                ) : ''}
                {moduleListAvailable?.length > 0 && (
                  <>
                    <div className="items">
                      {moduleListAvailable.map((item, index) => (
                        <ModuleViewItem
                          key={index}
                          item={item}
                          handleOpenVideo={handleOpenVideo}
                          handleOpenModuleDialog={handleOpenModuleDialog}
                        />
                      ))}
                      {isLoadingMoreAvailable && (
                        <ModuleListLoading/>
                      )}
                    </div>
                    {showLoadMoreButtonAvailable && (
                      <div className="btn-show-more-container">
                        <>
                          {!isLoadingMoreAvailable ? (
                            <Button
                              variant="contained"
                              onClick={() => loadMore(AVAILABLE)}
                              className="btn btn-show-more"
                            >
                              Load more
                            </Button>
                          ) : (
                            ""
                          )}
                        </>
                      </div>
                    )}
                  </>
                )}
              </>
            }
          </div>
        </>
      </div>
      <ModuleVideoPopup open={openVideo} videoModule={videoModule} moduleID={moduleID} handleOpenModuleDetails={handleOpenModuleDetails} handleOpenVideo={handleOpenVideo} handleCloseVideo={handleCloseVideo} />
    </div>
  );
};

export default memo(ModuleListView);
