import { useSelector } from "react-redux";
import { getRoute } from '../../../components/Utilities';

import '../../../../sass/private/Footer/Footer.scss'
import iconApple from '../../../../assets/svg/applestore.svg'
import iconChplay from '../../../../assets/svg/chplay.svg'

export const Footer = () => {
  const currentTime = new Date()
  const currentYear = currentTime.getFullYear()

  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  return (
    <div className="footer">
      <div className="footer__top">
        <div>&copy; British Council {currentYear}</div>
        <div>The United Kingdom's international organisation for cultural relations and educational opportunities.</div>
        <div>A registered charity: 209131 (England and Wales) SC037733 (Scotland).</div>
        <div className="links">
          <a href="https://www.britishcouncil.org/terms" target="_blank">Terms</a>
          <a href="https://www.britishcouncil.org/privacy-cookies/data-protection" target="_blank">Privacy</a>
          {isLoggedIn && <a href="https://bcmyclass-material-public.s3.eu-west-1.amazonaws.com/Accessibility-statement_2022.pdf" target="_blank">Accessibility</a>}
          {window.isNativeAppAllowTracking !== false &&
            <a href={process.env.PUBLIC_URL + getRoute("COOKIESSETTINGS")} className="cookies-settings">Cookies Settings</a>
          }
          <a href={process.env.PUBLIC_URL + '/course-map'} className="course-map" target="_blank" >Course Map</a>
        </div>
      </div>

      {window.isNativeApp !== true &&
        <div className="footer__bottom">
          <div> Get the <b>My British Council English</b> app</div>
          <a href="https://englishinperson.britishcouncil.org/upload/app_redirect.htm" target="_blank"><img className="icon-apple" src={iconApple} alt="App Store"/></a>
          <a href="https://englishinperson.britishcouncil.org/upload/app_redirect.htm" target="_blank"><img className="icon-ch" src={iconChplay} alt="Google Play"/></a>
          {/* {isLoggedIn && <span className="help-links">
            <a target="_blank" rel="noopener noreferrer" href="https://bcmyclass-material-public.s3.eu-west-1.amazonaws.com/MyClass_UserGuide_Dec21.pdf">Getting started on MyClass</a>
            <a target="_blank" rel="noopener noreferrer" href="https://bcmyclass-material-public.s3.eu-west-1.amazonaws.com/MyClass_FAQs_Dec21.pdf">Frequently asked questions</a>
          </span>} */}
        </div>
      }
    </div>
  )
}

export default Footer