import * as Yup from 'yup';

export const validationSchema = Yup.object({}).shape({
  accountPassword: Yup
  .string()
  .required('Password required')
  .min(
    8, 
    "Passwords must be at least 8 characters in length, have at least one uppercase and one lowercase letter, and one number"
  )
  .matches(
    /(?!.*(?:<))(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/,
    "Passwords must be at least 8 characters in length, have at least one uppercase and one lowercase letter, and one number"
  ),
  accountRePassword: Yup
    .string()
    .required('Password required')
    .oneOf([Yup.ref('accountPassword'), null], ('Passwords are not the same.'))
});