import { useEffect, useState } from "react";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import BannerCreate from '../../../../assets/image/bnr-create.png';
import { setCentre, setProfileId, setGuid } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { route } from '../../../components/Config';
import profileCaptureApi from "../../../redux/services/profileCaptureApi";
import useQuery from "../../../../utils/useQuery";
import { CircularProgress } from "@material-ui/core";
import useProfileCapture from "../../../hooks/ProfileCapture/useProfileCapture";

const ValiteOnlinePage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { guid } = useQuery()
  const [currentCentreId, setCurrentCentreId] = useState();
  const [isLoading, setIsLoading] = useState(1);
  const {centreList, openProfileCapture} = useProfileCapture();
  const [message, setMessage] = useState();

  useEffect(() => {
    async function validationGUID() {
      setIsLoading(1);
      let response = await profileCaptureApi.validationGUID(guid);
      if(response.success) {
        let data = response.data;
        let centreId = data.id_centre;
        let profileId = data.profile_id;
        switch(data.status) {
          case 'Completion': case 'Deleted': case 'Archived': case 'Expiry':
            setMessage(data.message);
            break;
          case 'In progress':
            let prefixByCentre = centreList.find(centre => centre.id === centreId).prefix_api ?? '';
            if(prefixByCentre) {          
              localStorage.setItem('apiEndpointPrefixByCentre', prefixByCentre);
            }
            setCurrentCentreId(centreId);
            localStorage.setItem('current_centre', centreId);
            dispatch(setProfileId(profileId))
            dispatch(setGuid(guid))
            dispatch(setCentre(+centreId));  
          
            history.push(route['PROFILECAPTURE']);
            break;
          default:
            history.push(route['PROFILECAPTURE']);
            break;
        }
        setIsLoading(0);
      } else {
        setMessage('Something went wrong.');
        setIsLoading(0);
      }
    }

    if(centreList.length != 0) {
      validationGUID();          
    }
  }, [centreList])
  
  
  useEffect(() => {
    openProfileCapture();
  }, []);

  return (
    <ProfileCapturePage centreId={currentCentreId}>
      <div className="create-profile-inner create-profile-would">
        <div className="profile-capture-wrapper">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <h1 className="ttl-1">{message}</h1>
          )}
        </div>
        <div className="create-tell-inner">
          <div className="tell-us-right profile-capture-wrapper">
              <img className="img-create" src={BannerCreate} alt="" />
            </div>
            <div className="profile-capture-wrapper">
              <div className="tell-us-left"></div>
            </div>
        </div>
      </div>
    </ProfileCapturePage>
  )
}

export default ValiteOnlinePage;