import SvgIcon from '@material-ui/core/SvgIcon';

export default function Workplace(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" style={{fill: 'none'}} {...props}>
      <path d="M10 18.3337C14.6024 18.3337 18.3333 14.6027 18.3333 10.0003C18.3333 5.39795 14.6024 1.66699 10 1.66699C5.39763 1.66699 1.66667 5.39795 1.66667 10.0003C1.66667 14.6027 5.39763 18.3337 10 18.3337Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.56885 13.333L6.03076 6.89062H7.3623L8.3335 11.3159L9.51123 6.89062H11.0581L12.1875 11.3906L13.1763 6.89062H14.4858L12.9214 13.333H11.5415L10.2583 8.5166L8.97949 13.333H7.56885Z" fill="currentColor"/>
    </SvgIcon>
  );
}
