import React, { useMemo } from 'react';
import { withRouter } from 'react-router';
import ProfileWraper from '../Profile/ProfileWraper';
import ProfileItem from '../Profile/ProfileItem';
import SelectOption from '../AssessmentPage/SelectOption';
import ProductSummary from './ProductSummary';
import CreditHistory from './CreditHistory';
import CreditHistoryMobile from './CreditHistoryMobile';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import useStudentAccount from '../../../hooks/StudentAccount/useStudentAccount';
import useProfilePage from '../../../hooks/ProfilePage/useProfilePage';

import '../../../../sass/private/StudentAccount/StudentAccount.scss';

const StudentAccount = (props) => {
  const { history, productTypeId } = props;
  const { isTablet } = useCheckMobileTablet();
  const { dataAccount, user } = useProfilePage()
  const onClose = () => {
    isTablet ? history.goBack() : props.onClose()
  }

  const {
    productData,
    onChangeProduct,
    studentCreditData,
    creditHistories,
    onShowMore,
    isShowMore,
    productId,
    onShowLess,
    isShowLess
  } = useStudentAccount(productTypeId);
  
  const newOptionProductdata = productData.map(({prodcut_name: name, ...rest}) => ({
    name,
    ...rest,
  }));
  const dataOptionProduct = {
    options: newOptionProductdata,
    label: 'Product',
  }

  const contentAboutProduct = useMemo(() => {
    const userItem = [
      {key: 'Start date', value: studentCreditData.startDate},
      {key: 'Expiry date', value: studentCreditData.expiryDate}
    ]
    return userItem && userItem.map((item, index) => {
      return <ProfileItem
        key={index}
        name={item.key}
        value={item.value}
      />
    })
  }, [studentCreditData]);

  const contentCredit = useMemo(() => {
    const userItemFirst = [
      {key: 'Total credit', value: studentCreditData.creditPurchased},
      {key: 'Classes booked/attended', value: studentCreditData.classesAttended},
      {key: 'Classes  missed', value: studentCreditData.classesMissed},
      {key: 'Late cancellations', value: studentCreditData.lateCancellations},
      {key: 'Credit balance', value: studentCreditData.creditBalance, isStyleBg: true}
    ];
    const userItemSecond = studentCreditData && studentCreditData.creditExpirings && studentCreditData.creditExpirings.map(item => {
      return {key: item, value: '', isStyleKey: true};
    });
    let userItem;
    if (userItemFirst && userItemSecond) {
      userItem = [...userItemFirst, ...userItemSecond]
    } else {
      userItem = [];
    }
    return userItem && userItem.map((item, index) => {
      return <ProfileItem
        key={index}
        name={item.key}
        value={item.value}
        isStyleBg={item.isStyleBg || false}
        isStyleKey={item.isStyleKey || false}
      />
    })
  }, [studentCreditData]);

  const dataHistoryMobile = creditHistories.map(item => {
    return { item: [item.transaction, `${item.date}, ${item.classTime}`, item.difference, item.item], credit: item.credit, balance: item.balance }
  })

  return (
    <ProfileWraper 
      onClose={onClose} 
      title={'Account and credits'}
      showTitle="Account and credits"
      isShowBack 
      isHideBtnBottom
      className="setting-page setting-credits"
    >
      <div className="student-account">
        <div className="account-summary">
          <div className="--name">{user.name_en} {user.surname_en}</div>
          <table className="table-summary">
            <tr className="--trow">
              <td className="--tcol">Start date</td>
              <td className="--tcol">{dataAccount.startDate}</td>
            </tr>
            <tr className="--trow">
              <td className="--tcol">Expiry date</td>
              <td className="--tcol">{dataAccount.expiryDate}</td>
            </tr>
            {
              dataAccount.class_quota &&
              <tr className="--trow">
                <td className="--tcol">Paid class quota</td>
                <td className="--tcol">{dataAccount.class_quota}</td>
              </tr>
            }
            <tr className="--trow">
              <td className="--tcol">Free class quota</td>
              <td className="--tcol">{dataAccount.freeQuota}</td>
            </tr>
            {
              dataAccount?.expiringCredits?.map((item, index) => (
                <tr className="--trow" key={index}>
                  <td className="--tcol">{item.label}</td>
                  <td className="--tcol">
                    {
                      item?.credit_expiring?.map((credit) => (
                        <div key={credit} style={{color: "#AB0033"}}>{credit}</div>
                      ))
                    }
                  </td>
                </tr>
              ))
            }

           
          </table>
        </div>
        <div className="account-header">
          <div className="select-option-block">
            <SelectOption data={dataOptionProduct} onChangeOption={onChangeProduct} defaultValue={productTypeId} value={productId} />
          </div>
          <ProductSummary
            score={studentCreditData.score}
            name={studentCreditData.productName}
            shortName={studentCreditData.productShortName}
            progressBar1={studentCreditData.progressBar1}
            progressBar2={studentCreditData.progressBar2}
            progressBar3={studentCreditData.progressBar3}
          />
          <div className="account-main__row about-product__row">
            {contentAboutProduct}
          </div>
          <div className="account-main__row credit-summary__row">
            <h2 className="account-header__title">Credit use summary</h2>
            {contentCredit}
          </div>
          <div className="account-main__row credit-history__row">
            <h2 className="account-header__title">Credit history</h2>
            {isTablet ?
              <CreditHistoryMobile
                data={dataHistoryMobile}
                onShowMore={onShowMore}
                isShowMore={isShowMore}
                onShowLess={onShowLess}
                isShowLess={isShowLess}
              /> :
              <CreditHistory
                data={creditHistories}
                onShowMore={onShowMore}
                isShowMore={isShowMore}
                onShowLess={onShowLess}
                isShowLess={isShowLess}
              />
            }
          </div>
        </div>
      </div>
    </ProfileWraper>
  )
}

export default withRouter(StudentAccount);