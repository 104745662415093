import { ActionTypes } from '../actions/types';

const initialState = {
  data: {},
  updateInfo: {
    status: false,
    message: '',
  },
  dataUpdate: []
};

export default function communicationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.GET_COMMUNICATION_SUCCESS:
      return { ...state, data: payload.data }
    case ActionTypes.UPDATE_COMMUNICATION_SUCCESS:
      return { ...state, dataUpdate: payload.data, updateInfo: {status: true, message: 'Updates saved'} };
    case ActionTypes.UPDATE_COMMUNICATION_FAIL:
    case ActionTypes.GET_COMMUNICATION_FAIL:
      return { ...state, updateInfo: {status: false, message: payload} };
    default:
      return state;
  }
}