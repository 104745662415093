import SvgIcon from '@material-ui/core/SvgIcon';

export default function P(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" style={{fill: 'none'}} {...props}>
      <path d="M15.8333 17.5L9.99999 13.3333L4.16666 17.5V4.16667C4.16666 3.72464 4.34225 3.30072 4.65481 2.98816C4.96737 2.67559 5.3913 2.5 5.83332 2.5H14.1667C14.6087 2.5 15.0326 2.67559 15.3452 2.98816C15.6577 3.30072 15.8333 3.72464 15.8333 4.16667V17.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.24866 10.833V5.10645H10.1041C10.8073 5.10645 11.2656 5.13509 11.4791 5.19238C11.8073 5.27832 12.082 5.46582 12.3033 5.75488C12.5247 6.04134 12.6354 6.41243 12.6354 6.86816C12.6354 7.21973 12.5716 7.5153 12.444 7.75488C12.3164 7.99447 12.1536 8.18327 11.9557 8.32129C11.7604 8.45671 11.5612 8.54655 11.358 8.59082C11.082 8.64551 10.6823 8.67285 10.1588 8.67285H9.40491V10.833H8.24866ZM9.40491 6.0752V7.7002H10.0377C10.4934 7.7002 10.7981 7.67025 10.9518 7.61035C11.1054 7.55046 11.2252 7.45671 11.3112 7.3291C11.3997 7.2015 11.444 7.05306 11.444 6.88379C11.444 6.67546 11.3828 6.50358 11.2604 6.36816C11.138 6.23275 10.983 6.14811 10.7955 6.11426C10.6575 6.08822 10.3802 6.0752 9.9635 6.0752H9.40491Z" fill="currentColor"/>
    </SvgIcon>
  );
}
