import { useEffect, useState } from "react";
import CustomMenuTab from "../Common/CustomMenuTab";
import BookedClassesMobile from "./BookedClassesMobile";

import '../../../../sass/private/Home/HomeMobile.scss'
import useMyCourseDetails from "../../../hooks/MyCoursePage/useMyCourseDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getRoute } from "../../../components/Utilities";
import PracticeListViewMobile from "../Calendar/CalendarListView/PracticeListViewMobile";
import useQuery from "../../../../utils/useQuery";
import ProgressListViewMobile from "../Calendar/CalendarListView/ProgressListViewMobile";
import ModuleListViewMobile from "../Module/ModuleListViewMobile";
import { Skeleton } from "@mui/material";
const TABS = {
	BOOK: "Booked classes",
	MODULES: "Modules",
	PRACTICE: "Practice",
	PROGRESS: "Progress Check",
}
const tabs = Object.keys(TABS).map(tab => TABS[tab]);
const tabLabels = Object.keys(TABS).map(tab => TABS[tab]);
const tabCounts = Object.keys(TABS).map(tab => 0);

export const HomePageMobile = (props) => {
  const { landingData, isLoadingLandingData, reloadData, dataReloadTokenOthers } = props;
  const [activatedTab, setActivatedTab] = useState(TABS.BOOK);
  const history = useHistory();
  const {clickedTab} = useQuery();
  
  const {
    setCourseId,
    setCourseType
  } = useMyCourseDetails();
  
  useEffect(() => {
    setActivatedTab(clickedTab ?? TABS.BOOK);
  }, [clickedTab])
  
	const handleTabClick = (tab) => {
		setActivatedTab(tab);
    history.push(getRoute('HOME')+"?clickedTab="+tab);
	}
  
  const handleOpenPracticeModal = (itemId) => {
    setCourseId(itemId);
    setCourseType('lesson');
    history.push(getRoute('HOME'));
  };
  
  useEffect(() => {
    if(landingData) {     
      tabCounts[0] = landingData.bookedClassNumber ?? 0;
      tabCounts[1] = landingData.module_tab?.booked_module_number ?? 0;
      tabCounts[2] = landingData.practiceNumber ?? 0;
      tabCounts[3] = landingData.progressCheckNumber ?? 0;
    }
  }, [landingData])
  return (
    <div className="home-page-mobile">
      
      {isLoadingLandingData ? 
        <Skeleton 
          animation="wave" 
          variant="text" 
          style={{width: 'auto', height: '57px', transform: 'none', borderRadius: '0', margin: '4px 4px 10px', borderBottom: '5px solid var(--color-teal-blue)'}} 
        />:
        (
        <CustomMenuTab
            tabs={tabs}
            tabLabels={tabLabels}
            activatedTab={activatedTab}
            handleTabClick={handleTabClick}
            tabCounts={tabCounts}
            hideModuleTab={!landingData?.module_tab?.show_module_tab || false}
        />
        )}
      {
        activatedTab === TABS.BOOK && <BookedClassesMobile landingData={landingData} isLoadingLandingData={isLoadingLandingData} reloadData={reloadData} dataReloadTokenOthers={dataReloadTokenOthers} handleTabClick={handleTabClick} />
      }
      {
        activatedTab === TABS.MODULES && <ModuleListViewMobile reloadData={reloadData}/>
      }
      {
        activatedTab === TABS.PRACTICE && <PracticeListViewMobile handleOpenPracticeModal={handleOpenPracticeModal} />
      }
      {
        activatedTab === TABS.PROGRESS && <ProgressListViewMobile />
      }
    </div>
  )
}

export default HomePageMobile