import { memo, useEffect, useState } from 'react';
import ProfileWraper from '../Profile/ProfileWraper';
import modalAudioApi from '../../../redux/services/modalAudioApi';
import ReactHtmlParser from 'react-html-parser';
import AudioPlayer from '../Common/AudioPlayer';

import '../../../../sass/private/Booking/ModalAudioDesktop.scss';
import useMyCourseDetails from '../../../hooks/MyCoursePage/useMyCourseDetails';

const ModalAudioDesktop = (props) => {
  const { onCloseModalAudio, idAudio, nameAudio } = props;
  const [myData, setMyData] = useState({})
  const [srcAudio, setSrcAudio] = useState("")
  const [isShowTranscript, setShowTranscript] = useState(true) 
  const [playing, setPlaying] = useState(false)   
  const [audioIdActived, setAudioIdActived] = useState()   
  const {courseDetails} = useMyCourseDetails();
  const activitiesAudio = courseDetails?.post_class_activities?.audio;

  const onToggleTranscript = () => {
    setShowTranscript(!isShowTranscript)
  }

  const onClose = () => {
    setPlaying(false)
    onCloseModalAudio()
  }
  
  const handleChangeAudio = (audioId) => {
    audioId && modalAudioApi.getAudioDetails(audioId).then(data => {
      setPlaying(false)
      setMyData({
        title: "Transcript",
        desc: data.transcript,
      })
      setSrcAudio(data.audioLink)
      setAudioIdActived(audioId)
    })
  }

  useEffect(() => {
    idAudio && modalAudioApi.getAudioDetails(idAudio).then(data => {
      setMyData({
        title: "Transcript",
        desc: data.transcript,
      })
      setSrcAudio(data.audioLink)
      setAudioIdActived(idAudio)
    })
  },[idAudio])

  return (
    <ProfileWraper 
      onClose={onClose} 
      isHideBtnBottom 
      isShowClose
      showHeader="Listen again"
      className="modal-audio-desktop">
      <div className="audio-details-desktop">
        <div className="audio-details-desktop__top">
          <div className='audio-lists'>
            {activitiesAudio?.items?.map((item) => {
              return (
                <span onClick={() => handleChangeAudio(item.id)} className={`audio-title ${item.id == audioIdActived ? 'active' : ''}`}>{item.name}</span>                                            
              )
            })}
          </div>
          {
            srcAudio && <AudioPlayer src={srcAudio} playing={playing} onChangePlay={setPlaying}/>
          }
        </div>

        <div className="audio-details-desktop__bottom">
          <div className="header">
            <div className="transcript">Transcript</div>
            <div onClick={onToggleTranscript} className="btn-toggle">{isShowTranscript ? 'Hide' : 'Show'}</div>
          </div>

          {
            isShowTranscript &&
            <div className="content">
              {ReactHtmlParser(myData.desc)}
            </div>
          }
        </div>
      </div>
    </ProfileWraper>
  )
}

export default memo(ModalAudioDesktop);