import moment from 'moment';

/*eslint no-unused-expressions: 2*/
export class Profile {
  constructor(profile = {}) {
    this.Student_ID = profile.student_id || '';
    this.First_name = profile.name_en || '';
    this.Last_name = profile.surname_en || '';
    this.Preferred_name = profile.class_name || '';
    this.Home_centre = profile.centre_name || '';
    this.Email = profile.email || '';
    this.Contact_phone_number = profile.phone_mobile || profile.phone_home || profile.phone_work || '';
    this.Date_of_birth = (profile.dob && moment(profile.dob, 'YYYY-MM-DD').format("DD MMM YYYY")) || '';
  }
}