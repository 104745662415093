import { ActionTypes } from '../types';

export const bookingAction = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.ADD_BOOKING_SUCCESS,
        payload: data,
    });
}

export const cancelBookingAction = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.CANCEL_BOOKING_SUCCESS,
        payload: data,
    });
}

export const setOpenBookingWindow = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.OPEN_BOOKING_SUCCESS,
        payload: data,
    });
}

export const setBookedWindow = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.ADD_BOOKED_SUCCESS,
        payload: data,
    });
}

export const setMobileBookingActiveTab = (data) => (dispatch) => {
    dispatch({
        type: ActionTypes.MOBILE_BOOKING_SET_ACTIVE_TAB,
        payload: data,
    });
}