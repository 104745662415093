import { useMemo } from 'react';
import ArrowRight from '../../../components/icon/ArrowRight';

const MAX_CHARACTERS = 120;

const ItemTeacher = (props) => {
	const { item, showViewDetail = false, redirect, showFullDes = false } = props;

	const description = useMemo(() => `${item.description?.substring(0, MAX_CHARACTERS)}...`, [item.description]);
	let totalAvailableClasses;
	if (item.classes_available_count) {
		totalAvailableClasses = item.classes_available_count > 9 ? "9+" : item.classes_available_count;
	}
	let themeItem = '';
	switch (item.final_status) {
		case 'past':
			themeItem = 'lesson-theme-past';
			break;
		case 'full':
			themeItem = 'lesson-theme-full';
			break;
		case 'booked':
			themeItem = 'lesson-theme-booked';
			break;
	
		default:
			themeItem = '';
			break;
	}
	return  (
		<div className={`list-item list-item--custom lesson-theme ${themeItem}`} style={{cursor: showViewDetail? 'pointer': 'auto'}} onClick={(e) => { showViewDetail && redirect(item.id) }}>
			<div className="list-item__avatar">
				<img src={item.avatar} alt="Avatar" />
			</div>
			<div className="list-item__content">
				<p className="list-item__name secondary">{item.name} {item.surname}</p>
				{ showFullDes
				? <p className="list-item__description list-item__description--custom">{item.description}</p>
				:
				<p className="list-item__description list-item__description--custom">{description}</p>
				}
			</div>
			{totalAvailableClasses &&
				<span className="total-classes">
					{totalAvailableClasses}
				</span>
			}
			{showViewDetail &&
				<ArrowRight className="__view-detail" />
			}
		</div>
	)
}

export default ItemTeacher;