import { memo } from 'react';
import '../../../../sass/private/ProgressBar/ProgressBar.scss';

const ProgressBarPathway = (props) => {
  const { total, completed, width, height, inProgress, colorActive, colorDefault, colorProgress, showCompletedIcon = true, completedText = "" } = props;
  const showColorItem = (index) => {
    if (index < completed) {
      return colorActive;
    } else if (index < (completed + inProgress)) {
      return colorProgress;
    } else {
      return colorDefault;
    }
  }
  return (
    <div className="ProgressBarPathway">
      <div className="progressbar-list__wrapper">
        <div className="progressbar-list">
          {[...Array(total)].map((item, index) => {
            return <div
              key={index}
              className={`
                progressbar-item
                ${index < completed ? 'active' : ''}
                ${index === 0 ? 'first' : ''}
                ${index === total - 1 ? 'last' : ''}
                ${inProgress < completed ? 'inprogress' : ''}
              `}
              style={
                {
                  background: showColorItem(index),
                  width: width,
                  height: height
                }
              }
            >
            </div>
          })}
        </div>
          <div className="icon-block">
            {showCompletedIcon && <span className={`completed-icon icon ${completed < 1 ? "no-completed" : "completed"} `} />}
            {completedText === "" && `${completed} of ${total} module${total > 1? 's': ''} completed`}
            {completedText !== "" && completedText}
          </div>
          {(inProgress > 0) && <div className="icon-block">
            <span className="progress-icon icon" style={{'backgroundColor': colorProgress}}></span>
          {`${inProgress } module${total > 1? 's': ''} in progress`}
          </div>}
      </div>
    </div>
  )
}

export default memo(ProgressBarPathway);