export const ActionTypes = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGOUT: "LOGOUT",

  SET_AVATAR: "SET_AVATAR",

  SET_MESSAGE: "SET_MESSAGE",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",

  SEND_MAIL_RESET_PASSWORD_SUCCESS: "SEND_MAIL_RESET_PASSWORD_SUCCESS",
  SEND_MAIL_RESET_PASSWORD_FAIL: "SEND_MAIL_RESET_PASSWORD_FAIL",

  GET_ASSESSMENT_SUCCESS: "GET_ASSESSMENT_SUCCESS",
  GET_ASSESSMENT_FAIL: "GET_ASSESSMENT_FAIL",
  GET_SKILL_SUCCESS: "GET_SKILL_SUCCESS",
  GET_SKILL_FAIL: "GET_SKILL_FAIL",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_FAIL: "GET_PRODUCT_FAIL",

  ADD_OPTION_ASSESSMENT_SUCCESS: "ADD_OPTION_ASSESSMENT_SUCCESS",
  ADD_SKILL_ASSESSMENT_SUCCESS: "ADD_SKILL_ASSESSMENT_SUCCESS",

  GET_COMMUNICATION_SUCCESS: "GET_COMMUNICATION_SUCCESS",
  GET_COMMUNICATION_FAIL: "GET_COMMUNICATION_FAIL",

  UPDATE_COMMUNICATION_SUCCESS: "UPDATE_COMMUNICATION_SUCCESS",
  UPDATE_COMMUNICATION_FAIL: "UPDATE_COMMUNICATION_FAIL",

  SETTING_RESET_PASSWORD_SUCCESS: "SETTING_RESET_PASSWORD_SUCCESS",
  SETTING_RESET_PASSWORD_FAIL: "SETTING_RESET_PASSWORD_FAIL",

  ADD_BOOKING_SUCCESS: "ADD_BOOKING_SUCCESS",

  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",

  UPDATE_AVATAR_SUCCESS: "UPDATE_AVATAR_SUCCESS",
  UPDATE_AVATAR_FAIL: "UPDATE_AVATAR_FAIL",
  DELETE_AVATAR_SUCCESS: "DELETE_AVATAR_SUCCESS",
  DELETE_AVATAR_FAIL: "DELETE_AVATAR_FAIL",

  GET_PRODUCT_ACCOUNT_SUCCESS: "GET_PRODUCT_ACCOUNT_SUCCESS",
  GET_PRODUCT_ACCOUNT_FAIL: "GET_PRODUCT_ACCOUNT_FAIL",

  GET_ACCOUNT_CREDITS_SUCCESS: "GET_ACCOUNT_CREDITS_SUCCESS",
  GET_ACCOUNT_CREDITS_FAIL: "GET_ACCOUNT_CREDITS_FAIL",

  GET_LANDING_SUCCESS: "GET_LANDING_SUCCESS",
  GET_LANDING_FAIL: "GET_LANDING_FAIL",
  
  GET_BOOKING_CALENDAR_SUCCESS: "GET_BOOKING_CALENDAR_SUCCESS",
  GET_BOOKING_CALENDAR_FAIL: "GET_BOOKING_CALENDAR_FAIL",

  SET_GET_BOOKING_TIMESTAMP: "SET_GET_BOOKING_TIMESTAMP",
  SET_ACTIVE_DAY_OF_WEEK: "SET_ACTIVE_DAY_OF_WEEK",

  GET_FILTER_CALENDAR_SUCCESS: "GET_FILTER_CALENDAR_SUCCESS",
  GET_FILTER_CALENDAR_FAIL: "GET_FILTER_CALENDAR_FAIL",

  SELECT_DATE_OF_WEEK: "SELECT_DATE_OF_WEEK",

  GET_SCHEDULE_CALENDAR_SUCCESS: "GET_SCHEDULE_CALENDAR_SUCCESS",
  GET_SCHEDULE_CALENDAR_FAIL: "GET_SCHEDULE_CALENDAR_FAIL",

  GET_PASS_CLASS_CALENDAR_SUCCESS: "GET_PASS_CLASS_CALENDAR_SUCCESS",
  GET_PASS_CLASS_CALENDAR_FAIL: "GET_PASS_CLASS_CALENDAR_FAIL",

  RESET_PASS_CLASS: "RESET_PASS_CLASS",

  UPDATE_FILTER: "UPDATE_FILTER",
  RESET_NON_DESKTOP_FILTER: "RESET_NON_DESKTOP_FILTER",
  RESET_NON_MOBILE_FILTER: "RESET_NON_MOBILE_FILTER",

  ADD_BOOKING_WINDOW_START: "ADD_BOOKING_WINDOW_START",
  ADD_BOOKING_WINDOW_SUCCESS: "ADD_BOOKING_WINDOW_SUCCESS",
  ADD_BOOKING_WINDOW_FAIL: "ADD_BOOKING_WINDOW_FAIL",

  ADD_BOOKING_CLASS_START: "ADD_BOOKING_CLASS_START",
  ADD_BOOKING_CLASS_SUCCESS: "ADD_BOOKING_CLASS_SUCCESS",
  ADD_BOOKING_CLASS_FAIL: "ADD_BOOKING_CLASS_FAIL",

  GET_REFUND_CREDIT_SUCCESS: "GET_REFUND_CREDIT_SUCCESS",
  GET_REFUND_CREDIT_FAIL: "GET_REFUND_CREDIT_FAIL",

  CANCEL_CLASS_SUCCESS: "CANCEL_CLASS_SUCCESS",
  CANCEL_CLASS_FAIL: "CANCEL_CLASS_FAIL",

  GET_CONTACT_SUCCESS: "GET_CONTACT_SUCCESS",
  GET_CONTACT_FAIL: "GET_CONTACT_FAIL",

  GET_PROGRESS_SUCCESS: "GET_PROGRESS_SUCCESS",
  GET_PROGRESS_FAIL: "GET_PROGRESS_FAIL",

  GET_PRODUCT_PROGRESS_SUCCESS: "GET_PRODUCT_PROGRESS_SUCCESS",
  GET_PRODUCT_PROGRESS_FAIL: "GET_PRODUCT_PROGRESS_FAIL",

  GET_TEACHER_LIST_START: "GET_TEACHER_LIST_START",
  GET_TEACHER_LIST_SUCCESS: "GET_TEACHER_LIST_SUCCESS",
  GET_TEACHER_LIST_FAIL: "GET_TEACHER_LIST_FAIL",

  CANCEL_BOOKING_SUCCESS: "CANCEL_BOOKING_SUCCESS",
  OPEN_BOOKING_SUCCESS: "OPEN_BOOKING_SUCCESS",

  ADD_BOOKED_SUCCESS: "ADD_BOOKED_SUCCESS",

  GET_MYCLASS_FRIEND_SUCCESS: "GET_MYCLASS_FRIEND_SUCCESS",
  GET_MYCLASS_FRIEND_FAIL: "GET_MYCLASS_FRIEND_FAIL",

  ADD_INVITE_EMAIL_SUCCESS: "ADD_INVITE_EMAIL_SUCCESS",
  ADD_INVITE_EMAIL_FAIL: "ADD_INVITE_EMAIL_FAIL",
  HANDLE_INVITE_SUCCESS: "HANDLE_INVITE_SUCCESS",
  HANDLE_INVITE_FAIL: "HANDLE_INVITE_FAIL",

  GET_CREDITS_HISTORY_SUCCESS: "GET_CREDITS_HISTORY_SUCCESS",
  REDUCE_CREDITS_HISTORY: "REDUCE_CREDITS_HISTORY",
  CLEAR_CREDITS_HISTORY: "CLEAR_CREDITS_HISTORY",
  GET_CREDITS_HISTORY_FAIL: "GET_CREDITS_HISTORY_FAIL",

  GET_COURSE_SIDEBAR_SUCCESS: "GET_COURSE_SIDEBAR_SUCCESS",
  GET_COURSE_SIDEBAR_FAIL: "GET_COURSE_SIDEBAR_FAIL",

  GET_COURSE_CONTENT_SUCCESS: "GET_COURSE_CONTENT_SUCCESS",
  GET_COURSE_CONTENT_FAIL: "GET_COURSE_CONTENT_FAIL",

  GET_NEWS_SUCCESS: "GET_NEWS_SUCCESS",
  GET_NEWS_FAIL: "GET_NEWS_FAIL",

  GET_COURSE_DETAILS_SUCCESS: "GET_COURSE_DETAILS_SUCCESS",
  GET_COURSE_DETAILS_FAIL: "GET_COURSE_DETAILS_FAIL",

  GET_COURSE_LEVEL_SUCCESS: "GET_COURSE_LEVEL_SUCCESS",
  GET_COURSE_LEVEL_FAIL: "GET_COURSE_LEVEL_FAIL",

  MOBILE_BOOKING_SET_ACTIVE_TAB: "MOBILE_BOOKING_SET_ACTIVE_TAB",
  
  GET_PATHWAY_PROGRESS_SUCCESS: 'GET_PATHWAY_PROGRESS_SUCCESS',
  GET_PATHWAY_PROGRESS_FAIL: 'GET_PATHWAY_PROGRESS_FAIL',

  GET_PATHWAY_PERFORMANCE_SUCCESS: 'GET_PATHWAY_PERFORMANCE_SUCCESS',
  GET_PATHWAY_PERFORMANCE_FAIL: 'GET_PATHWAY_PERFORMANCE_FAIL',

  GET_PATHWAY_MILESTONES_SUCCESS: 'GET_PATHWAY_MILESTONES_SUCCESS',
  GET_PATHWAY_MILESTONES_FAIL: 'GET_PATHWAY_MILESTONES_FAIL',

  GET_PATHWAY_PROFICIENCY_SUCCESS: 'GET_PATHWAY_PROFICIENCY_SUCCESS',
  GET_PATHWAY_PROFICIENCY_FAIL: 'GET_PATHWAY_PROFICIENCY_FAIL',

  GET_PROGRESS_PACE_SUCCESS: 'GET_PROGRESS_PACE_SUCCESS',
  GET_PROGRESS_PACE_FAIL: 'GET_PROGRESS_PACE_FAIL',

  GET_ACHIEVEMENT_SUCCESS: 'GET_ACHIEVEMENT_SUCCESS',
  GET_ACHIEVEMENT_FAIL: 'GET_ACHIEVEMENT_FAIL',

  GET_HELP_SUCCESS: 'GET_HELP_SUCCESS',
  GET_HELP_FAIL: 'GET_HELP_FAIL',

  ADD_FAVORITE_SUCCESS: 'ADD_FAVORITE_SUCCESS',
  ADD_FAVORITE_FAIL: 'ADD_FAVORITE_FAIL',
  REMOVE_FAVORITE_SUCCESS: 'REMOVE_FAVORITE_SUCCESS',
  REMOVE_FAVORITE_FAIL: 'REMOVE_FAVORITE_FAIL',
  
  UPDATE_HAS_LISTEN_LESSON: 'UPDATE_HAS_LISTEN_LESSON',

  GET_NEWSFEED_SUCCESS: "GET_NEWSFEED_SUCCESS",
  GET_NEWSFEED_FAIL: "GET_NEWSFEED_FAIL",

  GET_NEWSFEED_LOGIN: "GET_NEWSFEED_LOGIN",
  UPDATE_STATUS_NEWSFEED: "UPDATE_STATUS_NEWSFEED",

  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAIL: "GET_NOTIFICATION_FAIL",
  RESET_NOTIFICATION: "RESET_NOTIFICATION",
  DISMISS_NOTIFICATION: "DISMISS_NOTIFICATION",

  VALIDATE_PASSWORD_REQUEST_SUCCESS: "VALIDATE_PASSWORD_REQUEST_SUCCESS",
  VALIDATE_PASSWORD_REQUEST_FAIL: "VALIDATE_PASSWORD_REQUEST_FAIL",

  ADD_QUESTION_SCREEN_ONE_FEEDBACK: "ADD_QUESTION_SCREEN_ONE_FEEDBACK",
  ADD_QUESTION_SCREEN_TWO_FEEDBACK: "ADD_QUESTION_SCREEN_TWO_FEEDBACK",
  ADD_QUESTION_SCREEN_TWO_ID: "ADD_QUESTION_SCREEN_TWO_ID",
  RESET_FEEDBACK: "RESET_FEEDBACK",
  FEEDBACK_SUCCESS: "FEEDBACK_SUCCESS",
  FEEDBACK_FAILED: "FEEDBACK_FAILED",
  SET_COURSEMAP_LEVEL: "SET_COURSEMAP_LEVEL",
  SET_COURSEMAP_ZONE_ID: "SET_COURSEMAP_ZONE_ID",
  SET_COURSEMAP_THEME_NAME: "SET_COURSEMAP_THEME_NAME",
  SET_COURSEMAP_MENU_NAME: "SET_COURSEMAP_MENU_NAME",

  SET_PROFILE_CAPTURE_CENTRE: 'SET_PROFILE_CAPTURE_CENTRE',
  SET_PROFILE_CAPTURE_STEP: "SET_PROFILE_CAPTURE_STEP",
  SET_PROFILE_CAPTURE_STEP_ORDER: "SET_PROFILE_CAPTURE_STEP_ORDER",
  SET_PROFILE_CAPTURE_PROFILE_ID: "SET_PROFILE_CAPTURE_PROFILE_ID",
  SET_PROFILE_CAPTURE_GUID: "SET_PROFILE_CAPTURE_GUID",
  SET_PROFILE_CAPTURE_MOTIVATIONS: "SET_PROFILE_CAPTURE_MOTIVATIONS",
  SET_PROFILE_CAPTURE_SECTIONS: "SET_PROFILE_CAPTURE_SECTIONS",
  SET_PROFILE_CAPTURE_COMPONENTS: "SET_PROFILE_CAPTURE_COMPONENTS",
  UPDATE_PROFILE_CAPTURE_SECTION: "UPDATE_PROFILE_CAPTURE_SECTION",
  SET_PROFILE_CAPTURE_FINAL_DATA: "SET_PROFILE_CAPTURE_FINAL_DATA",
  SET_PROFILE_CAPTURE_LAST_SECTION_TEXT: "SET_PROFILE_CAPTURE_LAST_SECTION_TEXT",
  RESET_PROFILE_CAPTURE: "RESET_PROFILE_CAPTURE",
  SET_STORE_LIST_EXAM_EDITED: "SET_STORE_LIST_EXAM_EDITED",
  SET_PROFILE_CAPTURE_FINAL_TEXT: "SET_PROFILE_CAPTURE_FINAL_TEXT",
  SET_LANGUAGE_ID: "SET_LANGUAGE_ID",
  SET_LANGUAGES: "SET_LANGUAGES",
  SET_LAST_STEP: "SET_LAST_STEP",
  SET_TRANSLATIONS: "SET_TRANSLATIONS",
}