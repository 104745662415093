import PrivatePage from '../../../components/PrivatePage';
import SelectOption from '../AssessmentPage/SelectOption';
import { getProductList } from '../../../redux/actions/StudentAccount/StudentAccountAction';
import { useEffect, useState } from 'react';
import { getCurrentUser } from '../../../../utils/currentUser';
import { useDispatch, useSelector } from 'react-redux';
import facebookIcon from '../../../../assets/svg/facebook-wc.svg'
import linkedinIcon from '../../../../assets/svg/linkedin-wc.svg'
import twitterIcon from '../../../../assets/svg/twitter-wc.svg'
import whatsappIcon from '../../../../assets/svg/whatsapp-wc.svg'
import awardIcon from '../../../../assets/svg/icon-award-indigo-pink.svg'
import badgeIcon from '../../../../assets/image/badge-start.png'
import badgeIconCompleted from '../../../../assets/image/badge-completed.png'
import badgeIconNotStart from '../../../../assets/image/badge-not-start.png'
import downloadCloud from '../../../../assets/svg/download-cloud.svg'
import { Button } from '@material-ui/core';
import ProfileWraper from '../Profile/ProfileWraper';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import Navbar from '../Settings/Navbar';
import CustomCircularProgress from '../Common/CircularProgress';
import { downloadAction, getAchievementsAction } from '../../../redux/actions/Achievement/AchievementAction';
import ReactHtmlParser from 'react-html-parser';
import { useSocket } from "../../../hooks/HomePage/useSocket";
import useAchievementsPage from '../../../hooks/AchievementsPage/useAchievementsPage';
const AchievementContent = (props) => {
  const { achievementsData, url, handleOpenProgressModal, isOpenProgressDetail } = props;
  // const history = useHistory();
  const [productId, setProductId] = useState(0);
  const [certificateData, setCertificateData] = useState([]);
  const [memberShipTypeLoading, setMemberShipTypeLoading] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const [productData, setProductData] = useState([]);
  const dispatch = useDispatch();
  const { isTablet } = useCheckMobileTablet();
  // const [menuActiveId, setMenuActiveId] = useState(1);
  
  // const { onRedirectPage, pathways } = props;
  // const [achievementsData, setAchievementsData] = useState([]);
  const [menuData, setMenuData] = useState([
    {
      name: 'In progress',
      active: true,
      id: 1,
    },
    {
      name: 'Completed',
      active: false,
      id: 2,
    }
  ]);

  const filterMenu = (menuData, id) => {
    return menuData.filter(item => {
      if(item.id === id) {
        item.active = true;
        // setMenuActiveId(id);
      } else {
        item.active = false;
      }
      
      return item;
    });
  }
  
  const onClickMenu = (id) => {
    const newMenu = [...menuData];
    const menuFilter = filterMenu(newMenu, id);
    setMenuData(menuFilter);
  }
  
  const newOptionProductdata = productData.map((item) => {
    return {name: item.membership_type_name, id: item.membership_type_id}
  });
  
  const dataOptionProduct = {
    options: newOptionProductdata,
    label: 'Product',
    textAll: 'All'
  }
  
  const onChangeProduct = (name, value) => {
    setProductId(value);
    setIsLoading(1);  
    getAchievementsAction(value).then(data => {
      if(value != 0 && data && !Array.isArray(data)) {
        data = [data];
      }
      setCertificateData(data)
      setIsLoading(0);
    })
  }
  
  const handleButtonDownload = (memberShipTypeId, memberShipTypeName) => {
    setMemberShipTypeLoading(memberShipTypeId);
    downloadAction(memberShipTypeId).then(response => {
      let url = response.url ?? '';
      if(url) {
        setMemberShipTypeLoading(0);
        window.open(url, isTablet ? "_top" : "_blank");
      }
    })
  }

  const handleClick = (url) => {
    window.open(url, "_blank");
  }
  const BadgesItem = (item) => {
    return (
      <>
         <div className='badge-lesson'>
            <div className='badge-information'>
              <p className='level'>{item.badge_level}</p>
              <p className='title'>{item.theme_name}</p>
              <p className='ttl-paway'>{item.badge_product_category_name}</p>
            </div>
            <div className='badge-logo'>
              <img src={item.badge_image} alt='Theme Badge' />
            </div>
          </div>
          <div className={`badge-download ${item.badge_progress_check_icon === 'locked' ?  "hidden-button" : ""} `}>
            <div className='badge-download-inner'>
              <p>{item.badge_lesson_attended}/{item.badge_lesson_requried} lessons</p>
              {item.badge_progress_check_icon === 'open' &&
                <div className="propress-check propress-check-unlock">Progress check</div>
              }
              {item.badge_progress_check_icon === 'completed' &&
                <div className="propress-check propress-check-comp">Progress check</div>
              }
              {item.badge_progress_check_icon === 'locked' &&
                <div className="propress-check propress-check-lock">Progress check</div>
              }
            </div>
            <div className='badge-total'>{item.grade}%</div> 
            {item.button_text == "Share" ?
              <>
                <Button variant='contained' className={`btn btn-download`}  onClick={()=> handleClick(item.button_url)} >{item.button_text}</Button>
                
              </>
              : (
                <>
                  <Button variant='contained' className={`btn btn-download`}   onClick={() => handleOpenProgressModal(item.button_url)} >{item.button_text}</Button>
                </>
              )
            }
          </div>
      </>
    );
  }

  const BadgesItems = (items, itemNotFound) => {
    return (
      <>
        {items?.themes?.length > 0 ? (
            items.themes?.map(
              (item, index) => {
                return (
                  <div className='badge' key={index}>
                      {BadgesItem(item)}
                  </div>
                );
              }
              )
          ) : (
            <>
              <p className='message-not-found'>{itemNotFound}</p>
            </>
          )}
      </>
    );
  }
  
  useEffect(() => {
    setIsLoading(1);  
    getAchievementsAction(productId).then(data => {
      setCertificateData(data)
      setIsLoading(0);
      if(productData.length == 0) {
        let productTypes = [];
        let dataProductData = [];
        data.map((item) => {
          if(!productTypes[item.membership_type_id]) {
            productTypes[item.membership_type_id] = item.membership_type_name;                        
            dataProductData.push({membership_type_name: item.membership_type_name, membership_type_id: item.membership_type_id});
          }
        })
        
        setProductData(dataProductData);
      }
    })
  }, [])
   //update socket
   const {
    getData
  } = useAchievementsPage();
  useEffect(() => {
    if (isOpenProgressDetail == false) {
      getData();
    }
  }, [isOpenProgressDetail]);
  
  useSocket({
    callBack: (payload) => {
      // reloadData()
      getData();
      alert(payload.message);
    }
  });
  return (
    <>
      <div className='achievement-page'>
        <div className='achievement-top'>
          <div className='heading'>
            <h2>Achievements</h2>
          </div>
          {/* <div className='filter'>
            <SelectOption data={dataOptionProduct} onChangeOption={onChangeProduct} value={productId} />
          </div> */}
          <div className="header-select-product">
              {dataOptionProduct.textAll?
                <button type='button' disabled={isLoading} className={productId === 0 ? 'active' : ''} onClick={() => onChangeProduct("", 0)}>{dataOptionProduct.textAll}</button>
              :''}
              {dataOptionProduct.options.map(item => {
                return <button type='button' disabled={isLoading} className={item.id === productId ? 'active' : ''} onClick={() => onChangeProduct("", item.id)} key={item.id}>{item.name}</button>
              })}
          </div>
        </div>
        <h3 className='ttl-certificates'>Certificates</h3>
        <div className='achievement-content'>
          {isLoading ? <CustomCircularProgress></CustomCircularProgress> : (
            certificateData.length == 0 || certificateData == false ? (
              <div className='message-not-found'>
                Not found certificate
              </div>
            ) : (
              certificateData.length > 0 && certificateData.map((item, index) => {
                return (                
                  <div className='achievements' key={index}>
                    <div className='achievement-information'>          
                      {!isTablet ? (       
                        <>                
                          <p className='sub-heading'>{item.certificate_explain}</p>
                          <p className='heading'>{item.certificate_title}</p>
                        </>         
                      ) : (  
                        <>          
                          <p className='heading'>{item.certificate_title}</p>
                          <p className='sub-heading'>{item.certificate_explain}</p>
                        </>         
                      )}
                      <p className='description'>{ReactHtmlParser(item.certificate_description)}</p>
                      <div className='btn-group'>
                        <Button 
                          variant="contained" 
                          className={`btn btn-download ${memberShipTypeLoading == item.membership_type_id ? 'btn-loading-circle' : ''}`}
                          disabled={memberShipTypeLoading == item.membership_type_id ? true : false}
                          onClick={() => handleButtonDownload(item.membership_type_id, item.membership_type_name)}
                        >
                          {memberShipTypeLoading == item.membership_type_id ? (
                            <CustomCircularProgress></CustomCircularProgress>
                          ) : (
                            <>
                              Download 
                              {/* {isTablet ? '.PDF' : ''} */}
                            </>
                          )}
                        </Button>
                        {/* <div className='socials'>
                          <img src={linkedinIcon} alt='linkedin' />
                          <img src={facebookIcon} alt='facebook' />
                          <img src={twitterIcon} alt='twitter' />
                          <img src={whatsappIcon} alt='whatsapp' />
                        </div> */}
                      </div>
                    </div>
                    <div className='achievement-logo'>
                      <img style={{"backgroundColor" : item.color}} src={awardIcon} alt='' />
                    </div>
                  </div> 
                )
              })               
            )
          )}
        </div>
        
        <div className='badges-top badges-inner'>
          <div className='heading'>
            <h2>Badges</h2>
            <div className='txt-badges'>
              Earn a British Council digital certificate and badge for each module you complete. Share your awards with prospective employers and institutions to boost your opportunities.<br/><br/>
              To claim your credentials:
              
              <ul className='txt-list'>
                <li>Attend all 6 module lessons</li>
                <li>Complete the module progress check - you will need to achieve a score of 70% or higher</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className='badges-content'>
          <Navbar menuLists={menuData} onClickMenu={onClickMenu}/>
          <div className='badges'>
            {menuData[0].active==true ? BadgesItems(achievementsData?.inProgress, "You have not started any module yet.") : BadgesItems(achievementsData?.completed, "You have not completed any modules yet.")}
          </div>
        </div>
      </div>
    </>
  )
}

export default AchievementContent;