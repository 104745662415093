import { ActionTypes } from '../../actions/types';

const initialState = {
  data: [],
  dataCentre: [],
  loading: false
}

export default function teacherListReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_TEACHER_LIST_START:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.GET_TEACHER_LIST_SUCCESS:
      return {
        ...state,
        data: (Array.isArray(payload.teacherList) && payload.teacherList) || [],
        dataCentre: (Array.isArray(payload.centreList) && payload.centreList) || [],
        loading: false
      }
    case ActionTypes.GET_TEACHER_LIST_FAIL:
      return {
        ...state,
        message: payload,
        loading: false
      }
    default:
      return state;
  }
}
