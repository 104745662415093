import { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { CalendarGlobalState } from '../../pages/private/Calendar/CalendarGlobalState';
import { getFiltersAction, bookingCalendarAction, updateFilterAction, setGetBookingTimestampAction } from '../../redux/actions/Calendar/CalendarAction';
import useCheckMobileTablet from '../../../utils/useCheckMobileTablet';

const weekListInitial = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const useCalendarWraper = (dataReloadToken, dataReloadTokenOthers, active = true) => {
  const [weekList, setWeekList] = useState(weekListInitial);
  const [isShowCalendarView, setIsShowCalendarView] = useState(false);
  const dataFilter = useSelector(state => state.calendar.filter);
  const [isPendingUpdate, setIsPendingUpdate] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(true);
  const [getDataTimeout, setGetDataTimeout] = useState(0);

  const globalContext = useContext(CalendarGlobalState);
  const idActiveWeekList = globalContext.state.idActiveWeekList;
  const dispatch = useDispatch();
  const filterData = useSelector(state => state.calendar.filterData);
  const { dateOfWeek } = dataFilter;
  const bookingCard = useSelector(state => state.calendar.bookingCard);
  const isShowClassBookedPage = useSelector(state => state.booking.isShowClassBookedPage);
  const mobileActiveTab = useSelector(state => state.booking.mobileBooking.activeTab);

  const [isActive, setIsActive] = useState(active);

  const { isTablet } = useCheckMobileTablet();

  useEffect(() => {
    globalContext.actions.setIsViewCalendar(isShowCalendarView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowCalendarView]);

  useEffect(() => {
    dispatch(getFiltersAction()).then((response) => {
      if (response?.success) {
        let { centre, filterReady } = dataFilter;

        if (filterReady) {
          return false;
        }

        const defaultCentre = (response.data?.centreList || []).find((centre) => centre?.default === 1);

        if (defaultCentre) {
          centre = defaultCentre.id;
        }
        else if (response.data?.centreList?.length) {
          centre = response.data.centreList[0].id;
        }

        centre = centre || 0;

        setDataFilter({
          centre: centre,
          filterReady: true,
        });
      }
    });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    clearTimeout(getDataTimeout);
    setIsPendingUpdate(true);

    setGetDataTimeout(setTimeout(() => {
      getBookingCalendar();
    }, dataFilter.timeout));

    // eslint-disable-next-line
  }, [isActive, dataFilter, dataReloadToken, mobileActiveTab]);

  useEffect(() => {
    if (dataReloadTokenOthers) {
      getBookingCalendar(false);
    }
  }, [dataReloadTokenOthers]);

  const getBookingCalendar = (showLoading = true) => {
    if (!isActive) {
      return false;
    }

    if (showLoading) {
      setIsPendingUpdate(false);
    }

    if (dateOfWeek.length) {
      const { filterReady } = dataFilter;

      if (filterReady) {
        if (showLoading) {
          setIsLoadingUpdate(true);
        }

        const { centre, productId, timeslot, teacherId, lessonTypeId, lessonTaken, friend, wishList, availability } = dataFilter;
        const dateFrom = moment(dateOfWeek[0]).format("YYYY-MM-DD");
        const dateTo = moment(dateOfWeek[dateOfWeek.length - 1]).format("YYYY-MM-DD");
        const timestamp = new Date().getTime();

        const json = {
          centre: centre,
          dateFrom: dateFrom,
          dateTo: dateTo,
          productId: productId,
          timeslot: timeslot,
          teacherId: teacherId,
          lessonTypeId: lessonTypeId,
          lessonTaken: lessonTaken,
          friend: friend,
          wishList: wishList,
          availability: availability,
          getDataFor: isTablet && mobileActiveTab,
          timestamp: timestamp,
        };

        dispatch(bookingCalendarAction(json)).then(() => {
          if (showLoading) {
            setIsLoadingUpdate(false);
          }
        });

        dispatch(setGetBookingTimestampAction(timestamp));
      }
    }
  };

  const onChangeOption = (name, id) => {
    if (name?.length > 0) {
      updateDataFilter(id, name);
    } else {
      return;
    }
  }

  function updateDataFilter(id, nameChange) {
    const newData = {...dataFilter};
    newData[nameChange] = id;
    setDataFilter(newData);
  }

  const setDataFilter = (json) => {
    dispatch(updateFilterAction(json));
  };

  const onChangeWeekList = (id) => {
    globalContext.actions.setIdActiveWeekList(id);
  }

  const onShowCalendarView = (value) => {
    if (isShowCalendarView === value) {
      return;
    }
    setIsShowCalendarView(value);
  }
  return {
    dataOptionAddress: filterData?.centreList,
    onChangeOption,
    bookAheadLimit: filterData?.bookAheadLimit,
    dataOptionTime: filterData?.timeList,
    dataLessonType: filterData?.lessonTypeList,
    dataTeacher: filterData?.teacherList,
    dataProductFilter: filterData?.productList?.map(({prodcut_name: name, ...rest}) => ({
      name,
      ...rest,
    })),
    weekList,
    idActive: idActiveWeekList,
    onChangeWeekList,
    onShowCalendarView,
    isShowCalendarView,
    dataFilter,
    bookingCard,
    dateRangeTo: filterData?.dateRangeTo,
    isPendingUpdate,
    isLoadingUpdate,
    setIsActive,
  }
}


export default useCalendarWraper;