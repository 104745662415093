import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import '../../../../sass/private/AccountSetting/Navbar.scss';

const Navbar = (props) => {
  const { menuLists, onClickMenu } = props;

  const content = menuLists.map((item, index) => {
    const classNav = item.default? (item.default ?'active':''):item.active? 'active' : '';
    if (item.hide) return '';
    return (
        <li
          className={classNav}
          key={index}
          onClick={() => onClickMenu(item.id)}
          tabIndex={0}
          onKeyPress={() => onClickMenu(item.id)}
        >
          {item.name}
          <span className="border-bottom"></span>
        </li>
    )
  });

  return (
    <ul className="navbar">
      {content}
    </ul>
  )
}

Navbar.propTypes = {
  menuLists: PropTypes.array,
}

Navbar.defaultProps = {
  menuLists: [],
}

export default Navbar;