import SvgIcon from '@material-ui/core/SvgIcon';

export default function U(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" style={{fill: 'none'}} {...props}>
      <path d="M15.8333 17.5L9.99999 13.3333L4.16666 17.5V4.16667C4.16666 3.72464 4.34225 3.30072 4.65481 2.98816C4.96737 2.67559 5.3913 2.5 5.83332 2.5H14.1667C14.6087 2.5 15.0326 2.67559 15.3452 2.98816C15.6577 3.30072 15.8333 3.72464 15.8333 4.16667V17.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.43359 5.10645H8.58984V8.20801C8.58984 8.7002 8.60417 9.01921 8.63281 9.16504C8.68229 9.39941 8.79948 9.58822 8.98438 9.73145C9.17188 9.87207 9.42708 9.94238 9.75 9.94238C10.0781 9.94238 10.3255 9.87598 10.4922 9.74316C10.6589 9.60775 10.7591 9.44238 10.793 9.24707C10.8268 9.05176 10.8438 8.72754 10.8438 8.27441V5.10645H12V8.11426C12 8.80176 11.9688 9.28743 11.9062 9.57129C11.8438 9.85514 11.7279 10.0947 11.5586 10.29C11.3919 10.4854 11.168 10.6416 10.8867 10.7588C10.6055 10.8734 10.2383 10.9307 9.78516 10.9307C9.23828 10.9307 8.82292 10.8682 8.53906 10.7432C8.25781 10.6156 8.03516 10.4515 7.87109 10.251C7.70703 10.0479 7.59896 9.83561 7.54688 9.61426C7.47135 9.28613 7.43359 8.80176 7.43359 8.16113V5.10645Z" fill="currentColor"/>
    </SvgIcon>
  );
}
