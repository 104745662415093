import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class PracticeApi {
  async getPracticeList() {
    try {
      let url = `practice-list`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
  
  async getPracticeListLoadMore(type, page) {
    try {
      let url = `practice-list?type=${type}&page=${page}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
  
  async submitHasListenPractice(id) {
    try {
      let url = `listen-audio?lesson_id=${id}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'post',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
}

export default new PracticeApi();