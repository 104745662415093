import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { getMyclassFriendAction, sendInvitation, handleInvitation } from '../../redux/actions/MyclassFriend/MyClassFriendAction';

const useMyFriend = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const listPendding = useSelector(state => state.myClassFriend.friendList?.friend_pending_requests);
  const listFriend = useSelector(state => state.myClassFriend.friendList?.friends);
  const listRequest = useSelector(state => state.myClassFriend.friendList?.friend_requests);

  useEffect(() => {
    dispatch(getMyclassFriendAction());
    // eslint-disable-next-line
  }, [])

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const onSendInvitation = () => {
    dispatch(sendInvitation(email)).then(response => {
      if(!response.success) {
        setErrMsg(response.message)
      } else {
        setErrMsg('')
        setEmail('')
        dispatch(getMyclassFriendAction())
      } 
    })
  }

  const onHandleInvitation = (friend, status) => {
    dispatch(handleInvitation(friend, status));
    if(status === 'unfriend') {
      dispatch(getMyclassFriendAction());
    }
  }

  return {
    email,
    errMsg,
    listPendding,
    listFriend,
    listRequest,
    handleChange,
    onSendInvitation,
    onHandleInvitation
  }
}

export default useMyFriend;