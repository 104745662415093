import { memo } from 'react';
import '../../../../sass/private/ProgressBar/ProgressBar.scss';

const BookingProgressBar = (props) => {
  const { total, completed, width, height, inProgress, colorActive, colorDefault, colorProgress, label, isHideLable } = props;
  const showColorItem = (index) => {
    if (index < completed) {
      return colorActive;
    } else if (index < (completed + inProgress)) {
      return colorProgress;
    } else {
      return colorDefault;
    }
  }
  return (
    <div className="ProgressBarPathway BookingProgressBarPathway">
      {!isHideLable && <div className="text-label">{label}</div>}
      <div className="progressbar-list__wrapper">
        <div className="progressbar-list">
          {[...Array(total)].map((item, index) => {
            return <div
              key={index}
              className={`
                progressbar-item
                ${index < completed ? 'active' : ''}
                ${index === 0 ? 'first' : ''}
                ${index === total - 1 ? 'last' : ''}
                ${inProgress < completed ? 'inprogress' : ''}
              `}
              style={
                {
                  background: showColorItem(index),
                  width: width,
                  height: height
                }
              }
            >
            </div>
          })}
        </div>
        <div className="text info">{completed}/{total}</div>
      </div>
    </div>
  )
}

export default memo(BookingProgressBar);