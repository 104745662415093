import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class resetPasswordApi {
  async resetPassword(token, formData) {
    try {
      let url = `auth/reset-password/${token}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        formData,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
        return Promise.reject(err)
    }
    
  }

  async validateResetPassword(email, token) {
    try {
      const data = {"email": email, "token": token};
      const response = await makeRequest(
          getAPIEndpoint() + 'auth/reset-password-validate',
          'post',
          data
      );
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new resetPasswordApi();