import { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { CalendarGlobalState } from '../../pages/private/Calendar/CalendarGlobalState';
import { setOpenBookingWindow } from '../../redux/actions/Booking/BookingAction';
import { MOBILE_BOOKING_TABS } from '../BookingPage/useMobileBookingPage';

const useCalendarView = () => {
  const [dataCalendarView, setDataCalendarView] = useState([]);
  const [dataCalendarViewAvailable, setDataCalendarViewAvailable] = useState([]);
  const [dataCalendarViewFull, setDataCalendarViewFull] = useState([]);
  const [isPastTime, setIsPastTime] = useState(0);
  const [title, setTitle] = useState('');
  const dispatch = useDispatch();

  const bookingCard = useSelector(state => state.calendar.bookingCard);
  const dateOfWeek = useSelector(state => state.calendar.filter.dateOfWeek);
  const bookingWindow = useSelector(state => state.bookingWindow);

  const globalContext = useContext(CalendarGlobalState);
  const idActiveWeekList = globalContext.state.idActiveWeekList;

  const activeTab = useSelector(state => state.booking.mobileBooking.activeTab);

  const history = useHistory();
  useEffect(() => {
    if (!dateOfWeek.length) {
      return false;
    }

    const dateFirst = moment(dateOfWeek[0]).format("Do MMMM");
    const dateLast = moment(dateOfWeek[dateOfWeek.length - 1]).format("Do MMMM");
    const titleClassList = `Week ${dateFirst} - ${dateLast}`;
    setTitle(titleClassList);
  }, [dateOfWeek]);

  useEffect(() => {
    setDataCalendarViewFull([]);
    setDataCalendarViewAvailable([]);
    const objKeys = Object.keys(bookingCard).sort();
    const bookingCardList = bookingCard[objKeys[idActiveWeekList]]?.class ?? [];
    
    bookingCardList.length > 0 && bookingCardList.map((item) => {
      if(typeof item.infoLines != 'undefined') {       
        if(item.infoLines.fullWarningIcon && item.isPast === false) {
          setDataCalendarViewFull(prevState => [...prevState, item]);
        } else {
          setDataCalendarViewAvailable(prevState => [...prevState, item]);
        } 
        
        if(item.isPastEndTime) {
          setIsPastTime(1);
        }
      }
    });

    setDataCalendarView(bookingCardList);
    if (!bookingCardList.length && activeTab !== MOBILE_BOOKING_TABS.TIME) {
      setDataCalendarView(bookingCard[objKeys[idActiveWeekList]]);
    }
    // eslint-disable-next-line
  }, [idActiveWeekList, bookingCard]);

  const onNextPrevWeekList = (value) => {
    if (value === 'next' && idActiveWeekList < 6) {
      globalContext.actions.setIdActiveWeekList(idActiveWeekList + 1);
    } else if (value === 'prev' && idActiveWeekList > 0) {
      globalContext.actions.setIdActiveWeekList(idActiveWeekList - 1);
    } else {
      return;
    }
  }

  const onRedirectBookingWidow = (id) => {
    history.push(`/home/${id}`);
    dispatch(setOpenBookingWindow(true));
  }

  return {
    dataCalendarView,
    dataCalendarViewAvailable,
    dataCalendarViewFull,
    title,
    onNextPrevWeekList,
    onRedirectBookingWidow,
    isPastTime,
  }
}

export default useCalendarView;