import { Skeleton } from "@mui/material";
import { memo, useEffect, useState } from 'react';

const PracticeListLoading = (props) => {
  
  return (
    <>
    <div
      className={`PassClassViewItem PracticeViewItem lesson-theme ${props.classes}`}
    >
      <div className="view-left">
        <div className="text"><Skeleton animation="wave" variant="text" className="skeleton-title" /></div>
        <div className="text"><Skeleton animation="wave" variant="text" className="skeleton-subtitle" /></div>
      </div>
      <div className="view-center">
      </div>
      <div className="view-right">
      </div>
    </div>
    
    {/* <div
      className={`PassClassViewItem PracticeViewItem lesson-theme ${props.classes}`}
    >
      <div className="view-left">
        <div className="text"><Skeleton animation="wave" variant="text" className="skeleton-title" /></div>
        <div className="text"><Skeleton animation="wave" variant="text" className="skeleton-subtitle" /></div>
      </div>
      <div className="view-center">
      </div>
      <div className="view-right">
      </div>
    </div>  */}
    </>
  )
};


export default memo(PracticeListLoading);