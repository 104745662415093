import React from 'react';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import { connect } from 'react-redux';
import * as calendarAction from '../../../redux/actions/Calendar/CalendarAction';
import { bindActionCreators } from 'redux';

import 'react-day-picker/lib/style.css';
import '../../../../sass/private/DatePicker/DatePicker.scss';
import arrowIcon from '../../../../assets/image/arrow.png';

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(
      moment(weekStart)
        .add(i, 'days')
        .toDate()
    );
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date)
      .startOf('isoWeek')
      .toDate(),
    to: moment(date)
      .endOf('isoWeek')
      .toDate(),
  };
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverRange: undefined,
      selectedDays: [],
      isShowDate: false
    }
  }

  componentDidMount() {
    let dateOfWeek;

    if (this.props.dateOfWeek.length) {
      dateOfWeek = this.props.dateOfWeek;
    }
    else {
      dateOfWeek = getWeekDays(getWeekRange(new Date()).from);
      this.props.actions.selectDateOfWeek(dateOfWeek);
    }

    this.setState({
      selectedDays: dateOfWeek,
    })
  }

  handleDayChange = (date) => {
    const dateList = new Date(); // Now
    dateList.setDate(dateList.getDate() + this.props.bookAheadLimit);

    const dateOfWeek = getWeekDays(getWeekRange(date).from);
    this.props.actions.selectDateOfWeek(dateOfWeek, 1500);
    this.setState({
      selectedDays: dateOfWeek,
      isShowDate: false
    });
  };

  handleDayEnter = date => {
    const dateList = new Date(); // Now
    dateList.setDate(dateList.getDate() + this.props.bookAheadLimit);

    this.setState({
      hoverRange: date < dateList ? getWeekRange(date) : undefined
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  handleWeekClick = (weekNumber, days, e) => {
    this.setState({
      selectedDays: days,
    });
  };

  onChangeWeek = (isPre = true) => {
    const dateCurrent = Array.isArray(this.state.selectedDays) && this.state.selectedDays.length > 0 ? this.state.selectedDays[0] : new Date()
    
    const startOfWeek = isPre ? moment(dateCurrent).subtract(1, 'weeks').startOf('isoWeek') :  moment(dateCurrent).add(1, 'weeks').startOf('isoWeek')
    const endOfWeek = isPre ? moment(dateCurrent).subtract(1, 'weeks').endOf('isoWeek') :  moment(dateCurrent).add(1, 'weeks').endOf('isoWeek')

    const dates = []
    let date = startOfWeek
    while(date <= endOfWeek) {
      dates.push(date.toDate())
      date = date.clone().add(1, 'day')
    }
    this.setState({
      selectedDays: dates
    });

    this.props.actions.selectDateOfWeek(dates, 1500);
  }

  render() {
    const { hoverRange, selectedDays, isShowDate } = this.state;

    const date = new Date(); // Now
    date.setDate(date.getDate() + this.props.bookAheadLimit);

    const daysAreSelected = selectedDays.length > 0;
    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const generateTempalte = () => {
      const { isMobile, disabled } = this.props;
      const date = new Date(); // Now
      date.setDate(date.getDate() + this.props.bookAheadLimit);
      date.setHours(0, 0, 0, 0)

      const prevWrapperStyle = disabled? { visibility: 'hidden' }: {};
      const nextWrapperStyle = (!disabled && selectedDays[6] < date)? {}: { visibility: 'hidden' };

      return(
        <div className={isMobile? "week-view__heading": "select-input"}>
          <span style={prevWrapperStyle}>
            <img
              src={arrowIcon}
              className="btn-previous"
              tabIndex={0}
              onClick={() => this.onChangeWeek(true)}
              onKeyPress={() => this.onChangeWeek(true)}
              alt="Previous week"
            />
          </span>
          <div className="select-date" style={isMobile? {margin: 'auto'}: {}}>
            {!isMobile && <span>Week: {' '}</span>}
          {selectedDays.length === 7 && (<React.Fragment>
              {moment(selectedDays[0]).format(isMobile? 'DD MMM': 'DD')} - {' '}
              {moment(selectedDays[6]).format(isMobile? 'DD MMM YYYY': 'DD MMMM YYYY')}
              </React.Fragment>)}
          </div>
          <span style={nextWrapperStyle}>
            <img
              src={arrowIcon}
              className="btn-next"
              tabIndex={0}
              onClick={() => this.onChangeWeek(false)}
              onKeyPress={() => this.onChangeWeek(false)}
              alt="Next week"
            />
          </span>
        </div>
      )
    }

    return (
      <div className="SelectedWeek">
        { generateTempalte() }
        <div className={`date-picker-modal ${isShowDate ? 'active' : ''}`}>
          <DayPicker
            selectedDays={selectedDays}
            showWeekNumbers
            showOutsideDays
            modifiers={modifiers}
            onDayClick={this.handleDayChange}
            onDayMouseEnter={this.handleDayEnter}
            onDayMouseLeave={this.handleDayLeave}
            onWeekClick={this.handleWeekClick}
            firstDayOfWeek={ 1 }
            disabledDays= {[{after: date}]}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, calendarAction), dispatch),
  };
}

const mapStateToProps = ({ calendar }) => {
  return {
    dateOfWeek: calendar.filter.dateOfWeek,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);