import {getAPIEndpoint, makeRequest} from '../../components/Utilities';
import { MOBILE_BOOKING_TABS } from '../../hooks/BookingPage/useMobileBookingPage';

class CalendarApi {
  async getBookingCalendar(json) {
    const { getDataFor, centre, dateFrom, dateTo, productId, timeslot, teacherId, lessonTypeId, lessonTaken, friend, wishList, availability, timestamp } = json;

    let url;

    if (getDataFor === MOBILE_BOOKING_TABS.TEACHER) {
      url = `teacher-list-mobile?centreId=${centre}&dateFrom=${dateFrom}&dateTo=${dateTo}&lessonTaken=${lessonTaken}&friend=${friend}&wishList=${wishList}&availability=${availability}&timestamp=${timestamp}`;
    }
    else if (getDataFor === MOBILE_BOOKING_TABS.CONTENT) {
      url = `booking-content?centre_id=${centre}&dateFrom=${dateFrom}&dateTo=${dateTo}&lessonTaken=${lessonTaken}&friend=${friend}&wishList=${wishList}&availability=${availability}&timestamp=${timestamp}`;
    }
    else {
      url = `booking-calendar?centreId=${centre}&dateFrom=${dateFrom}&dateTo=${dateTo}&productId=${productId}&timeslot=${timeslot}&teacherId=${teacherId}&lessonTypeId=${lessonTypeId}&lessonTaken=${lessonTaken}&friend=${friend}&wishList=${wishList}&availability=${availability}&timestamp=${timestamp}`;
    }

    try {
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getFilters() {
    try {
      const url = `filters`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getScheduleCalendar(id) {
    try {
      let url = `schedule?id=${id}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getPassClassesCalendar(page) {
    try {
      const url = `past-class?page=${page}&limit=3`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
}

export default new CalendarApi();