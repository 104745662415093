import { ActionTypes } from '../../actions/types';

const initialState = {
  level: '',
  zoneId: '',
  themeName: ''
}

export default function courseMapReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.SET_COURSEMAP_LEVEL:
      return {
        ...state,
        level: payload
      }
    case ActionTypes.SET_COURSEMAP_ZONE_ID:
      return {
        ...state,
        zoneId: payload
      }
    case ActionTypes.SET_COURSEMAP_THEME_NAME:
      return {
        ...state,
        themeName: payload
      }
    case ActionTypes.SET_COURSEMAP_MENU_NAME:
      return {
        ...state,
        menuName: payload
      }
    default:
      return state;
  }
}
