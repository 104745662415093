import React, { useState, useEffect, useRef, useReducer } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { Menu, MenuItem, Button, Popper } from '@material-ui/core';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';


import { getCurrentUser } from '../../../../utils/currentUser';
import { logout } from '../../../redux/actions/auth';
import { getRoute, getTarget } from '../../../components/Utilities';
import useQuery from '../../../../utils/useQuery';
import Contact from '../Contact/Contact';
import Modal from '../Dialog/Modal';

import ellipseIcon from '../../../../assets/image/T_indigo.png';
import vectorIcon from '../../../../assets/image/vector_indigo.png';
import vectorIconWhite from '../../../../assets/image/vector_white.png';

import '../../../../sass/private/UserMenu.scss';
import useNotification from '../../../hooks/NotificationPage/useNotificationPage';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import NotificationItem from '../HeaderMenu/NotificationItem';

import ProfilePage from '../Profile/ProfilePage';
import SettingPage from '../Settings/SettingPage';
import StudentAccount from '../StudentAccount/StudentAccount';
import notifiCation from '../../../../assets/image/bell.png';
import notifiCationClick from '../../../../assets/image/notication.png';
import CircularProgress from '../Common/CircularProgress';
import notificationApi from '../../../redux/services/notificationApi';

import FbQuestion1 from '../Feedback/FbQuestion1';
import FbQuestion2 from '../Feedback/FbQuestion2';
import Complete from '../Feedback/Complete';
import { resetFeedback } from '../../../redux/actions/Feedback/FeedbackAction';
import CloseConfirmation from '../Feedback/CloseConfirmation';
import { dismissItem } from '../../../redux/actions/Notification/NotificationAction';
import useRedirectFeedback from '../../../hooks/RedirectFeedbackHook/useRedirectFeedback';
import { useLocation } from 'react-router-dom'
const UserMenu = (props) => {
  const { logout, history } = props;
  const [fullname, setFullname] = useState('');
  const avatar = useSelector(state => state.auth.avatar);
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false)
  const [openSetting, setOpenSetting] = useState(false)
  const [openCredit, setopenCredit] = useState(false)
  const [openNoti, setOpenNoti] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const {notificationData, openNotification, resetAndRecall} = useNotification();
  const [showMenu, setShowMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNewNoti, setHasNewNoti] = useState(false);
  const isMounted = useRef(true);
  const dispatch = useDispatch();

  const userData = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const isShowWidget = userData.responseUserData?.is_show_widget || false;
  const urlWidget = userData.responseUserData?.widget_url;
  const { isTablet } = useCheckMobileTablet();
  const queryParam = useQuery();

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.fullname) {
      setFullname(currentUser.fullname);
    }

    if (queryParam.target === getTarget("MYFRIENDS")) {
      setOpenSetting(true);
    }
    else if (queryParam.target === getTarget("NOTIFICATIONLIST")) {
      onClickNotiIcon();
      if(typeof queryParam.open == "undefined") {
        history.replace(history.location.pathname);        
      }
    }
    
    return () => {
      isMounted.current = false;
    }
  }, []);

  useEffect(() => {
    const checkNewNoti = async () => {
      const newNoti = await notificationApi.checkRedDot();
      const hasNew = newNoti?.data?.is_red_dot || false;
      setHasNewNoti(hasNew);
    };
    checkNewNoti();
    // const interval = setInterval(checkNewNoti, 60000);
    // return () => clearInterval(interval);
  }, [])
  const onDismiss = (index, id) => {
    dispatch(dismissItem(index, id));
  }

  const handleClick = (event) => {
    setShowMenu(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setShowMenu(false);
  }

  const handleLogout = () => {
    logout().then(() => {
      if (isMounted.current) {
        handleClose();
        history.push(getRoute("ROOT"));
      }
    })
  };
  // const [isActive, setActive] = useState(false);

  const location = useLocation();
  const routerName = location.pathname;

  const redirectProfile = (link) => {
    history.push(getRoute(link));
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleCloseNotification = () => {
    setOpenNoti(false);
  }

  const onClickNotification = (currentPage, perPage) => {
    setIsLoading(true);
    openNotification(currentPage, perPage)
    setIsLoading(false);
  }

  const onClickNotiIcon = () => {
    setOpenNoti(!openNoti);
    if (!openNoti) {
      resetAndRecall();
      notificationApi.clearRedDot();
      setHasNewNoti(false);
    }
  }
  
  const handleKeyPressClickNotiIcon = (e) => {
    if(e.charCode == 13) {
      onClickNotiIcon();
    }
  }
  
  const handleGoAchievementsPage = () => {
    history.push(getRoute("ACHIEVEMENTSPAGE"));
  };
  

  return (
    <div className="UserMenu">
      { isTablet ?
        <>
          <div className="menu-online" onClick={handleClickOpen}>Find a centre</div>
          { isShowWidget && 
            <div 
              className="menu-online" 
              onClick={() => window.open(urlWidget, "_blank")}
            >
              Grammar practice
            </div>
          }
        </> :
        <>
          { isShowWidget && 
            <div 
              className="menu-online"
              tabIndex={0}
              onKeyPress={() => window.open(urlWidget, "_blank")}
              onClick={() => window.open(urlWidget, "_blank")}
            >
              Grammar practice
            </div>}
          <div className="menu-online" tabIndex={0} onClick={handleClickOpen} onKeyPress={handleClickOpen}>Find a centre</div>
          <div className="menu-online" tabIndex={0} onClick={(e) => redirectProfile('COMMUNITYPAGE')} onKeyPress={(e) => redirectProfile('COMMUNITYPAGE')}>Community</div>
          <div
            className={routerName == '/help' ? 'menu-help menu-online active': "menu-online"}
            tabIndex={0}
            onClick={(e) => redirectProfile("HELPPAGE")}
            onKeyPress={(e) =>redirectProfile("HELPPAGE")}
          >
            Help
          </div>
        </>
      }
      <div className="line-column"></div>

      <div className="notification-wrapper">
        <div 
          className={`notification ${openNoti ? 'active' : ''}`}
          tabIndex={0}
          onKeyPress={(e) => handleKeyPressClickNotiIcon(e)}
          >
          <span className="icon-notication" onClick={onClickNotiIcon}>
            <img className="notification notification-icon" src={notifiCation} alt="notifiCation" />
            <img className="notification click" src={notifiCationClick} alt="notifiCation" />
            {hasNewNoti && <span className='circle'></span>}
          </span>
          {openNoti && <ClickAwayListener onClickAway={handleCloseNotification}>
            <Popper
              open={openNoti}
              id="simple-notification"
              className="simple-notification"
            >
              <NotificationItem notificationData={notificationData} setOpenSetting={setOpenSetting} setActivePage={setActivePage} setOpenCredit={setopenCredit}/>
              {notificationData.is_more &&
                <div className="btn-show-more-container" style={{"paddingBottom": "100px", "justifyContent": "center", "display": "flex", "marginTop": "15px"}}>
                  <Button 
                    variant="contained" 
                    className="btn btn-show-more" 
                    onClick={() => 
                      onClickNotification(
                        (parseInt(notificationData?.current_page) + 1) || 1,
                        notificationData?.current_limit) || 10} 
                    disabled={isLoading}
                    >
                    {isLoading && <CircularProgress />}
                    {!isLoading && "Load More"}
                  </Button>
                </div>
              }
            </Popper>
          </ClickAwayListener>
          }
        </div>
      </div>
      
      <div className="menu-profile" onClick={handleClick}>
        <div className="menu-profile__iconleft">
          {
            avatar !== ""?
            <img 
              src={avatar} 
              alt="icon" 
              className="iconAvatar"
            />
            : 
            <div 
            className="iconText" 
            >
              {fullname.charAt(0).toUpperCase()}
            </div>

          }
          
        </div>
        { isTablet ? null : <Button aria-controls="simple-menu" aria-haspopup="true">
          {fullname}
        </Button>}
        <div className={`icon-arrow ${showMenu ? 'active' : ''}`}>
          <img src={vectorIcon} alt="icon arrow" />
        </div>
        <Menu
          id="simple-menu"
          keepMounted
          open={showMenu}
          onClose={handleClose}
        >
          <MenuItem
            onClick={handleClose}
            className="accout-setting menu-profile"
            tabIndex={0}
          >
            <div className="menu-profile__iconleft">
            {
              avatar !== ""?
              <img 
                src={avatar} 
                alt="icon" 
                className="iconAvatar"
              />
              : 
              <div 
                className="iconText" 
              >
                {fullname.charAt(0).toUpperCase()}
              </div>
            }
            </div>
            <Button aria-controls="simple-menu" tabIndex={-1} aria-haspopup="true" onClick={handleClose}>{fullname}</Button>
            <img className="icon-arrow" src={vectorIcon} alt="icon arrow"/>
          </MenuItem>
          <MenuItem
            onClick={() => setOpenProfile(true)}
            tabIndex={0}
            onKeyPress={() => setOpenProfile(true)}>
              Personal profile
          </MenuItem>
          <MenuItem
            onClick={() => setOpenSetting(true)}
            tabIndex={0}
            onKeyPress={() => setOpenSetting(true)}>
              Settings
          </MenuItem>
          <MenuItem
            onClick={() => setopenCredit(true)}
            tabIndex={0}
            onKeyPress={() => setopenCredit(true)}>
              Account and credits
          </MenuItem>
          <MenuItem
            onClick={() => handleGoAchievementsPage()}
            tabIndex={0}
            onKeyPress={() => handleGoAchievementsPage()}
          >
            Achievements
          </MenuItem>
          <MenuItem
            onClick={handleLogout}
            tabIndex={0}
            onKeyPress={handleLogout}>
            Log out
          </MenuItem>
        </Menu>
      </div>
      <Modal
        open={open}
        handleCloseDialog={handleCloseDialog}
        useThemeContact={false}
      >
        <Contact onClose={handleCloseDialog}/>
      </Modal>

      <Modal
        open={openProfile}
        handleCloseDialog={handleCloseDialog}
        useThemeContact={false}
      >
        <ProfilePage onClose={() => setOpenProfile(false)}/>
      </Modal>

      <Modal
        open={openSetting}
        handleCloseDialog={handleCloseDialog}
        useThemeContact={false}
      >
        <SettingPage onClose={() => setOpenSetting(false)} activePage={activePage}/>
       
      </Modal>

      <Modal
        open={openCredit}
        handleCloseDialog={handleCloseDialog}
        useThemeContact={false}
      >
        <StudentAccount onClose={() => setopenCredit(false)}/>
      </Modal>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout
    },
    dispatch
  );
}

export default withRouter(connect(null, mapDispatchToProps)(UserMenu));