import React, {useEffect, useState} from "react";

const useCheckMobileTablet = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    return ({isMobile: width <= 768, isTablet: width <= 1024, isTabletCustom: width < 1024});
}

export default useCheckMobileTablet;