import SvgIcon from '@material-ui/core/SvgIcon';

export default function I(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" style={{fill: 'none'}} {...props}>
      <path d="M15.8333 17.5L9.99999 13.3333L4.16666 17.5V4.16667C4.16666 3.72464 4.34225 3.30072 4.65481 2.98816C4.96737 2.67559 5.3913 2.5 5.83332 2.5H14.1667C14.6087 2.5 15.0326 2.67559 15.3452 2.98816C15.6577 3.30072 15.8333 3.72464 15.8333 4.16667V17.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.85022 10.833V5.10645H11.0065V10.833H9.85022Z" fill="currentColor"/>
    </SvgIcon>
  );
}
