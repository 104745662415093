import { ActionTypes } from '../actions/types';

const initialState = {
  infoReset: {}
};

export default function settingPasswordReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SETTING_RESET_PASSWORD_SUCCESS:
      return { ...state, infoReset: action.payload};
    case ActionTypes.SETTING_RESET_PASSWORD_FAIL:
      return { ...state, infoReset: {status: false, message: payload} };
    default:
      return state;
  }
}