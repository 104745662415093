import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { getRoute } from "../../../components/Utilities";
import ActiveMilestone from "../../../../assets/image/active-milestone.svg";
import InactiveMilestone from "../../../../assets/image/inactive-milestone.svg";
import Slider from "react-slick";
const Achievements = (props) => {
  const { isMobile, milestonesList } = props;
  const history = useHistory();

  const onRedirectPage = () => {
    history.push(getRoute("ACHIEVEMENTSPAGE"));
  };
  
  const MilestoneItem = (item) => {
    return (
      <div className="milestone-item">
        <div className="milestone-item__img">
          <img 
            src={item.completed === true ? ActiveMilestone : InactiveMilestone}
            alt={`${item.completed === true ? 'Active' : 'Inactive'} Milestone`}
          />
          <span className="milestone-item__number">{item.milestone}</span>
        </div>
        <h3 className={`milestone-item__title ${item.completed}`} dangerouslySetInnerHTML={{__html: item.text}}></h3>
      </div>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // slidesToShow: 2,
    rows: 2,
    slidesToScroll: 1,
    slidesPerRow: 2,
    arrows: false
  };
  return (
    <div className="block-content block-content-achievements">
      <h4 className="section-title">Achievements</h4>
      <div className="content-wrapper">
        <p className="block-title">Certificates of Attendance</p>
        <div className="box-content">
          With a Certificate of Attendance, you can demonstrate how many hours
          of English lessons you’ve attended, and at what level. Click below to
          view and download your certificates to share with your friends,
          college, or prospective employers.
        </div>
        <Button
          variant="contained"
          className="btn btn-default btn-progress-primary"
          onClick={onRedirectPage}
        >
          Details
        </Button>
      </div>
      <div className="content-wrapper milestone-content">
        <p className="block-title">Milestones</p>
        {!isMobile &&(
          <div className="row">
            {milestonesList.map((item, index) => {
              return (
                <div className="col" key={index}>
                  {MilestoneItem(item)}
                </div>
              );
            })}
          </div>
        )}
        {isMobile &&(
          <div className="slider-wrapper">

            <Slider 
              {...settings}
            >
              {milestonesList.map((item, index) => {
                return (
                  <div className="slide-item" key={index}>
                    {MilestoneItem(item)}
                  </div>
                )
              })}
            
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
};

export default Achievements;
