import PrivatePage from "../../../components/PrivatePage";
import '../../../../sass/private/CommunityPage/CommunityPage.scss';
import commmunityBanner from '../../../../assets/image/resolution-turkey.jpg';
import { useState } from "react";
import { useSelector } from 'react-redux';

const CommunityPage = (props) => {
  const [copySuccess, setCopySuccess] = useState(false);
  
  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };
  const auth = useSelector(state => state.auth) || "";



  
  return (
    <PrivatePage className="CommunityPage community-main" isShowMainNavigation={true}>
        <div className="communitypage-content">
          <div className="community-header">
            <div className="header-left">
              <h2 className="ttl-2">Join The English Club</h2>
            </div>
            {/* <div className="header-right">
              <a href="https://www.facebook.com/groups/myclassworld" className="btn btn-community" target="_blank">Join our community</a>
            </div> */}
          </div>
          <div className="community-banner">
            <img src={commmunityBanner} alt="image of community members" />
          </div>
          <div className="wraper-community-our">
            <p className="txt-join-out">Join our community to access online events and connect with British Council students all over the world.</p>
            <div className="how-community">
              <h3 className="ttl-3">How do I join our community?</h3>
              <div className="list-join community-join">
                <div className="inner-number-title">
                  <div className="nuber">1</div>
                  <div className="nuber-step">Step 1.</div>
                  <span className="txt-text">Click this button to copy the code</span>
                </div>
                <div className="join-right">
                  <button type="button" className={`btn btn-code ${copySuccess ? 'btn-code-active': ''}`} onClick={() =>  copyToClipBoard(auth.user.responseUserData.student_id)}>
                    {auth.user.responseUserData.student_id}
                  </button>
                  <span className="tooltips-code">
                    {copySuccess ? 'Code Copied' : 'Click to copy the code'}
                  </span>
                </div>
              </div>
              <div className="list-join community-join">
                <div className="inner-number-title">
                  <div className="nuber">2</div>
                  <div className="nuber-step">Step 2.</div>
                  <span className="txt-text">Click this button to go to the group</span>
                </div>
                <div className="header-right">
                  <a href="https://www.facebook.com/groups/myclassworld" className="btn btn-community" target="_blank">Join our community</a>
                </div>
              </div>
              <div className="list-join community-join">
                <div className="inner-number-title">
                  <div className="nuber">3</div>
                  <div className="nuber-step">Step 3.</div>
                  <span className="txt-text">Ask to join the group, then paste in the code</span>
                </div>
              </div>
              <div className="inner-dont-forget">
                <button className="btn-dont-forget btn">Don't forget to paste the code!</button>
              </div>
            </div>
          </div>
          <div className="inner-community-about">
            <div className="about-community">
              <h3 className="ttl-3">About the community</h3>
              <p className="txt-about">
                The community is your opportunity to join online events and connect with British Council students all over the world.<br/>
              </p>
              <p className="txt-about">You’ll find videos, articles, quizzes and practice activities to help you learn. You can also chat to other learners around the world and join in our regular live-streaming events.</p>
              <p className="txt-about">Our expert teachers will be there too, to share tips and answer all your language learning questions.</p>
            </div>
            <div className="list-community">
              <h3 className="ttl-3">How will it help me?</h3>
              <ul className="list-community-content">
                <li>Connect with students all over the world</li>
                <li>Improve your reading, writing, listening and speaking skills</li>
                <li>Practise your English for study and work</li>
                <li>Learn about life and culture in the UK</li>
                <li>Get daily language tips</li>
              </ul>
            </div>
          </div>
          {/* <div className="community-join">
            <div className="join-left">
              <h3 className="ttl-3">How do I join?</h3>
              <span className="txt-visit">Just visit the community page: <a href="https://www.facebook.com/groups/myclassworld" target="_blank">https://www.facebook.com/groups/myclassworld<span className="tooltips-link"><a href="https://www.facebook.com/groups/myclassworld" target="_blank">This link will open Facebook</a></span></a></span>
            </div>
            <div className="join-right">
              <button type="button" className={`btn btn-code ${copySuccess ? 'btn-code-active': ''}`} onClick={() =>  copyToClipBoard('MYCLASSWORLD22')}>
                <span className="sub-ttl">And enter this code:</span> MYCLASSWORLD22
              </button>
              <span className="tooltips-code">
                {copySuccess ? 'Copied' : 'Click to copy'}
              </span>
              
            </div>
          </div> */}
        </div>
    </PrivatePage>
  )
}

export default CommunityPage;