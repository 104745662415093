import '../../../../sass/private/ProgressBar/ProgressBarV2.scss';
import KeyIcons from '../../../../assets/image/key-lesson.png';
const ProgressBar = (props) => {
  const { label, total, count, isHideInfo, styles, isHideLable, customInfo, lock, score, isLockedAll, canAccess } = props;
  return (
    <div className="ProgressBar">
      <div className="progressbar-list__title-wrapper">
        {!isHideLable && <div className="text">{label}</div>}
        {/* {!canAccess ? (
          <img src={KeyIcons} alt="icon" />
        ) : ''} */}
        <div className="product-name">Your current progress with this module</div>
        <div className="progress-wrapper">
          {score > 0 && <div className="circle">{score}</div>}
        </div>
      </div>
      <div className="progressbar-list__wrapper">
        <div className="progressbar-list">
          {[...Array(total)].map((item, index) => {
            return (
              <div
                key={index}
                className={`progressbar-item${index < count ? ' active' : ''}${index === 0 ? ' first' : ''}${index === total - 1 ? ' last' : ''}${index > (total - 1 - lock) ? ' lock' : ''}${isLockedAll ? ' lock': ''} ${!canAccess ? 'no-access' : ''}`}
                style={styles}
              >
              </div>
            )
          })}
      </div>
        {!isHideInfo &&
          <div className={`lesson-text info ${(count === 0 && lock < total) ? 'not-started' : ''} ${!canAccess ? 'no-access' : ''}`}>
            { !canAccess ? "No access" : <>
                  {customInfo? customInfo: <>{count}/{total} lessons <span className='ttl-completed'>completed</span></>}
                  {(count === 0 && lock < total) && <div className="not-started">(not started)</div>}
                </>
            }
          </div>
        }
      </div>
    </div>
  )
}

export default ProgressBar;