import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class ContactListApi {
  async getContactList() {
    try {
      let url = `contact-list`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
}

export default new ContactListApi();