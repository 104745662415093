import { Fragment } from 'react';

import useCalendarWraper from '../../../hooks/CalendarPage/useCalendarWraper';
import SortSearch from './SortSearch';
import CalendarListView from './CalendarListView/CalendarListView';
import CalendarCard from './CalendarCard';
import AdvancedSelects from '../Common/Select';
import CircularProgress from '../Common/CircularProgress';

import '../../../../sass/private/CalendarPage/Calendar.scss';
import '../../../../sass/private/CalendarPage/SortSearch.scss';

const CalendarWraper = (props) => {
  const { isViewPage, isShowFilter, title, isPastTime, dataCalendarView, dataCalendarViewFull, dataCalendarViewAvailable, onNextPrevWeekList, onRedirectBookingWidow, dataReloadToken, dataReloadTokenOthers } = props;
  const {
    dataOptionAddress,
    onChangeOption,
    dataOptionTime,
    weekList,
    idActive,
    onChangeWeekList,
    onShowCalendarView,
    dataFilter,
    dataLessonType,
    dataTeacher,
    dataProductFilter,
    bookAheadLimit,
    dateRangeTo,
    isPendingUpdate,
    isLoadingUpdate,
  } = useCalendarWraper(dataReloadToken, dataReloadTokenOthers);

  const isUpdating = isPendingUpdate || isLoadingUpdate;

  return (
    <Fragment>
      <div className="header">
        <div className="address">
          <div className="calendar-text">Centre</div>
          <AdvancedSelects disabled={isUpdating} data={dataOptionAddress} label="" value={dataFilter.centre} onChangeOption={onChangeOption} nameSelect="centre"/>
        </div>
        <div className="time">
          <div className="calendar-text">Start time</div>
          <AdvancedSelects disabled={isUpdating} data={dataOptionTime} label="" value={dataFilter.timeslot} onChangeOption={onChangeOption} nameSelect="timeslot" />
        </div>
        <div className="lesson-type">
          <div className="calendar-text">Lesson type</div>
          <AdvancedSelects disabled={isUpdating} data={dataLessonType} label="" value={dataFilter.lessonTypeId} onChangeOption={onChangeOption} nameSelect="lessonTypeId" />
        </div>
      </div>
      <SortSearch
        isViewPage={isViewPage}
        isShowFilter={isShowFilter}
        weekList={weekList}
        idActive={idActive}
        onChangeWeekList={onChangeWeekList}
        onShowCalendarView={onShowCalendarView}
        dataTeacher={dataTeacher}
        dataLessonType={dataLessonType}
        dataProductFilter={dataProductFilter}
        onChangeOption={onChangeOption}
        bookAheadLimit={bookAheadLimit}
        isPendingUpdate={isPendingUpdate}
        isLoadingUpdate={isLoadingUpdate}
      />
      {isUpdating && <CircularProgress />}
      {!isUpdating &&
        <>
          {isViewPage?
            <CalendarListView
              title={title}
              data={dataCalendarView}
              dataCalendarViewFull={dataCalendarViewFull}
              dataCalendarViewAvailable={dataCalendarViewAvailable}
              onNextPrevWeekList={onNextPrevWeekList}
              onRedirectBookingWidow={onRedirectBookingWidow}
              isPastTime={isPastTime}
              showNoResultsMsg
            /> :
            <CalendarCard
              bookAheadLimit={bookAheadLimit}
              dateRangeTo={dateRangeTo}
              onRedirectBookingWidow={onRedirectBookingWidow}
            />
          }
        </>
      }
    </Fragment>
  )
}

export default CalendarWraper;