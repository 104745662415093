import { Button } from '@material-ui/core';
import { memo } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getColorBorlineByScore } from '../../../../../utils/helper';
import Volumn from '../../../../components/icon/Volume'
import ReactHtmlParser from 'react-html-parser';


const PracticeViewItemMobile = (props) => {
  const history = useHistory();
  const { subTitle, date, numberDidAsssessment, totalAssessment, score, id, lessonType, practiceType} = props;
  
  const onClickLesson = (id, lessonType) => {
    history.push(`/mycourse/${id}?historyGo=-1&lessonType=${lessonType}&levelId=1`)        
  }
  
  return (
    <div 
      className="item-practice lesson-theme"
      onClick={() => onClickLesson(id, lessonType)}
    >
      <div className="left-practice">
        <p className='heading'>{ReactHtmlParser(subTitle)} {practiceType == 'audio' ? <Volumn></Volumn> : ''}</p>
        <p className='date'>{date}</p>
      </div>
      <div className="right-practice icon-block secondary">        
        <div className='number-progress'>{numberDidAsssessment >= 0 && totalAssessment > 0 ? `${numberDidAsssessment}/${totalAssessment}` : ''}</div>
        {practiceType != 'audio' && 
          <div className='circle-score-practice' style={{"border": `3px solid ${getColorBorlineByScore(score)}`}}>{score == 0 ? '' : score}</div>
        }
      </div>
    </div>
  )
}

export default memo(PracticeViewItemMobile);