import '../../../../sass/private/FbQuestion/FbQuestion.scss';

const Complete = (props) => {
    const onClose = () => {
      props.onClose();
    };
    const onContinue = () => {
      props.onContinue();
    }

    return (
        <div className="feedback-wrapper">
            <div className="feedback">
                <main className="main">
                <div className="main-content">
                    <div className="main-desr">
                        <div className="confirm-message">Are you sure you want to leave?</div>
                    </div>
                    <div className="inner-confirm">
                        <div className="confirm">
                            <button className="btn-close" onClick={onClose}>Yes, I want to leave</button>
                            <span className="txt-feedback">My feedback won't be recorded</span>
                        </div>
                        <div className="confirm continue">
                            <button className="btn-close" onClick={onContinue}>Let me continue</button>
                            <span className="txt-feedback">I want to submit feedback</span>
                        </div>
                    </div>
                </div>
                </main>
            </div>
        </div>
    )
}

export default Complete;
