import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTeacherAction } from '../../redux/actions/Teacher/TeacherAction';

const useTeacherPage = (dataReloadToken, dataReloadTokenOthers, active = true, teacherId, dateFrom, dateTo) => {
  const teacherListFromStore = useSelector(state => state.teacherList.data);
  const dataCentre =  useSelector(state => state.teacherList.dataCentre);
  const [isLoading, setIsLoading] = useState(true);
  const isBookedStatus = useSelector(state => state.booking.isBookedStatus)
  const dataFilter = useSelector(state => state.calendar.filter);
  const centreFiltered = dataFilter.centre;
  const dispatch = useDispatch();
  const [statusOnOff, setStatusOnOff] = useState(0);
  const [teacherList, setTeacherList] = useState([]);
  const [allTeacherList, setAllTeacherList] = useState([]);
  const [centreSelected, setCentreSelected] = useState(centreFiltered);
  const [teacherActive, setTeacherActive] = useState(0);
  const [teacherCourseId, setTeacherCourseId] = useState(0);
  const [courseLessonTimeId, setCourseLessonTimeId] = useState(0);

  const [isActive, setIsActive] = useState(active);

  useEffect(() => {
    getTeacher();
  },[dispatch, isActive, centreSelected, statusOnOff, dataReloadToken]);

  useEffect(() => {
    if (dataReloadTokenOthers) { // only if variable exists and > 0
      getTeacher(false);
    }
  }, [dataReloadTokenOthers]);

  useEffect(() => {
    if (teacherListFromStore?.length > 0) {
      const dataCustom = customTeacherList(teacherListFromStore);
      setTeacherList(dataCustom);
      setAllTeacherList(teacherListFromStore);
    } else {
      setTeacherList(teacherListFromStore);
      setAllTeacherList(teacherListFromStore);
    }
  }, [teacherListFromStore]);

  useEffect(() => {
    if (isBookedStatus) {
      const newArr = [...teacherList];
      newArr.filter(teachers => {
        if (teachers.id === teacherCourseId) {
          teachers.future_lessons?.filter(lesson => {
            if (lesson.lessonTimeId === courseLessonTimeId && lesson.booked === false) {
              lesson.booked = true;
              return lesson;
            } else {
              return lesson;
            }
          })
        }
        return teachers;
      })
      setTeacherList(newArr);
    }
    // eslint-disable-next-line
  }, [isBookedStatus])

  const getTeacher = (showLoading = true) => {
    if (isActive) {
      if (showLoading) {
        setIsLoading(true);
      }
      const { lessonTaken, friend, wishList, availability } = dataFilter;
      const json = {
        centreId: centreSelected,
        status: statusOnOff,
        teacherId: teacherId,
        dateFrom: dateFrom,
        dateTo: dateTo,
        lessonTaken: lessonTaken,
        friend: friend,
        wishList: wishList,
        availability: availability
      }
      dispatch(getTeacherAction(json)).then((response) => {
        if (response?.success) {
          if (showLoading) {
            setIsLoading(false);
          }
        }
      });
    }
  };

  function customTeacherList(data) {
    const newArr = JSON.parse(JSON.stringify(data));
    return newArr.map(item => {
      teacherList.forEach((teacher) => {
        if (teacher.id == item.id) {
          item.is_collapsed = teacher.is_collapsed ?? false;
        }
      });
      if (item.future_lessons?.length > 2 && !item.is_collapsed) {
        const newFutureLessons = JSON.parse(JSON.stringify(item.future_lessons));
        let newFutureLessonsSplice = newFutureLessons.splice(0, 2);
        let dataFindId = data.find(x => x.id === item.id);
        item.future_lessons = newFutureLessonsSplice;
        item.count = 2;
        item.countTotal = dataFindId?.future_lessons?.length;
        item.is_collapsed = false;
        return item;
      } else {
        return item;
      }
    });
  }

  const onChangeToogle = () => {
    let status = statusOnOff === 0 ? 1 : 0;
    setStatusOnOff(status);
  }

  const onChangeOption = (name, id) => {
    setCentreSelected(id);
  }

  const onShowMoreLesson = (id) => {
    const newArr = [...teacherList];
    newArr.map(item => {
      if (item.id === id) {
        const arrFromId = allTeacherList.find(x => x.id === id);
        const newFutureLessons = [...arrFromId.future_lessons];
        item.future_lessons = newFutureLessons;
        item.count = newFutureLessons.length;
        item.countTotal = newFutureLessons.length;
        item.is_collapsed = true;
        return item;
      } else {
        return item;
      }
    });
    setTeacherList(newArr);
  }

  const onShowLessLesson = (id) => {
    const newArr = [...teacherList];
    newArr.map(item => {
      if (item.id === id) {
        const arrFromId = allTeacherList.find(x => x.id === id);
        const newFutureLessons = (JSON.parse(JSON.stringify(arrFromId.future_lessons))).splice(0, 2);
        item.future_lessons = newFutureLessons;
        item.count = newFutureLessons.length;
        item.countTotal = arrFromId?.future_lessons?.length;
        item.is_collapsed = false;
        return item;
      } else {
        return item
      }
    })
    setTeacherList(newArr);
  }

  const onActiveTeacher = (idActive) => {
    if (teacherActive !== idActive) {
      setTeacherActive(idActive);
    } else {
      setTeacherActive(0);
    }
  }

  const getParentIdBooking = (teacherId, lessonTimeId) => {
    setTeacherCourseId(teacherId);
    setCourseLessonTimeId(lessonTimeId);
  }

  return {
    statusOnOff,
    teacherList,
    dataCentre,
    isLoading,
    teacherActive,
    idSelected: centreSelected,
    allTeacherList,
    onChangeToogle,
    onChangeOption,
    onShowMoreLesson,
    onShowLessLesson,
    onActiveTeacher,
    getParentIdBooking,
    setIsActive,
  }
}

export default useTeacherPage;