import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class accountCreditsApi {
  async getAccountCredits(id, productId) {
    try {
      let url = `product-info?id=${id}&productId=${productId}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }

  async getProducts(id) {
    try {
      const responseData = await makeRequest(
        getAPIEndpoint() + `product-list?id=${id}`,
        'get',
        null,
        true,
    );
    return Promise.resolve(responseData);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getCreditHistory(id, productId, page) {
    try {
      let url = `credit-history?productId=${productId}&id=${id}&limit=20&page=${page}`;
      const responseData = await makeRequest(
          getAPIEndpoint() + url,
          'get',
          null,
          true,
      );
      return Promise.resolve(responseData);
    } catch (err) {
        return Promise.reject(err);
    }
  }
  
}

export default new accountCreditsApi();