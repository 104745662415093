import { useEffect, useRef } from 'react';
import '../../../../sass/private/Common/CustomRadio.scss';

const CustomRadio = (props) => {
	const { name, id, children, hasBorder, selectedValue, value, onChange } = props;
	const inputRef = useRef(null);

	useEffect(() => {
		inputRef.current.checked = selectedValue === value;
	});

  return (
		<div className="custom-radio">
			<input
				ref={inputRef}
				type="radio"
				name={name}
				id={`${id}-${name}`}
				value={name}
				onChange={() => onChange(value)}
			></input>
			{ hasBorder}
			<label
				htmlFor={`${id}-${name}`}
				className={`${hasBorder ? 'custom' : ''}`}
			>
				{ children }
			</label>
		</div>
	)
}

export default CustomRadio;