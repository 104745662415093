import { useSelector } from 'react-redux';
import { getAPIEndpoint, getAPIEndpointByCentre, makeRequest } from '../../components/Utilities';

class ProfileCaptureApi {
  async getCentres() {
    try {
      const url = 'list-profile-capture-centres'
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  
  async getLanguages() {
    try {
      let centreId = localStorage.getItem('current_centre');
      let params = '';
      if(centreId) {
        params = `?centre_id=${centreId}`;          
      }
      const url = `profile-capture/languages${params}`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'get',
        null,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getWelcomescreen() {
    try {
      let languageId = localStorage.getItem('current_language') ?? 1;
      const url = `profile-capture/welcome-screen?language_id=${languageId}`

      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'get',
        null,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  
  async getTranslations() {
    try {
      let languageId = localStorage.getItem('current_language') ?? 1;
      const url = `profile-capture/translations?language_id=${languageId}`

      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'get',
        null,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getMotivations(centreId, profileId) {
    try {
      let languageId = localStorage.getItem('current_language') ?? 1;
      let url = `profile-capture/motivations?centre_id=${centreId}&language_id=${languageId}`
      if(profileId) {
        url = url+'&profile_id='+profileId;
      }
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'get',
        null,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  async checkValidPassword(password) {
    try {
      const url = `profile-capture/check-valid-password`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        {password: password},
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitMotivations(data) {
    try {      
      let languageId = localStorage.getItem('current_language') ?? 1;
      data['language_id'] = languageId;
      const url = `profile-capture/submit-motivations`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitSectionMotivation(data) {
    try {
      const url = `profile-capture/profile-statements`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitSectionInterest(data) {
    try {
      const url = `profile-capture/profile-interest`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitSectionLanguage(data) {
    try {
      const url = `profile-capture/profile-language`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  
  async submitSectionSubskill(data) {
    try {
      const url = `profile-capture/profile-subskill`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitSectionLearning(data) {
    try {
      const url = `profile-capture/profile-learning`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitSectionLocal(data) {
    try {
      const url = `profile-capture/profile-local`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitSectionFinal(data) {
    try {
      const url = `profile-capture/profile-final`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitSectionExam(data) {
    try {
      const url = `profile-capture/profile-exam`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async submitFinal(data) {
    try {
      const url = `profile-capture/final-submit`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async deleteProfile(data) {
    try {
      const url = `profile-capture/delete-profile`
      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'delete',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async validationGUID(guid) {
    try {
      const url = `profile-capture/validate`

      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        {guid: guid},
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
  
  async profileLogBack(data) {
    try {
      const url = `profile-capture/profile-log-back`

      const responseData = await makeRequest(
        getAPIEndpointByCentre() + url,
        'post',
        data,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new ProfileCaptureApi();