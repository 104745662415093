import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Select, MenuItem } from '@material-ui/core';
import iconChecked from '../../../../assets/image/pass.png';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    color: '#2f1160',
    fontWeight: '700',
    fontSize: 14,
    lineHeight: '21px'
  },
  dropdownStyle: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    width: 295,
    maxHeight: 430,
    boxSizing: 'border-box',
    "& ul": {
      padding: 0,
    },
    "& fieldset": {
      border: '2px solid #005cb9'
    }
  },
  select: {
    width: 320,
    height: 54,
    color: '#2f1160',
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 500,
    "& img": {
      display: 'none'
    },
    "& svg": {
      height: '25px',
      width: '30px',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#2f1160',
    }
  },
  option: {
    padding: '42.75px 20px',
    color: '#2F1160',
    fontSize: '16px',
    lineHeight: '24px',
    display: 'flex',
    alightItem: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    "&:active": {
      backgroundColor: '#97daff'
    },
    "&:hover": {
      backgroundColor: '#ffffff'
    }
  },
  multiValue: {
    padding: '9.5px 20px'
  },
  active: {
    backgroundColor: '#97daff !important'
  },
  image: {
    position: 'absolute',
    left: 20
  },
  search: {
    color: '#54565a'
  }
}));

export default function AdvancedSelects(props) {
  const { 
    data, 
    label, 
    value, 
    isMultiValue, 
    isShowSearch, 
    onChangeOption, 
    styles, 
    nameSelect,
    disabled,
  } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    selectedValue: '',
  });

  useEffect(() => {
    setState({
      selectedValue: value
    })
  }, [value])

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      [name]: event.target.value,
    });
    onChangeOption(nameSelect, event.target.value)
  };

  return (
    <FormControl variant="outlined" className={`${classes.formControl}`} style={styles} disabled={disabled}>
        <InputLabel htmlFor={label.split(' ').join('')} className={classes.label}>{label}</InputLabel>
        <Select
          value={state.selectedValue}
          onChange={handleChange}
          inputProps={{
            name: 'selectedValue',
            id: label.split(' ').join(''),
          }}
          MenuProps={{ classes: { paper: classes.dropdownStyle }, 
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null}}
          className={classes.select}
        >
          {isShowSearch &&
            <MenuItem
              className={`${classes.option} ${classes.multiValue} ${classes.search}`}
            >
              Search...
            </MenuItem>
          }

          {data?.map(item => {
            return (
              <MenuItem
                key={item.id}
                value={item.id}
                className={`
                  ${classes.option}
                  ${item.id === state.selectedValue ? classes.active : ''}
                  ${isMultiValue ? classes.multiValue : ''}
                `}
              >
                {item.id === state.selectedValue && <img className={classes.image} src={iconChecked} alt="Selected"/>}{item.name}
              </MenuItem>)
            })
          }
        </Select>
      </FormControl>
  );
}