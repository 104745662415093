export function getCurrentUser() {
  return JSON.parse(localStorage.getItem('user'));
}

export function setAvatarCurrentUser(image) {
  const user = getCurrentUser();
  if (user) {
    const newUser = {...user};
    newUser.responseUserData.avatar = image || "";
    localStorage.setItem("user", JSON.stringify(newUser));
  }
}