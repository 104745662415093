import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class accountActivationApi {
  async validateActivation(studentID, confirmationCode) {
    try {
      let url = `auth/validate-activation?student_id=${studentID}&confirmation_code=${confirmationCode}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'get',
        null,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async activateAccount(formData) {
    try {
      let url = `auth/activation`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        formData,
        true,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new accountActivationApi();