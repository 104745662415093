import { ActionTypes } from '../../actions/types';

const initialState = {
  friendList: {
    friends: [],
    friend_pending_requests: [],
    friend_requests: [],
  }
}

export default function myClassFriendReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_MYCLASS_FRIEND_SUCCESS:
      return {
        ...state,
        friendList: payload,
      }
    case ActionTypes.HANDLE_INVITE_SUCCESS: 
      if(payload.status == "accept") {
        let newFriendList = {
          ...state.friendList
        }
        let newPending = newFriendList.friend_pending_requests?.filter((pending) => pending.id !== payload.friend.id)
        newFriendList.friends.push(payload.friend)
        
        return {
          ...state, 
          friendList: {...newFriendList, friend_pending_requests: newPending,}
        }
      }
      if(payload.status == "ignore") {
        let newFriendList = {
          ...state.friendList
        }
        let newPending = newFriendList.friend_pending_requests?.filter((pending) => pending.id !== payload.friend.id)
        return {
          ...state,
          friendList: {...newFriendList, friend_pending_requests: newPending },
        }
      }
      if(payload.status == "cancel") {
        let newFriendList = {
          ...state.friendList
        }
        
        let newRequest = newFriendList.friend_requests?.filter((request) => request.id !== payload.friend.id)
        return {
          ...state,
          friendList: {...newFriendList, friend_requests:newRequest},
        }
      }
      return {
        ...state
      }
      
    case ActionTypes.GET_MYCLASS_FRIEND_FAIL:

      return {
        ...state,
        message: payload
      }
    default:
      return state;
  }
}
