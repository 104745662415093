import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import useProfileCapture from "../../../hooks/ProfileCapture/useProfileCapture";
import { setCentre } from '../../../redux/actions/ProfileCapture/profileCaptureAction';
import { useSelector } from 'react-redux';

const ModalPassword = (props) => {
  const { profileId, onClose, open, handleSubmit, onChangePass, dispatch, step,isPasswordValid } = props
  const { translations, languages } = useSelector(state => state.profilecapture)

  return (
    <div>
      <Modal
          onClose={onClose}
          open={open}
          dir={localStorage.getItem('rtl') == 1 ? 'rtl' : ''}
      >
        <div className="wrap-modal-pass">
          <div className="head-modal">
            <h5>{translations['[label_for_staff_use]']}</h5>
          </div>
          <div className='wrap-form-pass'>
            {!isPasswordValid && 
            <div
              className="error-msg"
              style={{ color: '#AB0033', textAlign: "center", marginBottom: "10px" }}
            >
              {translations['[label_incorrect_password]']}
            </div>}
            <form autoComplete="off">
              <FormControl className='group-input' fullWidth margin='normal'>
                <span>{translations['[label_password]']}</span>
                <Input
                  name='password'
                  type='password'
                  className='input-pass'
                  onChange={onChangePass}
                  label={'error'}
                  autoComplete="off"
                />
              </FormControl>
            </form>
              <FormControl className='group-btn-pass' fullWidth margin='normal'>
                <Button
                  color='primary'
                  type='submit'
                  onClick={handleSubmit}
                >
                  {translations['[button_submit]']}
                </Button>
                <Button
                  color='primary'
                  type='submit'
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </FormControl>
          </div>
        </div>
      </Modal>

    </div>  
  )
    
}
ModalPassword.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onChangePass: PropTypes.func,
  isPasswordValid: PropTypes.bool
}

export default ModalPassword;