import { Button } from "@material-ui/core";
import { memo } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactHtmlParser from "react-html-parser";
import CircularProgressBar from "../../Common/CircularProgressBar";

const ProgressViewItemMobile = (props) => {
  const history = useHistory();
  const {
    handleOpenProgressModal,
    handleOpenReviewModal,
    buttonTitle,
    classes,
    title,
    subTitle,
    desc,
    url,
		grade,
    credentialPage,
    color,
    item
  } = props;

	
  const handleClick = (url) => {
    window.open(url, "_blank");
  }
  return (
    <div className={`item-practice lesson-theme item-progress ${classes}`} style={{'borderLeftColor': color ? color : '#009D8C'}}>
      <div className="left-practice">
        <p className="text">{title}</p>
        <p className="heading">{ReactHtmlParser(subTitle)}</p>
        <p className="desc">{desc}</p>
      </div>
      <div className="right-practice icon-block secondary">
        <CircularProgressBar
          strokeWidth="3"
          sqSize="34"
          completeProgress={grade}
          percentage={100 - grade * 10}
        />
        <div className="button-group">
          {credentialPage ?
            <>
              <span className='btn-link' onClick={() => handleOpenReviewModal(item)}>Review activities</span>
              <Button variant='contained' className={`btn btn-booked btn-start btn-practice secondary`} onClick={()=> handleClick(credentialPage)}>Share</Button>
            </> 
          : (
            <Button variant="contained" className={`btn btn-booked btn-start btn-practice secondary`} onClick={() => handleOpenProgressModal(item)}>{buttonTitle ?? 'Progress Check'}</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ProgressViewItemMobile);
