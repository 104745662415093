import { useState,useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import { resetAll } from '../../../redux/actions/ProfileCapture/profileCaptureAction';
import useProfileCapture from "../../../hooks/ProfileCapture/useProfileCapture";
import ModalPassword from './ModalPassword';

const ModalAction = (props) => {
  const { onClose, open, dispatch, step, profileId, translations } = props
  const [confirmationType, setConfirmationType] = useState('')
  const { isLoading, centreList, openProfileCapture } = useProfileCapture()
  const [showModalPassword, setShowModalPassword] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const { checkValidPassword } = useProfileCapture()
  const [ password, setPassword ] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [showModalListCentre, setShowModalListCentre] = useState(false)

  const onClick = (value) => {
    setConfirmationType(value);
    setShowModalConfirm(true)
  }
  // Resolve Modal Password
  const handleOpenModal = () => setShowModalPassword(true)
  const handleHideModal = () => setShowModalPassword(false)
  const handleOpenModalConfirm = () => setShowModalConfirm(true)
  const handleHideModalConfirm = () => setShowModalConfirm(false)
  const onChangePass = (e) => {
    setPassword(e.target.value)
  }
  const handleSubmitModalPassword = async() => {
    let response = await checkValidPassword(password);
    if(response === true) {
      setShowModalConfirm(true)
      setShowModalPassword(false)
    } else{
      setIsPasswordValid(false)
    }   
  }

  // =======================
  const onCancel = () => {
    setConfirmationType('')
    handleHideModalConfirm()
  }
  const onReset = () => {
    setConfirmationType('')
    dispatch(resetAll()) 
    setShowModalConfirm(false)
  }
  const onDelete = () => {
    setConfirmationType('')
    dispatch(resetAll(true, profileId))
    setShowModalConfirm(false)
  }
  
  return (
    <div>
       <Modal
          onClose={onClose}
          open={open}
          dir={localStorage.getItem('rtl') == 1 ? 'rtl' : ''}
        >
        <div className="wrap-modal-pass">
          <div className="head-modal">
            <h5>{translations['[label_for_staff_use]']}</h5>
          </div>
          <div className='wrap-form-pass'>
            <FormControl className='group-btn' fullWidth margin='normal'>
              <Button
                className='btn-reset'
                color='primary'
                type='submit'
                onClick={() => onClick('reset')}
              >
                {translations['[button_reset]']}
              </Button>
              {step !== 'reason' && <Button
                className='btn-delete'
                color='primary'
                type='submit'
                onClick={() => onClick('delete')}
              >
                {translations['[button_delete]']}
              </Button>}
              <Button
                className='btn-cancel'
                color='primary'
                type='submit'
                onClick={onClose}
              >
                {translations['[option_cancel]']}
              </Button>
            </FormControl>
          </div>
        </div>
      </Modal>
      
      <ModalPassword
          profileId={profileId}
          onClose={handleHideModal}
          open={showModalPassword}
          handleSubmit={handleSubmitModalPassword}
          onChangePass={onChangePass}
          dispatch={dispatch}
          isPasswordValid = {isPasswordValid}
          step={step}
      />
      <Modal
          onClose={handleHideModalConfirm}
          open={showModalConfirm}
        >
         <div className="wrap-modal-pass">
          <div className="head-modal">
            <h5>{translations['[label_for_staff_use]']}</h5>
          </div>
          <div className="wrap-form-pass confirmation">
          <label>
            {confirmationType === 'reset' ?
            translations['[label_reset_message]'] : translations['[label_delete_message]']}
          </label>
            <FormControl className='group-btn' fullWidth margin='normal'>
                <Button
                  className={confirmationType === 'reset' ? 'btn-reset' : 'btn-reset'}
                  color='primary'
                  type='submit'
                  onClick={confirmationType === 'reset' ? onReset : onDelete}
                >
                  {translations['[button_confirm]']}
                </Button>
                <Button
                  className='btn-cancel'
                  color='primary'
                  type='submit'
                  onClick={onCancel}
                >
                  {translations['[option_cancel]']}
                </Button>
              </FormControl>
          </div>
        </div>
      </Modal>
    </div>  
   
  )
}

export default ModalAction