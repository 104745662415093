import { useState } from "react";
import { 
  getLessonDetail,
  getLevelList,
  getThemeList 
} from "../../redux/actions/CourseMap/CourseMapAction";

const useCourseMap = () => {
  const [zone, setZone] = useState(null)
  const [levelList, setLevelList] = useState([])
  const [levelName, setLevelName] = useState('')
  const [themeList, setThemeList] = useState([])
  const [lessonList, setLessonList] = useState([])
  const [lessonInfo, setLessonInfo] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [pdfLink, setPdfLink] = useState('')

  const openCourseMap = async (zoneId) => {
    const data = await getLevelList(zoneId)
    setIsLoading(false)
    setLevelList(data.level_list)
    setZone(data.zone_id)
  }
  const openCourseMapDetail = async (levelId, zoneId) => {
    const themeData = await getThemeList(levelId, zoneId)
    setIsLoading(false)
    setLevelName(themeData.level_name)
    setThemeList(themeData.theme_list)
    setPdfLink(themeData.pdf_link)
  }
  const openCourseMapLessonDetail = async (contentId, zoneId) => {
    const data = await getLessonDetail(contentId, zoneId)
    setIsLoading(false)
    setLessonInfo(data)
    setLessonList(data.lesson)
  }

  return {
    levelList,
    themeList,
    lessonList,
    lessonInfo,
    levelName,
    openCourseMap,
    openCourseMapDetail,
    openCourseMapLessonDetail,
    isLoading,
    zone,
    pdfLink,
  }
}

export default useCourseMap;