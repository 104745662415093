import { ActionTypes } from '../../actions/types';

const initialState = {
  achievementsData: {},
}

export default function achievementsReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
   

      case ActionTypes.GET_ACHIEVEMENT_SUCCESS:
        return {
          ...state,
          achievementsData: payload,
        }
      case ActionTypes.GET_ACHIEVEMENT_FAIL:
        return {
          ...state,
          message: payload
        }

    case ActionTypes.GET_ACHIEVEMENT_FAIL:
      return {
        ...state,
        message: payload
      }
    default:
      return state;
  }
}