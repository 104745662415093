import React from 'react';
import Page from './Page';
import Container from './Container';
import { getRoute, getString, isNumber } from './Utilities';
import ListCentre from '../pages/public/ProfileCapture/ListCentre';
import Logo from '../../assets/image/logo.png';
import BannerHeader from '../../assets/image/bnr-top-1.png';
import BannerCreate from '../../assets/image/bnr-create.png';

import '../../sass/public/ProfileCapture.scss';
import { checkValidPassword, setStep } from '../redux/actions/ProfileCapture/profileCaptureAction';
import ProfileCaptureApi from '../redux/services/profileCaptureApi';
import ModalPassword from '../pages/public/ProfileCapture/ModalPassword';
import ModalListCentre from '../pages/public/ProfileCapture/ModalListCentre';
import { handleGoBack } from '../../utils/helper';
import { connect } from 'react-redux';
import { setLanguageId } from '../redux/actions/ProfileCapture/profileCaptureAction';
import ListLanguage from '../pages/public/ProfileCapture/ListLanguage';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { createMuiTheme } from '@material-ui/core/styles';

class ProfileCapturePage extends React.Component {
  constructor(props) {
    super(props);
    this.handleFullScreenText = this.handleFullScreenText.bind(this);
    this.state = ({
      active : false,
      showModalPassword: false,
      password: '',
      showModalListCentre : false,
      isPasswordValid : true,
      isFullscreenEnabled: false
    })
  }
  
  handleFullScreenText() {
    let maxHeight = window.screen.height,
        maxWidth = window.screen.width,
        curHeight = window.innerHeight,
        curWidth = window.innerWidth;
        
    
    if (maxWidth == curWidth && maxHeight == curHeight) {
      this.setState({isFullscreenEnabled : true})  
    } else {
      this.setState({isFullscreenEnabled : false})  
    }
  }
  
  componentDidMount() {
    this.handleFullScreenText();
    window.addEventListener('resize', this.handleFullScreenText);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleFullScreenText);
  }
 
  render() {
    const { history, dispatch, step, previousStep, pageTitle, centreId, profileId, bannerMaster, motivationId, handleGoBackExamDetail} = this.props
    const onClickLogo = () => {
      history.push(getRoute("HOME"))
    }
    
    const { active } = this.state;
    const { password } = this.state;
    const { isPasswordValid } = this.state;
    const handleClick = () => {
      this.setState({ active: !this.state.active });
    };
    
    const handleHideModal = () => {
      this.setState({showModalPassword: false})
    }
    const handleHideModalListCentre = () => {
      this.setState({showModalListCentre: false})
      this.setState({showModalPassword: false})
      this.setState({isPasswordValid : true})
    }
    const handleShowModal = () => {
      this.setState({showModalPassword: true})
    }
    const onChangePass = (e) => {
      this.setState({password: e.target.value})
    }
    const handleSubmit = async() => {
      let response = await checkValidPassword(password);
      if(response === true) {
        handleHideModal()
        this.setState({showModalListCentre : true})
        this.setState({isPasswordValid : true})
        // onClose()
      } else{
        this.setState({isPasswordValid : false})
      }   
    }
    
    const toggleFullSceen = () => {
      this.setState({isFullscreenEnabled : !this.state.isFullscreenEnabled})
      if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (document.documentElement.webkitEnterFullscreen) {
          document.documentElement.webkitEnterFullscreen(); //for iphone this code worked
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    };    
    
    return (
      <Page dir={localStorage.getItem('rtl') == 1 ? 'rtl' : ''} className="profile-capture-page profile-capture-page-home">
        <div>
          <header className="header">
            <div className="header-wrap">
              <div className="header-top">
                <div className="header__left">
                  <img className="img-logo" src={Logo} alt="British Council" onClick={onClickLogo} />
                </div>
                <ListLanguage></ListLanguage>
              </div>
              <div className="header-bottom">
                {previousStep && <div className="back-left" onClick={() => typeof handleGoBackExamDetail !== 'undefined' ? handleGoBackExamDetail() : handleGoBack(dispatch, previousStep, profileId, step, motivationId)}></div>}
                <div className="ttl-h1">
                  <h1 className="ttl-1">{pageTitle}</h1>
                </div>
                {step !== 'welcome' && step !== 'final' && step !== 'complete' &&
                <div className="bnr-right">
                  <img className="img-bnr" src={bannerMaster ?? BannerHeader} alt="" />
                </div>}
              </div>
            </div>
          </header>
        </div>
        <main>
          <div className="main-content main-content-profile">
            {this.props.children}
            <div className="create-tell-inner">
              <div className="profile-capture-wrapper">
                <div className="center-set-inner">
                  {centreId ?
                  <ListCentre isFullscreenEnabled={this.state.isFullscreenEnabled} 
                  toggleFullSceen={toggleFullSceen} 
                  centreId={centreId} 
                  dispatch={dispatch} 
                  step={step} 
                  profileId={profileId}
                  /> :
                  <div>
                  <div className="wrapper-fullscreen">
                    <button type="button" className="btn-select-options" onClick={handleShowModal}>{this.props.profilecapture.translations['[label_centre_set]'] ?? ''}</button>
                    <button className='btn-fullscreen-active btn-select-options btn-fullscreen' onClick={() => toggleFullSceen()}>
                      {this.state.isFullscreenEnabled ? "Exit full screen" : "Full screen"}
                    </button>
                    {/* <ListLanguage></ListLanguage> */}
                  </div>
                  <ModalPassword
                  // profileId={profileId}
                  currentCentreId={centreId}
                  onClose={handleHideModal}
                  open={this.state.showModalPassword}
                  handleSubmit={handleSubmit}
                  onChangePass={onChangePass}
                  dispatch={dispatch}
                  isPasswordValid = {this.state.isPasswordValid}
                  // step={step}
                />
                <ModalListCentre 
                  profileId={profileId}
                  onClose={handleHideModalListCentre}
                  open={this.state.showModalListCentre}
                  dispatch={dispatch}
                  step={step}
                />
                </div>
                  }
                </div>
              
                {/* <ModalPassword onClose={handleHideModal} open={this.state.showModalPassword} currentCentreId={centreId} dispatch={dispatch}/> */}
              </div>
            </div>
          </div>
        </main>
      </Page>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguageId: (languageId) => dispatch(setLanguageId(languageId))
  }
};

const mapStateToProps = (state) => {
  return {
    profilecapture: state.profilecapture
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCapturePage);