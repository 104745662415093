import '../../../../sass/private/Common/CustomMenuTab.scss';

const CustomMenuTab = (props) => {
	const { tabs, activatedTab, handleTabClick, disabled, tabLabels, tabCounts, hideModuleTab } = props;
	const activeClass = (tab) => {
		if (activatedTab === tab) {
			return "mobile-menu-tab--active";
		}

		return "";
	}

	return (
		<ul className="mobile-menu-tabs">
			{tabs.map((tab, index) => {
				if (hideModuleTab && tab === 'Modules') return '';
				return (
					<li
						key={tab}
						className={`mobile-menu-tab ${activeClass(tab)}`}
						style={{width: `${100/(!hideModuleTab ? tabs.length : tabs.length - 1)}%`}}
						onClick={disabled? ()=> {}: () => handleTabClick(tab)}
					>
						<span className="mobile-menu-tab__label">{tabLabels ? tabLabels[index] : tab}</span>
						{tabCounts && (
							<span className="mobile-menu-tab__count">{tabCounts[index]}</span>
						)}
						<div className="mobile-menu-tab__line"></div>
					</li>
				)
			})}
		</ul>
	)
}

export default CustomMenuTab;