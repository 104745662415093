import { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import { resetAll, setStoreListExamEdited } from '../../../redux/actions/ProfileCapture/profileCaptureAction';
import { useSelector } from 'react-redux';

const ModalExam = (props) => {
  const {
    onClose,
    setShowModal,
    open,
    data,
    currentExamTypeId,
    currentExamId,
    dispatch,
    step,
    profileId,
    onSelectMotivation,
    onChangeMotivationComment,
    onSelectTargetDate,
    onSelectTargetScore,
    onSelectPrevAttempt,
    isExamTakenBefore,
    onSelectPrevAttemptTime,
    onChangePrevAttemptComment,
    setFormValid,
    formValid,
    storeListExamEdited,
    tempMotivationSections,
    setIsExamTakenBefore,
    translations
  } = props

  const onNext = () => {
    storeListExamEdited[data.id] = data.id_profile_exam_type;
    dispatch(setStoreListExamEdited(storeListExamEdited));
    setShowModal(false);
  }

  useEffect(() => {
    if (
      (!data.is_ask_motivation ||
        (data.is_ask_motivation && data.profile_exam_motivation_statements.filter(item => item.checked).length > 0)
      ) &&
      (!data.is_ask_prev_attempts ||
        (data.is_ask_prev_attempts 
          //case choose Yes option
          && ((data.previous_attempt_array.filter(item => item.checked).length > 0 && data.previous_attempt_times_array.filter(item => item.checked).length) 
          //case choose No option
          || (data.previous_attempt_array.filter(item => item.checked && item.value == 0).length > 0))
        )
      ) &&
      (!data.is_ask_target_date ||
        (data.is_ask_target_date && data.target_date_array.filter(item => item.checked).length > 0)
      ) &&
      (!data.is_ask_target_score ||
        (data.is_ask_target_score && data.target_score_array.filter(item => item.checked).length > 0)
      )
    ) {
      setFormValid(true)
    } else {
      setFormValid(false)
    }


    if(storeListExamEdited[data.id]) {
      if(data.is_ask_prev_attempts && data.previous_attempt_array.filter(item => item.checked && item.value == 0).length > 0) {
        setIsExamTakenBefore(false);
      } else {
        setIsExamTakenBefore(true);
      }
    }

  }, [data])

  return (
    <div>
      <Modal
          onClose={onClose}
          open={open}
          dir={localStorage.getItem('rtl') == 1 ? 'rtl' : ''}
      >
        <div className="modal-skills">
          <div className="header-modal">
            <h1 className="ttl-1">{tempMotivationSections.exam_motivation_text}</h1>
            <button type="button" className="btn btn-close" onClick={onClose}>{ translations['[button_close]'] }</button>
          </div> 
          <div className="content-modal main-content main-content-profile">
            {data.is_ask_motivation === 1 && <div className="m">
              <div className="profile-capture-wrapper">
                <div className="profile-capture-form">
                  <label className="lbl-other">{ translations['[motivate_list_heading]'] }</label>
                  <div className="option-item-wrapper">
                  {data.profile_exam_motivation_statements?.length > 0 && data.profile_exam_motivation_statements.map(item => {
                    return (
                      <div className="list-option-item" key={item.id}>
                        <div className={`option-item ${item.checked ? 'active': ''}`}  onClick={() => onSelectMotivation(currentExamTypeId, currentExamId, item.id)}>
                          <input className="form-control" type="checkbox" checked={item.checked} readOnly={true} value=""/>
                          <label form="check-2">{item.description}</label>
                        </div>
                        {item.is_add_text && item.checked ?
                        <div className="type-other-social type-specific">
                          <label className="lbl-type-specific lbl-other">{ translations['[request_detail]'] }</label>
                          <input
                            type="text"
                            className="form-control type-input"
                            placeholder={ translations['[motivate_other]'] }
                            onChange={(e) => onChangeMotivationComment(currentExamTypeId, currentExamId, item.id, e.target.value)}
                            value={item.response_text || ''}
                          />
                        </div> : ''
                        }
                      </div>
                    )
                  })}
                  </div>
                  {/* <div className="other-reason-inner">
                    <div className="other-reason-w">
                      <div className="type-other-social">
                        <label className="lbl-other">
                          If you're taking the IELTS exam for any other reason, tell us about it here
                        </label>
                        <input type="text" className="form-control type-input" placeholder="Type here" value={data.comment} onChange={e => onChangeMotivationComment(currentExamTypeId, currentExamId, e.target.value)} />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>}

            {data.is_ask_target_date === 1 &&
            <>
            <div className="bg-come-className">
              <div className="profile-capture-wrapper w-profile">
                <h2 className="ttl-2">{ translations['[exam_when_text]'] }</h2>
              </div>
            </div>
            <div className="profile-capture-wrapper profile-taking modal-padding">
              <div className="profile-capture-form">
                <div className="option-item-wrapper option-radio-wrapper">
                  {data.target_date_array?.length > 0 && data.target_date_array.map(item => {
                    return (
                      <div className="list-option-item" key={item.value} onClick={() => onSelectTargetDate(currentExamTypeId, currentExamId, item.value)}>
                        <div className={`option-item ${item.checked ? 'active' : ''}`}>
                          <input className="form-control" type="radio" checked={item.checked} readOnly={true}/>
                          <label>{item.name}</label>
                        </div>
                      </div>
                    )
                  })}
                  {/* <div className="list-option-item">
                    <div className="option-item "><input className="form-control" type="radio" /><label>Online</label></div>
                  </div>
                  <div className="list-option-item">
                    <div className="option-item "><input className="form-control" type="radio" /><label>Face-to-face</label></div>
                  </div>
                  <div className="list-option-item">
                    <div className="option-item "><input className="form-control" type="radio" /><label>Both</label></div>
                  </div> */}
                </div>
              </div>
            </div>
            </>
            }

            {data.is_ask_target_score === 1 &&
            <>
            <div className="bg-come-className">
              <div className="profile-capture-wrapper w-profile">
                <h2 className="ttl-2">{ translations['[exam_target_score_text]'] }</h2>
              </div>
            </div>
            <div className="profile-capture-wrapper profile-band modal-padding">
              <div className="profile-capture-form">
                <div className="option-item-wrapper option-radio-wrapper">
                  {data.target_score_array?.length > 0 && data.target_score_array.map(item => {
                    return (
                      <div className="list-option-item" key={item.value} onClick={() => onSelectTargetScore(currentExamTypeId, currentExamId, item.value)}>
                        <div className={`option-item ${item.checked ? 'active' : ''}`}>
                          <input className="form-control" type="radio" checked={item.checked} readOnly={true}/>
                          <label>{item.name}</label>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            </>
            }

            {data.is_ask_prev_attempts === 1 &&
            <>
            <div className="bg-come-className">
              <div className="profile-capture-wrapper w-profile">
                <h2 className="ttl-2">{ translations['[exam_taken_before_text]'] }</h2>
              </div>
            </div>
            <div className="profile-capture-wrapper profile-taking modal-padding">
              <div className="profile-capture-form">
                <div className="option-item-wrapper option-radio-wrapper">
                  {data.previous_attempt_array?.length > 0 && data.previous_attempt_array.map(item => {
                    return (
                      <div className="list-option-item" key={item.value} onClick={() => onSelectPrevAttempt(currentExamTypeId, currentExamId, item.value)}>
                        <div className={`option-item ${item.checked ? 'active' : ''}`}>
                          <input className="form-control" type="radio" checked={item.checked} readOnly={true}/>
                          <label>{item.name}</label>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            </>
            }

            {data.is_ask_prev_attempts === 1 && isExamTakenBefore &&
            <>
            <div className="bg-come-className">
              <div className="profile-capture-wrapper w-profile">
                <h2 className="ttl-2">{ translations['[exam_taken_times_text]'] }</h2>
              </div>
            </div>
            <div className="profile-capture-wrapper profile-many modal-padding">
              <div className="profile-capture-form">
                <div className="option-item-wrapper option-radio-wrapper">
                  {data.previous_attempt_times_array?.length > 0 && data.previous_attempt_times_array.map(item => {
                    return (
                      <div className="list-option-item" key={item.value} onClick={() => onSelectPrevAttemptTime(currentExamTypeId, currentExamId, item.value)}>
                        <div className={`option-item ${item.checked ? 'active' : ''}`}>
                          <input className="form-control" type="radio" checked={item.checked} readOnly={true}/>
                          <label>{item.name}</label>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            </>
            }

            {isExamTakenBefore && data.is_ask_prev_results ? (
              <div className="bg-come-class">
                <div className="profile-capture-wrapper w-profile">
                  <h2 className="ttl-2">{ translations['[exam_taken_results_text]'] }</h2>
                </div>
              </div>
            ) : ''}
            <div className="profile-capture-wrapper profile-many modal-padding">
              <div className="profile-capture-form">
                <div className="other-reason-inner">
                  <div className="other-reason-w">
                    {isExamTakenBefore && data.is_ask_prev_results ? (
                      <div className="type-other-social">
                        <label className="lbl-other">{ translations['[exam_taken_results_subtext]'] }</label>
                        <input type="text" className="form-control type-input" placeholder={ translations['[motivate_other]'] } value={data.prev_result} onChange={e => onChangePrevAttemptComment(currentExamTypeId, currentExamId, e.target.value)}/>
                      </div>
                    ) : null }
                    <div className="select-options-inner">
                    {formValid ?
                    <button className="btn-select-options next active" type="button" onClick={onNext}>{ translations['[button_next]'] }</button> :
                    <button className="btn-select-options" type="button">{ translations['[button_back]'] }</button>
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </Modal>
    </div>  
  )
}

export default ModalExam