import { ActionTypes } from '../actions/types';

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? {isLoggedIn: true, avatar: "", user}
    : {isLoggedIn: false, avatar: "", user: null, passwordReset: false};

export default function authReducer(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                avatar: payload.user.responseUserData?.avatar || "",
            };
        case ActionTypes.LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case ActionTypes.LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        case ActionTypes.SEND_MAIL_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordReset: action.payload.success
            }
        case ActionTypes.SET_AVATAR:
            return {
                ...state,
                avatar: payload || "",
            };
        default:
            return state;
    }
}