import React from "react";
import Modal from "../Dialog/Modal";
import { Button } from "@material-ui/core";
import AlertCircle from "../../../components/icon/AlertCircle";
import useCheckMobileTablet from "../../../../utils/useCheckMobileTablet";

const ModuleVideoPopup = (props) => {
  const { videoModule, handleOpenModuleDetails, handleCloseVideo } = props;
  const { isMobile, isTablet } = useCheckMobileTablet();
  return (
    <Modal open={props.open} handleCloseDialog={handleCloseVideo} className="module-video-modal">
      <div className="module-video-modal__wrapper">
        {!isTablet &&
          <Button variant="contained" onClick={handleCloseVideo} className="btn btn-return btn-top">Close</Button>
        }
        <div className="module-video-modal__inner">
          {isTablet &&
            <button onClick={handleCloseVideo} className="btn-mob-close">
              <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.00391 1.00342L0.99697 7.00342L7.00391 13.0034" stroke="#23085A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
                Back
            </button>
          }
          <div className="content-block-video">
            {videoModule && videoModule.map((item, index)=> (
              <>
              {item.button_flag == true ?
                <div key={index} className={`block-video ${(item.unlock_before == 24 ? 'block-unlocked' : "")} ${item.unlock_before == 24 && item.is_unlocked == false ? 'lock-video' : ''}`}>
                  <h3 className="block-title">{item.title}</h3>
                  <p className="txt-description">{item.description}</p>
                  <div className="block-video__main">
                    <span class="loader"></span>
                    <div className="viddler-auto-embed" data-embed-id="1Sb0TXPrS1" data-video-id={item.url} data-width="100%" data-height={isMobile ? "160" : "261"} data-hide-controls-until-play="1" data-default-resolution="1080p" data-hd="true"></div>
                  </div>
                  {(item.unlock_before == 24 && item.is_unlocked == false) ?
                    <div className="show-alert">
                      <div className="show-alert__content">
                        <AlertCircle/>
                        <p>
                          This video will become available<br/>
                          24 hours before the module start.
                        </p>
                      </div>
                    </div>
                  : ""}
              </div> : ""}
              </>
            ))}
          </div>
          {handleOpenModuleDetails &&
            <Button variant="contained" onClick={handleOpenModuleDetails} className="btn btn-primary">View module</Button>
          }
        </div>
      </div>
    </Modal>
  );
};

export default ModuleVideoPopup;
