import { useDispatch, useSelector } from "react-redux";
import WelcomePage from "./WelcomePage";
import ReasonPage from "./ReasonPage";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { setCentre, setFinalSectionText, setLastStep, setSectionData, submitExam, submitFinal, submitInterest, submitLanguage, submitLearning, submitLocal, submitMotivation, submitMotivations, submitSubskill } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import MotivationPage from "./MotivationPage";
import InterestPage from "./InterestPage";
import LanguagePage from "./LanguagePage";
import LearningPage from "./LearningPage";
import LocalPage from "./LocalPage";
import FinalPage from "./FinalPage";
import CompletePage from "./CompletePage";
import ExamPage from "./ExamPage";
import SubskillPage from "./SubskillPage";
import { route } from "../../../components/Config";
import { CircularProgress } from "@material-ui/core";
import ProfileCapturePage from "../../../components/ProfileCapturePage";

const MainNavigator = () => {
  const { guid, step, lastStep, centreId, sections, components, stepOrder, profileId, lastText, commentFinal, motivations, translations } = useSelector(state => state.profilecapture)
  const dispatch = useDispatch()
  const history = useHistory()
  const [submitFlag, setSubmitFlag] = useState(false)
  const [submitStep, setSubmitStep] = useState('')
  const [finalText, setFinalText] = useState('')

  const getPreviousStep = (currentStep) => {
    const currentIndex = stepOrder.indexOf(currentStep)
    const nextIndex = (currentIndex - 1) % stepOrder.length

    return stepOrder[nextIndex]
  }

  const getNextStep = (currentStep) => {
    const currentIndex = stepOrder.indexOf(currentStep)
    const nextIndex = (currentIndex + 1) % stepOrder.length

    return stepOrder[nextIndex]
  }

  const getPageOrder = (step) => {
    // Uncount welcome & complete page
    return `${stepOrder.indexOf(step)} ${translations['[label_of]']} ${stepOrder.length - 2}`
  }  

  useEffect(() => {
    const centreIdFromLocalStorage = localStorage.getItem('current_centre')
    if (centreIdFromLocalStorage && centreIdFromLocalStorage !== centreId) {
      dispatch(setCentre(+centreIdFromLocalStorage))
    }
  }, [])

  // Handle submission
  useEffect(() => {
    async function submitSections(submitStep, submitFlag) {  
      if (submitStep && submitFlag) {
        window.scrollTo(0, 0);
        let result;
        const data = sections.filter(item => item.type === submitStep)[0]
        if (submitStep.includes('motivation')) {
          result = await submitMotivation(data, profileId)
        }
        if (submitStep === 'interest') {
          result = await submitInterest(data, profileId)
        }
        if (submitStep === 'skill') {
          result = await submitLanguage(data, profileId)
        }
        if (submitStep === 'subskill') {
          result = await submitSubskill(data, profileId)
        }
        if (submitStep === 'learning') {
          result = await submitLearning(data, profileId)
        }
        if (submitStep === 'local_operational') {
          result = await submitLocal(data, profileId)
        }
        if (submitStep === 'final') {
          result = await submitFinal(commentFinal, profileId)
        }
        if (submitStep === 'exam') {
          result = await submitExam(data, profileId)
        }
        if(!result.success && guid != 0) {
          history.push(route['PROFILECAPTUREVALIDATE']+'?guid='+guid);
        }
        setSubmitFlag(false)

        //check to know which step user put anwser on last step and haven't submit yet.
        let nextStep = getNextStep(submitStep);
        if(stepOrder.indexOf(lastStep) <= stepOrder.indexOf(nextStep)) {
          dispatch(setLastStep(nextStep));
        }
      }
    }
    
    submitSections(submitStep, submitFlag);
  }, [submitStep, submitFlag])

  return (
    <>
      {step === 'loading' && 
        <>
        <ProfileCapturePage history={history} dispatch={dispatch} step={step} previousStep={''} centreId={centreId}>
          <CircularProgress />
        </ProfileCapturePage>
        </>
      }
      {step === 'welcome' && 
        <WelcomePage
          history={history}
          dispatch={dispatch}
          step={'welcome'}
          nextStep={getNextStep('welcome')}
          centreId={centreId}
          translations={translations}
        />
      }
      {step === 'reason' && 
        <ReasonPage
          history={history}
          dispatch={dispatch}
          step={'reason'}
          previousStep={getPreviousStep('reason')}
          centreId={centreId}
          profileId={profileId}
          translations={translations}
        />
      }
      {step.includes('motivation') &&
        <MotivationPage
          history={history}
          dispatch={dispatch}
          step={step}
          sections={sections}
          previousStep={getPreviousStep(step)}
          nextStep={getNextStep(step)}
          centreId={centreId}
          profileId={profileId}
          pageOrder={getPageOrder(step)}
          setSubmitStep={setSubmitStep}
          setSubmitFlag={setSubmitFlag}
          translations={translations}
        />
      }
      {step === 'interest' &&
        <InterestPage
          history={history}
          dispatch={dispatch}
          step={step}
          sections={sections}
          previousStep={getPreviousStep(step)}
          nextStep={getNextStep(step)}
          centreId={centreId}
          profileId={profileId}
          pageOrder={getPageOrder(step)}
          setSubmitStep={setSubmitStep}
          setSubmitFlag={setSubmitFlag}
          translations={translations}
        />
      }
      {step === 'skill' &&
        <LanguagePage
          history={history}
          dispatch={dispatch}
          step={step}
          sections={sections}
          previousStep={getPreviousStep(step)}
          nextStep={getNextStep(step)}
          centreId={centreId}
          profileId={profileId}
          pageOrder={getPageOrder(step)}
          setSubmitStep={setSubmitStep}
          setSubmitFlag={setSubmitFlag}
          translations={translations}
        />
      }
      {
        step === 'subskill' &&
        <SubskillPage
          history={history}
          dispatch={dispatch}
          step={step}
          sections={sections}
          previousStep={getPreviousStep(step)}
          nextStep={getNextStep(step)}
          centreId={centreId}
          profileId={profileId}
          pageOrder={getPageOrder(step)}
          setSubmitStep={setSubmitStep}
          setSubmitFlag={setSubmitFlag}
          translations={translations}
        />
      }
      {step === 'learning' &&
        <LearningPage
          history={history}
          dispatch={dispatch}
          step={step}
          sections={sections}
          previousStep={getPreviousStep(step)}
          nextStep={getNextStep(step)}
          centreId={centreId}
          profileId={profileId}
          pageOrder={getPageOrder(step)}
          setSubmitStep={setSubmitStep}
          setSubmitFlag={setSubmitFlag}
          translations={translations}
        />
      }
      {step === 'local_operational' &&
        <LocalPage
          history={history}
          dispatch={dispatch}
          step={step}
          sections={sections}
          previousStep={getPreviousStep(step)}
          nextStep={getNextStep(step)}
          centreId={centreId}
          profileId={profileId}
          pageOrder={getPageOrder(step)}
          setSubmitStep={setSubmitStep}
          setSubmitFlag={setSubmitFlag}
          components={components}
          translations={translations}
        />
      }
      {step === 'final' &&
        <FinalPage
          history={history}
          dispatch={dispatch}
          step={step}
          previousStep={getPreviousStep(step)}
          nextStep={getNextStep(step)}
          centreId={centreId}
          profileId={profileId}
          pageOrder={getPageOrder(step)}
          setSubmitStep={setSubmitStep}
          setSubmitFlag={setSubmitFlag}
          setFinalText={setFinalText}
          lastText={lastText}
          commentFinal={commentFinal}
          translations={translations}
        />
      }
      {step === 'complete' &&
        <CompletePage
          history={history}
          dispatch={dispatch}
          step={step}
          centreId={centreId}
          profileId={profileId}
          previousStep={getPreviousStep(step)}
          translations={translations}
        />
      }
      {step === 'exam' &&
        <ExamPage
          history={history}
          dispatch={dispatch}
          step={step}
          sections={sections}
          previousStep={getPreviousStep(step)}
          nextStep={getNextStep(step)}
          centreId={centreId}
          profileId={profileId}
          pageOrder={getPageOrder(step)}
          setSubmitStep={setSubmitStep}
          setSubmitFlag={setSubmitFlag}
          translations={translations}
        />
      }
    </>
  )
}

export default MainNavigator;