import { ActionTypes } from '../types';
import ContactListApi from '../../services/contactListApi';

export const getContactList = () => (dispatch) => {
  return ContactListApi.getContactList().then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_CONTACT_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_CONTACT_FAIL,
        payload: 'Get contact fail!'
      })
    }
  })
}