import SvgIcon from '@material-ui/core/SvgIcon';

export default function C2(props) {
  return (
    <SvgIcon viewBox="0 0 20 20" style={{fill: 'none'}} {...props}>
      <path d="M15.8333 17.834L9.99999 13.6673L4.16666 17.834V4.50065C4.16666 4.05862 4.34225 3.6347 4.65481 3.32214C4.96737 3.00958 5.3913 2.83398 5.83332 2.83398H14.1667C14.6087 2.83398 15.0326 3.00958 15.3452 3.32214C15.6577 3.6347 15.8333 4.05862 15.8333 4.50065V17.834Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.41279 9.15771L10.3938 9.46875C10.2434 10.0156 9.99271 10.4224 9.6418 10.689C9.29316 10.9533 8.84997 11.0854 8.31221 11.0854C7.64684 11.0854 7.09997 10.8587 6.67158 10.4053C6.2432 9.94954 6.029 9.32747 6.029 8.53906C6.029 7.70508 6.24434 7.05794 6.675 6.59766C7.10566 6.13509 7.67191 5.90381 8.37373 5.90381C8.98669 5.90381 9.48457 6.08496 9.86738 6.44727C10.0952 6.66146 10.2661 6.96908 10.3801 7.37012L9.37861 7.60938C9.31937 7.34961 9.19518 7.14453 9.00605 6.99414C8.81921 6.84375 8.59134 6.76855 8.32246 6.76855C7.95104 6.76855 7.64912 6.90186 7.4167 7.16846C7.18656 7.43506 7.07148 7.86686 7.07148 8.46387C7.07148 9.09733 7.18542 9.5485 7.41328 9.81738C7.64115 10.0863 7.93737 10.2207 8.30195 10.2207C8.57083 10.2207 8.80212 10.1353 8.9958 9.96436C9.18949 9.79346 9.32848 9.52458 9.41279 9.15771ZM13.9471 10.1079V11H10.5804C10.6168 10.6628 10.7262 10.3438 10.9085 10.043C11.0908 9.73991 11.4508 9.33887 11.9886 8.83984C12.4215 8.43652 12.687 8.16309 12.785 8.01953C12.9171 7.82129 12.9832 7.62533 12.9832 7.43164C12.9832 7.21745 12.9251 7.05339 12.8089 6.93945C12.695 6.82324 12.5366 6.76514 12.3338 6.76514C12.1333 6.76514 11.9738 6.82552 11.8553 6.94629C11.7368 7.06706 11.6684 7.26758 11.6502 7.54785L10.6932 7.45215C10.7501 6.9235 10.929 6.54411 11.2298 6.31396C11.5306 6.08382 11.9065 5.96875 12.3577 5.96875C12.8522 5.96875 13.2407 6.10205 13.5232 6.36865C13.8058 6.63525 13.9471 6.9668 13.9471 7.36328C13.9471 7.58887 13.9061 7.8042 13.824 8.00928C13.7443 8.21208 13.6167 8.42513 13.4412 8.64844C13.325 8.79655 13.1154 9.0096 12.8123 9.2876C12.5092 9.56559 12.3167 9.75016 12.2347 9.84131C12.1549 9.93245 12.09 10.0213 12.0398 10.1079H13.9471Z" fill="currentColor"/>
    </SvgIcon>
  );
}
