import PrivatePage from '../../../components/PrivatePage';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from "query-string";
import moment from 'moment';
import useTeacherPage from '../../../hooks/TeacherPage/useTeacherPage';
import { useEffect, useMemo, useState } from 'react';
import ItemTeacher from './ItemTeacher';
import TeacherClasses from './TeacherClasses';
import { groupBy } from '../../../components/Utilities';
import CircularProgress from '../Common/CircularProgress';

const TeacherDetailPage = (props) => {
  const history = useHistory();
  const params = queryString.parse(history.location.search);
  const { id } = params;
  const dateOfWeek = useSelector(state => state.calendar.filter.dateOfWeek);
  const dateOfWeekActiveIndex = useSelector(state => state.calendar.dateOfWeekActiveIndex);

  let dateFrom, dateTo;

  if (dateOfWeek.length) {
    dateFrom = dateTo = moment(dateOfWeek[dateOfWeekActiveIndex]).format("YYYY-MM-DD");
  }

  const {
    allTeacherList,
    isLoading,
  } = useTeacherPage(0, 0, true, id, dateFrom, dateTo);

  const currTeacherId = id;
  const [currentTeacher, setCurrentTeacher] = useState(null);

  useEffect(() => {
    const teacher = allTeacherList.find(teacher => teacher.id === +currTeacherId);
    if (teacher) {
      const lessons =  teacher.future_lessons.sort((a, b) => {
        return new Date(a.dateFull.split(",")[0]).getTime() - new Date(b.dateFull.split(",")[0]).getTime();
      })

      setCurrentTeacher(teacher);
    }
    // eslint-disable-next-line
  }, [allTeacherList, currTeacherId]);

  const lessons = useMemo(
    () => {
      if (!currentTeacher) {
        return [];
      }

      const futureLessons = currentTeacher.future_lessons.sort((a, b) => {
        return new Date(a.dateFull.split(",")[0]).getTime() - new Date(b.dateFull.split(",")[0]).getTime();
      })
      
     return groupBy(futureLessons, "dateInDayMonth")
    }, [currentTeacher]
  )

  const onBack = () => {
    history.goBack();
  }

  return (
    <PrivatePage 
      className="page-teacher-detail"
      isShowMenuBack={true}
      title="Lessons"
      backTitle="Back"
      isShowMainNavigation={false}
      onBack={onBack}
    >
      {isLoading && <CircularProgress />}
      {!isLoading && <>
        { currentTeacher && 
          <div className="list-view__list-items">
            <ItemTeacher
              showFullDes={true}
              isTeacherView={true}
              item={currentTeacher}
            />
            { lessons &&
              Object.keys(lessons).map((dateTitle => {
                return (
                  <TeacherClasses
                    key={dateTitle}
                    title={lessons[dateTitle][0]['dateFull'].split(',')[0]}
                    classes={lessons[dateTitle]}
                  />
                )
              }))
            }
          </div>
        }
      </>}
    </PrivatePage>
  )
}

export default TeacherDetailPage;