import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PrivatePage from '../../../components/PrivatePage';
import OnOffSwitch from '../Common/OnOffSwitch';
import AdvancedSelects from '../Common/Select';
import useTeacherPage from '../../../hooks/TeacherPage/useTeacherPage';
import { Button } from '@material-ui/core';
import CalendarViewItem from '../Calendar/CalendarListView/CalendarViewItem';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import useModalBooking from '../../../hooks/ModalBooking/useModalBooking';
import CircularProgress from '../Common/CircularProgress';

import Modal from '../Dialog/Modal';
import BookingPage from '../Booking';
import ClassBookedPage from '../Booking/ClassBooked';
import CancelBooking from '../Booking/Cancel';

import { setOpenBookingWindow } from '../../../redux/actions/Booking/BookingAction';

import '../../../../sass/private/Teacher/TeacherPage.scss';
import arrowIcon from '../../../../assets/image/arrow.png';
import { getCurrentUser } from '../../../../utils/currentUser';
import ModuleBooking from '../Module/ModuleBooking';

const TeacherPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [dataReloadToken, setDataReloadToken] = useState(0);
  const [dataReloadTokenOthers, setDataReloadTokenOthers] = useState(0);

  const [autoDataReload, setAutoDataReload] = useState(0);

  useEffect(() => {
    setAutoDataReload(setInterval(() => {
      setDataReloadTokenOthers(getTimestamp());
    }, 60000));
  }, []);

  useEffect(() => {
    return () => { // separate unmount of autoDataReload so as to get the correct JS scope
      clearAutoDataReload();
    };
  }, [autoDataReload]);

  const {
    statusOnOff,
    dataCentre,
    teacherList,
    isLoading,
    teacherActive,
    idSelected,
    onChangeToogle,
    onChangeOption,
    onShowMoreLesson,
    onShowLessLesson,
    onActiveTeacher,
  } = useTeacherPage(dataReloadToken, dataReloadTokenOthers);

  const { isTablet } = useCheckMobileTablet();

  const {
    open,
    handleCloseDialog,
    isShowClassBookedPage,
    isShowCancelBooking,
    onCloseBooking
  } = useModalBooking();

  const onRedirectBookingWidow = (lessonTimeId) => {
    history.push(`/teachers/${lessonTimeId}`);
    dispatch(setOpenBookingWindow(true));
  };

  const clearAutoDataReload = () => {
    clearInterval(autoDataReload);
  };

  const reloadData = () => {
    setDataReloadToken(getTimestamp());
  };

  const getTimestamp = () => {
    return new Date().getTime();
  };
  const [isOpenModuleBookingDetail, setIsOpenModuleBookingDetail] = useState(false);
  const [moduleId, setModuleId] = useState(null);
  const [isOpenProgressDetail, setIsOpenProgressDetail] = useState();
  const [progressUrl, setProgressUrl] = useState('');

  const handleOpenModuleDialog = (id) => {
    setModuleId(id);
    setIsOpenModuleBookingDetail(true);
  }
  const handleCloseModuleDialog = () => {
    setIsOpenModuleBookingDetail(false);
  }
  const handleOpenProgressModal = (url) => {
    setProgressUrl(url);
    setIsOpenProgressDetail(true);
  };
  const handleCloseProgressModal = () => {
    setIsOpenProgressDetail(false); 
  }
  return (
    <PrivatePage className="TeacherPage">
      <div className="teacher-header">
      <div className="line-blue"></div>         
        <h2 className="teacher-title">Teachers</h2>
        <div className="teacher-filter">
          <div className="address">
            <div className="calendar-text">Centre</div>
            <AdvancedSelects
              data={dataCentre}
              label=""
              value={idSelected || 0}
              onChangeOption={onChangeOption}
              nameSelect="centre"
              disabled={isLoading}
            />
          </div>
          <div className="teacher-filter__toggle">
            <p>Only show teachers who have taught me</p>
            <OnOffSwitch status={statusOnOff} onChangeToogle={onChangeToogle} disabled={isLoading} />
          </div>
        </div>
      </div>
      {isLoading ?
        <CircularProgress />:
        <div className={`teacher-list animate-bottom
          ${teacherList?.length > 0 && (teacherActive === teacherList[0]?.id || teacherActive === teacherList[teacherList?.length -1]?.id) ? 'active_first_last' : ''}`}
        >
          {teacherList?.map(item => {
            return <div className={`teacher-item ${item.id === teacherActive ? 'active' : ''}`} key={item.id} >
              <div className="teacher-image">
                <img src={item.avatar} alt="Teacher profile"/>
              </div>
              {isTablet && <div className="teacher-name-mobile" onClick={() => isTablet ? onActiveTeacher(item.id) : {}}>
                <p>{item.name + ' ' + item.surname}</p>
                <img src={arrowIcon} alt="Teacher profile"/>
              </div>}
              <div className="teacher-content">
                {!isTablet && 
                  <div className="info">
                    <div className="info-name">{item.name + ' ' + item.surname}</div>
                    <div className="column-grey"></div>
                    <div className="info-text">{item.email}</div>
                  </div>
                }
                <div className="description info-text">{item.description}</div>
                {item.future_lessons?.length > 0 && <>
                  <div className="lesson-text">Next available classes</div>
                  <div className="lesson-list">
                    {item.future_lessons?.map((item, index) =>
                      <CalendarViewItem
                        key={index}
                        title={item.productType}
                        desc={item.title}
                        time={item.infoLines?.title}
                        active={item.booked}
                        borderColor={item.colorHex}
                        isPastEndTime={item.isPastEndTime || false}
                        lessonTimeId={item.lessonTimeId}
                        onRedirectBookingWidow={(lessonTimeId) => {onRedirectBookingWidow(lessonTimeId)}}
                        teacher={item.infoLines?.centreTeacherName}
                        friendIcon={item.iconSet?.friend?.showIcon}
                        levelsIcon={item.otherLevel}
                        wishListIcon={item.iconSet?.wishList?.showIcon}
                        workPlaceIcon={item.iconSet?.workPlace?.showIcon}
                        showJoinNow={item.actionArea?.showButtonJoinClass}
                        joinLink={item.actionArea?.linkJoinClass}
                        joinNowText={item.actionArea?.textJoinClassForLessonBlock}
                        ticked={item.ticked}
                        isLocked={item.isLocked}
                        availabilityText={item.infoLines?.availability}
                        showAlertIcon={item.infoLines?.fullWarningIcon}
                        module={item.module}
                        theme={item.theme_object}
                      />
                    )}
                  </div>
                </>}
              </div>
              {(!item.is_collapsed && item?.countTotal > 2 && item?.countTotal > item?.count ) &&
                <Button
                  variant="contained"
                  className="btn btn-showmore"
                  onClick={() => onShowMoreLesson(item.id)}
                >Show more</Button>
              }
              {
                item.is_collapsed &&
                <Button
                  variant="contained"
                  className="btn btn-showless"
                  onClick={() => onShowLessLesson(item.id)}
                >Show Less</Button>
              }
            </div>
          })}
        </div>}
        <Modal
          open={open}
          handleCloseDialog={handleCloseDialog}
          className="booking-modal"
        >
          {(!isShowClassBookedPage && !isShowCancelBooking) &&
            <BookingPage onCloseBooking={onCloseBooking} reloadData={reloadData} handleOpenModuleDialog={handleOpenModuleDialog} />
            
          }
          {(isShowClassBookedPage && !isShowCancelBooking) &&
            <ClassBookedPage onRedirectPage={onCloseBooking} reloadData={reloadData} />
          }
          {(isShowCancelBooking && !isShowClassBookedPage) &&
            <CancelBooking onCloseBooking={onCloseBooking} reloadData={reloadData} />
          }
        </Modal>
        <Modal
          open={isOpenModuleBookingDetail}
          handleCloseDialog={handleCloseModuleDialog}
          className={`module-booking-modal`}
        >
          <Button variant="contained" onClick={handleCloseModuleDialog} className="btn btn-return btn-top">Close</Button>
          <div className="external-box">
            <ModuleBooking
              id={moduleId}
              handleOpenProgressModal={handleOpenProgressModal}
              handleCloseModuleDialog={handleCloseModuleDialog}
              reloadData={reloadData}
            />
          </div>
        </Modal>
        <Modal
          open={isOpenProgressDetail}
          handleCloseDialog={handleCloseProgressModal}
        >
          <Button variant="contained" onClick={handleCloseProgressModal} className="btn btn-return btn-top">Close</Button>
          <div className="external-box">
            <iframe src={progressUrl} width="100%" height="100%" title="Progress"/>
          </div>
        </Modal>
    </PrivatePage>
  )
}

export default TeacherPage;