import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel,Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import '../../../../sass/private/AssessmentPage/SelectOption.scss';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';

const SelectOption = (props) => {
  const { data, onChangeOption, value, defaultValue } = props;
  const { isTablet } = useCheckMobileTablet();
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: isTablet ? '100%' : '50%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChangeOption(name, value);
  };

  return (
    <FormControl variant="outlined" className={`${classes.formControl} select-option`}>
      {/* <InputLabel htmlFor="outlined-age-native-simple" className='select-option__label'>{data.label}</InputLabel> */}
      <label className="select-option__label">{data.label}</label>
      <Select
        native
        defaultValue={defaultValue}
        value={value || 0}
        onChange={handleChange}
        label="Age"
        inputProps={{
          name: data.label,
          id: 'outlined-age-native-simple',
        }}
      >
        <option value={true}>{data.placeholder}</option>
        {data.textAll ? <option value={0}>{data.textAll}</option> : ''}
        {data.options && data.options.map((item, index) => {
          return <option key={index} value={item.id === null ? 0 : item.id}>{item.name}</option>
        })}
      </Select>
    </FormControl>
  )
}

SelectOption.propTypes = {
  data: PropTypes.object,
}

SelectOption.defaultProps = {
  data: {
    options: [],
    label: '',
    placeholder: ''
  }
}

export default SelectOption;