import { ActionTypes } from '../../actions/types';

const initialState = {
  feedbackScreenOne: {},
  feedbackScreenTwo: {
    lesson_time_id: 0,
    questions: []
  },
  message: ""
}

export default function feedbackReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.ADD_QUESTION_SCREEN_ONE_FEEDBACK:
      return {
        ...state,
        feedbackScreenOne: payload
      }
    case ActionTypes.ADD_QUESTION_SCREEN_TWO_ID:
      return {
        ...state,
        feedbackScreenTwo:{
          ...state.feedbackScreenTwo,
          lesson_time_id: payload
        }
      }
    case ActionTypes.ADD_QUESTION_SCREEN_TWO_FEEDBACK:
      return {
        ...state,
        feedbackScreenTwo: {
          ...state.feedbackScreenTwo,
          questions: [
            ...state.feedbackScreenTwo.questions,
            payload
          ]
        }
      }
    case ActionTypes.FEEDBACK_SUCCESS:
      return {
        ...state,
        message: payload
      }
    case ActionTypes.FEEDBACK_FAILED:
      return {
        ...state,
        message: payload
      }
    case ActionTypes.RESET_FEEDBACK:
      return initialState;
    default:
      return state;
  }
}