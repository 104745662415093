import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../../utils/currentUser';

import { landingAction, newsFeedAction, newsFeedLoginAction, updateStatusNewsFeedAction } from '../../redux/actions/HomePage/HomePageAction';
import { setOpenBookingWindow } from '../../redux/actions/Booking/BookingAction';
import { getNewsFeedAction } from "../../redux/actions/NewsFeed/NewsFeedAction";


const useHomePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const landingData = useSelector(state => state.home.landing);
  const newsData = useSelector(state => state.home.newsData);
  const newsFeedData = useSelector(state => state.home.newsFeedData);
  const newsFeedLogin = useSelector(state => state.home.newsFeedLogin);

  const [isLoadingLandingData, setIsLoadingLandingData] = useState(true);
  const [isLoadingNewsFeed, setIsLoadingNewsFeed] = useState(false);
  const [isLoadingNewsFeedLogin, setIsLoadingNewsFeedLogin] = useState(true);

  const [dataReloadTokenSelf, setDataReloadTokenSelf] = useState(0);
  const [dataReloadTokenOthers, setDataReloadTokenOthers] = useState(0);

  const [autoDataReload, setAutoDataReload] = useState(0);
  
  const [countDownStartIn, setCountDownStartIn] = useState('');

  const showedLoginNewsFeed = localStorage.getItem("showedLoginNewsFeed") || "false";

  const enableDataReloadTokenOthers = false;
  
  const runintervalIDRef = useRef(false);

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.responseUserData) {
      getLandingData();

      dispatch(newsFeedAction(currentUser.responseUserData.id));

      if (showedLoginNewsFeed === "false") {
        dispatch(newsFeedLoginAction()).then((response) => {
          if (response?.success) {
            setIsLoadingNewsFeedLogin(false);
            localStorage.setItem("showedLoginNewsFeed", "true");
          }
        });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => { // separate unmount of autoDataReload so as to get the correct JS scope
      clearAutoDataReload();
    };
  }, [autoDataReload]);

  useEffect(() => {
    if (dataReloadTokenSelf) { // run only if > 0, i.e. default state
      getLandingData();
    }
  }, [dataReloadTokenSelf]);

  const clearAutoDataReload = () => {
    clearInterval(autoDataReload);
  };

  const getLandingData = () => {
    clearAutoDataReload();
    actualGetLandingData(true, true);
    actualGetLandingData();

    // setAutoDataReload(setInterval(() => {
    //   actualGetLandingData(false, true);

    //   if (enableDataReloadTokenOthers) {
    //     setDataReloadTokenOthers(getTimestamp());
    //   }
    // }, 60000));
  }

  const actualGetLandingData = (showLoading = true, nextClassOnly = false) => {
    if (showLoading) {
      setIsLoadingLandingData(true);
    }

    dispatch(landingAction(nextClassOnly)).then((response) => {
      if (showLoading && response?.success) {
        setIsLoadingLandingData(false);
      }
    });
  };

  const onRedirectBookingWidow = (id) => {
    history.push(`/home/${id}`);
    dispatch(setOpenBookingWindow(true));
  }

  const openNewsFeed = (id)=> {
    setIsLoadingNewsFeed(true);
    dispatch(getNewsFeedAction(id)).then((response) => {
      if (response?.success) {
        setIsLoadingNewsFeed(false);
      }
    });
  }

  const onStatusNewsFeed = (data)=> {
    dispatch(updateStatusNewsFeedAction(data))
  }

  const reloadData = () => {
    setDataReloadTokenSelf(getTimestamp());
  };

  const getTimestamp = () => {
    return new Date().getTime();
  };
  
  useEffect(() => {
    let nextClass = landingData.nextClass;
    if(nextClass) {
      let showStartIn = nextClass.showStartIn;
      if(showStartIn && nextClass.type && nextClass.remainingSeconds && !runintervalIDRef.current) {
        let isRunCountDown;
        let countDownDate = new Date();
        countDownDate.setSeconds(countDownDate.getSeconds() + nextClass.remainingSeconds);
        if(countDownStartIn == '') {
          setCountDownStartIn(nextClass.startIn);                    
        }
        
        switch(nextClass.type) {
          case 'hour':  
            isRunCountDown = true;        
            break;
          case 'minute':
            isRunCountDown = true;
            break;
          case 'day':
            isRunCountDown = false;
            break;
          default:
            isRunCountDown = false;
        }
        
        if(isRunCountDown) {
          let countDownInterval = setInterval(function() {
            // Get current date and time
            let now = new Date().getTime();
            // Time to the date
            let timeToDate = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(timeToDate / (1000 * 60 * 60 * 24));
            let hours = Math.floor((timeToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((timeToDate % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((timeToDate % (1000 * 60)) / 1000);
            
            if(hours > 0) {
              setCountDownStartIn(`${hours} hour${hours > 1 ? 's' : ''}`);
            } else if(minutes) {
              setCountDownStartIn(`${minutes} minutes`);
            }
            if ((nextClass.lessonType == 'F2F class' && timeToDate <= 0) || (nextClass.lessonType != 'F2F class' && timeToDate < 900000)) {
              runintervalIDRef.current = true;
              //less than 15 minutes then clear interval and call api to show join now button
              clearInterval(countDownInterval);
              actualGetLandingData(false, true);
            }
          }, 60000);
        }
      }
    }
  }, [landingData])

  return {
    landingData,
    newsData,
    newsFeedData,
    newsFeedLogin: isLoadingNewsFeedLogin? {}: newsFeedLogin,
    onRedirectBookingWidow,
    openNewsFeed,
    onStatusNewsFeed,
    isLoadingLandingData,
    reloadData,
    isLoadingNewsFeed,
    dataReloadTokenOthers,
    countDownStartIn,
  }
}

export default useHomePage;