import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { passClassesCalendarAction, resetPassClassAction } from '../../../redux/actions/Calendar/CalendarAction';
import ItemClassesMobile from './ItemClassesMobile';
import { useHistory } from 'react-router-dom';

import CircularProgress from '../Common/CircularProgress';
import '../../../../sass/private/Home/PastClassesMobile.scss'


export const PastClassesMobile = (props) => {
  const { dataReloadTokenOthers } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const passClass = useSelector(state => state.calendar.passClass);
  const passClassCurrentPage = useSelector(state => state.calendar.passClassCurrentPage);
  const passClassHasMore = useSelector(state => state.calendar.passClassHasMore);

  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isMounted && dataReloadTokenOthers) {
      getPassClasses(false);
    }
  }, [dataReloadTokenOthers])

  useEffect(() => {
    getPassClasses();
    setIsMounted(true);

    return () => {
      resetPassClass();
    };
  }, []);

  const resetPassClass = () => {
    dispatch(resetPassClassAction());
  };

  const getPassClasses = (showLoading = true) => {
    setIsLoading(true);

    dispatch(passClassesCalendarAction(passClassCurrentPage + 1)).then((response) => {
      if (showLoading && response?.success) {
        setIsLoading(false);
      }
    });
  };

  const onClickClasses = (params) => {
    history.push(`/mycourse/${params}`)
  }

    return (
    <>
      <div className="past-classes">
          <div className="past-classes__todo">
            {
              isLoading || passClass.length > 0 ? "" :
              <div className="body-text">
                You don't have any post-class activities
              </div>
            }
            <div>
              {
                passClass.map((item, index) =>
                <ItemClassesMobile 
                  key={index} 
                  title={item.title}
                  time={item.infoLines?.title}
                  teacher={item?.infoLines?.centreTeacherName}
                  colorHex={item.colorHex}
                  defaultLessonParam={item.defaultLessonParam}
                  onClickClasses={onClickClasses}
                  actionAudio={item.actionArea?.showIconAudio}
                  showIconUsers={item.iconSet?.friend?.showIcon}
                  showIconStar={item.iconSet?.wishList?.showIcon}
                  showIconLevel={item.otherLevel}
                  showIconWorkplace={item.iconSet?.workPlace?.showIcon}
                  module={item.module}
                  theme={item.theme_object}
                />)
              }
            </div>
            {!isLoading && passClassHasMore &&
              <div className="btn-show-more-container">
                <Button variant="contained" className="btn btn-show-more" onClick={() => getPassClasses()}>Show more</Button>
              </div>
            }
          </div>
      </div>
      {isLoading && <CircularProgress />}
    </>
    )
  }
  
export default PastClassesMobile