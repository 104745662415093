import useCancel from '../../../hooks/BookingPage/useCancel';
import ProfileWraper from '../Profile/ProfileWraper';
import CircularProgress from '../Common/CircularProgress';
import { Button } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

import '../../../../sass/private/Booking/Cancel.scss';

import iconMissing from '../../../../assets/svg/alert-triangle.svg';

const Cancel = (props) => {
  const { onCloseBooking, reloadData } = props;
  const { onNotCancel, onCancelBooking, isCanceled, checkCancel, isLoadingCheck, isLoadingCancel } = useCancel(reloadData);
  return (
        <ProfileWraper 
          onClose={onCloseBooking} 
          title="Lesson details" 
          isHideBtnBottom 
          isShowClose={!isCanceled}
          isShowBack={isCanceled}
          className="booking-profile">
        {isCanceled &&
          <div className="cancel-booking">
            <h2>Your booking has been cancelled</h2>
            <div className="btn-block">
              <Button variant="contained" className="btn btn-return" onClick={onCloseBooking}>Close</Button>
            </div>
          </div>
        }
        {!isCanceled && <>
          {isLoadingCheck && <CircularProgress />}
          {!isLoadingCheck && <div className="cancel-booking">
            <h2>Are you sure you want to cancel this booking?</h2>
            {checkCancel.showWarning &&
              <div className="cancel-missing">
                <img src={iconMissing} alt="Warning"/>
                <div>
                  <p>{ReactHtmlParser(checkCancel.warningMessage)}</p>
                </div>
              </div>
            }
            <div className="btn-block">
              <Button variant="contained" className="btn btn-return" onClick={onNotCancel}>No, I don't want to cancel</Button>
              <Button variant="contained" className="btn btn-cancel" onClick={onCancelBooking} disabled={isLoadingCancel}>
                {isLoadingCancel && <CircularProgress />}
                {!isLoadingCancel && "Cancel Booking"}
              </Button>
            </div>
          </div>}
        </>}
        </ProfileWraper>
  )
}

export default Cancel;