import { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Modal from '../Dialog/Modal';
import FbQuestion1 from '../Feedback/FbQuestion1';
import FbQuestion2 from '../Feedback/FbQuestion2';
import Complete from '../Feedback/Complete';
import CloseConfirmation from '../Feedback/CloseConfirmation';
import { resetFeedback } from '../../../redux/actions/Feedback/FeedbackAction';
import { dismissItem } from '../../../redux/actions/Notification/NotificationAction';
import { useDispatch } from 'react-redux';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import { getRoute, getTarget } from '../../../components/Utilities';
import { setOpenBookingWindow } from '../../../redux/actions/Booking/BookingAction';
import { useHistory } from 'react-router-dom';
import useRedirectFeedback from '../../../hooks/RedirectFeedbackHook/useRedirectFeedback';
import useQuery from '../../../../utils/useQuery';

const NotificationItem = (props) => {
  const dispatch = useDispatch();
  const { isTablet } = useCheckMobileTablet();
  const { notificationData, setOpenSetting, setActivePage, setOpenCredit } = props;
  /* FEEDBACK */
  const {
    openFeedbackTwo,
    openComplete,
    isRated,
    openCloseConfirmation,
    openFeedbackOne,
    currentId,
    setOpenFeedbackOne,
    setIsRated,
    setCurrentId,
    handleCloseFeedbackOne,
    handleCloseFeedbackTwo,
    handleCloseComplete,
    goToFeedBackTwo,
    goToComplete,
    onCloseConfirm,
    onContinue
  } = useRedirectFeedback();
  const queryParam = useQuery();
  const onClickOpenOne = (id) => {
    setOpenFeedbackOne(true);
    setCurrentId(id);
  }
  /*********/
  
  const onDismiss = (index, id) => {
    dispatch(dismissItem(index, id));
  }
  
  useEffect(() => {
    if (queryParam.open === getTarget("FEEDBACK") && queryParam.feedbackId.length != 0) {  
      setOpenFeedbackOne(true);
      setCurrentId(queryParam.feedbackId);
      history.replace(history.location.pathname);
    }    
  }, [])

  const history = useHistory();

  const onClickOthers = (page, params, actionable) => {
    if (!actionable) return;
    switch(page) {
      case 'friends':
        if (isTablet) {
          history.push(getRoute('SETTING') + '?target=' + getTarget('MYFRIENDS'));
          return;
        }
        setOpenSetting(true);
        setActivePage(2);
        return;
      case 'booking-window':
        history.push(`/home/${params}`);
        dispatch(setOpenBookingWindow(true));
        return;
      case 'account':
        if (isTablet) {
          history.push(getRoute('MYACCOUNT'));
          return;
        }
        setOpenCredit(true);
        return;
      case 'assessment':
        history.push(getRoute('ASSESSMENT') +`/${params}`);
        return;
      default:
        return;
    }
  }

  return (
    <>
    {notificationData.notifications?.map((item, index) => {
    return (
      <>
      {item.data?.page === 'Feedback' ?
        <div className="notification--wrapper feedback" key={index} onClick={() => onClickOpenOne(item.data.params)}>
          <h2>{item.title}</h2>
          {ReactHtmlParser(item.body)}
          <div className="time">{item.time}</div>
          <div
              className="dismiss"
              onClick={(e) => {e.stopPropagation();onDismiss(index, item.id);}}
              tabIndex={0}
              onKeyPress={(e) => {e.stopPropagation();onDismiss(index, item.id);}}
            ></div>
          {false && <a href={"#"}>More details</a>}
        </div>
        :
        <div 
          key={index}
          className={"notification--wrapper" + (item.data?.is_actionable ? " clickable" : "")} 
          onClick={() => onClickOthers(item.data?.page, item.data?.params, item.data?.is_actionable)}>
            <h2>{item.title}</h2>
            {ReactHtmlParser(item.body)}
            <div className="time">{item.time}</div>
            <div
              className="dismiss"
              onClick={(e) => {e.stopPropagation();onDismiss(index, item.id);}}
              tabIndex={0}
              onKeyPress={(e) => {e.stopPropagation();onDismiss(index, item.id);}}
            ></div>
            {false && <a href={"#"}>More details</a>}
        </div>
      }
      </>
    )})}
    <Modal
      open={openFeedbackOne}
      handleCloseDialog={handleCloseFeedbackOne}
      useThemeContact={false}
      className="modal-improve"
    >
      <FbQuestion1 onClose={handleCloseFeedbackOne} isRated={isRated} setIsRated={setIsRated} lessonTimeId={currentId} onNext={goToFeedBackTwo}/>
    </Modal>
    <Modal
      open={openFeedbackTwo}
      handleCloseDialog={handleCloseFeedbackTwo}
      useThemeContact={false}
      className="modal-improve"
    >
      <FbQuestion2 onClose={handleCloseFeedbackTwo} lessonTimeId={currentId} onComplete={goToComplete}/>
    </Modal>
    <Modal
      open={openComplete}
      handleCloseDialog={handleCloseComplete}
      useThemeContact={false}
      className="modal-improve"
    >
      <Complete onClose={handleCloseComplete}/>
    </Modal>
    <Modal
      open={openCloseConfirmation}
      handleCloseDialog={onCloseConfirm}  
      useThemeContact={false}
      className="modal-confirm modal-improve"
    >
      <CloseConfirmation onClose={onCloseConfirm} onContinue={onContinue}/>
    </Modal>
    </>
  )
}

export default NotificationItem;
