import { ActionTypes } from '../types';
import ProfileApi from '../../services/profileApi';

export const getProfileAction = (id) => (dispatch) => {
  return ProfileApi.getProfile(id).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_PROFILE_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_PROFILE_FAIL,
        payload: 'Get profile fail!'
      })
    }
  })
}

export const updateAvatar = (imagePreviewUrl, id) => (dispatch) => {
  return ProfileApi.updateAvatar(imagePreviewUrl, id).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.UPDATE_AVATAR_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.UPDATE_AVATAR_FAIL,
        payload: 'Get profile fail!'
      })
    }
    return response;
  })
}

export const deleteAvatar = (id) => (dispatch) => {
  return ProfileApi.deleteAvatar(id).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.DELETE_AVATAR_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.DELETE_AVATAR_FAIL,
        payload: 'Get profile fail!'
      })
    }
    return response;
  })
}
