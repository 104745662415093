import React from "react";

import ProgressBarV2 from "../Common/ProgressBarV2";

import "../../../../sass/private/Home/ModuleWidgetModular.scss";
import KeyIcons from "../../../../assets/image/key-lesson-small.png";
import Slider from "react-slick";

export default class ModuleProgressModular extends React.Component {
  render() {
    const { data, isLockedAll, isMobile, productId } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      variableWidth: true,
    };

    const newData = data.filter(item => item.productTypeId === productId);
    return (
      <>
        {Array.isArray(newData) &&
          newData.map((product) => (
            <div
              key={product.productTypeId}
              className="modular-product-progress"
            >
              <h4 className="block-title">
                  Lesson target for {product.productTypeName}
              </h4>
              {!isMobile && (
                <>
                  {/* <div className="product-name">{product.productTypeName || ""} lesson targett</div> */}
                  
                  <ul className="product-theme-listing product-listing">
                    {Array.isArray(product.themes) &&
                      product.themes.map((theme, index) => {
                        const lessonAttended = theme.lessonAttended || 0;
                        const lessonTotal = theme.lessonTotal || 0;
                        const tooltipTitle =
                          "Module " +
                          (index + 1) +
                          ": " +
                          (theme.themeName || "");
                        const lessonLock = theme.lessonLock || 0;
                        const score = theme.themeProgressScore || 0;

                        return (
                          <li
                            key={theme.themeId}
                            className="product-theme-listing-item"
                          >
                            <ProgressBarV2
                              label={theme.themeName || tooltipTitle}
                              total={lessonTotal}
                              count={lessonAttended}
                              lock={lessonLock}
                              score={score}
                              isLockedAll={isLockedAll}
                              canAccess={theme.canAccess}
                            />
                          </li>
                        );
                      })}
                  </ul>
                  <div className="lesson-list-note">
                    <span className="icon">
                      <img src={KeyIcons} alt="icon" />
                    </span>
                    Contact your centre to gain access to locked modules.
                  </div>
                </>
              )}
              {isMobile && (
                <div className="slider-wrapper slider-wrapper-mb">
                  <ul className="product-theme-listing product-listing">
                    <Slider {...settings}>
                      {Array.isArray(product.themes) &&
                        product.themes.map((theme, index) => {
                          const lessonAttended = theme.lessonAttended || 0;
                          const lessonTotal = theme.lessonTotal || 0;
                          const tooltipTitle =
                            "Module " +
                            (index + 1) +
                            ": " +
                            (theme.themeName || "");
                          const lessonLock = theme.lessonLock || 0;
                          const score = theme.themeProgressScore || 0;

                          return (
                            <li
                              key={theme.themeId}
                              className="product-theme-listing-item slide-item"
                            >
                              <ProgressBarV2
                                label={theme.themeName || tooltipTitle}
                                total={lessonTotal}
                                count={lessonAttended}
                                lock={lessonLock}
                                score={score}
                                isLockedAll={isLockedAll}
                                canAccess={theme.canAccess}
                              />
                            </li>
                          );
                        })}
                    </Slider>
                  </ul>
                </div>
              )}
            </div>
          ))}
      </>
    );
  }
}
