import { ActionTypes } from '../types';
import CourseDetailsPageApi from '../../services/myCourseDetailsApi';

export const getCourseDetailsAction = (student_id, centre_id, course_id, course_type) => (dispatch) => {
  return CourseDetailsPageApi.getCourseDetails(student_id, centre_id, course_id, course_type).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_COURSE_DETAILS_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_COURSE_DETAILS_FAIL,
        payload: 'Get course details failed'
      })
    }
  })
}

export const addFavorite = (course_id) => (dispatch) => {
  return CourseDetailsPageApi.addFavorite(course_id).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.ADD_FAVORITE_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.ADD_FAVORITE_FAIL,
        payload: 'Add favorite failed'
      })
    }
  })
}

export const removeFavorite = (course_id) => (dispatch) => {
  return CourseDetailsPageApi.removeFavorite(course_id).then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.REMOVE_FAVORITE_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.REMOVE_FAVORITE_FAIL,
        payload: 'Remove favorite failed'
      })
    }
  })
}

export const updateHasListenLesson = (isChecked) => (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_HAS_LISTEN_LESSON,
    payload: isChecked
  })
}
