import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CoursePageApi from '../../redux/services/myCourseApi';
import { getSideBarAction, getCourseLevel } from '../../redux/actions/MyCoursePage/CoursePageAction'
import { getCurrentUser } from '../../../utils/currentUser';
import { useQueryString } from '../../../utils/useQueryString';

const useMyCourse = () => {
  const dispatch = useDispatch();
  const courseSideBar = useSelector(state => state.course.courseSideBar);
  //const courseContent = useSelector(state => state.course.courseContent);
  const courseLevelList = useSelector(state => state.course.courseLevel);

  const [isLoadingMenu, setIsLoadingMenu] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [courseContent, setCourseContent] = useState(null);

  const currentUserData = getCurrentUser()?.responseUserData;

  const [userId, setUserId] = useState(currentUserData?.id);
  const [centreId, setCentreId] = useQueryString("centreId", parseInt(currentUserData?.id_centre) || 0);
  const [courseLevelSelected, setCourseLevelSelected] = useQueryString("levelId", null)
  
  const [c1Id, setc1Id] = useQueryString("c1Id", null)
  const [c2Id, setc2Id] = useQueryString("c2Id", null)
  const [c3Id, setc3Id] = useQueryString("c3Id", null)
  const [c2UniqueId, setC2UniqueId] = useQueryString("c2UniqueId", null)
  const [c3Type, setc3Type] = useQueryString("c3Type", null)

  const onSideBarClick = useCallback((c1, c2, c3, type, c2UniqueId) => {
    setc1Id(c1)
    setc2Id(c2)
    setc3Id(c3)
    setc3Type(type)
    setC2UniqueId(c2UniqueId)

    if(!c1 && !c2 && !c3) {
      if(courseSideBar && courseSideBar?.length > 0) {
        const parentItem = courseSideBar[0]
        
        setc1Id(parentItem.name)
        
        if(parentItem && parentItem?.items?.length > 0) {
          const child1 = parentItem.items[0]
          setc2Id(child1.name)
          setC2UniqueId(child1.id)
  
          if(child1 && child1?.items?.length > 0) {
            setc3Id(child1.items[0].id)
            setc3Type(child1.items[0].type)
  
          } else {
            setc3Id(child1.id)
            setc3Type(child1.type)
          }
        }
      }
    }

    if(c1 && !c2 && !c3) {
      const parentItem = courseSideBar.find(item => item.name === c1)
      
      if(parentItem && parentItem?.items?.length > 0) {
        const child1 = parentItem.items[0]
        setc2Id(child1.name)
        setC2UniqueId(child1.id)

        if(child1 && child1?.items?.length > 0) {
          setc3Id(child1.items[0].id)
          setc3Type(child1.items[0].type)

        } else {
          setc3Id(child1.id)
          setc3Type(child1.type)
        }
      }
      else {
        setc2Id(parentItem.name)
        setc3Id(parentItem.id)
        setc3Type(parentItem.type)
      }
    }

    if(c1 && c2 && !c3) {
      const parentItem = courseSideBar.find(item => item.name === c1)
      if(parentItem && parentItem?.items?.length > 0) {
        
        const child1 = parentItem.items.find(item => (item.name === c2 && item.id === c2UniqueId))

        child1 && setc2Id(child1.name)
        
        if(child1 && child1?.items?.length > 0) {
          setc3Id(child1.items[0].id)
          setc3Type(child1.items[0].type)
        } else {
          setc3Id(child1.id)
          setc3Type(child1.type)
        }
      }
    }
    
  },[courseSideBar, setc1Id, setc2Id, setc3Id, setc3Type])

  const courseLevel = useMemo(() => courseLevelList.map(item => ({
    ...item,
    default: parseInt(courseLevelSelected)  === item.id
  })),[courseLevelList, courseLevelSelected])

  // userID
  useEffect(() => {
    userId &&  dispatch(getCourseLevel(userId));
  },[dispatch, userId])

  // set course level
  useEffect(() => {
    if (courseLevelSelected === null && courseLevelList?.length > 0) {
      const itemLevelDefault = courseLevelList.find(item => item.default)
      if(itemLevelDefault) {
        setCourseLevelSelected(itemLevelDefault.id)
      }
      
    }
  },[courseLevelList, courseLevelSelected, setCourseLevelSelected])

  
  useEffect(() => {
    courseLevelSelected !== null && dispatch(getSideBarAction(userId, courseLevelSelected)).then(() => setIsLoadingMenu(false))
  }, [courseLevelSelected, dispatch, userId]);

  //change centre
  const onChangeOption = (name, id) => {
    setCentreId(id)
  }

  // change level
  const onChangeCourseLevel = (id) => {
    setIsLoadingMenu(true);
    setCourseLevelSelected(id)
  }

  const getData = async (content_type, content_id, student_id, centre_id) => {
    setIsLoadingContent(true);
    return CoursePageApi.getCourseContent(content_type, content_id, student_id, centre_id)
    .then((response) => {
      if(response && response.success) {
        setCourseContent(response.data);
        setIsLoadingContent(false);       
      } else {
        setIsLoadingContent(false);
      }
    })
    .catch((e) => {
      setIsLoadingContent(false);
    });
  };
  return {
    isLoadingContent,
    courseSideBar,
    courseContent,
    courseLevel,
    centreId,
    userId,
    c1Id,
    c2Id,
    c3Id,
    c3Type,
    courseLevelSelected,
    c2UniqueId,
    isLoadingMenu,
    onSideBarClick,
    onChangeOption,
    onChangeCourseLevel,
    getData,
  }
}

export default useMyCourse