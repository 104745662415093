import { useEffect, useState } from "react";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import CentreBanner from '../../../../assets/image/centre_background.png';
import ProfileCaptureApi from '../../../redux/services/profileCaptureApi';
import { handleGoBack } from "../../../../utils/helper";
import useLocal from "../../../hooks/ProfileCapture/useLocal";
import { setStep, setStepOrder } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import { ClassType } from "./Centres/ClassType";
import { Timeslot } from "./Centres/Timeslot";
import { Centre } from "./Centres/Centre";
import { render } from "@testing-library/react";

const LocalPage = (props) => {
  const { history,
    dispatch,
    step,
    previousStep,
    nextStep,
    centreId,
    sections,
    pageOrder,
    profileId,
    setSubmitStep,
    setSubmitFlag,
    components,
    motivationId,
    translations
  } = props
  
  const {
    tempMotivationSections, 
    setTempMotivationSections,
    formValid,
    setFormValid,
    classType,
    setClassType,
    classTime,
    setClassTime,
    timeSlots,
    setTimeSlots,
    centres,
    setCentres,
    classTypeActive,
    setClassTypeActive,
    timeSlotActive,
    setTimeSlotActive,
    centreActive,
    setCentreActive,
    onChooseClassType,
    onChooseClassTime,
    onPickTimeSlots,
    onChooseCentres,
    onChangeText,
    onSelect
  } = useLocal(sections, step, components);
  const [orderSections, setOrderSections] = useState([]);
  
  const onNext = () => {
    dispatch(setStep(nextStep))
    setSubmitStep(step)
    setSubmitFlag(true)
  }
  
  useEffect(() => {
    if (sections.length > 0) {
      const data = sections.filter(item => item.type === step)[0]
      setTempMotivationSections(data)
    }
  }, [sections, step])
  
  useEffect(() => {
    if (components && components.length > 0) {
      const centreComponent = components.filter(item => item.component_name === 'Centre')[0]
      if (centreComponent) {
        const condition = centreComponent.profile_sections
        let sections = condition.map((item) => {
          if(item.section_name === 'Class type') {
            setClassTypeActive(1)
          } else if(item.section_name === 'Timeslot') {
            setTimeSlotActive(1)
          } else if(item.section_name === 'Centre') {
            setCentreActive(1)
          }
          
          return item.section_name;
        })
        
        setOrderSections(sections);
      }
    }
  }, [components])

  return (
    <ProfileCapturePage history={history} dispatch={dispatch} previousStep={previousStep} pageTitle={tempMotivationSections?.centre_when_text ?? ''} centreId={centreId} step={step} profileId={profileId} bannerMaster={CentreBanner}>
      {tempMotivationSections.motivation_text && 
      <div className="rate-important-top">
        <div className="profile-capture-wrapper">
          <p className="rate-ttl">{tempMotivationSections.motivation_text}</p>
        </div>
      </div>}
      <div className="rate-important-inner">
        {orderSections?.map((item) => {
          if(item === 'Class type') {
            return (
              <ClassType
                classTypeActive={classTypeActive} 
                onChooseClassType={onChooseClassType} 
                classType={classType}
                translations={translations}
              ></ClassType>
            )
          } else if(item === 'Timeslot') {
            return (
              <Timeslot 
                timeSlotActive={timeSlotActive} 
                onChooseClassTime={onChooseClassTime} 
                classTime={classTime} 
                timeSlots={timeSlots} 
                onPickTimeSlots={onPickTimeSlots}
                translations={translations}
              >
              </Timeslot>
            )
          } else if(item === 'Centre') {
            return (
              <Centre
                centreActive={centreActive} 
                tempMotivationSections={tempMotivationSections} 
                centres={centres} 
                onChooseCentres={onChooseCentres}
                translations={translations}
              >
              </Centre>
            )
          }
        })}
  
        <div className="other-reason-inner">
          <div className="other-reason-w">
            <div className="type-other-social">
              <label className="lbl-other">{tempMotivationSections.motivation_comment_text}</label>
              <input
                  type="text"
                  className="form-control type-input"
                  placeholder={ translations['[text_type_here]'] }
                  onChange={e => onChangeText(e.target.value)}
                  value={tempMotivationSections.comment || ''}
                />
            </div>
            <div className="select-options-inner">
              <div className="page">{pageOrder}</div>
              <div>
                <button className="btn-back-options button" onClick={() => handleGoBack(dispatch, previousStep, profileId, step, tempMotivationSections?.id)}>{ translations['[button_back]'] }</button>  
                {formValid ? 
                  <button className="btn-select-options next active" type="button" onClick={onNext}>{ translations['[button_next]'] }</button>
                :
                  <button className="btn-select-options" type="button">{ translations['[button_select_option]'] }</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProfileCapturePage>
  )
}

export default LocalPage;