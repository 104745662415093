import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';
import ProgressBarPathway from '../Common/ProgressBarPathway';

const PathWays = (props) => {
  const { isTablet } = useCheckMobileTablet();
  return (
    <div className="pathway-item">
      <div>
        <div className="title">{props.title}</div>
        <div className="name">{props.name}</div>
      </div>
      <div>
        <ProgressBarPathway
          label=""
          total={props.total}
          completed={props.completed}
          inProgress={props.inProgress}
          width={isTablet ? "16.67%" : "40px"}
          height="16px"
          colorActive="#00EDC3"
          colorDefault="#23085A"
          colorProgress="#99EDD1"
        />
      </div>
    </div>
  )
}

export default PathWays;