import React from 'react';
import GrammarItem from './GrammarItem';

import ideaIcon from '../../../../assets/image/idea.png';
import CircularProgressBar from '../Common/CircularProgressBar';
import Slider from 'react-slick';

const Grammar = (props) => {
    const { isMobile, grammar, grammarUrl} = props;
    const items = grammar?.score;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true,
    };
    
    const arrayChunk = items?.reduce((all,one,i) => {
        const ch = Math.floor(i/3); 
        all[ch] = [].concat((all[ch]||[]),one); 
        return all
     }, [])
    return (
        <div className="content-wrapper grammar-list">
            <p className="block-title">Grammar practice</p>
            {!isMobile && (
                <>
                    {items && (

                        <div className="row">
                            {items?.map((item, index) => (
                                <div className="col" key={index}>
                                    <GrammarItem title={item.total.name} isMobile={isMobile} total={item.total} data={item} />
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
            {isMobile && (
                <div className="slider-wrapper">
                    <Slider
                        {...settings}
                    > 
                        {arrayChunk?.map((item, index) => (
                            <div className="slide-item" key={index}>
                                <div className="slide-item-wrapper">
                                    {item.map((item, index) => (
                                        <GrammarItem title={item.total.name} isMobile={isMobile} total={item.total} data={item} key={index} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            )}
            <div className="grammar-list__note">
                <span className="icon">
                    <img src={ideaIcon} alt="icon" />
                </span>
                Click on numbered items to view an activity.
            </div>
            <div className="grammar-list__foot">
                {grammarUrl && (
                    <a className="MuiButtonBase-root MuiButton-root MuiButton-contained btn btn-default btn-progress-primary" href="https://myclassresources.britishcouncil.org/login/index.php" target='_blank' rel='noreferrer'>
                        View all activities
                    </a>
                )}
                <div className="grammar-list__foot-right">
                    <span className="grammar-list__foot-text">
                        Overall average score
                    </span>
                    <CircularProgressBar
                        strokeWidth="3"
                        sqSize="52"
                        completeProgress={grammar?.totalOverallPercentages}
                        percentage={100 - (grammar?.totalOverallPercentages*10)}
                        
                    />

                </div>
            </div>
        </div>
    );
};

export default Grammar;