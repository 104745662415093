import { useState, useEffect } from "react";
import PrivatePage from "../../../components/PrivatePage";
import "../../../../sass/private/Achievement/AchievementPage.scss";
import ProfileWraper from "../Profile/ProfileWraper";
import useCheckMobileTablet from "../../../../utils/useCheckMobileTablet";
import AchievementContent from "./AchievementContent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useAchievementsPage from "../../../hooks/AchievementsPage/useAchievementsPage";
import Modal from "../Dialog/Modal";
import { getRoute } from "../../../components/Utilities";
import { Button } from "@material-ui/core";

const AchievementPage = (props) => {
  const { achievementsData } = useAchievementsPage();
  const { isTablet } = useCheckMobileTablet();
  const history = useHistory();

  const [isOpenProgressDetail, setIsOpenProgressDetail] = useState();
  const [progressUrl, setProgressUrl] = useState("");

  const handleCloseProgressModal = () => {
    setIsOpenProgressDetail(false);
  };
  const handleOpenProgressModal = (url) => {
    setProgressUrl(url);
    setIsOpenProgressDetail(true);
    // history.push(getRoute('HOME'));
  };

  const onClose = () => {
    history.goBack();
  };

  return (
    <>
      {isTablet ? (
        <ProfileWraper
          isShowBack
          isHideBtnBottom
          onClose={onClose}
          title={`${"Achievements"}`}
          className="AchievementPage"
        >
          <AchievementContent
            achievementsData={achievementsData}
            handleOpenProgressModal={handleOpenProgressModal}
            isOpenProgressDetail={isOpenProgressDetail}
          ></AchievementContent>
        </ProfileWraper>
      ) : (
        <PrivatePage className="AchievementPage">
          <AchievementContent
            achievementsData={achievementsData}
            handleOpenProgressModal={handleOpenProgressModal}
            isOpenProgressDetail={isOpenProgressDetail}
          ></AchievementContent>
        </PrivatePage>
      )}

      <Modal
        open={isOpenProgressDetail}
        handleCloseDialog={handleCloseProgressModal}
      >
        <Button
          variant="contained"
          onClick={handleCloseProgressModal}
          className="btn btn-return btn-top"
        >
          Close
        </Button>
        <div className="external-box">
          <iframe
            src={progressUrl}
            width="100%"
            height="100%"
            title="Progress"
          />
        </div>
      </Modal>
    </>
  );
};

export default AchievementPage;
