import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../../utils/currentUser';
import {getCourseDetailsAction, addFavorite,removeFavorite} from '../../redux/actions/MyCoursePage/CourseDetailsPageActions'
import { setOpenBookingWindow } from '../../redux/actions/Booking/BookingAction';
import { useParams, useLocation } from 'react-router-dom';
import useQuery from '../../../utils/useQuery'

const useMyCourseDetails = () => {
  const dispatch = useDispatch();
  const {id} = useParams()
  const location = useLocation()
  const [selectedIdAudio, setSelectedIdAudio] = useState("");
  const [audioName, setAudioName] = useState('')
  const {lessonType, centreId: paramCentreId} = useQuery();
  const [centreId, setCentreId] = useState(paramCentreId || 0);
  const [userId, setUserId] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);

  const courseDetails = useSelector(state => state.courseDetails.details);
  
  //this part use for call API course detail in another page.
  const [courseId, setCourseId] = useState(null);
  const [courseType, setCourseType] = useState('lesson');

  useEffect(() => {
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.responseUserData) {
      const idUser = currentUser.responseUserData.id
      setUserId(idUser);
      if(id) {
        setCourseId(id);        
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(()=> {
    getData();
  },[dispatch, id, lessonType, courseId])

  const onChangeCentre = (name, newCentreId) => {
    getData(newCentreId, false);
    setCentreId(newCentreId);
  }

  const getData = (centre = centreId, showLoading = true) => {
    if (userId < 0) {
      return false;
    }

    if (showLoading) {
      setIsLoading(true);
    }

    dispatch(getCourseDetailsAction(userId, centre, courseId ?? id, lessonType ?? courseType)).then(() => {
      if (showLoading) {
        setIsLoading(false);
      }
    });
  }

  const openModalAudio = (id, name) => {
    if (id) {
      setSelectedIdAudio(id.toString());
      setAudioName(name)
      dispatch(setOpenBookingWindow(true));
    }
  }

  const onCloseModalAudio = () => {
    dispatch(setOpenBookingWindow(false));
    setSelectedIdAudio("");
  }

  const onAddFavorite = () => {
    dispatch(addFavorite(id)).then(() => getData());
  }

  const onRemoveFavorite = () => {
    dispatch(removeFavorite(id)).then(() => getData());
  }

  return {
    courseDetails,
    selectedIdAudio,
    audioName,
    id,
    onChangeCentre,
    openModalAudio,
    onCloseModalAudio,
    onAddFavorite,
    onRemoveFavorite,
    isLoading,
    centreId,
    courseId, 
    setCourseId,
    setCourseType,
    getData
  }
}

export default useMyCourseDetails
