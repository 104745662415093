import helpApi from '../../services/helpApi';
import { ActionTypes } from '../types';


export const getHelpAction = () => (dispatch) => {
  return helpApi.getHelp().then(response => {
    if(response && response.success) {
      dispatch({
        type: ActionTypes.GET_HELP_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_HELP_FAIL,
        payload: 'Get help fail!'
      })
    }
  })
}