import React from 'react';
import Mic from '../../../components/icon/Mic';
import Writing from '../../../components/icon/Writing';
import ArrowRight from '../../../components/icon/ArrowRight';

const PerformanceCard = (props) => {
    const {item, onItemClick, skill } = props;

    let ScoreCircle;

    if (item.score != "") {
      ScoreCircle= <div className="performance-card__point">
        {item.score}
    </div>;
    } else {
      ScoreCircle= <div></div>;
    }
    return (
        <div className={`performance-card ${(skill === 'writing') || skill === 'c2 proficiency writing' || skill === 'b2 first writing' ? 'performance-card--writing' : ''}`} onClick={() => onItemClick(item.assessment_id)}>
            <div className="performance-card__icon">
                {(skill === 'writing') || skill === 'c2 proficiency writing' || skill === 'b2 first writing' ? <Writing/> : <Mic />}
                {ScoreCircle}
            </div>
            <div className="performance-card__content">
                <div className="performance-card__content-top">    
                    <div className="performance-card__description">
                        <p className="performance-card__date">{item.date}</p>
                        <h3 className="performance-card__title">{item.title}</h3>
                    </div>
                    
                    {ScoreCircle}
                </div>
                <div className="performance-card__foot">
                    Teacher
                    <span>{item.teacher_name}</span>
                    <ArrowRight className="performance-card__icon-arrow" />
                </div>
            </div>
        </div>
    );
};

export default PerformanceCard;