import { ActionTypes } from '../../actions/types';

const initialState = {
  courseSideBar: [],
  courseContent: {},
  courseLevel: [],
}

export default function courseReducer(state=initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_COURSE_SIDEBAR_SUCCESS:
      return {
        ...state,
        courseSideBar: payload,
      }

    case ActionTypes.GET_COURSE_CONTENT_SUCCESS:
      return {
        ...state,
        courseContent: payload,
      }

    case ActionTypes.GET_COURSE_LEVEL_SUCCESS:
      return {
        ...state,
        courseLevel: payload,
      }
    default:
      return state;
  }
}
