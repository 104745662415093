import { getRoute } from '../../components/Utilities';
import { setMobileBookingActiveTab } from '../../redux/actions/Booking/BookingAction';
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCalendarView from "../CalendarPage/useCalendarView";

export const MOBILE_BOOKING_TABS = {
  TIME: "Time",
  TEACHER: "Teacher",
  CONTENT: "Content",
};

const useMobileBookingPage = () => {
	const {
    dataCalendarView
  } = useCalendarView();

	const TABS = MOBILE_BOOKING_TABS;
	
	const timeOpts = [
		{
			value: "all",
			text: "All times"
		},
		{
			value: "am",
			text: "AM"
		},
		{
			value: "pm",
			text: "PM"
		}
	];
	
	const tabs = Object.keys(TABS).map(tab => TABS[tab]);

	const history = useHistory();
	const dispatch = useDispatch();

	const activeTab = useSelector(state => state.booking.mobileBooking.activeTab);
	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [selectedTime, setSelectedTime] = useState("all");

	const [dataReloadTokenSelf, setDataReloadTokenSelf] = useState(0);
	const [dataReloadTokenOthers, setDataReloadTokenOthers] = useState(0);
	const [autoDataReload, setAutoDataReload] = useState(0);

	const enableAutoDataReload = false;

	useEffect(() => {
		if (enableAutoDataReload) {
			initAutoDataReload();
		}
	}, []);

	useEffect(() => {
		return () => { // separate unmount of autoDataReload so as to get the correct JS scope
			clearAutoDataReload();
		};
	}, [autoDataReload]);

	const initAutoDataReload = () => {
		clearAutoDataReload();

		setAutoDataReload(setInterval(() => {
			setDataReloadTokenOthers(getTimestamp());
		}, 60000));
	};

	const clearAutoDataReload = () => {
		clearInterval(autoDataReload);
	};

	const reloadData = () => {
		setDataReloadTokenSelf(getTimestamp());
	};

	const getTimestamp = () => {
		return new Date().getTime();
	};

	const handleTabClick = (tab) => {
		dispatch(setMobileBookingActiveTab(tab));
	}

	const toggleFilter = (isShow) => {
		setShowAdvancedFilter(isShow)
	}

	const filterByTime = (list, time) => {
		switch (time) {
			case "am":
				return list.filter(item => parseFloat(item.startTime) < 12)
			case "pm":
				return list.filter(item => parseFloat(item.startTime) >= 12)
			default:
				return list;
		}
	}

	const redirect = (teacherId) => {
		history.push(getRoute("TEACHERDETAIL") + '?id=' + teacherId);
	}

	const filteredListView = useMemo(
		() => {
			if (activeTab === TABS.TIME) {
				let dataCalendarViewFull = [];
				let dataCalendarViewAvailable = [];
				let isPastTime = 0;
				let dataCalendar = filterByTime(dataCalendarView || [], selectedTime);
        
				dataCalendar.length > 0 && dataCalendar.map((item) => {
					if(typeof item.infoLines != 'undefined') {       
						if(item.infoLines.fullWarningIcon && item.isPast === false) {
						dataCalendarViewFull = [...dataCalendarViewFull, item];
						} else {
						dataCalendarViewAvailable = [...dataCalendarViewAvailable, item];
						}
						
						if(item.isPastEndTime) {
						isPastTime = 1;
						}
					}
				});
        
				return {
					'dataCalendarViewFull': dataCalendarViewFull,
					'dataCalendarViewAvailable': dataCalendarViewAvailable,
					'isPastTime': isPastTime,
				};
			}
			else {
				return dataCalendarView;
			}
		}
		, [dataCalendarView, selectedTime]
	)

	return {
		tabs,
		TABS,
		timeOpts,
		activeTab,
		selectedTime,
		showAdvancedFilter,
		filteredListView,
		redirect,
		toggleFilter,
		handleTabClick,
		setSelectedTime,
		reloadData,
		dataReloadToken: dataReloadTokenSelf,
		dataReloadTokenOthers,
	}
}

export default useMobileBookingPage