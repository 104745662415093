import React, { Fragment } from 'react';
import CommmunicationItem from './CommunicationItem';
import { Button } from '@material-ui/core';
import checkIcon from '../../../../assets/svg/check.svg';

import useCommunicationPage from '../../../hooks/SettingPage/useCommunicationPage';

const Commmunication = () => {
  const {
    data,
    onSelect,
    onDeselect,
    onSelectOption,
    onUpdate,
    dataMethods,
    infoMessageUpdate,
    dataLoaded,
    updateResultsLoaded,
  } = useCommunicationPage();
  const content = data.map((item, index) => {
    return <CommmunicationItem
      key={index}
      data={item.notification}
      title={item.name}
      onSelect={onSelect}
      onDeselect={onDeselect}
      id={item.id}
      onSelectOption={onSelectOption}
    />
  });
  const dataAllNotification = [
    {
      id: 'all',
      methods: [...dataMethods],
      name: 'Select/deselect all notifications for'
    }
  ];
  
  return (
    <Fragment>
      <div className="setting-title">
        Communication
      </div>
      <div className="setting-subtitle">
        Choose how you receive communications.
      </div>
      {dataLoaded &&
        <div className="communication">
          <CommmunicationItem
            data={dataAllNotification}
            title={''}
            id={'select all'}
            onSelectOption={onSelectOption}
            styles={{paddingTop: '10px', paddingBottom: '40px'}}
          />
          {content}
          <div className="setting-footer">
            {updateResultsLoaded &&
              <div className={`setting-footer__status ${infoMessageUpdate.status === true ? 'success' : 'error'}`}>
                {infoMessageUpdate.status === true && <img src={checkIcon} alt="Success" />}
                <span>
                  {infoMessageUpdate.message}
                </span>
              </div>
            }
            <Button variant="contained" className="btn btn-update" onClick={onUpdate}>Update</Button>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default Commmunication;