import CourseMapApi from '../../services/courseMapApi';
import { ActionTypes } from '../types';

export const getLevelList = async (zoneId) => {
  return await 
    CourseMapApi.getLevelList(zoneId)
    .then(response => response.data)
}

export const getThemeList = async (levelId, zoneId) => {
  return await 
    CourseMapApi.getThemeList(levelId, zoneId)
    .then(response => response.data);
}

export const getLessonDetail = async (contentId, zoneId) => {
  return await
    CourseMapApi.getLessonDetail(contentId, zoneId)
    .then(response =>  response.data)
}

export const setCourseMapLevel = level => dispatch => {
  dispatch({
    type: ActionTypes.SET_COURSEMAP_LEVEL,
    payload: level
  })
}

export const setZoneId = zoneId => dispatch => {
  dispatch({
    type: ActionTypes.SET_COURSEMAP_ZONE_ID,
    payload: zoneId
  })
}

export const setThemeName = themeName => dispatch => {
  dispatch({
    type: ActionTypes.SET_COURSEMAP_THEME_NAME,
    payload: themeName
  })
}

export const setMenuName = menuName => dispatch => {
  dispatch({
    type: ActionTypes.SET_COURSEMAP_MENU_NAME,
    payload: menuName
  })
}