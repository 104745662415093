import MapCentre from './MapCentre';

import homeIcon from '../../../../assets/image/home.png';
import homeIconMobile from '../../../../assets/svg/homemobile.svg';
import mapIcon from '../../../../assets/image/map-pin.png';
import phoneIcon from '../../../../assets/image/phone.png';
import mailIcon from '../../../../assets/image/mail.png';
import monitorIcon from '../../../../assets/svg/monitor.svg';
import useCheckMobileTablet from '../../../../utils/useCheckMobileTablet';


import '../../../../sass/private/Contact/AddressItem.scss';

const AddressItem = (props) => {
  const { isShowHomeCentre, name, address, email, phone, isVirtual, lat, lng } = props;
  const { isTablet } = useCheckMobileTablet();

  return (
    <div className="AddressItem">
      {/* {isShowHomeCentre && 
        <div className="address-desr">
          You can take classes at any of the centres listed below.
          Those closest to your home centre are shown first.
      </div>
      } */}
      {isShowHomeCentre && 
        <div className="home-centre">
          <img src={homeIcon} alt=""/>
          <span>Your home centre</span>
        </div>
        
      }
      <div className="address-block">
        <div className="address-left">
          <div className="address-name">{isVirtual && <img src={monitorIcon} alt="Virtual"/>}<span>{name}</span></div>
          {address && <div className="address-info">
            <img src={mapIcon} alt="Address"/>
            <p>{address}</p>
          </div>}
          {phone && <div className="address-info">
            <img src={phoneIcon} alt="Phone"/>
            <p>{phone}</p>
          </div>}
          {email && <div className="address-info">
            <img src={mailIcon} alt="Email" />
            <p>{email}</p>
          </div>}
        </div>
        <div className="address-right">
          <MapCentre center={{lat: JSON.parse(lat), lng: JSON.parse(lng)}}/>
        </div>
      </div>
    </div>
  )
}

export default AddressItem;