import { ActionTypes } from '../types';
import TeacherListApi from '../../services/teacherListApi';

export const getTeacherAction = (json) => (dispatch) => {
  dispatch({type: ActionTypes.GET_TEACHER_LIST_START})
  return TeacherListApi.getTeacherList(json).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_TEACHER_LIST_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_TEACHER_LIST_FAIL,
        payload: 'Get teacher fail!'
      })
    }
    return response;
  })
}
