import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  getCentres,
  getMotivations,
  checkValidPassword,
  getWelcomeSection,
  setMotivationsData,
} from "../../redux/actions/ProfileCapture/profileCaptureAction";

const useProfileCapture = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [centreList, setCurrentCentreList] = useState([])
  const [motivationData, setMotivationData] = useState([])
  const [welcomeData, setWelcomeData] = useState({})
  const dispatch = useDispatch();

  const openWelcomeSection = async () => {
    setIsLoading(true)
    await getWelcomeSection().then(result => {
      setIsLoading(false)
      setWelcomeData(result)
    })
  }

  const openProfileCapture = async () => {
    setIsLoading(true)
    await getCentres().then(result => {
      setIsLoading(false)
      setCurrentCentreList(result)
    })
  }

  const openMotivationPage = async (centreId, profileId) => {
    setIsLoading(true)
    await getMotivations(centreId, profileId).then(result => {
      setMotivationData(result)
      setIsLoading(false)
      dispatch(setMotivationsData(result?.motivations))
    }).catch(e => {
      setIsLoading(false)
    })
  }

  return {
    isLoading,
    centreList,
    motivationData,
    welcomeData,
    openProfileCapture,
    checkValidPassword,
    openMotivationPage,
    openWelcomeSection
  }
}

export default useProfileCapture;