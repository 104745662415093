import SvgIcon from '@material-ui/core/SvgIcon';

export default function Repeat(props) {
  return (
    <SvgIcon viewBox="0 0 14 14" style={{fill: 'none'}} {...props}>
      <path d="M9.91602 0.583008L12.2493 2.91634L9.91602 5.24967" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M1.75 6.41699V5.25033C1.75 4.63149 1.99583 4.03799 2.43342 3.60041C2.871 3.16282 3.46449 2.91699 4.08333 2.91699H12.25" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.08333 13.4167L1.75 11.0833L4.08333 8.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.25 7.58301V8.74967C12.25 9.36851 12.0042 9.962 11.5666 10.3996C11.129 10.8372 10.5355 11.083 9.91667 11.083H1.75" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
    
  );
}
