import { ActionTypes } from '../types';
import newsFeedActivation from '../../services/newsFeedActivationApi';

export const getNewsFeedAction = (id) => (dispatch) => {
  return newsFeedActivation.getNewsFeed(id).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_NEWSFEED_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_NEWSFEED_FAIL,
        payload: 'Get newsfeed fail!'
      })
    }
    return response;
  })
}