import React, { useState } from 'react';
import Page from './Page';
import Container from './Container';
import { getRoute, getString } from './Utilities';
import Footer from '../pages/private/Footer/Footer';
import Logo from '../../assets/image/british_council_english_white.svg';

export default class CourseMapPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchOpen: false
    };
  }
  render() {
    const { history, handleSearchClick } = this.props
    const onClickLogo = () => {
      history.push(getRoute("HOME"))
    }

    const onClickCourseMap = () => {
      history.push(getRoute("COURSEMAP"))
    }

    const clickSearchButton = () => {
      this.setState({ searchOpen: !this.state.searchOpen })
      return handleSearchClick(this.state.searchOpen)
    }

    return (
      <Page className={"coursemap-page " + getString(this.props.className)}>
        <header className="header">
          <div className="header__left">
            <img className="img-logo" src={Logo} alt="British Council" onClick={onClickLogo} />
            <h2 className="heading-navbar" onClick={onClickCourseMap}>Course map</h2>
          </div>
          {/* <div className="header__right" onClick={onClickLogo}>
              Go to British Council English
          </div> */}
          {handleSearchClick && <button onClick={clickSearchButton} className={`header__search ${this.state.searchOpen ? 'open' : ''}`}>
            {this.state.searchOpen &&
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.6026L7 8.12312L13 1.6026" stroke="#23085A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M1 14.6436L7 8.1231L13 14.6436" stroke="#23085A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            }
            {!this.state.searchOpen &&
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 19a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM21 21l-4.35-4.35" stroke="#99EDD1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
            }
            
          </button>
          }
        </header>
        <main>
          <Container className="page-content">
            {this.props.children}
          </Container>
        </main>
        <Footer />
      </Page>
    )
  }
}
