import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../../../utils/currentUser';

import { notificationAction, resetNotificationState } from "../../redux/actions/Notification/NotificationAction";

const useNotification = () => {
    const dispatch = useDispatch();
    const notificationData = useSelector(state => state.notification.data)

    const openNotification = (page, limit) => {    
        dispatch(notificationAction(page, limit));
    }

    const resetAndRecall = () => {
      dispatch(resetNotificationState(true));
    }
    return {
        notificationData,
        openNotification,
        resetAndRecall
    }
}

export default useNotification