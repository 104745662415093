import React from "react";

import CircularProgressBar from "../Common/CircularProgressBar";
import LockedCircularProgressBar from "../Common/LockedCircularProgressBar";
import Tooltip from "../Common/Tooltip";
import { useHistory } from 'react-router';

import '../../../../sass/private/Home/ModuleWidgetModular.scss';
import useCheckMobileTablet from "../../../../utils/useCheckMobileTablet";

const ModuleWidgetModular = (props) => {
  const { data } = props;
  const history = useHistory();
  const {isTablet} = useCheckMobileTablet();
  const onModularClick = (e, params) => {
    if(isTablet || !params.isClickable) {
      return false;
    }
    
    if (params.c1Id && params.c2Id && params.c3Id && params.c3Type && params.canAccess) {
      delete params.canAccess;
      params.c2Id = params.c2Id ? encodeURI(params.c2Id) : "";
      const urlParams = new URLSearchParams(params);
      history.push(`/mycourse?${urlParams}`);
    } 
  }
  return (
    <>
      {Array.isArray(data) && data.map((product) => (
        <div key={product.productTypeId} className="modular-product-progress">
          <div className="product-name">{product.productTypeName || ""} lesson target</div>
          <ul className="product-theme-listing">
            {Array.isArray(product.themes) && product.themes.map((theme, index) => {
              const lessonAttended = theme.lessonAttended || 0;
              const lessonTotal = theme.lessonTotal || 0;

              let percentage = 0;

              if (lessonTotal) {
                percentage = lessonAttended / lessonTotal * 100;
              }
              
              const tooltipTitle = theme.themeName || "";

              return (
                <div
                  key={theme.themeId}
                  tabIndex={theme.canAccess ? 0 : -1}
                  onClick={(e) => onModularClick(e, {
                    c1Id: theme.c1Id || null,
                    c2Id: theme.c2Id || null,
                    c2UniqueId: theme.themeId || null,
                    c3Id: theme.themeId || null,
                    c3Type: theme.c3Type || null,
                    canAccess: theme.canAccess || 0,
                    isClickable: theme.isClickable
                  })}
                  onKeyPress={(e) => onModularClick(e, {
                    c1Id: theme.c1Id || null,
                    c2Id: theme.c2Id || null,
                    c2UniqueId: theme.themeId || null,
                    c3Id: theme.themeId || null,
                    c3Type: theme.c3Type || null,
                    canAccess: theme.canAccess || 0,
                    isClickable: theme.isClickable
                  })}
                >  
                <Tooltip key={theme.themeId} title={tooltipTitle} placement="top" controlOnClick={true}>
                  <li className="product-theme-listing-item">
                    {!theme.canAccess ? 
                    <LockedCircularProgressBar sqSize={50}/> :
                    <CircularProgressBar completeProgress={percentage} sqSize={50} text={lessonAttended + "/" + lessonTotal} />}
                    <div className="product-theme-name">{theme.shortName || `Module ${index + 1}`}</div>
                  </li>
                </Tooltip>
                </div>
              )
            })}
          </ul>
        </div>
      ))}
    </>
  )
}

export default ModuleWidgetModular;
