import { Button, CircularProgress } from '@material-ui/core';
import { memo, useEffect, useState } from 'react';
import usePractice from '../../../../hooks/Practice/usePractice';
import PracticeViewItem from './PracticeViewItem';
import { Skeleton } from "@mui/material";
import PracticeListLoading from './PracticeListLoading';

const PracticeListView = (props) => {
  const {
    handleOpenPracticeModal,
    isOpenLessonDetail
  } = props;
  
  const {
    isLoading,
    isLoadingMoreToDo,
    isLoadingMoreInProgress,
    isLoadingMoreDone,
    practiceList,
    loadMore,
    showLoadMoreButtonToDo,
    showLoadMoreButtonInProgress,
    showLoadMoreButtonDone,
    TODO,
    INPROGRESS,
    DONE,
    getData
  } = usePractice();

  useEffect(() => {
    if(isOpenLessonDetail == false) {
      getData();
    }
  }, [isOpenLessonDetail])
  
  return (
    <div className="calendar-view practice-view">
      <div className="calendar-view__header">
        <div className="title">Practice what you learned in class with our self-study activities</div>
      </div>
      <div className='practice-lists'>
        {practiceList?.inProgress?.lessons?.length == 0 && practiceList?.toDo?.lessons?.length == 0 && practiceList?.done?.lessons?.length == 0 ? (
          <div className='container-not-found'>
            <p className='message'>Once you complete your first lesson your practice activities will be listed here</p>
          </div>
        ) : (
          <>
            {isLoading ? (
              <div className='practices'>
                <p className='heading'><Skeleton animation="wave" variant="text" className="skeleton-heading" /></p>
                <div className='items'>
                  <PracticeListLoading></PracticeListLoading>                                    
                </div>
              </div>
            ) : 
            (
              <>
              {practiceList?.inProgress?.lessons?.length > 0 && 
                <div className='practices'>
                  <p className='heading'>In progress ({practiceList?.inProgress?.total ?? 0})</p>
                    <div className='items'> 
                      {practiceList?.inProgress?.lessons?.map((practice, index) => (
                        <PracticeViewItem
                          handleOpenPracticeModal={handleOpenPracticeModal}
                          buttonTitle={'Continue'}
                          title={practice.productType}
                          subTitle={practice.title}
                          date={practice.lastAttendDate}
                          numberDidAsssessment={practice.finish}
                          totalAssessment={practice.total}
                          score={practice.score}
                          id={practice.lesson_id}
                          lessonType={practice.lessonType}
                          practiceType={practice.practiceType}
                          key={index}
                        />
                      ))}   
                      {isLoadingMoreInProgress && <PracticeListLoading></PracticeListLoading>}
                    </div>
                    <div className="btn-show-more-container">
                      {showLoadMoreButtonInProgress && 
                        <>
                          {!isLoadingMoreInProgress ? (
                            <Button variant="contained" onClick={() => loadMore(INPROGRESS)} className="btn btn-show-more">Load more</Button>                      
                          ) : ''}
                        </>
                      }
                    </div>
                </div>
              }
              
              {practiceList?.toDo?.lessons?.length > 0 && 
                <div className='practices'>
                  <p className='heading'>To-do ({practiceList?.toDo?.total ?? 0})</p>
                    <div className='items'>
                      {practiceList?.toDo?.lessons?.map((practice, index) => (
                        <PracticeViewItem
                          handleOpenPracticeModal={handleOpenPracticeModal}
                          buttonTitle={practice.practiceType == 'audio' ? 'Listen' : 'Practice'}
                          title={practice.productType}
                          subTitle={practice.title}
                          date={practice.lastAttendDate}
                          numberDidAsssessment={practice.finish}
                          totalAssessment={practice.total}
                          score={practice.score}
                          id={practice.lesson_id}
                          lessonType={practice.lessonType}
                          practiceType={practice.practiceType}
                          key={index}
                        />
                      ))}
                      {isLoadingMoreToDo && <PracticeListLoading></PracticeListLoading>}
                    </div>
                    <div className="btn-show-more-container">
                      {showLoadMoreButtonToDo && 
                        <>
                          {!isLoadingMoreToDo ? (
                            <Button variant="contained" onClick={() => loadMore(TODO)} className="btn btn-show-more">Load more</Button>                      
                          ) : ''}
                        </>
                      }
                    </div>
                </div>
              }     
                       
              {practiceList?.done?.lessons?.length > 0 && 
                <div className='practices'>
                  <p className='heading'>Done ({practiceList?.done?.total ?? 0})</p>
                  <div className='items'>
                    {practiceList?.done?.lessons?.map((practice, index) => (
                      <PracticeViewItem
                        handleOpenPracticeModal={handleOpenPracticeModal}
                        buttonTitle={practice.practiceType == 'audio' ? 'Listen again' : 'Try again'}
                        classes={'listen-again'}
                        title={practice.productType}
                        subTitle={practice.title}
                        date={practice.lastAttendDate}
                        numberDidAsssessment={practice.finish}
                        totalAssessment={practice.total}
                        score={practice.score}
                        id={practice.lesson_id}
                        lessonType={practice.lessonType}
                        practiceType={practice.practiceType}
                        key={index}
                      />
                    ))}
                    {isLoadingMoreDone && <PracticeListLoading classes='listen-again'></PracticeListLoading>}
                  </div>
                  <div className="btn-show-more-container">
                    {showLoadMoreButtonDone && 
                      <>
                        {!isLoadingMoreDone ? (
                          <Button variant="contained" onClick={() => loadMore(DONE)} className="btn btn-show-more">Load more</Button>                      
                        ) : ''}
                      </>
                    }
                  </div>
                </div>
              }
              </>
            )
            }
          </>
        )}
      </div>
    </div>
  )
}

export default memo(PracticeListView);