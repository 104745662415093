import { useEffect,useState } from "react";
import { listClasses, Select } from "@mui/material";
import useProfileCapture from "../../../hooks/ProfileCapture/useProfileCapture";
import ProfileCapturePage from "../../../components/ProfileCapturePage";
import ModalPassword from './ModalPassword';
import { useHistory } from "react-router-dom";
import BannerWelcome from '../../../../assets/image/banner-welcome.png';
import BannerHeader from '../../../../assets/image/bnr-top-1.png';
import BannerCreate from '../../../../assets/image/bnr-create.png'
import { setStep } from "../../../redux/actions/ProfileCapture/profileCaptureAction";
import { CircularProgress } from "@material-ui/core";
import { getTranslations } from "../../../redux/actions/ProfileCapture/profileCaptureAction";

const WelcomePage = (props) => {
  const { history, dispatch, step, nextStep, centreId, translations } = props
  const [showModalPassword, setShowModalPassword] = useState(false)
  const { welcomeData, openWelcomeSection, isLoading } = useProfileCapture()

  const handleHideModal = () => {
    setShowModalPassword(false)
  }

  const handleShowModal = () => {
    setShowModalPassword(true)
  }

  const navigate = () => {
    dispatch(setStep(nextStep))
  }

  useEffect(() => {
    openWelcomeSection()
    dispatch(getTranslations())
  }, [])

  return (
    <ProfileCapturePage history={history} dispatch={dispatch} step={step} previousStep={''} centreId={centreId}>
      {isLoading && <CircularProgress />}
      {!isLoading && <div className="create-profile-inner">
        <div className="profile-capture-wrapper">
          <h1 className="ttl-1">{welcomeData.intro_title}
            <span className="sub-ttl">{welcomeData.intro_subtitle}</span>
          </h1>
          <h2 className="ttl-mobile">We are sorry, this page is not optimised for mobile devices. Please use either a tablet or a laptop or desktop computer to open the link.</h2>
        </div>
        <div className="create-tell-inner">
          <div className="tell-us-right profile-capture-wrapper">
            <img className="img-create" src={BannerCreate} alt="" />
          </div>
          <div className="profile-capture-wrapper">
            <div className="tell-us-left">
              <p className="txt-tell-us">{welcomeData.intro_text}</p>
              <button
                type="button"
                className={`btn btn-start${centreId ? '' : '-disabled'}`}
                disabled={centreId ? false : true}
                onClick={navigate}
              >
                { translations['[button_start]'] }
              </button>
            </div>
          </div>
        </div>
      </div>}
    </ProfileCapturePage>
  )
}

export default WelcomePage;