import React, { useState } from 'react';
import { FormControl, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import '../../../../sass/private/AssessmentPage/SelectSkillMobile.scss';

const SelectSkillMobile = (props) => {
  const { data, onChangeOption, value } = props;

  const onChangeSkill = (id) => {
    onChangeOption(data.label, id);
  }

  return (
    <div className="select-skill-mobile">
      <div className="select-skill-mobile__title">Skills</div>
      <div className="select-skill-mobile__option">
        {data.options && data.options.map((item, index) => (
          <div 
            key={index} 
            className={`item-option ${value=== item.id ? 'active' : ''} `}
            onClick={() => onChangeSkill(item.id)}
          >
            {item.name}
          </div>
        ))}
      </div>

      <div className="select-skill-mobile__teacher">Teachers Assessments</div>

    </div>
  )
}

SelectSkillMobile.propTypes = {
  data: PropTypes.object,
}

SelectSkillMobile.defaultProps = {
  data: {
    options: [],
    label: '',
    placeholder: ''
  }
}

export default SelectSkillMobile;