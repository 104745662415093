import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRefundCreditAction, cancelClassAction } from '../../redux/actions/BookingWindow/BookingWindowAction';
import { getCurrentUser } from '../../../utils/currentUser';
import { cancelBookingAction, setOpenBookingWindow, bookingAction } from '../../redux/actions/Booking/BookingAction';

const useCancel = (reloadData) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const checkCancel = useSelector(state => state.bookingWindow.checkCancel);
  const [isCanceled, setIsCanceled] = useState(false);
  const [lessonTimeIdState, setLessonTimeIdState] = useState(0);
  const [userIdState, setUserIdState] = useState(0);

  const [isLoadingCheck, setisLoadingCheck] = useState(true);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);

  useEffect(() => {
    const currentUser = getCurrentUser();
    const pathname = history.location.pathname;
    const patnameSplit = pathname.split('/');
    const lessonTimeId = parseInt(patnameSplit[patnameSplit?.length -1]);

    if (isNaN(lessonTimeId)) { // invalid ID, stop all and close modal
      dispatch(setOpenBookingWindow(false));
      dispatch(cancelBookingAction(false));
      dispatch(bookingAction(false));
    }
    else {
      const userId = currentUser.responseUserData.id;
      setLessonTimeIdState(lessonTimeId);
      setUserIdState(userId);
      dispatch(getRefundCreditAction(userId, lessonTimeId)).then((response) => {
        if (response?.success) {
          setisLoadingCheck(false);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  const onNotCancel = () => {
    let isShowCancelBooking = false;
    dispatch(cancelBookingAction(isShowCancelBooking));
  }

  const onCancelBooking = () => {
    setIsLoadingCancel(true);
    dispatch(cancelClassAction(userIdState, lessonTimeIdState)).then(response => {
      if (response && response.success) {
        setIsCanceled(true);

        if (reloadData) {
          reloadData();
        }
      }
      setIsLoadingCancel(false);
    })
  }

  return {
    checkCancel,
    onNotCancel,
    isCanceled,
    onCancelBooking,
    isLoadingCheck,
    isLoadingCancel,
  }
}
export default useCancel;