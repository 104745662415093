import { Fragment } from "react";
import arrowIcon from "../../../../assets/svg/arrow-navbar.svg";
import iconChecked from '../../../../assets/image/passed.png';

import "../../../../sass/private/MyCourse/NavBarLeft.scss";

const NavBarLeft = (props) => {

  const {
    courseSideBar,
    c1Id,
    c2Id,
    c3Id,
    c2UniqueId,
    onSideBarClick
  } = props

  return (
    <Fragment>
      {courseSideBar?.map((item, index) => {
        return (
          <div key={index} className="navbar-left">
            <div className="menu-block">
              <div
                className="menu-name"
                onClick={() => onSideBarClick(item.name)}
                tabIndex={0}
                onKeyPress={() => onSideBarClick(item.name)}
              >
                <span className={`${item.name === c1Id ? "active-name-parent" : ""}`}>
                  {item.name}
                </span>
              </div>
              {item.name === c1Id && Array.isArray(item.items) && item.items.length > 0 && <div className="menu-list active">
                {item.items.map((option, index) => {
                  const optionItems = (Array.isArray(option.items) && option.items) || [];
                  const hasOptionItems = optionItems.length > 0;
                  return (
                    <div key={index} className="menu-list__item">
                      <div
                        className="menu-list__name"
                        tabIndex={0}
                        onClick={() =>
                          onSideBarClick(
                            item.name,
                            option.name,
                            option.id,
                            option.type,
                            option.id
                          )
                        }
                        onKeyPress={() => onSideBarClick(item.name, option.name, option.id, option.type, option.id)}
                      >
                        {hasOptionItems && <img
                          className={`${
                            option.name === c2Id ? "img-active" : ""
                          }`}
                          src={arrowIcon}
                          alt={`${
                            option.name === c2Id ? "Hide" : "Show"
                          }`}
                        />}
                        <span
                          className={
                            `${hasOptionItems? "" : "span-lv-2 "}${(option.name === c2Id && option.id == c2UniqueId) ? "name-active" : ""}`
                          }
                        >
                          {option.name}
                          {( !option.has_completed && option.has_started && option.theme_lessons_count) ? ` (${option.has_started}/${option.theme_lessons_count})` : ""}
                          {( option.has_completed && option.has_started === option.theme_lessons_count) ? <img style={{marginLeft: "5px"}}src={iconChecked} alt="Completed"/> : ""}
                        </span>
                      </div>
                      {option.name === c2Id && option.id == c2UniqueId && hasOptionItems && <ul
                        className="menu-child__list active-child"
                      >
                        {optionItems.map((value) => {
                          return (
                            <li
                              onClick={() =>
                                onSideBarClick(
                                  item.name,
                                  option.name,
                                  value.id,
                                  value.type,
                                  option.id
                                )
                              }
                              tabIndex={0}
                              onKeyPress={() => onSideBarClick(item.name, option.name, value.id, value.type, option.id)}
                              key={value.id}
                              className={`${
                                value.id === parseInt(c3Id) ? "active menu-list-name" : ""
                              }`}
                            >
                              {value.name}
                              {( !value.has_completed && value.has_started && value.theme_lessons_count) ? ` (${value.has_started}/${value.theme_lessons_count})` : ""}
                              {( value.has_completed && value.has_started === value.theme_lessons_count) ? <img style={{marginLeft: "10px"}}src={iconChecked} alt="Completed"/> : ""}
                            </li>
                          );
                        })}
                      </ul>}
                    </div>
                  );
                })}
              </div>}
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default NavBarLeft;
