import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProgressCheckAchievementAction } from '../../redux/actions/Achievement/AchievementAction';
import { getProgressAction } from '../../redux/actions/ProgressPage/ProgressPageAction';
import achievementApi from '../../redux/services/achievementApi';

const useAchievementsPage = () => {
  const achievementsData = useSelector(state => state.achievement.achievementsData);
 

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProgressCheckAchievementAction()).then((response) => {
      if (response?.success) {
        // setIsLoading(false)
        if (Array.isArray(response.data) && response.data.length) {
          onChangeOption("", response.data[0].productGroupId);
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  const onChangeOption = (name, id) => {
   
      // setIsLoading(true)
      Promise.all([
        dispatch(getProgressCheckAchievementAction()),
      ])
      
    
  }
  const getData = async () => {
    // setIsLoading(true);
    await achievementApi
      .getProgressCheckAchievement()
      .then((result) => { 
      })
      .catch((e) => {
        // setIsLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  return {
    achievementsData,
    getData,
  }
}

export default useAchievementsPage;