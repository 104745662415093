import { ActionTypes } from './types';

import CommunicationApi from "../services/communicationApi";

export const getCommunication = (id) => (dispatch) => {
    return CommunicationApi.getCommunication(id).then(
        (response) => {
            dispatch({
                type: ActionTypes.GET_COMMUNICATION_SUCCESS,
                payload: {data: response.data},
            });
  
            return Promise.resolve(response);
        },
        (error) => {
            dispatch({
                type: ActionTypes.GET_COMMUNICATION_FAIL,
                payload: 'Get communication failed!'
            });
  
            return Promise.reject();
        },
    );
};

export const updateCommunication = (id, checked, unchecked) => (dispatch) => {
  return CommunicationApi.updateCommunication(id, checked, unchecked).then(
      (response) => {
          if (response && response.success) {
            dispatch({
                type: ActionTypes.UPDATE_COMMUNICATION_SUCCESS,
                payload: {data: response.data},
            });
          } else {
            dispatch({
                type: ActionTypes.UPDATE_COMMUNICATION_FAIL,
                payload: 'Update communication failed!'
            });
          }
          

          return Promise.resolve(response);
      }
  );
};