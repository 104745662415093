import {getAPIEndpoint, makeRequest} from '../../components/Utilities';

class CourseMapApi {
  async getLevelList(zoneId) {
    try {
      let url = 'course-map/level-list'
      if (zoneId) 
        url = url + `?zone_id=${zoneId}`

      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        null,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getThemeList(levelId, zoneId) {
    try {
      let url = `course-map/theme-list?level_id=${levelId}`
      if (zoneId)
        url = url + `&zone_id=${zoneId}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        null,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }

  async getLessonDetail(contentId, zoneId) {
    try {
      let url = `course-map/lesson-list?content_id=${contentId}`
      if (zoneId)
        url = url + `&zone_id=${zoneId}`
      const responseData = await makeRequest(
        getAPIEndpoint() + url,
        'post',
        null,
        false,
      )
      return Promise.resolve(responseData)
    } catch (err) {
      return Promise.reject(err)
    }
  }
}

export default new CourseMapApi();