import { ActionTypes } from '../types';
import HomePageApi from '../../services/homeApi';

export const landingAction = (nextClassOnly = false) => (dispatch) => {
  return HomePageApi.getLanding(nextClassOnly).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_LANDING_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_LANDING_FAIL,
        payload: 'Get profile fail!'
      })
    }
    return response;
  })
}
export const moduleTotalAction = (data) => (dispatch) => {
  dispatch({
    type: ActionTypes.GET_LANDING_SUCCESS,
    payload: data
  })
}

export const newsFeedAction = (id) => (dispatch) => {
  return HomePageApi.getNewsFeed(id).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_NEWS_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_NEWS_FAIL,
        payload: 'Get news fail!'
      })
    }
  })
}

export const newsFeedLoginAction = () => (dispatch) => {
  return HomePageApi.getNewsFeedLogin().then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_NEWSFEED_LOGIN,
        payload: response.data
      })
    }
    return response;
  })
}

export const updateStatusNewsFeedAction = (data) => (dispatch) => {
  return HomePageApi.showNewsFeed(data).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.UPDATE_STATUS_NEWSFEED,
        payload: response.data
      })
    }
    return response
  })
}
