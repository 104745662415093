import { ActionTypes } from '../types';
import BokkingWindowApi from '../../services/bookingWindowApi';

export const bookingWindowAction = (lessonTimeId) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_BOOKING_WINDOW_START,
  })
  return BokkingWindowApi.getBookingWindow(lessonTimeId).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.ADD_BOOKING_WINDOW_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.ADD_BOOKING_WINDOW_FAIL,
        payload: response.message
      })
    }
    return response;
  })
}

export const bookingClassAction = (id, lessonTimeId) => (dispatch) => {
  dispatch({
    type: ActionTypes.ADD_BOOKING_CLASS_START,
  })
  return BokkingWindowApi.getBookingClass(id, lessonTimeId).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.ADD_BOOKING_CLASS_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.ADD_BOOKING_CLASS_FAIL,
        payload: 'Add booking window fail!'
      })
    }
    return response;
  })
}

export const getRefundCreditAction = (id, lessonTimeId) => (dispatch) => {
  return BokkingWindowApi.getRefundCredit(id, lessonTimeId).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.GET_REFUND_CREDIT_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.GET_REFUND_CREDIT_FAIL,
        payload: 'Get refund credits fail!'
      })
    }
    return response;
  })
}

export const cancelClassAction = (id, lessonTimeId) => (dispatch) => {
  return BokkingWindowApi.cancelClass(id, lessonTimeId).then(response => {
    if (response && response.success) {
      dispatch({
        type: ActionTypes.CANCEL_CLASS_SUCCESS,
        payload: response.data
      })
    } else {
      dispatch({
        type: ActionTypes.CANCEL_CLASS_FAIL,
        payload: 'Add booking window fail!'
      })
    }
    return response;
  })
}

export const getBookingWarningAction = (bookWarningParam) => BokkingWindowApi.getBookingWarning(bookWarningParam);
