import { ActionTypes } from './types';

import AuthService from "../services/auth.service";

export const login = (username, password, isRemember) => (dispatch) => {
    return AuthService.login(username, password, isRemember).then(
        (data) => {
            dispatch({
                type: ActionTypes.LOGIN_SUCCESS,
                payload: {user: data},
            });

            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: ActionTypes.LOGIN_FAIL,
            });

            dispatch({
                type: ActionTypes.SET_MESSAGE,
                payload: 'Login failed!',
            });

            return Promise.reject();
        },
    );
};

export const logout = () => (dispatch) => {
    return AuthService.logout().then(
        () => {
            dispatch({
                type: ActionTypes.LOGOUT,
            });
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type: ActionTypes.SET_MESSAGE,
                payload: 'Logout failed!',
            });

            return Promise.reject();
        },
    );
};

export const sendMailResetPassword = (email) => (dispatch) => {
    return AuthService.resetPassword(email).then(
        (data) => {
            dispatch({
                type: ActionTypes.SEND_MAIL_RESET_PASSWORD_SUCCESS,
                payload: data,
            });

            return Promise.resolve(data);
        },
        (error) => {
            dispatch({
                type: ActionTypes.SEND_MAIL_RESET_PASSWORD_FAIL,
                error: error
            });

            return Promise.reject();
        },
    );
};

export const setAvatar = (url) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_AVATAR,
    payload: url,
  });
};
