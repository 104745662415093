import SvgIcon from '@material-ui/core/SvgIcon';

export default function AlertCircle(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" style={{fill: 'none'}} {...props}>
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 16V16.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
      <path d="M12 8V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
}
