import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSection } from "../../redux/actions/ProfileCapture/profileCaptureAction";

const useLocal = (sections, step, components) => {
  const dispatch = useDispatch();
  const mappingComponents = {
    'Timeslot': 'Timeslot',
    'Centre': 'Centre',
    'Class type': 'ClassType',
  };
  const [orderSections, setOrderSections] = useState([]);
  const [tempMotivationSections, setTempMotivationSections] = useState([])
  const [formValid, setFormValid] = useState(false)
  const [classType, setClassType] = useState(null)
  const [classTime, setClassTime] = useState(null)
  const [timeSlots, setTimeSlots] = useState([])
  const [centres, setCentres] = useState([])
  const [classTypeActive, setClassTypeActive] = useState(false)
  const [timeSlotActive, setTimeSlotActive] = useState(false)
  const [centreActive, setCentreActive] = useState(false)

  const onChooseClassType = (value) => {
    setClassType(value)

    //Redux
    setTempMotivationSections(prevState => (
      {
        ...prevState,
        profile_motivation_statements: { ...prevState.profile_motivation_statements, type: value } 
      }
    ))
    dispatch(updateSection({ 
      type: step,
      data: { 
        ...tempMotivationSections, 
        profile_motivation_statements: {
          ...tempMotivationSections.profile_motivation_statements,
          type: value
        }
      } 
    }))
  }

  const onChooseClassTime = (value) => {
    setClassTime(value)

    //Redux
    setTempMotivationSections(prevState => (
      {
        ...prevState,
        profile_motivation_statements: { ...prevState.profile_motivation_statements, class_time: value } 
      }
    ))
    dispatch(updateSection({ 
      type: step,
      data: { 
        ...tempMotivationSections, 
        profile_motivation_statements: {
          ...tempMotivationSections.profile_motivation_statements,
          class_time: value
        }
      } 
    }))
  }

  const onPickTimeSlots = (value) => {
    const currentTimeSlots = timeSlots
    if (currentTimeSlots.includes(value)) {
      currentTimeSlots.splice(currentTimeSlots.indexOf(value), 1)
    } else {
      currentTimeSlots.push(value)
    }
    setTimeSlots([...currentTimeSlots])

    //Redux
    const data = currentTimeSlots.map(item => {return { id: item } })
    setTempMotivationSections(prevState => (
      {
        ...prevState,
        profile_motivation_statements: { ...prevState.profile_motivation_statements, time_slots: data } 
      }
    ))
    dispatch(updateSection({ 
      type: step,
      data: { 
        ...tempMotivationSections, 
        profile_motivation_statements: {
          ...tempMotivationSections.profile_motivation_statements,
          time_slots: data
        } 
      } 
    }))
  }

  const onChooseCentres = (value) => {
    const currentCentres = centres
    if (currentCentres.includes(value)) {
      currentCentres.splice(currentCentres.indexOf(value), 1)
    } else {
      currentCentres.push(value)
    }
    setCentres([...currentCentres])

    // Redux
    const data = tempMotivationSections.profile_motivation_statements.centres.map(data => {
      if (data.id === value)
        return { ...data, checked: !data.checked }
      return data
    })
    setTempMotivationSections(prevState => (
      {
        ...prevState,
        profile_motivation_statements: { ...prevState.profile_motivation_statements, centres: data } 
      }
    ))
    dispatch(updateSection({ 
      type: step,
      data: { 
        ...tempMotivationSections, 
        profile_motivation_statements: {
          ...tempMotivationSections.profile_motivation_statements,
          centres: data
        } 
      } 
    }))
  }

  const onChangeText = (value) => {
    if (!value) value = ''
    const data = { ...tempMotivationSections, comment: value }
    setTempMotivationSections(data)
    dispatch(updateSection({ type: step, data: data }))
  }

  const onSelect = (id, value) => {
    const data = tempMotivationSections.profile_motivation_statements.map(data => {
      if (data.id === id)
        return { ...data, checked: value }
      return data
    })
    setTempMotivationSections(prevState => ({ ...prevState, profile_motivation_statements: data }))
    dispatch(updateSection({ type: step, data: { ...tempMotivationSections, profile_motivation_statements: data } }))
  }

  // Validation & data set
  useEffect(() => {
    if (tempMotivationSections.profile_motivation_statements) {
      // Validation
      const valid = (!classTypeActive && !timeSlotActive && !centreActive) || (
        (
          !classTypeActive ||
          (classTypeActive && tempMotivationSections.profile_motivation_statements.type)
        ) &&
        (
          !timeSlotActive ||
          (timeSlotActive && ((tempMotivationSections.profile_motivation_statements.class_time === 'any') || (
            tempMotivationSections.profile_motivation_statements.class_time === 'specific' &&
            tempMotivationSections.profile_motivation_statements.time_slots?.length > 0 ))
          )
        ) && (
          !centreActive || (
            centreActive && tempMotivationSections.profile_motivation_statements.centres.filter(item => item.checked).length > 0
          )
        )
      )
      if (valid) setFormValid(true)
      else setFormValid(false)

      // Data set
      if (tempMotivationSections.profile_motivation_statements.type) {
        setClassType(tempMotivationSections.profile_motivation_statements.type)
      }
      if (tempMotivationSections.profile_motivation_statements.class_time) {
        setClassTime(tempMotivationSections.profile_motivation_statements.class_time)
      }
      if (tempMotivationSections.profile_motivation_statements.time_slots) {
        const data = tempMotivationSections.profile_motivation_statements.time_slots.map(item => item.id)
        setTimeSlots(data)
      }
      if (tempMotivationSections.profile_motivation_statements.centres) {
        const data = tempMotivationSections.profile_motivation_statements.centres.filter(item => item.checked)
          .map(item => item.id)
        setCentres(data)
      }
    }
  }, [tempMotivationSections])
  
  return {
    tempMotivationSections, 
    setTempMotivationSections,
    formValid,
    setFormValid,
    classType,
    setClassType,
    classTime,
    setClassTime,
    timeSlots,
    setTimeSlots,
    centres,
    setCentres,
    classTypeActive,
    setClassTypeActive,
    timeSlotActive,
    setTimeSlotActive,
    centreActive,
    setCentreActive,
    onChooseClassType,
    onChooseClassTime,
    onPickTimeSlots,
    onChooseCentres,
    onChangeText,
    onSelect,
  }
}

export default useLocal;