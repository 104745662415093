import iconSliders from '../../../../assets/svg/sliders.svg';
import CustomSelect from '../Common/CustomSelect';
import CircularProgress from '../Common/CircularProgress';

const MobileBookingControl = (props) => {
	const { showFilter, centreList, onChangeOption, selectedCentre } = props;

	const setSelectedOption = (opt) => {
		onChangeOption(opt.id)
	}

  const actualSelectedCentre = (centreList || []).find((centre) => centre.id === selectedCentre) || {};

	const isUpdating = props.isUpdating || false;

  return (
		<div className="mobile-booking__control">
			<span className="mobile-booking__control-title">Centre</span>
		{isUpdating && <CircularProgress size="2.4rem" />}
		{!isUpdating && <>
			<div className="mobile-booking__control-select">
				<CustomSelect
					options={centreList}
					selected={actualSelectedCentre}
					setSelectedOption={setSelectedOption}
				/>
			</div>
			{!isUpdating && <div
				className="mobile-booking__control-toggle"
				onClick={() => { showFilter(true) }}
			>
				<img src={iconSliders} alt="Advanced filters"/>
			</div>}
		</>}
		</div>
	)
}

export default MobileBookingControl;