import { CircularProgress } from '@mui/material';
import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import useProfileCapture from "../../../hooks/ProfileCapture/useProfileCapture";
import { setCentre } from '../../../redux/actions/ProfileCapture/profileCaptureAction';
import ListLanguage from './ListLanguage';
import ModalAction from './ModalAction';
import ModalListCentre from './ModalListCentre';
import ModalPassword from './ModalPassword';

const ListCentre = (props) => {
  const { centreId, dispatch, step, profileId, toggleFullSceen, isFullscreenEnabled, handleChangeLanguage } = props
  
  const centreListDispatch = useSelector(state => state.profilecapture.centreList);
  const translations = useSelector(state => state.profilecapture.translations);
  const { isLoading, centreList, openProfileCapture } = useProfileCapture()
  const [showModalAction, setShowModalAction] = useState(false)
  const { checkValidPassword } = useProfileCapture()
  const [ password, setPassword ] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(true)
  const [showModalListCentre, setShowModalListCentre] = useState(false)
  const [showModalPassword, setShowModalPassword] = useState(false)

  const handleOpenModal = () => setShowModalAction(true)
  const handleHideModal = () => setShowModalAction(false)
  const handleHideModalListCentre = () => setShowModalListCentre(false)

  const onChangePass = (e) => {
    setPassword(e.target.value)
  }
  const handleSubmit = async() => {
    let response = await checkValidPassword(password);
    if(response === true) {
      setShowModalListCentre(true)
      setIsPasswordValid(true)
      handleHideModal()
      // onClose()
    } else{
      setShowModalListCentre(false)
      setIsPasswordValid(false)
    }   
  }
  useEffect(() => {
    openProfileCapture()
  }, [])

  return (
    <>
      {isLoading && <CircularProgress />}
      {!isLoading && <div className="select-area">
        {centreList && centreList.length > 0 &&
        <>
          <div className="wrapper-fullscreen">
            <div className="centre-name btn-select-options" onClick={handleOpenModal}>
              {centreList.filter(item => item.id === +centreId)[0].code}{profileId ? profileId : ''}
            </div>
            <button className='btn-fullscreen-active btn-select-options btn-fullscreen' onClick={() => toggleFullSceen()}>
              {isFullscreenEnabled ? "Exit full screen" : "Full screen"}
            </button>
            {/* <ListLanguage></ListLanguage> */}
          </div>
          {step === 'welcome' ?
          <div>
            <ModalPassword
              profileId={profileId}
              onClose={handleHideModal}
              open={showModalAction}
              handleSubmit={handleSubmit}
              onChangePass={onChangePass}
              isPasswordValid = {isPasswordValid}
              dispatch={dispatch}
              step={step}
            />
            <ModalListCentre 
              profileId={profileId}
              onClose={handleHideModalListCentre}
              open={showModalListCentre}
              dispatch={dispatch}
              step={step}
            />
          </div>
          :
          <div>
            <ModalAction
              profileId={profileId}
              onClose={handleHideModalListCentre}
              open={showModalListCentre}
              dispatch={dispatch}
              step={step}
              translations={translations}
            />
            <ModalPassword
              profileId={profileId}
              onClose={handleHideModal}
              open={showModalAction}
              handleSubmit={handleSubmit}
              onChangePass={onChangePass}
              isPasswordValid = {isPasswordValid}
              dispatch={dispatch}
              step={step}
            />
          </div>
          }
        </>
        }
      </div>}
    </>
  )
}
export default ListCentre