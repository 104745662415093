import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useCalendarCard = () => {
  const [bookingCardList, setBookingCardList] = useState([]);
  const [startDayActive, setStartayActive] = useState(0);

  const bookingCard = useSelector(state => state.calendar.bookingCard);
  const dateOfWeek = useSelector(state => state.calendar.filter.dateOfWeek);

  useEffect(() => {
    let arr = [];
    const objKeys = Object.keys(bookingCard).sort();
    if (objKeys && objKeys.length > 0) {
      objKeys.map(item => {
        if (item) {
          arr = [...arr, ...bookingCard[item]?.class?.map(data => data?.startTime) ?? []]
        }
        return item;
      })
      arr = [...new Set(arr)].sort();
      arr = arr.map(item => {
        const customBookingCard = objKeys.map((objKey) => {
          const data = bookingCard[objKey]?.class?.filter(card => {
            if (card?.startTime === item) {
              return card
            }
            return ''
          });
          return data;
        })
        return {
          time: item,
          ...customBookingCard
        }
      });
    }

    setStartayActive(0);
    setBookingCardList(arr);
  }, [bookingCard]);

  return {
    data: bookingCardList,
    bookingCard,
    dateOfWeek,
    setStartayActive,
    startDayActive
  }
}

export default useCalendarCard;